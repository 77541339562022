import { Box, Flex, Spinner, Text } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { unstable_usePrompt } from "react-router-dom"

import { AutoUpdatingRelativeTime } from "Components/comment/AutoUpdatingRelativeTime"
import { useBeforeUnload } from "UsabilityHub/hooks/useBeforeUnload"

const STUDY_STATES = {
  saved: {
    color: "green.400",
    message: "All changes saved",
    indicator: (lastSavedAt: Date) => (
      <AutoUpdatingRelativeTime
        textColor="text.secondary"
        date={lastSavedAt.toISOString()}
      />
    ),
  },
  saving: {
    color: "orange.400",
    message: "Saving...",
    indicator: () => <Spinner size="xs" />,
  },
  invalid: {
    color: "red.500",
    message: "You have unsaved changes",
    indicator: () => null,
  },
}

type Props = {
  isAnyDirty: boolean
  isAnySubmitting: boolean
}

export const ModeratedStudySavingIndicator: React.FC<Props> = ({
  isAnyDirty,
  isAnySubmitting,
}) => {
  const [lastSavedAt, setLastSavedAt] = React.useState<Date>(() => new Date())

  const state = isAnySubmitting ? "saving" : isAnyDirty ? "invalid" : "saved"
  const { color, message, indicator } = STUDY_STATES[state]

  // Show a browser alert if the user tries to close the tab with unsaved changes
  useBeforeUnload(state !== "saved")
  // And also if they try to route to another page
  unstable_usePrompt({
    when: state !== "saved",
    message: `You${"\u2019"}ve made changes to this study that haven${"\u2019"}t been saved. Are you sure you want to leave without saving?`,
  })

  useEffect(() => {
    if (state === "saved") {
      setLastSavedAt(new Date())
    }
  }, [state])

  return (
    <Flex align="center">
      <Box rounded="full" boxSize={2} bg={color} mr={2} />
      <Text flexGrow={1}>{message}</Text>
      {indicator(lastSavedAt)}
    </Flex>
  )
}

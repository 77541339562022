import { useFilterOptions } from "UsabilityHub/hooks/useFilterOptions"
import { CreatableSelect } from "chakra-react-select"
import { throttle } from "lodash"
import { ComponentProps, useCallback, useState } from "react"
import React from "react"

import { NewProject } from "~/api/generated/usabilityhubSchemas"

type Props = {
  value: string | null
  onChange: (projectId: string | null) => void
} & ComponentProps<typeof CreatableSelect>

export const ProjectSelector: React.FC<Props> = ({
  value,
  onChange,
  ...props
}) => {
  const [search, setSearch] = useState("")
  const throttledSetSearch = useCallback(throttle(setSearch, 800), [])

  const projectOptions = useFilterOptions<NewProject>({
    filter: "projects",
    pagination: true,
    search,
    sortBy: "name",
    sortDirection: "asc",
  })

  const allProjects = projectOptions?.allOptions ?? []
  const options = allProjects.map((p, index) => ({
    label:
      projectOptions?.loaderRef !== undefined &&
      index === allProjects.length - 1 ? (
        <div>
          {p.name}
          <div ref={projectOptions?.loaderRef} />
        </div>
      ) : (
        p.name
      ),
    value: normalizeUuid(p.id),
  }))

  const projectsLoading =
    projectOptions === undefined ||
    projectOptions.isFetching ||
    projectOptions.isFetchingNextPage

  return (
    <CreatableSelect
      chakraStyles={{
        option: (provided, state) => {
          return {
            ...provided,
            bgColor:
              state.isFocused && state.isSelected
                ? "var(--chakra-colors-brand-primary-600)"
                : state.isSelected
                  ? "var(--chakra-colors-brand-primary-500)"
                  : provided.background,
          }
        },
      }}
      placeholder="Search for or create a project"
      options={options}
      value={options.find((o) => o.value === normalizeUuid(value))}
      isLoading={projectsLoading}
      onChange={(proj) =>
        onChange(proj ? (proj as (typeof options)[number]).value : null)
      }
      onInputChange={throttledSetSearch}
      {...props}
    />
  )
}

const normalizeUuid = (uuid: string | null) =>
  uuid?.replaceAll(/[^0-9a-f]+/gi, "") ?? null

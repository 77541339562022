import { GridItem } from "@chakra-ui/react"
import React from "react"
import { ExportCSVButton } from "./ExportCSVButton"
import { RateResponsesButton } from "./RateResponsesButton"
import { ShareViaLinkButton } from "./ShareViaLinkButton"
import { SummaryActionsGrid } from "./SummaryActionsGrid"

export const SummaryActions: React.FC<{
  handleShareClick: () => void
  handleRateClick: () => void
  isRatingFormVisible: boolean
  hasRatedAlready: boolean
  usabilityTestUniqueId: string
  usabilityTestPrivateId: string
  filteredResponseIds: number[]
  accountCanExportCsv: boolean
  showRatingButton: boolean
  isRatingButtonDisabled: boolean
}> = ({
  handleShareClick,
  handleRateClick,
  isRatingFormVisible,
  hasRatedAlready,
  usabilityTestUniqueId,
  usabilityTestPrivateId,
  filteredResponseIds,
  accountCanExportCsv,
  showRatingButton,
  isRatingButtonDisabled,
}) => (
  <SummaryActionsGrid>
    <GridItem display="grid">
      <ShareViaLinkButton onClick={handleShareClick} />
    </GridItem>
    <GridItem display="grid">
      <ExportCSVButton
        usabilityTestPrivateId={usabilityTestPrivateId}
        usabilityTestUniqueId={usabilityTestUniqueId}
        filteredResponseIds={filteredResponseIds}
        accountCanExportCsv={accountCanExportCsv}
      />
    </GridItem>
    {showRatingButton && (
      <GridItem display="grid">
        <RateResponsesButton
          hasRatedAlready={hasRatedAlready}
          onClick={handleRateClick}
          isDisabled={isRatingButtonDisabled}
          isRatingFormVisible={isRatingFormVisible}
        />
      </GridItem>
    )}
  </SummaryActionsGrid>
)

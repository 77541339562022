import { Text } from "@chakra-ui/react"
import { isEmpty, size } from "lodash"
import React, { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { compose } from "redux"
import {
  ConfigProps,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from "redux-form"

import { SubmitButton } from "Components/button/submit-button"
import { ThemedButton } from "Components/button/themed-button"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { State } from "Redux/app-store"
import { usabilityTestSectionScreenshotsSelector } from "Redux/reducers/screenshots/selectors"
import { useTranslate } from "Shared/hooks/useTranslate"
import {
  Omit,
  ResponseAnswer,
  UsabilityTestSection,
  UsabilityTestSectionQuestion,
} from "Types"
import styles from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout.module.scss"
import {
  Body,
  Container,
  Footer,
  Header,
  Instructions,
  SubTitle,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"
import { isScreenshotPlayable } from "Utilities/screenshot"
import { isDesignQuestionsSection } from "Utilities/usability-test-section"
import { isMultipleAnswer } from "Utilities/usability-test-section-question"
import { isBlank } from "Utilities/values"

type ImplProps = Props & InjectedFormProps<ResponseAnswer, Props>

const AnswerFormImpl: React.FC<React.PropsWithChildren<ImplProps>> = ({
  children,
  form,
  handleSubmit,
  instructions,
  question,
  section,
  onAnswerSubmit,
  willShowInInstructionsModal = false,
}) => {
  const translate = useTranslate()

  // Suppress warnings here because redux-form loves an `any` return type and typescript hates it.
  const selector = formValueSelector(form)
  const answers = useSelector((state: State) => selector(state, "answers"))
  const answer = useSelector((state: State) => selector(state, "answer"))
  const screenshots = useSelector((state: State) =>
    usabilityTestSectionScreenshotsSelector(state, section)
  )

  const [mountTime, setMountTime] = useState<number>(0)
  const [isContinueDisabled, setIsContinueDisabled] = useState(true)

  // Record the mount time (incl. render time) so we can record how long the task took.
  useEffect(() => {
    setMountTime(performance.now())
  }, [])

  // Briefly make the button disabled on load so it's not accidentally clicked.
  useEffect(() => {
    const DoubleClickTime = 800
    const timeoutId = window.setTimeout(() => {
      setIsContinueDisabled(false)
    }, DoubleClickTime)

    // Clean up
    return () => window.clearTimeout(timeoutId)
  }, [])

  const isAnswerBlank = isMultipleAnswer(question.type)
    ? isEmpty(answers)
    : isBlank(answer)
  const hasEnoughOptionsSelected =
    isMultipleAnswer(question.type) && question.min_selected_options !== null
      ? size(answers) >= question.min_selected_options
      : true

  let playableScreenshot = null
  if (isDesignQuestionsSection(section)) {
    // Design questions have *exactly* 1 screenshot
    const screenshot = screenshots[0]
    if (isScreenshotPlayable(screenshot)) {
      playableScreenshot = screenshot
    }
  }

  const onSubmit = (values: Pick<ResponseAnswer, "answers" | "answer">) => {
    onAnswerSubmit({
      duration_ms: performance.now() - mountTime,
      usability_test_section_question_id: question.id,
      answer_tags: [],
      ...values,
    })
  }

  const isPlayingScreenshot = playableScreenshot
    ? !playableScreenshot._isViewed
    : false
  const isPass = isAnswerBlank && !question.required
  return (
    <form
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
      onSubmit={handleSubmit(onSubmit)}
      className={
        !willShowInInstructionsModal
          ? styles.panelForm
          : styles.instructionPanelForm
      }
      autoComplete="off"
    >
      <Container>
        <Header>
          <SubTitle>
            <DisplayBlockMarkdownText text={question.text} />
          </SubTitle>
          {instructions && <Instructions>{instructions}</Instructions>}
        </Header>
        <Body>{children}</Body>
        <Footer>
          {isPass && (
            <SubmitButton
              colorScheme="gray"
              isDisabled={isPlayingScreenshot || isContinueDisabled}
            >
              {translate("test.buttons.pass")}
            </SubmitButton>
          )}
          {!isPass && (
            <ThemedButton
              isDisabled={
                isPlayingScreenshot ||
                !hasEnoughOptionsSelected ||
                isContinueDisabled ||
                isAnswerBlank
              }
              type="submit"
            >
              {translate("test.buttons.continue")}
            </ThemedButton>
          )}
          {playableScreenshot && isPlayingScreenshot && (
            <Text ml={3}>
              {translate(
                `test.screenshots.${playableScreenshot.media_type}.unfinished`
              )}
            </Text>
          )}
        </Footer>
      </Container>
    </form>
  )
}

export interface Props
  extends Omit<ConfigProps<ResponseAnswer>, "form" | "onSubmit"> {
  readonly instructions?: string
  readonly section: Readonly<UsabilityTestSection>
  readonly question: Readonly<UsabilityTestSectionQuestion>
  readonly onAnswerSubmit: (
    answer: Omit<ResponseAnswer, "id" | "response_id">
  ) => void
  readonly willShowInInstructionsModal?: boolean
}

export const AnswerForm: React.FC<React.PropsWithChildren<Props>> = compose(
  // NOTE: We previously specified the return type of `connect` as `ConfigProps`.
  // This caused an extreme performance regression when running `tsc` (omitting it
  // reduced the time by ~85%) for unknown reasons, so we've opted to just have
  // Typescript figure out an implicit return type. Given we're just returning
  // `{ form: string }` this is pretty unlikely to break compatibility in future.
  // See: https://gitlab.com/usabilityhub/app/-/merge_requests/10974
  connect((_initialState: State, { question }: Props) => {
    return { form: `answer-${question.id}` }
  }),
  reduxForm({})
)(AnswerFormImpl)

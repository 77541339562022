import { Flex, Tooltip } from "@chakra-ui/react"
import React, { FC, useMemo } from "react"
import { Link } from "react-router-dom"

import { Alert, Button } from "DesignSystem/components"
import { useFeatureFlag } from "Hooks/use-feature-flag"
import { PanelRecruitmentSummary } from "UsabilityHub/components/PanelRecruitmentSummary"
import { RecruitMethodCard } from "UsabilityHub/components/RecruitMethodSelection/RecruitMethodSelection"
import { RecruitPageUsabilityTest } from "UsabilityHub/hooks/useUsabilityTestRecruit"
import { ROUTES } from "UsabilityHub/views/routes"
import { useGetExperiments } from "~/api/generated/usabilityhub-components"

interface Props {
  readonly isRecruitmentAllowed: boolean
  readonly showTestContainsLinkAlert: boolean
  readonly usabilityTest: Pick<
    RecruitPageUsabilityTest,
    "admin_disabled" | "test_recording_types"
  >
}

export const PanelRecruitmentMethod: FC<React.PropsWithChildren<Props>> = ({
  isRecruitmentAllowed,
  showTestContainsLinkAlert,
  usabilityTest,
}) => {
  const { isLoading, data: experiments } = useGetExperiments({})

  const newSummary = experiments?.panel_order_discount === "banner"

  const ctaText = newSummary ? "Find participants" : "Place an order"

  // TODO: once out of beta, remove this check and allow recordings tests to be recruited.
  // Meanwhile we probably should remove `test_recording_types` from the `api/usability_tests#recruit` action response.
  const hasTestRecordingTypes = useMemo(
    () => usabilityTest.test_recording_types.length > 0,
    [usabilityTest.test_recording_types]
  )
  const ordersWithRecordingsEnabled = useFeatureFlag(
    "usability_test_recording_orders"
  )
  const hasDisabledTestRecording =
    hasTestRecordingTypes && !ordersWithRecordingsEnabled

  const isOrderPlacementDisabled =
    !isRecruitmentAllowed ||
    showTestContainsLinkAlert ||
    usabilityTest.admin_disabled ||
    hasDisabledTestRecording

  if (isLoading) {
    return null
  }

  return (
    <RecruitMethodCard
      method="panel"
      bodyContent={
        <Flex flexDirection="column" gap={6} flexGrow="1">
          <PanelRecruitmentSummary />
          {showTestContainsLinkAlert && (
            <Alert
              status="warning"
              description="Tests that include external URLs in instructions are ineligible for
        panel recruitment. To enable panel recruitment please remove the URL
        from this test."
            ></Alert>
          )}
        </Flex>
      }
      action={
        isOrderPlacementDisabled ? (
          // TODO: post-beta, remove this tooltip wrapper
          <DisabledOrderButtonTooltip
            showRecordingsTooltip={hasDisabledTestRecording}
          >
            <Button variant="primary" size="emphasized" isDisabled>
              {ctaText}
            </Button>
          </DisabledOrderButtonTooltip>
        ) : (
          <Button
            as={Link}
            variant="primary"
            size="emphasized"
            to={ROUTES.TEST.RECRUIT.$.ORDERS.relativePath}
            data-intercom-target="place-an-order-button"
          >
            {ctaText}
          </Button>
        )
      }
    />
  )
}

// TODO: once out of beta, remove this component
const DisabledOrderButtonTooltip: FC<
  React.PropsWithChildren<{ showRecordingsTooltip: boolean }>
> = ({ showRecordingsTooltip, children }) =>
  showRecordingsTooltip ? (
    <Tooltip
      hasArrow
      label="During the beta period, panel orders are unable to be placed for tests with recordings"
    >
      {children}
    </Tooltip>
  ) : (
    children
  )

import { Container, Flex, Text } from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Dispatch } from "Redux/app-store"
import { deleteResponse } from "Redux/reducers/current-response/action-creators"
import { CustomerSupportMailtoLink } from "Shared/components/Links/CustomerSupportMailtoLink"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ParticipantDeletionReason } from "Types"
import {
  Body,
  Header,
  Title,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"
import DetectRTC from "detectrtc"
import { without } from "lodash"
import React, { createContext, useEffect, useReducer, useState } from "react"
import { useDispatch } from "react-redux"
import type { RecordingType } from "~/api/generated/usabilityhubSchemas"
import { RecordingRequirements } from "../content-factory/RecordingRequirements"

type UploadRecordingRequests = {
  uploadRequests: number
  finishedRequests: number
  // When any recording of a usability_test_section is started, a false value will be set.
  // When all recordings are finished, the value will be set to true.
  sectionRecordingsFinished: Record<number, boolean>
}

const INITIAL_UPLOAD_RECORDING_REQUESTS: UploadRecordingRequests = {
  uploadRequests: 0,
  finishedRequests: 0,
  sectionRecordingsFinished: {},
}

type UploadRecordingRequestsAction =
  | {
      type: "start-section-recording"
      usabilityTestSectionId: number
    }
  | {
      type: "finish-section-recording"
      usabilityTestSectionId: number
    }
  | {
      type: "make-upload-request"
    }
  | {
      type: "finish-upload-request"
    }

const uploadRecordingRequestsChangeReducer = (
  state: UploadRecordingRequests,
  action: UploadRecordingRequestsAction
): UploadRecordingRequests => {
  switch (action.type) {
    case "start-section-recording": {
      const sectionRecordingsFinished = {
        ...state.sectionRecordingsFinished,
        [action.usabilityTestSectionId]: false,
      }
      return { ...state, sectionRecordingsFinished }
    }
    case "finish-section-recording": {
      const sectionRecordingsFinished = {
        ...state.sectionRecordingsFinished,
        [action.usabilityTestSectionId]: true,
      }
      return { ...state, sectionRecordingsFinished }
    }
    case "make-upload-request": {
      return { ...state, uploadRequests: state.uploadRequests + 1 }
    }
    case "finish-upload-request": {
      return { ...state, finishedRequests: state.finishedRequests + 1 }
    }
    default: {
      return state
    }
  }
}

type TestRecordingType = {
  recordingEnabled: boolean
  allowedRecordingTypes: RecordingType[]
  audioDeviceId?: string
  setAudioDeviceId?: (deviceId: string) => void
  videoDeviceId?: string
  setVideoDeviceId?: (deviceId: string) => void
  // screenStream will last for the entire test session when it's requested
  screenStream?: MediaStream
  setScreenStream?: (stream: MediaStream | undefined) => void
  setError: (
    value: React.SetStateAction<ParticipantDeletionReason | undefined>
  ) => void
  areAllRecordingsUploaded: boolean
  dispatchUploadRecordingRequests: React.Dispatch<UploadRecordingRequestsAction>
  cleanupScreenStream: () => void
  recordingSetupGuideFinished: boolean
  setRecordingSetupGuideFinished: (finished: boolean) => void
}

const TestRecordingContext = createContext<TestRecordingType>({
  recordingEnabled: false,
  allowedRecordingTypes: [],
  setError: () => {
    throw new Error("setError must be overridden")
  },
  areAllRecordingsUploaded: false,
  dispatchUploadRecordingRequests: () => {
    throw new Error("dispatchUploadRecordingRequests must be overridden")
  },
  cleanupScreenStream: () => {
    throw new Error("cleanupScreenStream must be overridden")
  },
  recordingSetupGuideFinished: false,
  setRecordingSetupGuideFinished: () => {
    throw new Error("setSetupGuideFinished must be overridden")
  },
})

type TestRecordingProps = {
  isPreview: boolean
  recordingTypes: RecordingType[]
}

export const TestRecordingContextProvider: React.FC<
  React.PropsWithChildren<TestRecordingProps>
> = ({ isPreview, recordingTypes, children }) => {
  const dispatch = useDispatch<Dispatch>()

  const translate = useTranslate()
  const [audioDeviceId, setAudioDeviceId] = useState<string | undefined>()
  const [videoDeviceId, setVideoDeviceId] = useState<string | undefined>()
  const [screenStream, setScreenStream] = useState<MediaStream | undefined>()
  const [uploadRecordingRequests, dispatchUploadRecordingRequests] = useReducer(
    uploadRecordingRequestsChangeReducer,
    INITIAL_UPLOAD_RECORDING_REQUESTS
  )
  const [error, setError] = useState<ParticipantDeletionReason | undefined>()

  const allowedRecordingTypes = recordingTypes
    ? DetectRTC.isMobileDevice
      ? without(recordingTypes, "screen")
      : recordingTypes
    : []

  const recordingEnabled = !!(!isPreview && allowedRecordingTypes.length > 0)

  const [recordingSetupGuideFinished, setRecordingSetupGuideFinished] =
    useState(!recordingEnabled)

  const cleanupScreenStream = () =>
    screenStream && screenStream.getTracks().forEach((track) => track.stop())

  const isDeviceUnsupported =
    !DetectRTC.isWebRTCSupported || typeof window.MediaRecorder !== "function"

  useEffect(() => {
    if (recordingEnabled && error) {
      // When the stream is stopped, the corresponding recorder will be stopped automatically
      cleanupScreenStream()

      void dispatch(deleteResponse(error))
    }
  }, [recordingEnabled, error])

  if (recordingEnabled && isDeviceUnsupported) {
    return (
      <Container h="100%">
        <Flex
          h="100%"
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Header>
            <Title>Browser not supported</Title>
          </Header>
          <Body>
            <Text>
              Switch to a browser that supports the following recording
              requirements:
            </Text>
            <Flex gap={6} flexFlow="column" my={8}>
              <RecordingRequirements
                allowedRecordingTypes={allowedRecordingTypes}
              />
            </Flex>
            <ExternalLink
              href={Constants.HELP_CENTER_TEST_RECORDINGS_PARTICIPANT_URL}
              color="ds.text.default"
              textDecoration="none"
              fontSize={14}
              mb={8}
              px={4}
              py={2}
              borderRadius="md"
              bgColor="ds.background.neutral.resting"
              alignItems="center"
            >
              Learn more about supported browsers
            </ExternalLink>
          </Body>
        </Flex>
      </Container>
    )
  }

  if (recordingEnabled && error) {
    const errorTitle = translate(
      error === ParticipantDeletionReason.RecordingUploadFailed
        ? "test.recording.error.heading"
        : "test.recording.recording_disabled.heading"
    )

    const errorDescription =
      error === ParticipantDeletionReason.RecordingUploadFailed ? (
        <>
          {translate("test.recording.error.body") + " "}
          <CustomerSupportMailtoLink>
            {translate("test.recording.error.support")}
          </CustomerSupportMailtoLink>
        </>
      ) : (
        translate("test.recording.recording_disabled.body")
      )

    // This error can be set from SectionRecordingContext or the recording setup guide
    return (
      <Container h="100%">
        <Flex
          h="100%"
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Header>
            <Title>{errorTitle}</Title>
          </Header>
          <Body>
            <Text>{errorDescription}</Text>
          </Body>
        </Flex>
      </Container>
    )
  }

  const areAllRecordingsUploaded =
    !recordingEnabled ||
    (Object.values(uploadRecordingRequests.sectionRecordingsFinished).every(
      (sectionFinished) => sectionFinished
    ) &&
      uploadRecordingRequests.uploadRequests ===
        uploadRecordingRequests.finishedRequests)

  return (
    <TestRecordingContext.Provider
      value={{
        recordingEnabled,
        allowedRecordingTypes,
        audioDeviceId,
        setAudioDeviceId,
        videoDeviceId,
        setVideoDeviceId,
        screenStream,
        setScreenStream,
        setError,
        areAllRecordingsUploaded,
        dispatchUploadRecordingRequests,
        cleanupScreenStream,
        recordingSetupGuideFinished,
        setRecordingSetupGuideFinished,
      }}
    >
      {children}
    </TestRecordingContext.Provider>
  )
}

export const useTestRecordingContext = () => {
  const context = React.useContext(TestRecordingContext)

  if (context === null) {
    throw new Error(
      `useTestRecordingContext must be rendered within the TestRecordingContextProvider`
    )
  }

  return context
}

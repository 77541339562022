import { UsabilityTest } from "Types"
import { useFilterOptions } from "UsabilityHub/hooks/useFilterOptions"
import { Select } from "chakra-react-select"
import { throttle } from "lodash"
import { ComponentProps, useCallback, useState } from "react"
import React from "react"

type Props = {
  value: number | null
  onChange: (testId: number | null) => void
  excludedTestIds: number[]
} & ComponentProps<typeof Select>

export const UsabilityTestSelector: React.FC<Props> = ({
  value,
  onChange,
  excludedTestIds,
  ...props
}) => {
  const [search, setSearch] = useState("")
  const throttledSetSearch = useCallback(throttle(setSearch, 800), [])

  const usabilityTestOptions = useFilterOptions<UsabilityTest>({
    filter: "usability_tests",
    pagination: true,
    search,
  })

  const allUsabilityTests = usabilityTestOptions?.allOptions ?? []

  const visibleUsabilityTests = allUsabilityTests.filter(
    (t) => !excludedTestIds.includes(t.id)
  )
  const options = visibleUsabilityTests.map((u, index) => ({
    label:
      usabilityTestOptions?.loaderRef !== undefined &&
      index === visibleUsabilityTests.length - 1 ? (
        <div>
          {u.name}
          <div ref={usabilityTestOptions?.loaderRef} />
        </div>
      ) : (
        u.name
      ),
    value: u.id,
  }))

  const usabilityTestsLoading =
    usabilityTestOptions === undefined ||
    usabilityTestOptions.isFetching ||
    usabilityTestOptions.isFetchingNextPage

  return (
    <Select
      chakraStyles={{
        option: (provided, state) => {
          return {
            ...provided,
            bgColor:
              state.isFocused && state.isSelected
                ? "var(--chakra-colors-brand-primary-600)"
                : state.isSelected
                  ? "var(--chakra-colors-brand-primary-500)"
                  : provided.background,
          }
        },
      }}
      placeholder="Select a test"
      options={options}
      value={options.find((o) => o.value === value) ?? null}
      isLoading={usabilityTestsLoading}
      onChange={(test) =>
        onChange(test ? (test as (typeof options)[number]).value : null)
      }
      onInputChange={throttledSetSearch}
      {...props}
    />
  )
}

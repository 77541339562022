import { useToast } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useState } from "react"

import { useGetRecruitmentLink } from "~/api/generated/usabilityhub-components"
import type { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"

type Return = [
  RecruitmentLink | null,
  Dispatch<SetStateAction<RecruitmentLink | null>>,
]

export const useUsabilityTestRecruitLink = (uniqueId: string): Return =>
  useRecruitLink(uniqueId, false)

export const useTestSetRecruitLink = (uniqueId: string): Return =>
  useRecruitLink(uniqueId, true)

const useRecruitLink = (uniqueId: string, isTestSetLink: boolean): Return => {
  const toast = useToast()

  const { data } = useGetRecruitmentLink(
    {
      queryParams: {
        [isTestSetLink ? "test_set_unique_id" : "usability_test_unique_id"]:
          uniqueId,
      },
    },
    {
      onError: () => {
        toast({
          title: "Unable to load recruitment link modal",
          status: "error",
        })
      },
    }
  )

  const [recruitmentLink, setRecruitmentLink] =
    useState<RecruitmentLink | null>(null)

  useEffect(() => {
    if (!data) return

    setRecruitmentLink(data)
  }, [data])

  return [recruitmentLink, setRecruitmentLink]
}

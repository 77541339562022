import React, { ComponentType, useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Dispatch } from "Redux/app-store"

import {
  ResponseSection,
  UsabilityTestSectionType as SectionType,
  UnpersistedScreenshotClick,
} from "Types"

import {
  addResponseSectionRecording,
  addScreenshotClick,
  startResponseSection,
  updateResponseSection,
} from "Redux/reducers/current-response/action-creators"
import { useDeviceFramesForSectionContext } from "UsabilityHub/contexts"
import {
  SectionRecordingContext,
  SectionRecordingContextProvider,
} from "../UsabilityTest/context/SectionRecordingContext"
import { CardSortTask } from "./SectionTasks/CardSortTask"
import { DesignQuestionsTask } from "./SectionTasks/DesignQuestionsTask"
import { FirstClickTestTask } from "./SectionTasks/FirstClickTestTask"
import { FiveSecondTestTask } from "./SectionTasks/FiveSecondTestTask"
import { InformationTask } from "./SectionTasks/InformationTask"
import { NavigationTestTask } from "./SectionTasks/NavigationTestTask"
import { PreferenceTestTask } from "./SectionTasks/PreferenceTestTask/PreferenceTestTask"
import { PrototypeTask } from "./SectionTasks/PrototypeTask/PrototypeTask"
import { QuestionsTask } from "./SectionTasks/QuestionsTask"
import { TreeTestTask } from "./SectionTasks/TreeTestTask"
import { InnerProps, OuterProps } from "./props"

function getTaskComponent(
  type: SectionType
): ComponentType<React.PropsWithChildren<InnerProps>> {
  switch (type) {
    case SectionType.CardSort:
      return CardSortTask
    case SectionType.DesignQuestions:
      return DesignQuestionsTask
    case SectionType.FirstClickTest:
      return FirstClickTestTask
    case SectionType.FiveSecondTest:
      return FiveSecondTestTask
    case SectionType.Information:
      return InformationTask
    case SectionType.NavigationTest:
      return NavigationTestTask
    case SectionType.PreferenceTest:
      return PreferenceTestTask
    case SectionType.Questions:
      return QuestionsTask
    case SectionType.PrototypeTask:
      return PrototypeTask
    case SectionType.TreeTest:
      return TreeTestTask
  }
}

export const UsabilityTestSectionTask: React.FC<OuterProps> = (
  props: OuterProps
) => {
  const { usabilityTestSection } = props
  const dispatch = useDispatch<Dispatch>()
  const [started, setStarted] = useState(false)

  useEffect(() => {
    dispatch(startResponseSection(usabilityTestSection.id))
    setStarted(true)
  }, [])

  const dispatchAddResponseSectionRecording = useCallback(
    (usabilityTestSectionId: number, recordingId: string) => {
      dispatch(addResponseSectionRecording(usabilityTestSectionId, recordingId))
    },
    [dispatch, addResponseSectionRecording]
  )

  const dispatchUpdateResponseSection = useCallback(
    (
      usabilityTestSectionId: number,
      payload: Partial<Omit<ResponseSection, "usability_test_section_id">>
    ) => {
      dispatch(updateResponseSection(usabilityTestSectionId, payload))
    },
    [dispatch, updateResponseSection]
  )

  const dispatchAddScreenshotClick = useCallback(
    (payload: UnpersistedScreenshotClick) => {
      dispatch(addScreenshotClick(payload))
    },
    [dispatch, addScreenshotClick]
  )

  const deviceFrame = useDeviceFramesForSectionContext(usabilityTestSection)!

  const TaskComponent = getTaskComponent(usabilityTestSection.type)

  return (
    started && (
      <SectionRecordingContextProvider
        usabilityTestSection={props.usabilityTestSection}
        responseSection={props.responseSection}
        updateResponseSection={dispatchUpdateResponseSection}
        addResponseSectionRecording={dispatchAddResponseSectionRecording}
      >
        <SectionRecordingContext.Consumer>
          {({ updateResponseSection }) => (
            <TaskComponent
              {...props}
              addScreenshotClick={dispatchAddScreenshotClick}
              updateResponseSection={updateResponseSection}
              deviceFrame={deviceFrame}
            />
          )}
        </SectionRecordingContext.Consumer>
      </SectionRecordingContextProvider>
    )
  )
}

export default UsabilityTestSectionTask

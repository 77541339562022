import { Box } from "@chakra-ui/react"
import { useFeatureFlag } from "Hooks/use-feature-flag"
import { useLanguage } from "Hooks/use-language"
import { DEVICE_TYPES } from "Utilities/device-types"
import React from "react"
import { Order, RecordingType } from "~/api/generated/usabilityhubSchemas"
import { DemographicTargetSummary } from "./DemographicTargetSummary"
import { DemographicTargetSummaryTableRow } from "./DemographicTargetSummaryTableRow"
import { PanelOrThirdPartyTestOrder, isPanelTestOrder } from "./orderTypes"

export const TestOrderSummaryCardExpandedSection: React.FC<{
  order: PanelOrThirdPartyTestOrder
  recordingTypes: RecordingType[]
}> = ({ order, recordingTypes }) => (
  <Box>
    <DemographicTargetSummary
      ageRange={[
        order.demographic_target?.min_age ?? null,
        order.demographic_target?.max_age ?? null,
      ]}
      countries={
        order.demographic_target?.locations?.length === 0
          ? order.demographic_target?.countries && []
          : []
      }
      granularLocations={{
        scope: "usability_tests",
        targetLocations: order.demographic_target?.locations ?? [],
      }}
      activeDemographicAttributes={
        order.demographic_target?.demographic_attributes ?? []
      }
    >
      <TestLanguageRow
        languageCode={order.usability_test_language_code ?? ""}
      />
      <TestPermittedDeviceTypeRow
        permittedDeviceType={order.usability_test_permitted_device_type}
      />
      <TestRecordingRow recordingTypes={recordingTypes} />
      <TestScreenerRow
        screenerEnabled={isPanelTestOrder(order) && "screener" in order}
      />
    </DemographicTargetSummary>
  </Box>
)

const TestPermittedDeviceTypeRow: React.FC<{
  permittedDeviceType: Order["usability_test_permitted_device_type"]
}> = (props) => (
  <DemographicTargetSummaryTableRow
    heading="Devices"
    value={DEVICE_TYPES[props.permittedDeviceType]}
  />
)

const TestScreenerRow: React.FC<{ screenerEnabled: boolean }> = (props) => (
  <DemographicTargetSummaryTableRow
    heading="Screener"
    value={props.screenerEnabled ? "Enabled" : "Disabled"}
  />
)

const TestLanguageRow: React.FC<{ languageCode: string }> = (props) => (
  <DemographicTargetSummaryTableRow
    heading="Language"
    value={useLanguage(props.languageCode)?.display_string ?? "Default"}
  />
)

const TestRecordingRow: React.FC<{ recordingTypes: RecordingType[] }> = ({
  recordingTypes,
}) => {
  const featureFlagEnabled = useFeatureFlag("usability_test_recording_orders")
  if (!featureFlagEnabled) return null

  const recordingTypesToText = (recordingTypes: RecordingType[]) => {
    if (recordingTypes.length < 1) return "None"

    const result = []
    if (recordingTypes.includes("microphone")) {
      result.push("Think-aloud")
    }
    if (recordingTypes.includes("screen")) {
      result.push("Screen share")
    }
    return result.join(", ")
  }

  const value = recordingTypesToText(recordingTypes)
  return <DemographicTargetSummaryTableRow heading="Recording" value={value} />
}

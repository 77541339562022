import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react"
import React, { useState } from "react"

import { Language, Option, ParticipantDeletionReason as Reason } from "Types"
import { memoizeOnce } from "Utilities/memoization"

import { defaultValueToKey } from "../form/default-value-to-key"

const optionsForLanguage = memoizeOnce(
  (language: Language): ReadonlyArray<Option<Reason>> => {
    return [
      {
        value: Reason.IncorrectProfileLanguage,
        label: `I don${"\u2019"}t speak ${language.english_name}`,
      },
      {
        value: Reason.IncorrectTestLanguage,
        label: `This test isn${"\u2019"}t in ${language.english_name}`,
      },
      {
        value: Reason.InappropriateTest,
        label: "This test contains inappropriate content",
      },
      {
        value: Reason.ConfusingTest,
        label: "This test is confusing or poorly worded",
      },
      {
        value: Reason.TechnicalProblem,
        label: "There is a technical problem with this test",
      },
      {
        value: Reason.TestRequestedPersonalInformation,
        label: (
          <>
            This test is requesting my personal information
            <br />
            (e.g. email address)
          </>
        ),
      },
      {
        value: Reason.TestSentMeOffsite,
        label: "This test asked me to complete tasks on another website",
      },
      { value: Reason.Skipped, label: "I just want to skip it" },
    ]
  }
)

interface Props {
  readonly onSkip: (reason: Reason) => Promise<void>
  readonly onClose: () => void
  readonly language: Language
  readonly isOpen: boolean
}

export const SkipUsabilityTestModal: React.FC<
  React.PropsWithChildren<Props>
> = (props) => {
  const [selectedReason, setSelectedReason] = useState<null | Reason>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async () => {
    if (selectedReason !== null) {
      setIsSubmitting(true)
      await props.onSkip(selectedReason)
      setIsSubmitting(false)
      props.onClose()
    }
  }

  const handleSelectReason = (reason: Reason) => {
    setSelectedReason(reason)
  }

  const { onClose, isOpen, language } = props

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="lg">
        <ModalHeader>
          Are you sure you want to skip this test?
          <Text fontSize="md" fontWeight="normal">
            Please select the reason you want to skip it.
          </Text>
        </ModalHeader>
        <ModalBody>
          <RadioGroup
            onChange={handleSelectReason}
            defaultValue={selectedReason ?? ""}
          >
            <Stack>
              {optionsForLanguage(language).map((option) => (
                <Radio
                  key={defaultValueToKey(option.value)}
                  value={option.value}
                >
                  {option.label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="brand.primary"
              isDisabled={selectedReason === null || isSubmitting}
              onClick={handleSubmit}
            >
              {isSubmitting && <Spinner size="xs" />} Skip the test
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

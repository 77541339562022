// contains ScreenerQuestion['type']
// derived from
// https://gitlab.com/usabilityhub/app/-/blob/a50a3ed3023f58952dc84a191afbbbb2c8fa9c9e/db/structure.sql#L539
export const SCREENER_QUESTION_TYPES = [
  "long_text",
  "short_text",
  "single_select",
  "multi_select",
] as const

// contains ScreenerQuestion['option'][0]['rule']
// derived from
// https://gitlab.com/usabilityhub/app/-/blob/a50a3ed3023f58952dc84a191afbbbb2c8fa9c9e/db/structure.sql#L528
export const SCREENER_QUESTION_OPTION_RULE = [
  "qualify",
  "disqualify",
  "not_relevant",
] as const

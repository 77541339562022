import {
  Badge,
  Button,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useBreakpointValue,
} from "@chakra-ui/react"
import { CreateVariationSetModal } from "Components/create-variation-set-modal/create-variation-set-modal"
import { DescribeVariationSetModal } from "Components/describe-variation-set-modal/DescribeVariationSetModal"
import { EventTime } from "Components/time/event-time"
import { ProTrialIcon } from "Components/trial-widget/ProTrialIcon"
import { VariationSetIcon } from "Shared/icons/VariationSetIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { generateFriendlyProjectPath } from "UsabilityHub/utils/generateFriendlyProjectPath"
import { useShowProTrialWidget } from "Utilities/account"
import { useModal } from "Utilities/modals/use-modal"
import { useHasTestSetsFeature } from "Utilities/plan"
import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { useUsabilityTestPage } from "~/api/generated/usabilityhub-components"
import { Header, HeaderContent } from "~/shared/components/Header/Header"
import { AppContext } from "~/shared/contexts/AppContext"
import { UsabilityTestContextMenu } from "../UsabilityTestContextMenu/UsabilityTestContextMenu"

interface UsabilityTestHeaderProps {
  id: number
  uniqueId: string
  redirect: boolean
  isDeletionAllowed: boolean
}

export const UsabilityTestHeader: React.FC<
  React.PropsWithChildren<UsabilityTestHeaderProps>
> = ({ id, uniqueId }) => {
  const showProTrialWidget = useShowProTrialWidget()
  const isAllowedTestSets = useHasTestSetsFeature()

  const { open: openVariationSetModal } = useModal(CreateVariationSetModal)
  const { open: openDescribeVariationSetModal } = useModal(
    DescribeVariationSetModal
  )
  const { isSPA } = useContext(AppContext)

  const size = useBreakpointValue({ base: "sm", md: "md" })

  const clickVariationSetButton = () => {
    isAllowedTestSets
      ? openVariationSetModal({
          usabilityTestIds: [id],
        })
      : openDescribeVariationSetModal({
          source: "usability_test_header",
        })
  }

  const { data } = useUsabilityTestPage({
    pathParams: { usabilityTestId: uniqueId },
  })
  const usabilityTest = data?.usability_test

  const canManage = useCurrentUser().can_manage_tests
  const canViewControls = canManage
  const inVariationSet = !!usabilityTest?.variation_set

  return (
    <Header>
      <HeaderContent>
        <Stack>
          <Heading lineHeight="none" display="flex" gap={1}>
            {usabilityTest?.project?.name ? (
              <>
                <Text
                  color="gray.500"
                  fontWeight="500"
                  maxW="350px"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  pb={0.5} // stops the descenders being cut off by the overflow: hidden
                >
                  {isSPA && usabilityTest.project.id ? (
                    <Link
                      to={generateFriendlyProjectPath({
                        id: usabilityTest.project.id,
                        name: usabilityTest.project.name,
                      })}
                    >
                      {usabilityTest.project.name}
                    </Link>
                  ) : (
                    usabilityTest.project?.name
                  )}{" "}
                </Text>
                <Text color="gray.500">/</Text>
              </>
            ) : (
              usabilityTest?.project?.name &&
              usabilityTest?.project?.name !== "" && (
                <Text color="gray.500" fontWeight="500">
                  {usabilityTest.project.name} /{" "}
                </Text>
              )
            )}
            {usabilityTest?.name || <Text color="gray.500">Untitled</Text>}

            {usabilityTest?.upgraded && (
              <Badge colorScheme="brand.primary" ms={2} py={1}>
                Upgraded
              </Badge>
            )}
          </Heading>
          {usabilityTest?.created_at && (
            <Wrap>
              <WrapItem>
                <Text color="gray.500" fontSize="sm">
                  Created <EventTime date={usabilityTest.created_at} />
                  {usabilityTest.creator
                    ? ` by ${usabilityTest.creator.name}`
                    : null}
                </Text>
              </WrapItem>
            </Wrap>
          )}
        </Stack>
        {canViewControls ? (
          <HStack spacing={{ base: 2, md: 3 }} mt={{ base: 3, md: 4, lg: 0 }}>
            {inVariationSet ? null : (
              <Button
                onClick={clickVariationSetButton}
                variant="outline"
                size={size}
                leftIcon={<Icon as={VariationSetIcon} />}
                rightIcon={
                  <ProTrialIcon
                    isAttractive={showProTrialWidget || !isAllowedTestSets}
                  />
                }
              >
                Make variation set
              </Button>
            )}
            {usabilityTest ? (
              <UsabilityTestContextMenu
                test={usabilityTest}
                redirectAfterDeletion
              />
            ) : null}
          </HStack>
        ) : null}
      </HeaderContent>
    </Header>
  )
}

import {
  Box,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react"
import { UserCircleIcon } from "@heroicons/react/solid"
import { omit } from "lodash"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"

import { ExpandableRegion } from "Components/expandable-region/expandable-region"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import { getScreenResultsFromIndividualPath } from "Components/test-results/utils/screen-results/get-screen-results-from-individual-path"
import { getIndividualFigmaTaskPathsFromTasks } from "Components/test-results/utils/task-paths/get-individual-paths/get-individual-paths"
import {
  getResponseSection,
  makeGetResponseIdForResponseSection,
} from "Redux/reducers/test-results/selectors"
import { IndividualFigmaTaskPath, ResponseSectionFigmaTask } from "Types"

import { RecordingsSimplePreview } from "../../../Recordings/RecordingsSimplePreview"
import { useFigmaImages } from "../../hooks/use-figma-images"
import {
  FigmaTaskPathSortMethod,
  SortMethodOptions,
  useSortedFigmaTaskPaths,
} from "../CommonPathsList/useSortedFigmaTaskPaths"
import { PathEntryWithDetailsModal } from "../PathEntryWithDetailsModal"
import { PathStatsContainer } from "../PathStats/PathStatsContainer"
import { PathImages, PathRow, PathSortSelect, PathsList } from "../PathsList"

type IndividualPathSortMethod = Exclude<FigmaTaskPathSortMethod, "participants">

const SORT_TYPE_LABELS: SortMethodOptions<IndividualPathSortMethod> = {
  none: "None",
  goalScreen: "Reached goal screen",
  duration: "Time to complete",
  misclicks: "Misclick rate",
}

const DEFAULT_SORT_METHOD: IndividualPathSortMethod = "none"

interface IndividualPathsListProps {
  goalNodeId: string | null
  tasks: ResponseSectionFigmaTask[]
}

export const IndividualPathsList: React.FC<
  React.PropsWithChildren<IndividualPathsListProps>
> = ({ goalNodeId, tasks }) => {
  const individualPaths = getIndividualFigmaTaskPathsFromTasks(
    tasks,
    goalNodeId
  )

  const { sortedPaths, sortMethod, setSortMethod } = useSortedFigmaTaskPaths<
    IndividualPathSortMethod,
    IndividualFigmaTaskPath
  >(individualPaths, DEFAULT_SORT_METHOD)

  if (individualPaths.length === 0) {
    return (
      <Box borderWidth={1} borderColor="gray.200" rounded="md" p={5}>
        <Text>No responses yet</Text>
      </Box>
    )
  }

  return (
    <PathsList>
      {goalNodeId ? (
        <PathSortSelect
          value={sortMethod}
          options={SORT_TYPE_LABELS}
          onSelect={(e) => setSortMethod(e)}
        />
      ) : (
        <PathSortSelect
          value={sortMethod === "goalScreen" ? DEFAULT_SORT_METHOD : sortMethod}
          options={omit(SORT_TYPE_LABELS, ["goalScreen"])}
          onSelect={(e) => setSortMethod(e)}
        />
      )}

      <ExpandableRegion
        maxHeight={600}
        expandText="Expand"
        collapseText="Collapse"
      >
        <Stack spacing={4}>
          {sortedPaths.map((path) => (
            <IndividualPath
              key={`individual-${path.task.id}-${path.pathId}`}
              path={path}
              sortMethod={sortMethod}
              canViewResponse
            />
          ))}
        </Stack>
      </ExpandableRegion>
    </PathsList>
  )
}

interface IndividualPathProps {
  canViewResponse?: boolean
  path: IndividualFigmaTaskPath
  sortMethod: FigmaTaskPathSortMethod
  showRecordings?: boolean
}

/**
 * An individual path is the path a single result produces. It is very similar in structure to
 * common paths, but only represents one `ResponseSectionFigmaTask`.
 */
export const IndividualPath: React.FC<
  React.PropsWithChildren<IndividualPathProps>
> = ({ canViewResponse = false, path, sortMethod, showRecordings = false }) => {
  const figmaImages = useFigmaImages(path.task.figma_file_version_id)
  const screenResults = getScreenResultsFromIndividualPath(path, figmaImages)

  const noPathsCompleted = screenResults.length === 0
  const [, setResponseId] = useIndividualResponseFilter()

  const getResponseIdForResponseSection = useMemo(
    () => makeGetResponseIdForResponseSection(path.task.response_section_id),
    [path.task.response_section_id]
  )
  const responseId = useSelector(getResponseIdForResponseSection)

  const responseSection = useSelector(
    getResponseSection(path.task.response_section_id)
  )

  const canViewResponseStyles = {
    role: "group",
    transition: "border-color 0.1s linear",
    _hover: { borderColor: "gray.500" },
    mt: 4, // Breathing room for the individual response button
  }

  return (
    <PathRow
      data-qa={`path-row-response-id-${responseId!}`}
      position="relative" // Container for the absolutely positioned individual response button
      {...(canViewResponse && canViewResponseStyles)}
    >
      {noPathsCompleted ? (
        <Text>No paths completed</Text>
      ) : (
        <>
          {showRecordings && responseSection && (
            <RecordingsSimplePreview responseSection={responseSection} />
          )}
          <HStack spacing={2} textStyle="label">
            <PathStatsContainer
              type="individual"
              pathMeta={path.meta}
              highlightTime={sortMethod === "duration"}
              highlightClicks={sortMethod === "misclicks"}
            />
            <Spacer />
            {canViewResponse && (
              <IconButton
                icon={<Icon as={UserCircleIcon} />}
                aria-label="View individual response"
                variant="outline"
                size="sm"
                colorScheme="gray"
                onClick={() => setResponseId(responseId)}
                data-qa="individual-response-button"
              />
            )}
          </HStack>
          <PathImages>
            {screenResults.map((screen, index) => (
              <Box
                key={`${screen.nodeId}-${index}`}
                width="160px"
                flexShrink="0"
              >
                <PathEntryWithDetailsModal
                  key={`${screen.nodeId}-${index}`}
                  index={index}
                  screenResults={screenResults}
                  path={path}
                />
              </Box>
            ))}
          </PathImages>
        </>
      )}
    </PathRow>
  )
}

import { Center, Spinner } from "@chakra-ui/react"
import { Translations } from "Types"
import React from "react"
import { PropsWithChildren, createContext } from "react"
import { useListTranslations } from "~/api/generated/usabilityhub-components"

type LocaleContextValue = {
  translations: Translations
}

export const LocaleContext = createContext<LocaleContextValue | null>(null)

type LocaleProviderProps = {
  showLoader?: boolean
  locale?: string
  keys: string[]
}

// Loads translations from the API for the given locale and keys.
// Blocks on loading translations before rendering children, so anyone down the tree
// can use useTranslate assuming they will be present.

// Note that you can pass `undefined` for `locale`, in which case the backend will use the
// locale for current_user.
export const LocaleProvider: React.FC<
  PropsWithChildren<LocaleProviderProps>
> = ({ children, locale, keys, showLoader = false }) => {
  const queryParams = {
    keys: keys.join(","),
    ...(locale ? { locale } : {}),
  }

  const { data: translations } = useListTranslations(
    {
      queryParams,
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  )

  if (!translations) {
    return showLoader ? (
      <Center height="full">
        <Spinner size="xl" />
      </Center>
    ) : null
  }

  return (
    <LocaleContext.Provider value={{ translations }}>
      {children}
    </LocaleContext.Provider>
  )
}

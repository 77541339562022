import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  Switch,
  Tooltip,
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { AnimatePresence, motion } from "framer-motion"
import { isEqual } from "lodash"
import React, { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import * as Yup from "yup"

import { SubmitButton } from "Components/button/submit-button"
import { FunctionalModal } from "Utilities/modals/types"

import { HelpCircleOutlineIcon } from "Shared/icons/untitled-ui/HelpCircleOutlineIcon"
import { Calendar } from "./Calendar"
import { TimeSlots } from "./TimeSlots"

const dayAvailability = Yup.array(
  Yup.object({
    starts_at: Yup.string().required(),
    ends_at: Yup.string().required(),
  }).test("time-range", "End time must be after start time", (value) => {
    const { starts_at, ends_at } = value
    if (!starts_at || !ends_at) {
      return true // don't run validation if starts_at or ends_at are missing
    }
    return (
      new Date(`2000-01-01T${ends_at}`) > new Date(`2000-01-01T${starts_at}`)
    )
  })
).required()

export type DayAvailabilityArray = Yup.InferType<typeof dayAvailability>

const dateOverrides = Yup.object({
  date: Yup.array().of(Yup.number().required()).length(3).required(),
  overrides: dayAvailability,
}).test("isValidDate", "Invalid date", (value) => {
  if (!value.date) return true
  const [year, month, date] = value.date

  return !!new Date(
    `${year}-${(month + 1).toString().padStart(2, "0")}-${date
      .toString()
      .padStart(2, "0")}T00:00:00`
  ).getTime()
})

export type DateOverrides = Yup.InferType<typeof dateOverrides>
const ModeratedStudyDayOverridesSchema = Yup.object({
  dateOverrides: dateOverrides.required(),
})

type ModeratedStudyDayOverridesFormValues = Yup.InferType<
  typeof ModeratedStudyDayOverridesSchema
>

export type DateType = [number, number, number] // year, month, date

interface Props {
  AddOrUpdateAvailabilityOverrides: (
    data: DateOverrides,
    oldDate?: DateType
  ) => void
  availabilityOverrides?: DateOverrides[]
  initialDate?: DateType
}

export const DayOverrideModal: FunctionalModal<Props> = ({
  AddOrUpdateAvailabilityOverrides,
  availabilityOverrides,
  initialDate,
  onClose,
}) => {
  const dayOverride =
    initialDate && availabilityOverrides
      ? availabilityOverrides.find((element) => {
          return isEqual(element.date, initialDate)
        })
      : undefined

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isLoading, isValid },
  } = useForm<ModeratedStudyDayOverridesFormValues>({
    resolver: yupResolver(ModeratedStudyDayOverridesSchema),
    defaultValues: {
      dateOverrides: dayOverride ?? {
        date: [2022, 5, 1],
        overrides: [
          {
            starts_at: "09:00",
            ends_at: "17:00",
          },
        ],
      },
    },
  })

  const [selectedDate, setSelectedDate] = useState<DateType | undefined>(
    initialDate
  )
  const [isSelectedDateUnavailable, setIsSelectedDateUnavailable] = useState(
    dayOverride?.overrides?.length === 0
  )

  useEffect(() => {
    selectedDate && setValue("dateOverrides.date", selectedDate)
  }, [availabilityOverrides, selectedDate, setValue])

  const changeDateAvailable = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue("dateOverrides.overrides", [])
    } else {
      setValue("dateOverrides.overrides", [
        {
          starts_at: "09:00",
          ends_at: "17:00",
        },
      ])
    }
    setIsSelectedDateUnavailable(e.target.checked)
  }

  const confirmAddOrUpdateOverrides: SubmitHandler<
    ModeratedStudyDayOverridesFormValues
  > = ({ dateOverrides }) => {
    AddOrUpdateAvailabilityOverrides(dateOverrides, initialDate)
    onClose()
  }

  return (
    <Modal isOpen onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent borderRadius="md">
        <ModalBody p={8}>
          <form onSubmit={handleSubmit(confirmAddOrUpdateOverrides)}>
            <Flex alignItems="flex-start" gap={12} height="420px">
              <Flex flexDir="column">
                <Heading as="h4" size="md" mb={4}>
                  Select the dates to override
                </Heading>
                <Calendar
                  overriddenDates={availabilityOverrides}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </Flex>
              <Divider orientation="vertical" />
              <Flex flexDir="column" height="100%" gap={2} width="400px">
                <Flex fontWeight="semibold" align="center">
                  Which hours are you available?
                  <Tooltip
                    hasArrow
                    rounded="md"
                    label={
                      'Add dates when your availability changes from your normal weekly hours. These will override your "weekly hour" settings.'
                    }
                  >
                    <HelpCircleOutlineIcon ms={2} />
                  </Tooltip>
                </Flex>
                <AnimatePresence mode="wait">
                  {selectedDate && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      {isSelectedDateUnavailable ? (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                        >
                          <Box
                            w="100%"
                            p={2}
                            color="text.secondary"
                            border="1px"
                            borderColor="gray.200"
                            borderRadius={4}
                            my={5}
                          >
                            Unavailable all day
                          </Box>
                        </motion.div>
                      ) : (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                        >
                          <Flex
                            direction="column"
                            overflowY="scroll"
                            my={4}
                            p={1}
                            maxH="280px"
                          >
                            <TimeSlots
                              control={control}
                              fieldName="dateOverrides.overrides"
                            />
                          </Flex>
                        </motion.div>
                      )}

                      <HStack>
                        <Switch
                          id="switch"
                          size="md"
                          isChecked={isSelectedDateUnavailable}
                          onChange={changeDateAvailable}
                        />
                        <FormLabel htmlFor="switch" cursor="pointer">
                          Mark unavailable (All day)
                        </FormLabel>
                      </HStack>
                    </motion.div>
                  )}
                </AnimatePresence>
                <Spacer />
                <Flex justifyContent="flex-end">
                  <Button onClick={onClose} variant="ghost" mr={2}>
                    Close
                  </Button>
                  <SubmitButton
                    isLoading={isLoading}
                    isDisabled={!isValid}
                    loadingAction={
                      initialDate ? "Updating Override" : "Adding Override"
                    }
                  >
                    {initialDate ? "Update Override" : "Add Override"}
                  </SubmitButton>
                </Flex>
              </Flex>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Heading } from "DesignSystem/components"
import { PanelOrderCompositeIcon } from "Shared/components/CompositeIcons/CompositeIcons"
import { InfoCircleIcon } from "Shared/icons/InfoCircleIcon"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { ThirdPartyOrderState } from "Types"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import {
  Order,
  OrderState,
  RecordingType,
} from "~/api/generated/usabilityhubSchemas"
import { CancelOrderModal } from "./CancelOrderModal"
import { ModeratedStudyOrderSummaryCardExpandedSection } from "./ModeratedStudyOrderSummaryCardExpandedSection"
import { OrderStateIcon } from "./OrderStateIcon"
import { TestOrderSummaryCardExpandedSection } from "./TestOrderSummaryCardExpandedSection"
import { TEST_ORDER_GRID_COLUMNS } from "./cardGridStyles"
import {
  PanelOrThirdPartyTestOrder,
  isModeratedStudyOrder,
  isPanelTestOrder,
} from "./orderTypes"

const friendlyOrderState = (orderState: OrderState | ThirdPartyOrderState) => {
  switch (orderState) {
    case "active":
    case "complete_pending_review":
    case "replacements_required":
    case "awaiting_placement":
      return "Active"
    case "canceled":
      return "Canceled"
    case "awaiting_payment":
      return "Awaiting payment"
    case "tentatively_complete":
    case "complete":
      return "Complete"
  }
}

interface OrderSummaryProps {
  description: string
  order: PanelOrThirdPartyTestOrder
  recordingTypes: RecordingType[]
}

export const OrderSummaryCard: React.FC<
  React.PropsWithChildren<OrderSummaryProps>
> = ({ description, order, recordingTypes }) => {
  const currentUser = useCurrentUser()
  const { open: showCancelModal } = useModal(CancelOrderModal)

  const deletedResponseCount = order.deleted_response_count
  const showCancelAction =
    (order.state === "active" || order.state === "tentatively_complete") &&
    isPanelTestOrder(order) &&
    currentUser.can_spend_credits

  return (
    <Box bg="ds.surface.raised.resting" rounded="16px" shadow="ds.raised">
      <Accordion allowToggle>
        <AccordionItem border="none">
          <AccordionButton p={0} textAlign="initial">
            <Grid
              w="100%"
              templateColumns={TEST_ORDER_GRID_COLUMNS}
              gap={6}
              p={6}
            >
              <HStack gap={4}>
                <PanelOrderCompositeIcon />

                <Stack spacing={1}>
                  <Heading as="h3" textStyle="ds.heading.primary">
                    {description}
                  </Heading>
                  <Text textStyle="ds.paragraph.primary" color="ds.text.subtle">
                    Created {order.creator ? `by ${order.creator.name}` : null}{" "}
                    on{" "}
                    {new Date(order.created_at).toLocaleDateString(undefined, {
                      dateStyle: "medium",
                    })}
                  </Text>
                </Stack>
              </HStack>

              <Flex align="center">
                <OrderStateIcon state={order.state} />
                <Stack spacing={1} ml={3}>
                  <Flex align="center" gap={2}>
                    <Text textStyle="ds.heading.secondary">
                      {order.completed_response_count} of{" "}
                      {order.requested_response_count} responses
                      {deletedResponseCount
                        ? ` (${deletedResponseCount} response${
                            deletedResponseCount > 1 ? "s" : ""
                          } deleted)`
                        : null}
                    </Text>

                    <Tooltip label="The number of screened-in and completed responses for this order.">
                      <Icon
                        as={InfoCircleIcon}
                        color="icon.subtle"
                        boxSize="0.875rem"
                      />
                    </Tooltip>
                  </Flex>

                  <Text textStyle="ds.paragraph.primary">
                    {friendlyOrderState(order.state)}
                    {showCancelAction && (
                      <>
                        {" "}
                        ·{" "}
                        <Link
                          variant="noUnderline"
                          colorScheme="red"
                          onClick={() => showCancelModal({ order })}
                        >
                          Cancel order
                        </Link>
                      </>
                    )}

                    {order.state === "canceled" &&
                      isPanelTestOrder(order) &&
                      ` · refunded ${order.total_refunded_credit_count} credits`}
                  </Text>
                </Stack>
              </Flex>

              <Flex>
                {isPanelTestOrder(order) && order.screener && (
                  <ScreenInRate order={order} />
                )}
              </Flex>

              <Box alignSelf="center" justifySelf="flex-end">
                <AccordionIcon
                  as={ChevronDownOutlineIcon}
                  boxSize={6}
                  color="ds.icon.subtle"
                />
              </Box>
            </Grid>
          </AccordionButton>

          <AccordionPanel p={6}>
            {isModeratedStudyOrder(order) ? (
              <ModeratedStudyOrderSummaryCardExpandedSection order={order} />
            ) : (
              <TestOrderSummaryCardExpandedSection
                order={order}
                recordingTypes={recordingTypes}
              />
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

const ScreenInRate: React.FC<{
  order: Order
}> = ({ order }) => {
  const estimatedScreenInRate = order.estimated_incidence_rate
  const estimatedRange = Constants.VALID_SIR_RANGES.find(
    (range) => range.min === estimatedScreenInRate
  )

  const total =
    order.completed_response_count + order.screened_out_response_count
  const actualScreenInRate =
    total <= 0 ? 0 : (order.completed_response_count / total) * 100

  return (
    <VStack alignItems="left" gap={1}>
      <Flex alignItems="center" gap={2}>
        <Text textStyle="ds.heading.secondary">Screen-in rate</Text>
        <Tooltip label="The percentage of participants that met your screener criteria.">
          <Icon as={InfoCircleIcon} color="icon.subtle" boxSize="0.875rem" />
        </Tooltip>
      </Flex>
      <Flex gap={1}>
        <Text
          as="span"
          color={actualScreenInRate <= 10 ? "ds.text.danger" : "inherit"}
          textStyle="ds.paragraph.primary"
        >
          {total >= 5 ? `${actualScreenInRate.toFixed(0)}%` : "Pending"}
        </Text>{" "}
        <Text as="span" textStyle="ds.paragraph.primary">
          {estimatedRange &&
            `(Estimated ${estimatedRange.min}-${estimatedRange.max}%)`}
        </Text>
      </Flex>
    </VStack>
  )
}

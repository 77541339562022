import { Box, BoxProps } from "@chakra-ui/react"
import { range } from "lodash"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTestRecordingContext } from "../UsabilityTest/context/TestRecordingContext"

type IndicatorSize = "small" | "default" | "emphasized"

type IndicatorProps = BoxProps & {
  size?: IndicatorSize
}

const boxSizes = {
  small: 6,
  default: 8,
  emphasized: 10,
} as const

export const RecordingVolumeIndicator: React.FC<IndicatorProps> = ({
  size = "default",
  ...props
}) => {
  const [state, setState] = useState<"resting" | "active">("resting")

  const { audioDeviceId } = useTestRecordingContext()

  const bars = useRef<(SVGPathElement | null)[]>([])

  const pulse = useCallback((value: number) => {
    const smallBarSize = 10
    const largeBarSize = 16

    for (const i of range(3)) {
      const y = (value * (i % 2 ? largeBarSize : smallBarSize)) / 2
      bars.current[i]?.setAttribute("d", `M0-${y}v${y * 2}`)
    }
  }, [])

  useEffect(() => {
    if (audioDeviceId) {
      setState("active")

      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: audioDeviceId } })
        .then((stream) => {
          const audioContext = new AudioContext()
          const analyser = audioContext.createAnalyser()
          const microphone = audioContext.createMediaStreamSource(stream)
          const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1)

          analyser.smoothingTimeConstant = 0.8
          analyser.fftSize = 1024

          microphone.connect(analyser)
          analyser.connect(scriptProcessor)
          scriptProcessor.connect(audioContext.destination)
          scriptProcessor.addEventListener("audioprocess", () => {
            const array = new Uint8Array(analyser.frequencyBinCount)
            analyser.getByteFrequencyData(array)
            const arraySum = array.reduce((a, value) => a + value, 0)
            const average = arraySum / array.length
            pulse(Math.min(average / 60.0, 1))
          })
        })
        .catch((error) => {
          console.error(error)
          setState("resting")
        })
    } else {
      setState("resting")
    }
  }, [audioDeviceId, pulse])

  return (
    <Box as="svg" boxSize={boxSizes[size]} viewBox="-20 -20 40 40" {...props}>
      <Box
        as="circle"
        cx={0}
        cy={0}
        r={20}
        fill={
          state === "active"
            ? "ds.background.accent.purple.bold"
            : "ds.background.disabled"
        }
      />
      {[0, 1, 2].map((i) => (
        <g key={i} transform={`translate(${i * 7.75 - 7.75}, 0)`}>
          <path
            d="M0-0v0"
            ref={(el) => {
              bars.current[i] = el
            }}
            stroke="white"
            strokeWidth={4.5}
            strokeLinecap="round"
          />
        </g>
      ))}
    </Box>
  )
}

import {
  Alert,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Button,
  Text,
} from "@chakra-ui/react"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { CalculatePriceAndEstimatedDurationResponse } from "~/api/generated/usabilityhub-components"
import { useOrderForm } from "../OrderFormProvider"
import { QuoteTable } from "../QuoteTable"
import { WelcomePresent } from "../WelcomePresent"
import { Panel } from "../types"
import { EstimatedTurnaround } from "./EstimatedTurnaround"
import { LyssnaPanelSubmitOrder } from "./LyssnaPanelSubmitOrder"
import { useLyssnaPanelOrderQuote } from "./useLyssnaPanelOrderQuote"

type Props = {
  setPanel: (panel: Panel) => void
}

export const LyssnaPanelTab: React.FC<Props> = ({ setPanel }) => {
  const { latestQuote, quoteState } = useLyssnaPanelOrderQuote()
  const canUserPurchaseCredits = useCurrentUser().can_purchase_credits

  return (
    <>
      <QuoteTable quote={latestQuote} quoteState={quoteState} />

      {latestQuote?.discount_applied && (
        <WelcomePresent
          formattedTotal={latestQuote.formatted_price}
          formattedDiscount={latestQuote.formatted_discount}
        />
      )}

      {latestQuote && (
        <LyssnaOrderQuoteErrors
          quote={latestQuote}
          canUserPurchaseCredits={canUserPurchaseCredits}
          setPanel={setPanel}
          mt={4}
        />
      )}

      <LyssnaPanelSubmitOrder quote={latestQuote} quoteState={quoteState} />

      <EstimatedTurnaround
        isLoading={quoteState === "loading"}
        duration={latestQuote?.estimated_duration ?? null}
        mt={4}
      />
    </>
  )
}

type LyssnaOrderQuoteErrorsProps = {
  quote: CalculatePriceAndEstimatedDurationResponse
  canUserPurchaseCredits: boolean
  setPanel: (panel: Panel) => void
}

const LyssnaOrderQuoteErrors: React.FC<
  LyssnaOrderQuoteErrorsProps & AlertProps
> = ({ quote, canUserPurchaseCredits, setPanel, ...alertProps }) => {
  const { testHasScreener } = useOrderForm()

  const considerExternal = !testHasScreener

  if (!quote.estimated_duration) {
    return (
      <Alert status="warning" {...alertProps}>
        <AlertIcon />
        <AlertTitle>Cannot fulfill order</AlertTitle>
        <Text color="text.primary">
          Sorry, we can{"\u2019"}t fulfill that order from the Lyssna panel.
          {considerExternal && (
            <>
              {" "}
              Consider{" "}
              <Button variant="link" onClick={() => setPanel("external")}>
                using the external panel
              </Button>
              .
            </>
          )}
        </Text>
      </Alert>
    )
  }

  if (!canUserPurchaseCredits && quote.credits_to_be_purchased) {
    return (
      <Alert status="warning" {...alertProps}>
        <AlertIcon />
        <Text>
          Placing this order costs money, but you don{"\u2019"}t have permission
          to use the credit card stored on this account. Please ask an account
          admin to place this order, or to pre-purchase credits for you to
          spend.
        </Text>
      </Alert>
    )
  }

  return null
}

export type TimeSlot = {
  id: string
  starts_at: string
  ends_at: string
}

const hours = [...Array(24).keys()]
const minutes = [0, 15, 30, 45]
const padNum = (value: number) => value.toString().padStart(2, "0")

export const times = [
  ...hours.flatMap((hour) =>
    minutes.map((minute) => {
      const moddedHour = hour % 12
      const correctedHour = moddedHour === 0 ? 12 : moddedHour
      const paddedMins = padNum(minute)
      return [
        `${padNum(hour)}:${paddedMins}`,
        `${padNum(correctedHour)}:${paddedMins}${hour < 12 ? "am" : "pm"}`,
      ]
    })
  ),
  ["23:59", "11:59pm"], // end of the day
]

export const getTimes = (min: string | null, max: string | null) => {
  const x = times.filter(
    (time: string[]) => (!min || min < time[0]) && (!max || time[0] < max)
  )
  return x
}

import { useToast } from "@chakra-ui/react"
import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { setPrototypeGoalScreenId } from "Redux/reducers/test-results/action-creators"
import { isAxiosError } from "Services/axios"
import { useSectionContext } from "UsabilityHub/contexts"

export function useSetTaskGoalNodeId() {
  const { section } = useSectionContext()
  const toast = useToast()
  const dispatch: Dispatch = useDispatch()

  const setGoalNodeId = useCallback(
    async (goalNodeId: string | null) => {
      try {
        await dispatch(
          setPrototypeGoalScreenId(
            section.usability_test_id,
            section.id,
            goalNodeId
          )
        )
      } catch (error) {
        if (isAxiosError(error) && error.response) {
          toast({
            title: "Unable to set goal screen",
            status: "error",
          })
        }
      }
    },
    [dispatch, toast, section]
  )

  return setGoalNodeId
}

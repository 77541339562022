import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Textarea,
  useCheckboxGroup,
} from "@chakra-ui/react"
import {
  DEVICE_PERIPHERAL_OPTIONS,
  DEVICE_TYPE_OPTIONS,
} from "Shared/constants/testTakingDeviceRequirements"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { ROUTES } from "UsabilityHub/views/routes"
import { useModal } from "Utilities/modals/use-modal"
import { capitalizeFirstLetter } from "Utilities/string"
import React, { useEffect, useRef } from "react"
import AutosizeTextarea from "react-autosize-textarea"
import { Path, PathValue, useController } from "react-hook-form"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks } from "~/api/generated/usabilityhub-components"
import { DurationLockedModal } from "./duration-locked-modal"
import {
  DURATION_MINS,
  useAvailabilitySectionForm,
} from "./forms/availability-section-form"
import {
  DeviceRequirementFormFields,
  useDeviceRequirementForm,
} from "./forms/device-requirement-form"
import { useMeetingUrlForm } from "./forms/meeting-url-form"
import { useStudyDetailsForm } from "./forms/study-details-form"
import { LocationForm } from "./location-form"

interface ModeratedStudyDetailsFormProps {
  studyDetailsForm: ReturnType<typeof useStudyDetailsForm>
  meetingUrlForm: ReturnType<typeof useMeetingUrlForm>
  deviceRequirementForm: ReturnType<typeof useDeviceRequirementForm>
  availabilityForm: ReturnType<typeof useAvailabilitySectionForm>
}

export const ModeratedStudyDetailsForm: React.FC<
  ModeratedStudyDetailsFormProps
> = ({
  studyDetailsForm,
  meetingUrlForm,
  deviceRequirementForm,
  availabilityForm,
}) => {
  const { moderatedStudyId } = useTypedParams(ROUTES.INTERVIEW.EDIT)

  const {
    handleSubmit: handleDetailsSubmit,
    register,
    formState: { errors: detailsErrors },
  } = studyDetailsForm

  const {
    handleSubmit: handleDeviceSubmit,
    control,
    formState: { errors: deviceErrors },
  } = deviceRequirementForm

  const {
    register: availabilityRegister,
    watch: availabilityWatch,
    setValue: availabilitySetValue,
    formState: { errors: availabilityErrors },
  } = availabilityForm

  const { field: deviceTypesField } = useController({
    name: "device_types",
    control,
  })

  const { field: devicePeripheralsField } = useController({
    name: "device_peripherals",
    control,
  })

  const { getCheckboxProps: getDeviceTypesCheckboxProps } = useCheckboxGroup({
    ...deviceTypesField,
    // Convert the type of useController to what Chakra's useCheckboxGroup expects
    onChange: (
      newValue: PathValue<
        DeviceRequirementFormFields["device_types"],
        Path<DeviceRequirementFormFields["device_types"]>
      >[]
    ) => {
      deviceTypesField.onChange(newValue)
    },
  })
  const { getCheckboxProps: getDevicePeripheralsCheckboxProps } =
    useCheckboxGroup({
      ...devicePeripheralsField,
      // Convert the type of useController to what Chakra's useCheckboxGroup expects
      onChange: (
        newValue: PathValue<
          DeviceRequirementFormFields["device_peripherals"],
          Path<DeviceRequirementFormFields["device_peripherals"]>
        >[]
      ) => {
        devicePeripheralsField.onChange(newValue)
      },
    })

  const { open: openDurationLockedModal } = useModal(DurationLockedModal)

  const deviceTypesLabel =
    deviceTypesField.value.length === 0
      ? "No devices"
      : deviceTypesField.value.length === DEVICE_TYPE_OPTIONS.length
        ? "Any device"
        : deviceTypesField.value.map(capitalizeFirstLetter).join(" or ")

  const { data: orderData } =
    useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks({
      pathParams: { moderatedStudyId },
    })

  const previousDurationRef = useRef<number | null>(null)
  const currentDuration = parseInt(
    availabilityWatch("eventDurationMins").toString()
  )

  // If there are active orders, show a modal when attempting to change duration.
  // It won't block them from changing it, but it will let them know that changes
  // won't affect active orders.
  useEffect(() => {
    const previousDuration = previousDurationRef.current
    previousDurationRef.current = currentDuration

    if (previousDuration === currentDuration || previousDuration === null)
      return

    const activeOrderCount = orderData?.moderated_study_orders.filter(
      (o) => o.state === "active" && o.event_duration_mins !== currentDuration
    ).length
    if (!activeOrderCount) return

    openDurationLockedModal({
      resetDuration: () => {
        previousDurationRef.current = previousDuration
        availabilitySetValue("eventDurationMins", previousDuration)
      },
      numOrders: activeOrderCount,
    })
  }, [currentDuration])

  return (
    <Flex flexDir="column" gap={4}>
      <form onChange={handleDetailsSubmit}>
        <Flex flexDir="column" gap={4}>
          <FormControl isInvalid={!!detailsErrors.external_name}>
            <FormLabel color="text.primary" fontSize={16} fontWeight={500}>
              Session name
            </FormLabel>
            <Input
              {...register("external_name")}
              type="text"
              placeholder="Enter a name for your event"
            />
            {detailsErrors.external_name && (
              <FormErrorMessage>
                {detailsErrors.external_name.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!detailsErrors.description}>
            <FormLabel color="text.primary" fontSize={16} fontWeight={500}>
              Description
            </FormLabel>
            <Textarea
              as={AutosizeTextarea}
              {...register("description")}
              placeholder="Provide a description of your event."
            ></Textarea>
            {detailsErrors.description && (
              <FormErrorMessage>
                {detailsErrors.description.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!availabilityErrors.eventDurationMins}>
            <FormLabel
              color="text.primary"
              fontSize="md"
              lineHeight={6}
              fontWeight="medium"
            >
              Duration
            </FormLabel>
            <Select maxW="200px" {...availabilityRegister("eventDurationMins")}>
              {DURATION_MINS.map((duration) => (
                <option key={duration} value={duration}>
                  {`${duration} minutes`}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {availabilityErrors.eventDurationMins?.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
      </form>

      <LocationForm meetingUrlForm={meetingUrlForm} />

      <form onChange={handleDeviceSubmit}>
        <Flex flexDir="column" gap={1} align="flex-start">
          <FormLabel fontSize={16} fontWeight={500}>
            Technical requirements
          </FormLabel>
          <HStack spacing={4}>
            <FormControl isInvalid={!!deviceErrors.device_types}>
              <Menu closeOnSelect={false}>
                <MenuButton
                  as={Button}
                  minW="197px"
                  textAlign="left"
                  variant="outline"
                  size="md"
                  fontWeight="normal"
                  sx={
                    deviceErrors.device_types ? { borderColor: "red.500" } : {}
                  }
                  rightIcon={<Icon as={ChevronDownOutlineIcon} />}
                >
                  {deviceTypesLabel}
                </MenuButton>
                <MenuList>
                  {DEVICE_TYPE_OPTIONS.map((deviceType) => (
                    <MenuItem padding={0} key={deviceType}>
                      <Checkbox
                        {...getDeviceTypesCheckboxProps({ value: deviceType })}
                        padding={3}
                        flexBasis="100%"
                        key={`device-type-${deviceType}`}
                      >
                        {capitalizeFirstLetter(deviceType)}
                      </Checkbox>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </FormControl>

            {DEVICE_PERIPHERAL_OPTIONS.map((peripheral) => (
              <Checkbox
                key={peripheral}
                sx={{
                  ".chakra-checkbox__label": {
                    marginLeft: "0.5rem",
                  },
                }}
                {...getDevicePeripheralsCheckboxProps({ value: peripheral })}
              >
                {capitalizeFirstLetter(peripheral)}
              </Checkbox>
            ))}
          </HStack>
          {deviceErrors.device_types && (
            <FormControl margin={0} isInvalid={!!deviceErrors.device_types}>
              <FormErrorMessage>
                {deviceErrors.device_types.message}
              </FormErrorMessage>
            </FormControl>
          )}
        </Flex>
      </form>
    </Flex>
  )
}

import { Flex, Text } from "@chakra-ui/layout"
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
} from "@chakra-ui/menu"
import { Portal } from "@chakra-ui/portal"
import { Button } from "DesignSystem/components"
import { CreateStudyInterviewIcon } from "Shared/icons/CreateStudyInterviewIcon"
import { CreateStudyTemplateIcon } from "Shared/icons/CreateStudyTemplateIcon"
import { CreateStudyTestIcon } from "Shared/icons/CreateStudyTestIcon"
import { PlusSolidIcon } from "Shared/icons/untitled-ui/PlusSolidIcon"
import { CreateNewModeratedStudyModal } from "UsabilityHub/components/CreateNewModeratedStudyModal/CreateNewModeratedStudyModal"
import { useIsInterviewsEnabled } from "UsabilityHub/hooks/useIsInterviewsEnabled"
import { useModal } from "Utilities/modals/use-modal"
import React, { ReactNode } from "react"
import { useMatch, useNavigate } from "react-router"
import { ROUTES } from "../routes"

export const CreateNewStudyButton: React.FC = () => {
  const navigate = useNavigate()
  const interviewsEnabled = useIsInterviewsEnabled()

  // If we're on an individual project page, let's pass that through to the test builder as a query
  // param so that it can pre-select the project.
  const projectIdWithSlug = useMatch("/projects/:idWithSlug")?.params.idWithSlug
  const viewingProjectId = projectIdWithSlug
    ?.replaceAll(/[^0-9a-f]+/gi, "")
    .slice(-32)

  const createTestUrl = ROUTES.TESTS.NEW.buildPath(
    {},
    viewingProjectId ? { project_id: viewingProjectId } : {}
  )

  const {
    open: openCreateNewModeratedStudyModal,
    close: closeCreateNewModeratedStudyModal,
  } = useModal(CreateNewModeratedStudyModal)

  const openCreateModal = () => {
    openCreateNewModeratedStudyModal({
      onCreate: (id) => {
        navigate(ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId: id }))
        closeCreateNewModeratedStudyModal()
      },
    })
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="primary"
        size="emphasized"
        leftIcon={<PlusSolidIcon />}
        data-intercom-target="create-new-study-button"
      >
        Create study
      </MenuButton>
      <Portal>
        <MenuList zIndex="dropdown" overflow="hidden" py={1.5}>
          <CreateNewMenuItem
            heading="Test or survey"
            description="Usability tests, surveys, card sorts and more"
            data-intercom-target="create-new-test-or-survey"
            onClick={() => navigate(createTestUrl)}
          >
            <CreateStudyTestIcon boxSize={12} />
          </CreateNewMenuItem>

          {interviewsEnabled && (
            <CreateNewMenuItem
              heading="Interview"
              description="Set up, recruit and manage remote interviews"
              data-intercom-target="create-new-interview-study"
              onClick={openCreateModal}
            >
              <CreateStudyInterviewIcon boxSize={12} />
            </CreateNewMenuItem>
          )}

          <CreateNewMenuItem
            heading="From template"
            description="Browse our template library to create your test"
            data-intercom-target="create-new-from-template"
            onClick={() => navigate(ROUTES.DASHBOARD.TEMPLATES.path)}
          >
            <CreateStudyTemplateIcon boxSize={12} />
          </CreateNewMenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}

type CreateNewMenuItemProps = {
  heading: ReactNode
  description: ReactNode
} & MenuItemProps

const CreateNewMenuItem: React.FC<CreateNewMenuItemProps> = ({
  heading,
  description,
  children,
  ...props
}) => {
  return (
    <MenuItem {...props}>
      <Flex px={2} gap={3}>
        {children}

        <Flex direction="column" justify="center" gap={1}>
          <Text fontSize="sm" color="text.primary">
            {heading}
          </Text>
          <Text fontSize="xs" fontWeight="normal" color="text.primary">
            {description}
          </Text>
        </Flex>
      </Flex>
    </MenuItem>
  )
}

import { Stripe, StripeCardElement, StripeError } from "@stripe/stripe-js"
import { axios, isDeprecatedAxiosError } from "Services/axios"
import { throwIfDeclinedStripeError, vaultCardInStripe } from "Services/stripe"
import { useCallback } from "react"
import CreditCardsApi from "~/api/CreditCardsApi"
import { useRefreshCurrentAccount } from "./useCurrentAccount"

export const useStripeCards = () => {
  const refreshCurrentAccount = useRefreshCurrentAccount()

  const replaceCustomerCard = useCallback(
    async (
      cardElement: StripeCardElement | null,
      cardName: string,
      stripe: Stripe | null
    ) => {
      const token = await vaultCardInStripe(cardElement, cardName, stripe)

      try {
        // TODO: Move this to OpenAPI (note that we no longer use the response body)
        await axios.put(CreditCardsApi.update.path(), {
          token,
        })
        refreshCurrentAccount()
      } catch (error) {
        if (
          isDeprecatedAxiosError<{ error: StripeError }>(error) &&
          error.response !== undefined &&
          error.response.data !== undefined
        ) {
          const stripeError = error.response.data.error
          throwIfDeclinedStripeError(stripeError)
          throw new Error(stripeError.message)
        }
        throw error
      }
    },
    []
  )

  const removeCustomerCard = useCallback(async () => {
    try {
      // TODO: Move this to OpenAPI (note that we no longer use the response body)
      await axios.delete(CreditCardsApi.destroy.path())
      refreshCurrentAccount()
    } catch (error) {
      if (
        isDeprecatedAxiosError<{ error: StripeError }>(error) &&
        error.response !== undefined &&
        error.response.data !== undefined
      ) {
        const stripeError = error.response.data.error
        throwIfDeclinedStripeError(stripeError)
        throw new Error(stripeError.message)
      }
      throw error
    }
  }, [])

  return {
    replaceCustomerCard,
    removeCustomerCard,
  }
}

import { BoxProps, HStack, Icon, Text } from "@chakra-ui/react"
import { ChevronLeftOutlineIcon } from "Shared/icons/untitled-ui/ChevronLeftOutlineIcon"
import { ChevronRightOutlineIcon } from "Shared/icons/untitled-ui/ChevronRightOutlineIcon"
import { TreeNode } from "Types"
import React, { Fragment } from "react"
import { Badge } from "./Badge"

type PathProps = {
  path: (TreeNode | null)[]
  containerRef?: React.Ref<HTMLDivElement>
} & BoxProps

export const Path: React.FC<PathProps> = ({ path, containerRef, ...props }) => (
  <HStack
    wrap={"wrap"}
    alignItems="baseline"
    lineHeight="1.5rem"
    color="text.secondary"
    rowGap={0}
    ref={containerRef}
    {...props}
  >
    {path.map((node, index) =>
      node ? (
        <Fragment key={index}>
          {index > 0 && node && (
            <Icon
              as={
                node.depth <= path[index - 1]!.depth
                  ? ChevronLeftOutlineIcon
                  : ChevronRightOutlineIcon
              }
              color="text.subtlest"
              alignSelf="center"
            />
          )}
          <Text as="span">{node.label}</Text>
        </Fragment>
      ) : (
        <Badge
          key={index}
          fontSize="sm"
          px={2}
          rounded="base"
          fontWeight="medium"
          lineHeight="1.5rem"
          result="skipped"
        />
      )
    )}
  </HStack>
)

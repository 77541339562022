import {
  Button,
  ButtonProps,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { DeclineApplicantModal } from "UsabilityHub/components/ModeratedStudy/ApplicationsTable/DeclineApplicantModal"
import { ModeratedStudyApplication } from "UsabilityHub/components/ModeratedStudy/ApplicationsTable/Types"
import React from "react"
import { useApplicantDeclineModeratedStudyInvitation } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { useModeratedStudyContext } from "../ModeratedStudyContext"
import { getBookingStatusLabel } from "../ParticipantBookingStateBadge"
import { DrawerData } from "./ParticipantInfoDrawerData"

export type ApplicationToDecline = Pick<
  ModeratedStudyApplication,
  "id" | "is_panelist"
>

export const DeclineButton: React.FC<{
  canDecline: boolean
  bookingStatus?: DrawerData["attributes"]["bookingStatus"]
  applicationToDecline: ApplicationToDecline
}> = ({ canDecline, bookingStatus, applicationToDecline }) => {
  const declineDisclosure = useDisclosure()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const { moderatedStudyId } = useModeratedStudyContext()

  const { mutate } = useApplicantDeclineModeratedStudyInvitation({
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        queryKeyFn({
          path: "/api/moderated_studies/{moderated_study_id}/applications",
          operationId: "getModeratedStudyApplications",
          variables: {
            pathParams: {
              moderatedStudyId,
            },
          },
        })
      )
      toast({
        title: "Applicant marked as unsuitable",
        status: "success",
      })
    },
    onError: () => {
      toast({
        title: "Could not mark the applicant as unsuitable",
        status: "error",
      })
    },
  })

  const declineApplication = (moderated_study_application_id: string) => {
    mutate({
      pathParams: {
        moderatedStudyApplicationId: moderated_study_application_id,
      },
    })
  }

  return canDecline ? (
    <>
      <DeclineButtonCore onClick={declineDisclosure.onOpen} />
      <DeclineApplicantModal
        applicationToDecline={applicationToDecline}
        onSubmit={() => {
          declineApplication(applicationToDecline.id)
        }}
        {...declineDisclosure}
      />
    </>
  ) : (
    <Tooltip
      hasArrow
      placement="left"
      shouldWrapChildren
      label={
        bookingStatus ? (
          <>
            Cannot decline an application
            <br />
            with a status of ”
            {getBookingStatusLabel(bookingStatus, {
              lowercase: true,
            })}
            ”
          </>
        ) : (
          "Cannot decline an application with this status"
        )
      }
    >
      <DeclineButtonCore isDisabled />
    </Tooltip>
  )
}

const DeclineButtonCore: React.FC<
  Pick<ButtonProps, "isDisabled" | "onClick">
> = ({ isDisabled, onClick }) => (
  <Button
    variant="outline"
    color="red.500"
    isDisabled={isDisabled}
    onClick={onClick}
  >
    Mark as unsuitable
  </Button>
)

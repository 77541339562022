import {
  Alert,
  AlertIcon,
  Link,
  Stack,
  StackDivider,
  useDisclosure,
} from "@chakra-ui/react"
import { isReadonly } from "Redux/reducers/test-results/selectors"
import { useMaybeCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { CategoryRow } from "./CardSortCategories/CategoryRow"
import { ControlBar } from "./CardSortCategories/ControlBar"
import { GroupingModal } from "./CardSortCategories/GroupingModal"
import { FilterType, generateBestMatches } from "./CardSortCategories/filtering"
import { notUnsortedGroupLabel } from "./CardSortCategories/grouping"
import { SortType, sortCategories } from "./CardSortCategories/sorting"
import { useCardSortResultsContext } from "./CardSortResultsContext"

export const CardSortSectionResultsCategoryTab: React.FC = () => {
  const {
    cardSortType,
    sectionId,
    cards,
    categories,
    groupedCategories,
    sortData,
  } = useCardSortResultsContext()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchTerm, setSearchTerm] = useState("")
  const [sortType, setSortType] = useState<SortType>("position")
  const [filterType, setFilterType] = useState<FilterType>("all")
  const [viewingIndividualGroupId, setViewingIndividualGroupId] = useState<
    string | null
  >(null)
  const [selectedCategoryGroups, setSelectedCategoryGroups] = useState(
    [] as string[]
  )

  const readonly = useSelector(isReadonly)
  const canManage = useMaybeCurrentUser()?.can_manage_tests ?? false

  const filteredGroups = Object.fromEntries(
    Object.entries(groupedCategories).filter(
      ([label, categories]) =>
        label.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (!["groupedCategories", "ungroupedCategories"].includes(filterType) ||
          (filterType === "groupedCategories" && categories.length > 1) ||
          (filterType === "ungroupedCategories" && categories.length === 1))
    )
  )

  const selectedGroupedCategories = Object.fromEntries(
    selectedCategoryGroups.map((groupLabel) => {
      return [groupLabel, groupedCategories[groupLabel] ?? []]
    })
  )

  const sortedCategoryGroups = sortCategories(
    filteredGroups,
    sortType,
    sortData
  )

  const visibleGroups = Object.keys(sortedCategoryGroups)
  const visibleGroupsWithoutUnsorted = visibleGroups.filter(
    notUnsortedGroupLabel
  )
  const selectedAndVisibleGroups = selectedCategoryGroups.filter((groupLabel) =>
    visibleGroups.includes(groupLabel)
  )

  // Disable 'Select all' checkbox if `Unsorted` is the only visible category or no visible categories
  const disableSelectAllCheckbox = visibleGroupsWithoutUnsorted.length === 0
  const allVisibleSelected =
    !disableSelectAllCheckbox &&
    selectedAndVisibleGroups.length === visibleGroupsWithoutUnsorted.length

  const cardsById = new Map(cards.map((c) => [c.id!, c]))
  const bestMatchByCard = generateBestMatches(cards, categories, sortData)
  const showGroupingControls =
    cardSortType !== "closed" && !readonly && canManage

  return (
    <>
      <ControlBar
        showGroupingControls={showGroupingControls}
        groupingEnabled={selectedAndVisibleGroups.length >= 2}
        handleGroup={onOpen}
        disableSelectAll={disableSelectAllCheckbox}
        allSelected={allVisibleSelected}
        selectAll={() =>
          setSelectedCategoryGroups(
            allVisibleSelected ? [] : visibleGroupsWithoutUnsorted
          )
        }
        sortType={sortType}
        setSortType={setSortType}
        filterType={filterType}
        setFilterType={setFilterType}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        cardSortType={cardSortType}
      />

      {visibleGroups.length > 0 ? (
        <Stack spacing={3} divider={<StackDivider />}>
          {visibleGroups.map((group_label) => (
            <CategoryRow
              key={group_label}
              groupLabel={group_label}
              sectionId={sectionId}
              categories={sortedCategoryGroups[group_label]}
              sortData={sortData}
              cardsById={cardsById}
              showGroupingControls={showGroupingControls}
              bestMatchByCard={bestMatchByCard}
              filterType={filterType}
              selected={selectedCategoryGroups.includes(group_label)}
              setSelected={(v) =>
                setSelectedCategoryGroups((prev) =>
                  v
                    ? [...prev, group_label]
                    : prev.filter((c) => c !== group_label)
                )
              }
              onGroupClick={() => {
                setViewingIndividualGroupId(group_label)
              }}
            />
          ))}
        </Stack>
      ) : (
        <Alert wordBreak="break-all">
          <AlertIcon />
          {searchTerm === "" ? (
            <>No category data.</>
          ) : (
            <>
              No categories matched the search &ldquo;{searchTerm}&rdquo;.
              <Link flexShrink={0} onClick={() => setSearchTerm("")} ml={1}>
                Clear search
              </Link>
            </>
          )}
        </Alert>
      )}

      {viewingIndividualGroupId && (
        <GroupingModal
          editingExisting
          onClose={() => setViewingIndividualGroupId(null)}
          selectedGroupMap={{
            [viewingIndividualGroupId]:
              groupedCategories[viewingIndividualGroupId],
          }}
          readonly={!showGroupingControls}
        />
      )}

      {isOpen && (
        <GroupingModal
          onClose={onClose}
          afterSave={() => setSelectedCategoryGroups([])}
          selectedGroupMap={selectedGroupedCategories}
        />
      )}
    </>
  )
}

// JsFromRoutes CacheKey 3af7a5a2e4cbae2d8e923739e6a7a131
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  uploadCardImage: /* #__PURE__ */ definePathHelper('post', '/api/card_sort_cards/upload_card_image'),
  duplicateCardImage: /* #__PURE__ */ definePathHelper('post', '/api/card_sort_cards/duplicate_card_image'),
  removeTempCardImage: /* #__PURE__ */ definePathHelper('post', '/api/card_sort_cards/remove_temp_card_image'),
}

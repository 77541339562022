import { ParticipantDeletionReason } from "Types"
import { useCallback, useEffect, useMemo, useReducer } from "react"
import { useUploadRecording } from "~/api/generated/usabilityhub-components"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"
import { useTestRecordingContext } from "./context/TestRecordingContext"

const RECORDING_FORMATS = [
  "video/webm;codecs=vp9,opus",
  "video/webm;codecs=vp8,opus",
  "video/webm;codecs=h264,opus",
  "video/mp4;codecs=h264,aac",
  "video/webm;codecs=av01,opus",
]

const filterCodec = (
  recordingFormat: string,
  recordingTypes: RecordingType[]
) => {
  const regex = /codecs=([^,]+),([^,]+)/
  const codecs = recordingFormat.match(regex)

  const videoCodec = codecs![1]
  const audioCodec = codecs![2]

  const audioType = "microphone"

  const isAudioOnly =
    recordingTypes.length === 1 && recordingTypes[0] === audioType
  const isVideoOnly = recordingTypes.indexOf(audioType) === -1

  if (isAudioOnly || isVideoOnly) {
    // On Firefox, if we set audio codec when it's video-only recording or set video codec when it's audio-only recording, the recording will silently fail to record and won’t trigger the `dataavailable` event, without raising any errors.
    // So we need to set the correct codec based on the recording type.
    return recordingFormat.replace(
      /(codecs=)[^,]+,[^,]+/,
      `$1${isAudioOnly ? audioCodec : videoCodec}`
    )
  } else {
    return recordingFormat
  }
}

const getRecorderOpts = (recordingTypes: RecordingType[]) => {
  if (recordingTypes.length === 0) {
    return undefined
  }

  const isAudioOnly =
    recordingTypes.length === 1 && recordingTypes[0] === "microphone"

  const mimeType = RECORDING_FORMATS.find((mimeType) => {
    const format = filterCodec(mimeType, recordingTypes)
    return (
      typeof window.MediaRecorder === "function" &&
      MediaRecorder.isTypeSupported(format)
    )
  })

  return {
    // Limit high bitrate
    videoBitsPerSecond: isAudioOnly ? undefined : 1.5e6,
    mimeType: mimeType && filterCodec(mimeType, recordingTypes),
  }
}

const SCREEN_TYPE: RecordingType = "screen"
type defaultHandleDataAvailableFn = (data: BlobEvent) => Promise<void>
type HandleDataAvailableFn = (
  data: BlobEvent,
  streamIndex: number
) => Promise<void>

type SuccessCallback = (stream: MediaStream) => void
type FailureCallback = (error: string) => void
export const shareScreen = async (
  success: SuccessCallback,
  failure: FailureCallback
) => {
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      audio: false,
      video: {
        displaySurface: "browser",
      },
      //@ts-ignore
      surfaceSwitching: "exclude",
      systemAudio: "exclude",
      preferCurrentTab: true,
    })
    success(stream)
  } catch (err) {
    failure(err.message)
  }
}
export const captureUserMedias = async (
  constraints: MediaStreamConstraints,
  success: SuccessCallback,
  failure: FailureCallback
) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      ...constraints,
    })
    success(stream)
  } catch (err) {
    failure(err.message)
  }
}
const recordStream = (
  stream: MediaStream,
  onStart: () => void,
  onDataAvailable: defaultHandleDataAvailableFn,
  success: (recorder: MediaRecorder) => void,
  failure: FailureCallback,
  options?: MediaRecorderOptions
) => {
  try {
    const mediaRecorder = new MediaRecorder(stream, options)
    // onDataAvailable will be called when the recording is paused or stopped.
    // mediaRecorder.start() can optionally be passed a timeslice argument with a value in milliseconds. If specified, this event will be called every timeslice milliseconds.
    mediaRecorder.ondataavailable = onDataAvailable
    mediaRecorder.onstart = onStart
    success(mediaRecorder)
    mediaRecorder.start()
  } catch (err) {
    failure(err)
  }
}
const mergeAudioAndVideoStream = (
  audioStream: MediaStream,
  videoStream: MediaStream
) => {
  const mediaStream = new MediaStream([
    ...audioStream.getAudioTracks(),
    ...videoStream.getVideoTracks(),
  ])
  mediaStream.getTracks().forEach((track) =>
    track.addEventListener("ended", () =>
      // If audio and video tracks have been merged and one is ended, stop both to trigger prematureStopListener
      mediaStream
        .getTracks()
        .forEach((track) => track.stop())
    )
  )
  return mediaStream
}

type SectionRecordingsStatus =
  | "init"
  | "prepare"
  | "streams-ready"
  | "recorders-ready"
  | "recording"
  | "stopped"

type SectionRecordings = {
  requiredRecordingTypes: RecordingType[]
  mediaStreamIndexes: Partial<Record<RecordingType, number>>
  mediaStreams: MediaStream[]
  tempAudioStream?: MediaStream
  mediaRecorders: (MediaRecorder | undefined)[]
  startedAt: string[]
  // prepare: The recording types are set, but the streams are not ready.
  // streams-ready: The streams are ready.
  // recorders-ready: The recorders are ready.
  // recording: The recordings are in progress.
  // stopped: The recordings are stopped.
  status: SectionRecordingsStatus
  error: string | null
  cleanup?: () => void
  expectedUploadRequests: number
  sentUploadRequests: number
}

const INITIAL_SECTION_RECORDINGS: SectionRecordings = {
  requiredRecordingTypes: [],
  mediaStreamIndexes: {},
  mediaStreams: [],
  mediaRecorders: [],
  startedAt: [],
  error: null,
  status: "init",
  expectedUploadRequests: 0,
  sentUploadRequests: 0,
}

type NewStream = {
  recordingTypes: RecordingType[]
  stream: MediaStream
}

type SectionRecordingAction =
  | {
      type: "set-required-recording-types"
      recordingTypes: RecordingType[]
    }
  | ({
      type: "add-stream"
      cleanup?: () => void
    } & NewStream)
  | {
      type: "add-recorder"
      recorder: MediaRecorder
      streamIndex: number
    }
  | {
      type: "update-time"
      streamIndex: number
    }
  | {
      type: "update-status"
      status: Extract<SectionRecordingsStatus, "recording" | "stopped">
    }
  | {
      type: "sent-upload-request"
    }
  | {
      type: "clear-cleanup"
    }

const recordingChangeReducer = (
  state: SectionRecordings,
  action: SectionRecordingAction
): SectionRecordings => {
  switch (action.type) {
    case "set-required-recording-types": {
      return {
        ...state,
        requiredRecordingTypes: action.recordingTypes,
        status: "prepare",
      }
    }
    case "add-stream": {
      const mediaStreams = [...state.mediaStreams]
      let updateMediaStreamIndexes = true
      let tempAudioStream = state.tempAudioStream

      const recordingTypes = action.recordingTypes

      const isAudioStream =
        recordingTypes.length === 1 && recordingTypes[0] === "microphone"
      const audioStreamShouldBeMerged =
        state.requiredRecordingTypes.length > 1 && isAudioStream
      const hasStream = mediaStreams.length > 0

      if (hasStream && isAudioStream) {
        // Replace the existing stream with the combined audio and video stream
        const lastStream = mediaStreams[mediaStreams.length - 1]
        const audioStream = tempAudioStream || action.stream
        mediaStreams[mediaStreams.length - 1] = mergeAudioAndVideoStream(
          audioStream,
          lastStream
        )
      } else if (tempAudioStream && !isAudioStream) {
        // Add the combined audio and video stream instead
        const stream = mergeAudioAndVideoStream(tempAudioStream, action.stream)
        tempAudioStream = undefined
        recordingTypes.push("microphone")
        mediaStreams.push(stream)
      } else if (audioStreamShouldBeMerged) {
        tempAudioStream = action.stream
        updateMediaStreamIndexes = false
      } else {
        mediaStreams.push(action.stream)
      }

      const mediaStreamIndexes = {
        ...state.mediaStreamIndexes,
        ...(updateMediaStreamIndexes &&
          recordingTypes.reduce(
            (acc, type) => ({ ...acc, [type]: state.mediaStreams.length }),
            {}
          )),
      }

      const isReady =
        Object.keys(mediaStreamIndexes).length ===
        state.requiredRecordingTypes.length

      return {
        ...state,
        mediaStreamIndexes,
        mediaStreams,
        tempAudioStream,
        status: isReady ? "streams-ready" : state.status,
        cleanup: () => {
          state.cleanup && state.cleanup()
          action.cleanup && action.cleanup()
        },
      }
    }
    case "add-recorder": {
      const mediaRecorders = [...state.mediaRecorders]
      mediaRecorders[action.streamIndex] = action.recorder

      const isReady = mediaRecorders.length === state.mediaStreams.length
      return {
        ...state,
        mediaRecorders,
        status: isReady ? "recorders-ready" : state.status,
      }
    }
    case "update-time": {
      const { startedAt } = state
      startedAt[action.streamIndex] = new Date().toISOString()

      return { ...state, startedAt }
    }
    case "update-status": {
      const expectedUploadRequests =
        action.status === "recording"
          ? state.mediaRecorders.length
          : state.expectedUploadRequests

      return { ...state, status: action.status, expectedUploadRequests }
    }
    case "sent-upload-request": {
      return { ...state, sentUploadRequests: state.sentUploadRequests + 1 }
    }
    case "clear-cleanup": {
      return { ...state, cleanup: undefined }
    }
    default: {
      return state
    }
  }
}

type Props = {
  audioDeviceId?: string
  videoDeviceId?: string
  screenStream?: MediaStream
  setScreenStream?: (stream: MediaStream | undefined) => void
  uploadCallback: (
    recordingId: string,
    isSectionRecordingStopped: boolean
  ) => void
  addUploadRequest: () => void
}

export const useTestTakingRecordings = ({
  audioDeviceId,
  videoDeviceId,
  screenStream,
  setScreenStream,
  uploadCallback,
  addUploadRequest,
}: Props) => {
  const [sectionRecordings, dispatchSectionRecording] = useReducer(
    recordingChangeReducer,
    INITIAL_SECTION_RECORDINGS
  )

  const recorderOptions = useMemo(
    () => getRecorderOpts(sectionRecordings.requiredRecordingTypes),
    [sectionRecordings.requiredRecordingTypes]
  )

  const { setError: setTestRecordingError } = useTestRecordingContext()

  const cleanup = () => {
    if (sectionRecordings.cleanup) {
      sectionRecordings.cleanup()
      dispatchSectionRecording({ type: "clear-cleanup" })
    }
  }

  const setError = (error: ParticipantDeletionReason) => {
    cleanup()
    setTestRecordingError((prevError) => (prevError ? prevError : error))
  }

  const { mutate } = useUploadRecording({
    onSuccess: (data, _variables) => {
      const isSectionRecordingStopped = sectionRecordings.mediaRecorders.every(
        (recorder) => recorder && recorder.state === "inactive"
      )
      uploadCallback(data.recording_id, isSectionRecordingStopped)
    },
    onError: () => setError(ParticipantDeletionReason.RecordingUploadFailed),
  })

  const uploadRecording: HandleDataAvailableFn = async (
    event,
    streamIndex: number
  ) => {
    if (event.data.size > 0) {
      const endTime = new Date().toISOString()
      const startTime = sectionRecordings.startedAt[streamIndex]

      addUploadRequest()

      const blob = new Blob([event.data], {
        type: "video/webm",
      })

      const file = new File([blob], `recording_${endTime}.webm`)
      const recordingTypes: RecordingType[] = []
      if (sectionRecordings.mediaStreamIndexes.camera === streamIndex)
        recordingTypes.push("camera")
      if (sectionRecordings.mediaStreamIndexes.microphone === streamIndex)
        recordingTypes.push("microphone")
      if (sectionRecordings.mediaStreamIndexes.screen === streamIndex)
        recordingTypes.push("screen")

      mutate({
        body: {
          file,
          recording_types: recordingTypes,
          started_at: startTime,
          ended_at: endTime,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      dispatchSectionRecording({ type: "sent-upload-request" })
    } else {
      setError(ParticipantDeletionReason.RecordingFailed)
    }
  }

  const addStream = (
    recordingTypes: RecordingType[],
    stream: MediaStream,
    cleanup?: () => void
  ) =>
    dispatchSectionRecording({
      type: "add-stream",
      recordingTypes,
      stream,
      cleanup,
    })

  const startScreenSharing = async () => {
    const success = (stream: MediaStream) => {
      // The screenStream might still provide the old value after `setScreenStream(undefined)`.
      const isScreenStreamInactive = !screenStream || !screenStream.active
      isScreenStreamInactive && setScreenStream && setScreenStream(stream)
      addStream([SCREEN_TYPE], stream)
    }

    if (screenStream && screenStream.active) {
      success(screenStream)
    } else {
      setScreenStream && setScreenStream(undefined)
      await shareScreen(success, () =>
        setError(ParticipantDeletionReason.RecordingPermissionDenied)
      )
    }
  }

  const startCaptureUserMedias = async (
    recordingTypes: Exclude<RecordingType, typeof SCREEN_TYPE>[]
  ) => {
    const constraints = {
      audio: recordingTypes.includes("microphone")
        ? { deviceId: audioDeviceId ? { exact: audioDeviceId } : undefined }
        : false,
      video: recordingTypes.includes("camera")
        ? { deviceId: videoDeviceId ? { exact: videoDeviceId } : undefined }
        : false,
    }
    const success = (stream: MediaStream) => {
      const cleanup = () => stream.getTracks().forEach((track) => track.stop())
      addStream(recordingTypes, stream, cleanup)
    }
    await captureUserMedias(constraints, success, () =>
      setError(ParticipantDeletionReason.RecordingPermissionDenied)
    )
  }

  const startRecording = (
    stream: MediaStream,
    streamIndex: number,
    handleDataAvailable: defaultHandleDataAvailableFn
  ) => {
    const success = (recorder: MediaRecorder) =>
      dispatchSectionRecording({
        type: "add-recorder",
        recorder,
        streamIndex,
      })

    const onStart = () => {
      dispatchSectionRecording({
        type: "update-time",
        streamIndex,
      })
    }
    recordStream(
      stream,
      onStart,
      handleDataAvailable,
      success,
      () => setError(ParticipantDeletionReason.RecordingPermissionDenied),
      recorderOptions
    )
  }

  const prematureStopListener = () => {
    setError(ParticipantDeletionReason.RecordingPrematureStop)
  }

  useEffect(() => {
    const status = sectionRecordings.status
    switch (status) {
      case "prepare": {
        const recordingTypes = sectionRecordings.requiredRecordingTypes
        if (recordingTypes.includes(SCREEN_TYPE)) {
          startScreenSharing()
        }

        const userMedias = recordingTypes.filter(
          (type) => type !== SCREEN_TYPE
        ) as Exclude<(typeof recordingTypes)[number], typeof SCREEN_TYPE>[]
        if (userMedias.length > 0) {
          startCaptureUserMedias(userMedias)
        }
        break
      }
      case "streams-ready": {
        sectionRecordings.mediaStreams.forEach((stream) => {
          // @ts-ignore
          stream.oninactive = prematureStopListener
        })
        break
      }
      default: {
        return
      }
    }
  }, [sectionRecordings.status])

  const areAllStreamsActive =
    sectionRecordings.status === "streams-ready" &&
    sectionRecordings.mediaStreams.every((stream) => stream.active)

  const isBeingRecorded =
    sectionRecordings.status === "recorders-ready" &&
    sectionRecordings.mediaRecorders.every(
      (recorder) => recorder && recorder.state === "recording"
    )

  useEffect(() => {
    if (isBeingRecorded) {
      dispatchSectionRecording({ type: "update-status", status: "recording" })
    }
  }, [isBeingRecorded])

  // `oninactive` event is not supported on Firefox and Safari, so we need to manually check if all streams are still active.
  const isValidRecordingState =
    sectionRecordings.status === "recording" &&
    sectionRecordings.mediaStreams.every((stream) => stream.active) &&
    sectionRecordings.mediaRecorders.length > 0 &&
    sectionRecordings.mediaRecorders.every(
      (recorder) => recorder && recorder.state === "recording"
    )

  const isBeingStopped =
    sectionRecordings.status === "recording" &&
    sectionRecordings.mediaRecorders.every(
      (recorder) => recorder && recorder.state === "inactive"
    )

  useEffect(() => {
    if (isBeingStopped) {
      dispatchSectionRecording({ type: "update-status", status: "stopped" })
    }
  }, [isBeingStopped])

  // This function is used to start streams
  const prepareSectionRecordings = (recordingTypes: RecordingType[]) => {
    dispatchSectionRecording({
      type: "set-required-recording-types",
      recordingTypes,
    })
  }

  // This function is used to start recording
  const startSectionRecordings = () => {
    if (areAllStreamsActive) {
      sectionRecordings.mediaStreams.forEach((stream, index) => {
        startRecording(
          stream,
          index,
          async (data: BlobEvent) => await uploadRecording(data, index)
        )
      })
    } else {
      setError(ParticipantDeletionReason.RecordingPermissionDenied)
    }
  }

  const stopSectionRecordings = useCallback(() => {
    sectionRecordings.mediaStreams.forEach((stream) => {
      // @ts-ignore
      stream.oninactive = null
    })
    sectionRecordings.mediaRecorders.forEach((recorder) => {
      recorder && recorder.stop()
    })
    cleanup()
  }, [sectionRecordings.status === "recording"])

  const allUploadRequestsSent =
    sectionRecordings.expectedUploadRequests > 0 &&
    sectionRecordings.sentUploadRequests ===
      sectionRecordings.expectedUploadRequests

  return {
    prepareSectionRecordings,
    startSectionRecordings,
    stopSectionRecordings,
    isValidRecordingState,
    allUploadRequestsSent,
    areAllStreamsActive,
  }
}

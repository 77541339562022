/* eslint-disable react/jsx-key */
import { createIcon } from "@chakra-ui/react"
import React from "react"

export const FigmaIcon = createIcon({
  displayName: "FigmaIcon",
  viewBox: "0 0 24 24",
  path: (
    <g id="Group">
      <path
        d="M9 21C9.79565 21 10.5587 20.6839 11.1213 20.1213C11.6839 19.5587 12 18.7956 12 18V15H9C8.20435 15 7.44129 15.3161 6.87868 15.8787C6.31607 16.4413 6 17.2044 6 18C6 18.7956 6.31607 19.5587 6.87868 20.1213C7.44129 20.6839 8.20435 21 9 21Z"
        fill="#24CE85"
      />
      <path
        d="M6 12C6 12.7956 6.31607 13.5587 6.87868 14.1213C7.44129 14.6839 8.20435 15 9 15H12V9H9C8.20435 9 7.44129 9.31607 6.87868 9.87868C6.31607 10.4413 6 11.2044 6 12Z"
        fill="#A160FC"
      />
      <path
        d="M6.00201 6C6.00201 6.79565 6.31808 7.55871 6.88069 8.12132C7.4433 8.68393 8.20636 9 9.00201 9H12.002V3H9.00201C8.20636 3 7.4433 3.31607 6.88069 3.87868C6.31808 4.44129 6.00201 5.20435 6.00201 6Z"
        fill="#F04F2B"
      />
      <path
        d="M11.999 3V9H14.999C15.7947 9 16.5577 8.68393 17.1203 8.12132C17.683 7.55871 17.999 6.79565 17.999 6C17.999 5.20435 17.683 4.44129 17.1203 3.87868C16.5577 3.31607 15.7947 3 14.999 3H11.999Z"
        fill="#FD7366"
      />
      <path
        d="M18 12C18 11.2042 17.6839 10.441 17.1212 9.87834C16.5585 9.31563 15.7953 8.99951 14.9995 8.99951C14.2037 8.99951 13.4406 9.31563 12.8778 9.87834C12.3151 10.441 11.999 11.2042 11.999 12C11.999 12.7957 12.3151 13.5587 12.8777 14.1213C13.4403 14.6839 14.2034 15 14.999 15C15.7947 15 16.5577 14.6839 17.1203 14.1213C17.683 13.5587 17.999 12.7957 17.999 12H18Z"
        fill="#2CBDFB"
      />
    </g>
  ),
})

import {
  Box,
  Center,
  Checkbox,
  Flex,
  Grid,
  Icon,
  Image,
  Text,
} from "@chakra-ui/react"
import { FolderIcon } from "@heroicons/react/outline"
import React from "react"

import { CardSortCard, CardSortCategoryCard } from "Types"

import { CategoryCardPairCountItem } from "../CategoryCardPairCountItem"
import { ExpandablePanel } from "../ExpandablePanel"

import { FilterType } from "./filtering"
import {
  EitherCategory,
  humanizeGroupLabel,
  isItalicCategoryGroupNames,
  notUnsortedGroupLabel,
} from "./grouping"

interface Props {
  groupLabel: string
  categories: EitherCategory[]
  sortData: CardSortCategoryCard[]
  cardsById: Map<number, CardSortCard>
  bestMatchByCard: Map<number, number>
  showGroupingControls: boolean
  filterType: FilterType
  sectionId: number
  selected: boolean
  setSelected: (selected: boolean) => void
  onGroupClick: () => void
}

export const CategoryRow: React.FC<Props> = ({
  groupLabel,
  categories,
  sortData,
  cardsById,
  bestMatchByCard,
  showGroupingControls,
  filterType,
  sectionId,
  selected,
  setSelected,
  onGroupClick,
}) => {
  const categoryIds = categories.map((c) => Number(c.id))

  const relatedSortData = sortData.filter((cardSort) =>
    categoryIds.includes(
      Number(
        cardSort.card_sort_category_id ?? cardSort.card_sort_open_category_id
      )
    )
  )

  const cardFrequencies = new Map<number, number>()
  relatedSortData.forEach((cardSortCategoryCard) => {
    const cardId = cardSortCategoryCard.card_sort_card_id
    cardFrequencies.set(cardId, (cardFrequencies.get(cardId) || 0) + 1)
  })

  const cardTotals = new Map<number, number>()
  sortData.forEach((cardSortCategoryCard) => {
    const cardId = cardSortCategoryCard.card_sort_card_id
    cardTotals.set(cardId, (cardTotals.get(cardId) || 0) + 1)
  })

  const cardsByFreq = [...cardFrequencies.entries()]
    .filter(([, freq]) => freq > 0)
    .filter(
      ([cardId]) =>
        filterType !== "bestMatch" ||
        (filterType === "bestMatch" &&
          categoryIds.includes(bestMatchByCard.get(cardId)!))
    )
    .sort(([, freqA], [, freqB]) => freqB - freqA)

  return (
    <Grid
      templateColumns="repeat(2, minmax(0,1fr))"
      key={groupLabel}
      gap={4}
      data-testid="card-sort-category"
    >
      <Flex gap={3} align="flex-start">
        {showGroupingControls && (
          <Center h="58px">
            {notUnsortedGroupLabel(groupLabel) ? (
              <Checkbox
                size="md"
                isChecked={selected}
                onChange={(e) => setSelected(e.target.checked)}
              />
            ) : (
              <Checkbox size="md" isDisabled />
            )}
          </Center>
        )}
        <Box
          flexGrow={1}
          _hover={(categoryIds.length > 1 && { cursor: "pointer" }) || {}}
          onClick={categoryIds.length > 1 ? onGroupClick : undefined}
        >
          <Flex flexGrow={1}>
            <Flex
              position="relative"
              align="center"
              flexGrow={1}
              rounded="md"
              borderWidth="1px"
              p={4}
              bg="white"
              zIndex={3}
              gap={2}
            >
              <Icon as={FolderIcon} color="gray.500" />
              <Text
                fontStyle={
                  isItalicCategoryGroupNames(groupLabel, categories)
                    ? "italic"
                    : "normal"
                }
              >
                {humanizeGroupLabel(groupLabel)}
              </Text>
              <Text
                fontSize="xs"
                color="gray.500"
                whiteSpace="nowrap"
                ms="auto"
              >
                {cardsByFreq.length}{" "}
                {cardsByFreq.length === 1 ? "card" : "cards"}
              </Text>
            </Flex>
          </Flex>
          {categoryIds.length > 1 && (
            <>
              <Box
                position="relative"
                rounded="md"
                borderWidth="1px"
                marginX={2}
                height={4}
                marginTop={-2}
                bg="gray.50"
                zIndex={2}
              ></Box>
              <Box
                position="relative"
                rounded="md"
                borderWidth="1px"
                marginX={4}
                height={4}
                marginTop={-2}
                bg="gray.100"
                zIndex={1}
              ></Box>
            </>
          )}
        </Box>
      </Flex>
      <Box>
        <ExpandablePanel
          numberToShow={3}
          maxH="188px"
          pluralEntityName="cards"
          items={cardsByFreq.map(([cardId, freq]) => {
            const card = cardsById.get(cardId)

            return (
              <CategoryCardPairCountItem
                key={cardId}
                sectionId={sectionId}
                cardId={Number(cardId)}
                categoryIds={categoryIds}
                count={freq}
                total={cardTotals.get(cardId)!}
              >
                {card?.uploaded_image_url && (
                  <Center
                    bg="white"
                    borderColor="gray.200"
                    borderWidth={1}
                    rounded="sm"
                    minW="100px"
                    w="100px"
                    h="80px"
                    mr={2}
                  >
                    <Image
                      borderRadius="sm"
                      maxH="100%"
                      maxW="100%"
                      src={card.uploaded_image_url}
                    />
                  </Center>
                )}
                <Text textOverflow="ellipsis" overflow="hidden">
                  {card?.label ?? "Missing label"}
                </Text>
              </CategoryCardPairCountItem>
            )
          })}
        />
      </Box>
    </Grid>
  )
}

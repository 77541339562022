import { createIcon } from "@chakra-ui/react"
import React from "react"

export const EllipsisVerticalIcon = createIcon({
  displayName: "EllipsisVerticalIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
    />
  ),
})

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react"
import { PurchaseConfirmationModal } from "Components/payments/purchase-confirmation-modal"
import { useFeatureFlag } from "Hooks/use-feature-flag"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import React, { useState } from "react"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { CalculatePriceAndEstimatedDurationResponse } from "~/api/generated/usabilityhub-components"
import { useOrderForm } from "../OrderFormProvider"
import { QuoteState } from "../types"
import { useLyssnaPanelOrderSubmit } from "./useLyssnaPanelOrderSubmit"

interface Props {
  quote: CalculatePriceAndEstimatedDurationResponse | null
  quoteState: QuoteState
}

export const LyssnaPanelSubmitOrder: React.FC<Props> = ({
  quote,
  quoteState,
}) => {
  const { testId: usabilityTestUniqueId } = useTypedParams(ROUTES.TEST.RECRUIT)
  const {
    numParticipants,
    estimatedIncidenceRate,
    testHasScreener,
    testHasRecordings,
  } = useOrderForm()

  const [isConfirming, setIsConfirming] = useState(false)

  const { submitOrder, orderCreateState } = useLyssnaPanelOrderSubmit({
    confirmCreditPurchase: () => setIsConfirming(true),
  })

  const canUserPurchaseCredits = useCurrentUser().can_purchase_credits
  const queryString = new URLSearchParams(window.location.search)
  const forceEnableSubmit = queryString.get("enable_submit") !== null

  const recordingsEnabled = useFeatureFlag("usability_test_recording_orders")

  const missingIncidenceRate =
    testHasScreener && estimatedIncidenceRate === null
  const canSubmit =
    forceEnableSubmit ||
    (quoteState === "success" &&
      !missingIncidenceRate &&
      (!testHasRecordings || recordingsEnabled) &&
      (canUserPurchaseCredits || quote?.credits_to_be_purchased === 0))

  const handleSubmit = async () => {
    if (!quote || (!forceEnableSubmit && quoteState !== "success")) return

    submitOrder(usabilityTestUniqueId, numParticipants, quote, isConfirming)
  }

  const hideErrors = testHasRecordings && !recordingsEnabled

  return (
    <Flex direction="column" gap={2}>
      <QuoteValidationErrors
        missingIncidenceRate={missingIncidenceRate}
        notAllowedToUseRecordings={testHasRecordings && !recordingsEnabled}
      />

      {quoteState === "error" && !hideErrors && (
        <Alert maxH="10rem" status="error">
          <AlertIcon />
          <Text fontWeight="bold">
            There was an error calculating your quote. Please try again.
          </Text>
        </Alert>
      )}

      <Button
        isDisabled={!canSubmit}
        isLoading={orderCreateState === "loading"}
        colorScheme="brand.primary"
        w="100%"
        onClick={handleSubmit}
        data-intercom-target="submit-order-button"
        mt={4}
      >
        Submit order
      </Button>

      {isConfirming && quote && (
        <PurchaseConfirmationModal
          submitPurchaseAsync={handleSubmit}
          onCancel={() => setIsConfirming(false)}
          formattedPrice={quote.formatted_price}
          heading={`Purchase ${quote.credit_cost} credits`}
        />
      )}
    </Flex>
  )
}

type QuoteValidationErrorsProps = {
  missingIncidenceRate: boolean
  notAllowedToUseRecordings: boolean
}

const QuoteValidationErrors: React.FC<QuoteValidationErrorsProps> = ({
  missingIncidenceRate,
  notAllowedToUseRecordings,
}) => {
  if (notAllowedToUseRecordings) {
    return (
      <Alert status="warning">
        <AlertIcon />

        <AlertDescription>
          Recording is not currently supported for Lyssna panel orders. Please
          remove all recording requirements from your test to continue.
        </AlertDescription>
      </Alert>
    )
  }

  if (missingIncidenceRate) {
    return (
      <Alert maxH="10rem" status="warning">
        <AlertIcon />
        <AlertDescription>
          As this test includes a screener, you must select an estimated
          screen-in rate.
        </AlertDescription>
      </Alert>
    )
  }
}

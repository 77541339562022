import { Flex, Text, TextProps, keyframes } from "@chakra-ui/react"
import styled from "@emotion/styled"
import React, { PropsWithChildren } from "react"

type Props = PropsWithChildren<{ isRaised?: boolean }>

type SubComponents = {
  TagAndAction: typeof TagAndAction
  Info: typeof Info
}

export const MetricsCard: React.FC<Props> & SubComponents = ({
  isRaised = true,
  ...props
}) => {
  const surfaceStyles = isRaised
    ? {
        shadow: "ds.raised",
      }
    : {
        borderWidth: "1px",
        borderColor: "ds.border.default",
        rounded: "12px",
      }

  return (
    <Flex
      p={4}
      grow={1}
      shrink={0}
      basis="14rem"
      bg="ds.surface.raised.resting"
      rounded="16px"
      {...surfaceStyles}
      h="100%"
    >
      <Flex direction="column" grow={1} gap={6}>
        {props.children}
      </Flex>
    </Flex>
  )
}

const TagAndAction: React.FC<{
  tag: React.ReactNode
  action?: React.ReactNode
}> = ({ tag, action }) => (
  <Flex justify="space-between" alignItems="flex-start" minH={6}>
    <>
      {tag}
      {action ? action : null}
    </>
  </Flex>
)

const Info: React.FC<{
  value: React.ReactNode
  description: React.ReactNode
}> = ({ value, description }) => (
  <Flex direction="column" gap={1}>
    <Text textStyle="ds.display.emphasized">
      {value ?? (
        <JumpingDots textStyle="ds.display.emphasized" color="ds.text.subtle" />
      )}
    </Text>

    <Text textStyle="ds.paragraph.primary" color="ds.text.subtle">
      {description}
    </Text>
  </Flex>
)

MetricsCard.TagAndAction = TagAndAction
MetricsCard.Info = Info

const wave = keyframes`
0%, 60%, 100% {
  transform: initial;
}

30% {
  transform: translateY(-15px);
}
`

const JumpingDot = styled.span`
  position: relative;
  display: inline-block;
  animation: ${wave} 1s ease-in-out infinite;

  &:nth-of-type(2) {
    animation-delay: -0.8s;
  }

  &:nth-of-type(3) {
    animation-delay: -0.7s;
  }
`

const JumpingDots: React.FC<TextProps> = (textProps) => {
  return (
    <Text {...textProps}>
      <JumpingDot>.</JumpingDot>
      <JumpingDot>.</JumpingDot>
      <JumpingDot>.</JumpingDot>
    </Text>
  )
}

import { Flex, HStack, Spacer, Switch, Text } from "@chakra-ui/react"
import React from "react"
import { Control, UseFormSetValue } from "react-hook-form"

import { ModeratedStudyAvailabilityFormValues } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-builder/forms/availability-section-form"
import { Availability } from "~/api/generated/usabilityhubSchemas"

import { TimeSlots } from "./TimeSlots"
import { Day } from "./days"

type DayAvailabilityProps = {
  name: string
  day: keyof Availability["availability_by_day"]
  availability: { starts_at: string; ends_at: string }[]
  control: Control<ModeratedStudyAvailabilityFormValues>
  setValue: UseFormSetValue<ModeratedStudyAvailabilityFormValues>
  copyAvailabilityByDay: (sourceDay: Day, targetDays: Day[]) => void
}

export const DayAvailability: React.FC<DayAvailabilityProps> = ({
  name,
  day,
  availability,
  control,
  setValue,
  copyAvailabilityByDay,
}) => {
  const isAvailable = availability.length > 0

  return (
    <HStack width="full" spacing={1} minHeight="40px" alignItems="flex-start">
      <Flex align="center" mt={1.5}>
        <HStack height="full" spacing={3}>
          <Switch
            size="md"
            isChecked={isAvailable}
            onChange={() => {
              if (isAvailable) {
                setValue(`availabilityByDay.${day}`, [])
              } else {
                setValue(`availabilityByDay.${day}`, [
                  { starts_at: "09:00", ends_at: "17:00" },
                ])
              }
            }}
          />
          <Text
            width="14"
            textColor="text.primary"
            fontSize="sm"
            lineHeight={7}
            fontWeight="semibold"
          >
            {name}
          </Text>
        </HStack>
      </Flex>
      <Spacer />
      <Flex height="full" width="full" align="center">
        {isAvailable ? (
          <TimeSlots
            control={control}
            fieldName={`availabilityByDay.${day}`}
            day={day}
            handleCopy={copyAvailabilityByDay}
          />
        ) : (
          <>
            <Text w="full" textColor="text.secondary">
              Unavailable
            </Text>
          </>
        )}
      </Flex>
    </HStack>
  )
}

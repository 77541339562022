import { Box, BoxProps, useToast } from "@chakra-ui/react"
import { useTranslate } from "Shared/hooks/useTranslate"
import { VideoScreenshot as VideoScreenshotType } from "Types"
import React from "react"
import { VideoPlayer } from "./VideoPlayer"

interface VideoScreenshotProps extends BoxProps {
  screenshot: VideoScreenshotType
  canSkipAhead: boolean
  onMediaEnded: () => void
}

export const VideoScreenshot: React.FC<
  React.PropsWithChildren<VideoScreenshotProps>
> = ({ screenshot, onMediaEnded, canSkipAhead, ...boxProps }) => {
  const toast = useToast()
  const translate = useTranslate()
  return (
    <Box bg="brand.primary.100" {...boxProps}>
      <VideoPlayer
        url={screenshot._url || undefined}
        canSkipAhead={canSkipAhead}
        onMediaEnded={onMediaEnded}
        onSkippedAhead={() => {
          toast.closeAll()
          toast({
            title: translate("test.screenshots.video.skipping_ahead_disabled"),
            status: "warning",
          })
        }}
      />
    </Box>
  )
}

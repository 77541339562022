import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Heading,
  HeadingProps,
  Icon,
  Input,
  InputProps,
  useBoolean,
  useOutsideClick,
} from "@chakra-ui/react"
import { PencilIcon } from "@heroicons/react/solid"
import React, { FormEvent, useRef } from "react"

type InPlaceEditorProps = {
  value: string
  handleSave: (newValue: string) => Promise<void>
  inputProps?: InputProps
  buttonProps?: ButtonProps
  defaultIsEditing?: boolean
} & HeadingProps

export const InPlaceEditor: React.FC<InPlaceEditorProps> = ({
  value,
  handleSave,
  inputProps,
  buttonProps,
  defaultIsEditing = false,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [isEditing, { on: startEditing, off: stopEditing }] =
    useBoolean(defaultIsEditing)
  const [isSaving, { on: startSaving, off: stopSaving }] = useBoolean(false)

  useOutsideClick({
    ref,
    handler: stopEditing,
  })

  if (!isEditing) {
    return (
      <Heading
        as="h1"
        textStyle="h1"
        size="md"
        color="text.primary"
        display="flex"
        alignItems="center"
        noOfLines={1}
        lineHeight="32px"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          startEditing()
        }}
        sx={{
          cursor: "pointer",
          "&:not(:hover) svg": {
            display: "none",
          },
        }}
        {...rest}
      >
        {value}
        <Icon as={PencilIcon} color="gray.500" ms={2} />
      </Heading>
    )
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const formData = e.target as typeof e.target & {
      value: { value: string }
    }
    const newName = formData.value.value

    startSaving()
    try {
      await handleSave(newName)
      stopEditing()
    } finally {
      // The handleSave function will implement error handling, but if it raises
      // we need to make sure the loading spinner goes away.
      stopSaving()
    }
  }

  return (
    <Box
      as="form"
      w="full"
      onSubmit={handleSubmit}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
      }}
    >
      <Flex gap={2} ref={ref} w="full">
        <Input
          autoFocus
          name="value"
          size="sm"
          isRequired
          defaultValue={value}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              stopEditing()
            }
          }}
          {...inputProps}
        />
        <Button
          type="submit"
          colorScheme="brand.primary"
          size="sm"
          isLoading={isSaving}
          {...buttonProps}
        >
          Save
        </Button>
      </Flex>
    </Box>
  )
}

import { createIcon } from "@chakra-ui/react"
import React from "react"

export const UsdIcon = createIcon({
  displayName: "UsdIcon",
  viewBox: "0 0 16 16",
  path: (
    <>
      <g id="usd">
        <path
          id="Combined Shape"
          d="M7.137 4.32464C6.46822 4.46801 6.12837 4.82804 6.12837 5.31895C6.12837 5.81159 6.35137 6.09471 7.137 6.38164C7.71978 6.57432 9.19606 6.92105 9.19606 6.92105C11.823 7.57495 13 8.65893 13 10.4908C13 12.3295 11.5257 13.626 9.26618 13.9187V13.9942C9.26618 14.5538 8.78219 15 8.1753 15C7.56736 15 7.06688 14.553 7.06688 13.9942V13.8841C5.82556 13.7176 4.66361 13.2841 3.62728 12.6103C3.22539 12.3706 3 12.0111 3 11.5736C3 10.9009 3.57164 10.3739 4.30124 10.3739C4.58555 10.3739 4.8689 10.461 5.07731 10.6107C5.77678 11.0772 6.41747 11.3795 7.10709 11.5509C7.11711 11.5554 7.1272 11.5599 7.13736 11.5643C7.73708 11.7191 8.42331 11.7453 9.19606 11.6432C9.90218 11.4902 10.2573 11.1261 10.2573 10.6039C10.2573 10.0939 10.008 9.79139 9.19606 9.50383C8.68208 9.33711 7.137 8.99961 7.137 8.99961C4.52008 8.32732 3.38565 7.30209 3.38565 5.43209C3.38565 3.629 4.85849 2.32966 7.06688 2.06528V2.00577C7.06688 1.44701 7.56736 1 8.1753 1C8.78219 1 9.26618 1.44623 9.26618 2.00577V2.09088C10.1956 2.22827 11.0293 2.52432 11.8114 2.97934C12.2414 3.2023 12.5092 3.55584 12.5092 4.026C12.5092 4.69735 11.9216 5.22571 11.1904 5.22571C10.9514 5.22571 10.7168 5.16084 10.4911 5.03596C10.0412 4.78712 9.61468 4.59984 9.20171 4.47175C8.37508 4.25918 7.68762 4.21161 7.137 4.32464Z"
          fill="currentColor"
        />
      </g>
    </>
  ),
})

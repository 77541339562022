import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react"
import { CheckIcon, HashtagIcon } from "@heroicons/react/solid"
import { motion } from "framer-motion"
import React, { ChangeEvent, useState } from "react"
import { useDispatch } from "react-redux"
import QuestionTagsApi from "~/api/QuestionTagsApi"

import { SubmitButton } from "Components/button/submit-button"
import { JsForm } from "Components/form/form"
import {
  COLOR_SWATCHES,
  DEFAULT_TAG_COLORS,
  textColorForBackground,
} from "Components/tags/colors"
import { ActionType } from "Redux/reducers/test-results/constants"
import { axios } from "Services/axios"
import { QuestionTag } from "Types"
import { FunctionalModal } from "Utilities/modals/types"
import { pluralize } from "Utilities/string"

interface Props {
  answerCount: number
  questionTag: QuestionTag
}

const hexDigitsOnly = (s: string) => s.replace(/[^0-9a-f]/i, "")

export const EditTagModal: FunctionalModal<Props> = ({
  answerCount,
  questionTag,
  onClose,
}) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const [name, setName] = useState(questionTag.name)
  const [color, setColor] = useState(
    hexDigitsOnly(
      questionTag.color ??
        DEFAULT_TAG_COLORS[questionTag._index % DEFAULT_TAG_COLORS.length]
    )
  )

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleSubmit = async () => {
    dispatch({
      type: ActionType.UPDATE_QUESTION_TAG_REQUEST,
    })
    try {
      const { data } = await axios.patch(
        QuestionTagsApi.update.path({ question_tag_id: questionTag.id }),
        { question_tag: { name, color: `#${color}` } }
      )
      dispatch({
        type: ActionType.UPDATE_QUESTION_TAG_SUCCESS,
        payload: { questionTag: data.question_tag },
      })
      const message =
        questionTag.name === name
          ? `Updated tag "${questionTag.name}"`
          : `Renamed "${questionTag.name}" to "${name}"`
      toast({
        title: message,
        status: "success",
      })
      onClose()
    } catch (error) {
      toast({
        title: `Error renaming "${questionTag.name}"`,
        status: "error",
      })
      dispatch({
        type: ActionType.UPDATE_QUESTION_TAG_FAILURE,
      })
      throw error
    }
  }

  const foregroundColor = textColorForBackground(`#${color}`)
  const customColorSelected = !COLOR_SWATCHES.includes(`#${color}`)

  // We're using a custom zIndex for the overlay so it's always over top of the
  // "Add/search tags" popover.
  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay zIndex={1505} />
      <ModalContent w="380px" containerProps={{ zIndex: 1506 }}>
        <JsForm onSubmit={handleSubmit}>
          <ModalHeader>Edit tag</ModalHeader>
          <ModalBody>
            {answerCount ? (
              <Alert status="info" mb="6" rounded="lg">
                <AlertIcon />
                This will update {answerCount} tagged{" "}
                {pluralize(answerCount, "answer", "answers")}
              </Alert>
            ) : null}
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                isRequired
                placeholder={questionTag.name}
                mb={6}
                value={name}
                onChange={handleInputChange}
                autoFocus
              />
            </FormControl>
            <FormControl>
              <FormLabel>Color</FormLabel>
              <SimpleGrid columns={8} gap={2} mb={4} w="fit-content" mx="auto">
                {COLOR_SWATCHES.map((c) => (
                  <Button
                    key={c}
                    as={motion.button}
                    whileHover={{ scale: 1.1, transition: { duration: 0.1 } }}
                    sx={{ _hover: { bg: c } }}
                    bg={c}
                    onClick={() => setColor(hexDigitsOnly(c))}
                    size="sm"
                  >
                    {`#${color}` === c ? (
                      <Icon
                        as={CheckIcon}
                        color={foregroundColor}
                        position="absolute"
                        w={6}
                        h={6}
                      />
                    ) : null}
                  </Button>
                ))}
              </SimpleGrid>
              <InputGroup>
                <Input
                  id="tagColor"
                  value={color}
                  onChange={(e) => setColor(hexDigitsOnly(e.target.value))}
                  pattern="^[0-9a-fA-F]{6}$"
                />
                <InputLeftElement pointerEvents="none" color="gray.400">
                  <Icon as={HashtagIcon} />
                </InputLeftElement>
                <InputRightElement pointerEvents="none">
                  {color.length === 6 ? (
                    <Button size="xs" bg={`#${color}`}>
                      {customColorSelected ? (
                        <Icon
                          as={CheckIcon}
                          color={foregroundColor}
                          position="absolute"
                          w={4}
                          h={4}
                        />
                      ) : null}
                    </Button>
                  ) : null}
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button onClick={onClose} variant="outline">
                Cancel
              </Button>
              <SubmitButton>Save</SubmitButton>
            </HStack>
          </ModalFooter>
        </JsForm>
      </ModalContent>
    </Modal>
  )
}

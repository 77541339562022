import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react"
import { Dispatch, State } from "Redux/app-store"
import { jumpToSection } from "Redux/reducers/current-response/action-creators"
import { getFullSectionTitle } from "Utilities/get-full-section-title"
import { sectionContainsTestLogic } from "Utilities/test-logic-statement"
import React from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { PreviewBanner } from "./preview-banner"

type Props = {
  setStarted: (started: boolean) => void
}

export const UsabilityTestPreviewBanner: React.FC<Props> = ({ setStarted }) => {
  const dispatch = useDispatch<Dispatch>()
  const allSections = useSelector(
    (state: State) => state.participantUsabilityTest?.sections ?? []
  )
  const sectionIds = allSections.map((s) => s.id)

  return (
    <PreviewBanner>
      <Menu>
        <MenuButton
          as={Button}
          size="xs"
          ms={2}
          whiteSpace="normal"
          blockSize="auto"
        >
          Jump to section
        </MenuButton>

        <MenuList overflow="auto" py={1}>
          <MenuItem
            key="welcome"
            color="text.primary"
            py={2}
            onClick={() => {
              setStarted(false)
              dispatch(jumpToSection([]))
            }}
          >
            Welcome screen
          </MenuItem>

          {allSections.map((section, index) => {
            const hasLogic = sectionContainsTestLogic(section)
            return (
              <Tooltip
                key={section.id}
                hasArrow
                rounded="md"
                isDisabled={!hasLogic}
                label="You cannot jump to this section because it has an active test logic statement."
              >
                <MenuItem
                  color="text.primary"
                  py={2}
                  sx={{
                    fontVariantNumeric: "tabular-nums",
                  }}
                  onClick={() => {
                    setStarted(true)
                    dispatch(jumpToSection(sectionIds.slice(0, index)))
                  }}
                  isDisabled={hasLogic}
                >
                  {getFullSectionTitle(section, index)}
                </MenuItem>
              </Tooltip>
            )
          })}

          <MenuItem
            key="thankyou"
            color="text.primary"
            py={2}
            onClick={() => {
              setStarted(true)
              dispatch(jumpToSection(sectionIds))
            }}
          >
            Thank you screen
          </MenuItem>
        </MenuList>
      </Menu>
    </PreviewBanner>
  )
}

import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
  keyframes,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { ExclamationIcon } from "@heroicons/react/solid"
import { useQueryClient } from "@tanstack/react-query"
import { assertNever } from "Components/filter-controls/utils"
import { DotIcon } from "Shared/icons/DotIcon"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import { XCircleOutlineIcon } from "Shared/icons/untitled-ui/XCircleOutlineIcon"
import { XCircleSolidIcon } from "Shared/icons/untitled-ui/XCircleSolidIcon"
import React from "react"
import { useCancelModeratedStudyOrder } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { ModeratedStudyOrder } from "~/api/generated/usabilityhubSchemas"
import { useModeratedStudyContext } from "./ModeratedStudyContext"

const pulse = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`

type RecruitButtonProps = {
  order: ModeratedStudyOrder
}

export const RecruitingButton: React.FC<RecruitButtonProps> = ({ order }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { moderatedStudySummary } = useModeratedStudyContext()

  // We're nested inside an AccordionButton, so anything interactive needs to stop propagation to prevent the accordian being toggled
  if (order.state === "active") {
    return (
      <>
        <Menu isLazy>
          <MenuButton
            as={Button}
            w="140px"
            variant="outline"
            size="sm"
            flexShrink={0}
            rightIcon={
              !moderatedStudySummary ||
              moderatedStudySummary.has_available_booking_slots ? undefined : (
                <Tooltip hasArrow placement="top" label="No availability">
                  <Icon as={ExclamationIcon} color="red.600" />
                </Tooltip>
              )
            }
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <DotIcon
              color="green.500"
              boxSize="1em"
              animation={`${pulse} infinite 2s ease`}
              mr={2}
            />
            Recruiting
          </MenuButton>
          <MenuList overflow="hidden">
            <MenuItem
              icon={<XCircleSolidIcon color="red.500" />}
              onClick={(e) => {
                e.stopPropagation()
                onOpen()
              }}
            >
              Cancel order
            </MenuItem>
          </MenuList>
        </Menu>

        <CancelModal order={order} isOpen={isOpen} onClose={onClose} />
      </>
    )
  }
  if (order.state === "canceled") {
    return (
      <Button
        variant="outline"
        size="sm"
        w="140px"
        flexShrink={0}
        leftIcon={<XCircleOutlineIcon color="red.500" />}
        disabled={true}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        Canceled
      </Button>
    )
  }

  if (order.state === "complete") {
    return (
      <Button
        variant="outline"
        size="sm"
        w="140px"
        flexShrink={0}
        leftIcon={<CheckCircleSolidIcon color="teal.500" />}
        disabled={true}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        Complete
      </Button>
    )
  }

  // Make sure we get a TS error if we forget to handle a state
  assertNever(order.state)
}

type CancelModalProps = {
  order: ModeratedStudyOrder
  isOpen: boolean
  onClose: () => void
}

const CancelModal: React.FC<CancelModalProps> = ({
  order,
  isOpen,
  onClose,
}) => {
  const toast = useToast()
  const { queryKeyFn } = useUsabilityhubContext()
  const queryClient = useQueryClient()
  const { mutate: cancelOrderMutation } = useCancelModeratedStudyOrder({
    onSuccess: () => {
      toast({
        title: "Order canceled",
        status: "success",
      })

      const key = queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/recruitment_links",
        operationId: "getApiModeratedStudiesModeratedStudyIdRecruitmentLinks",
        variables: {
          pathParams: {
            moderatedStudyId: order.moderated_study_id,
          },
        },
      })
      return queryClient.invalidateQueries(key)
    },
    onError: () => {
      toast({
        title: "Unable to cancel your order",
        status: "error",
      })
    },
  })

  const cancelOrder = () => {
    cancelOrderMutation({
      pathParams: {
        moderatedStudyId: order.moderated_study_id,
        id: order.id,
      },
    })
  }

  const bookingsCount = order.booking_counts.upcoming
  const originalSlots = order.requested_response_count
  const emptySlots = order.empty_slots

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel order</ModalHeader>
        <ModalCloseButton />

        <ModalBody as={VStack} spacing={2} align="left">
          {emptySlots < originalSlots ? (
            <>
              <Text>
                {originalSlots - emptySlots} out of {originalSlots} sessions
                have already been fulfilled for this order.
              </Text>

              <Text>
                Canceling this order will only refund the remaining {emptySlots}{" "}
                of {originalSlots} sessions.
                {bookingsCount > 0 &&
                  " It will not cancel any upcoming sessions. These can be managed via the Sessions page."}
              </Text>
            </>
          ) : (
            <Text>Your order will be fully refunded if canceled.</Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="red" onClick={cancelOrder}>
            Cancel order
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import {
  Box,
  Flex,
  Heading,
  Link,
  Radio,
  RadioGroup,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { IconButton } from "Components/icon-button/icon-button"
import { TestResultViewState } from "Components/test-results/context/test-result-view-state"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import { Dispatch } from "Redux/app-store"
import { setResponseReviewStatus } from "Redux/reducers/test-results/action-creators"
import {
  getIsRevising,
  getReviewingOrderId,
} from "Redux/reducers/test-results/selectors"
import { adminRevisePayoutOrders } from "Shared/constants/routes"
import { UserResponseIcon } from "Shared/icons/UserResponseIcon"
import { AdminUsabilityTest, Response, ReviewStatus } from "Types"
import { isPresent } from "Utilities/values"
import { sortBy } from "lodash"
import React, { InputHTMLAttributes, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import PayoutsApi from "~/api/Admin/PayoutsApi"
import ThirdPartyOrdersApi from "~/api/Admin/ThirdPartyOrdersApi"
import { GetTestResultsIndividualResponseResponse } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { useFilteredResponses } from "../../test-results/hooks/use-filtered-responses"
import { DurationIndicator } from "./duration-indicator"

interface ResponseReviewOptionsProps
  extends InputHTMLAttributes<HTMLInputElement> {
  response: GetTestResultsIndividualResponseResponse | Response
  onReviewChange: (responseId: number, reviewStatus: ReviewStatus) => void
}

export const REVIEW_STATUS_OPTIONS = [
  { label: "Good", value: ReviewStatus.Accepted },
  { label: "Poor", value: ReviewStatus.AcceptedLowQuality },
  { label: "Reject", value: ReviewStatus.Rejected },
]

export const REVIEW_STATUS_OPTIONS_THIRD_PARTY_ORDER = [
  { label: "Good", value: ReviewStatus.Accepted },
  { label: "Reject", value: ReviewStatus.Rejected },
]

const ResponseReviewOptions: React.FC<ResponseReviewOptionsProps> = ({
  response,
  onReviewChange,
}) => {
  const { isThirdPartyOrder } = useContext(TestResultViewState)

  const handleReviewSelection = (value: string) => {
    onReviewChange(response.id, Number(value))
  }

  return (
    <RadioGroup
      value={
        isPresent(response.review_status)
          ? response.review_status.toString()
          : response.automated_review_status?.toString()
      }
      onChange={handleReviewSelection}
    >
      <Flex gap={2} data-testid="review-status-options">
        {(isThirdPartyOrder
          ? REVIEW_STATUS_OPTIONS_THIRD_PARTY_ORDER
          : REVIEW_STATUS_OPTIONS
        ).map((option) => (
          <Radio key={option.value} value={option.value.toString()}>
            {option.label}
          </Radio>
        ))}
      </Flex>
    </RadioGroup>
  )
}

interface ResponseDurationProps {
  response: GetTestResultsIndividualResponseResponse | Response
  usabilityTest: AdminUsabilityTest
  layout?: "default" | "compact"
}

export const ResponseDuration: React.FC<ResponseDurationProps> = ({
  response,
  usabilityTest,
  layout = "default",
}) => {
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const [filteredResponseId, setResponseId] = useIndividualResponseFilter()
  const dispatch: Dispatch = useDispatch()

  // If we're on the shared version of the results page we need to proxy the privateId
  // from the URL to the API calls we're making here.
  // We might not be in a Router context so we need to parse the URL manually.
  // (Note that there is very similar code in IndividualResponseDrawer.tsx, might need to abstract it
  // if we find ourselves using this privateId in other places)
  const lastPathElement = window.location.pathname.split("/").pop()
  const privateId = lastPathElement === "results" ? undefined : lastPathElement

  const highlight = filteredResponseId === response.id && layout === "default"

  const onReviewChange = (responseId: number, reviewStatus: ReviewStatus) => {
    dispatch(setResponseReviewStatus(responseId, reviewStatus))

    // The individual response modal uses react-query as a source of truth, not Redux, so
    // we need to manually update the cache here to keep the UI in sync.
    const queryKey = queryKeyFn({
      path: "/api/test_results/{usability_test_unique_id}/responses/{response_id}",
      operationId: "getTestResultsIndividualResponse",
      variables: {
        pathParams: {
          usabilityTestUniqueId: usabilityTest.unique_id,
          responseId: response.id,
        },
        queryParams: {
          private_id: privateId,
        },
      },
    })

    queryClient.setQueryData<GetTestResultsIndividualResponseResponse>(
      queryKey,
      (oldResponse) => {
        if (oldResponse === undefined) return undefined

        return {
          ...oldResponse,
          review_status: reviewStatus,
        }
      }
    )
  }

  return (
    <Stack
      position="relative"
      alignItems="center"
      key={response.id}
      py={1}
      _before={
        highlight
          ? {
              content: '""',
              position: "absolute",
              left: -3,
              height: "100%",
              borderLeftWidth: "6px",
              borderColor: "red",
              bg: "red.100",
            }
          : undefined
      }
    >
      <Stack isInline width="100%" alignItems="flex-end">
        <DurationIndicator
          showTimes={layout === "default"}
          actualTime={response.duration_ms || 0}
          estimatedTime={
            response.estimated_duration_ms ||
            usabilityTest.last_estimated_duration_in_seconds * 1000
          }
        />
        {layout === "default" && (
          <IconButton
            icon={<UserResponseIcon />}
            onClick={() => setResponseId(response.id)}
          />
        )}
      </Stack>
      <ResponseReviewOptions
        response={response}
        onReviewChange={onReviewChange}
      />
    </Stack>
  )
}

interface ResponseDurationsProps {
  usabilityTest: Readonly<AdminUsabilityTest>
}

const ResponseDurations: React.FC<
  React.PropsWithChildren<ResponseDurationsProps>
> = ({ usabilityTest }) => {
  const responses = useFilteredResponses()
  const isRevising = useSelector(getIsRevising)
  const reviewingOrderId = useSelector(getReviewingOrderId)!

  const { isThirdPartyOrder } = useContext(TestResultViewState)

  const sortedResponses = sortBy(
    responses,
    (response) =>
      (response.duration_ms || 0) /
      (response.estimated_duration_ms ||
        usabilityTest.last_estimated_duration_in_seconds * 1000)
  )
  return (
    <Box borderWidth={1} borderColor="gray.200" rounded="md" p={4} mb={2}>
      <Stack divider={<StackDivider />}>
        <Heading as="h3" fontWeight="semibold" pb={1}>
          Responses
        </Heading>

        {sortedResponses.length > 0 ? (
          sortedResponses.map((response) => (
            <ResponseDuration
              key={response.id}
              response={response}
              usabilityTest={usabilityTest}
            />
          ))
        ) : (
          <Text>
            No {isRevising ? "reviewed" : "unreviewed"} responses.{" "}
            {isRevising ? (
              <Link
                href={
                  isThirdPartyOrder
                    ? ThirdPartyOrdersApi.reviewResponses.path({
                        id: reviewingOrderId,
                      })
                    : PayoutsApi.order.path({ id: reviewingOrderId })
                }
              >
                Review unreviewed responses.
              </Link>
            ) : (
              <Link
                href={
                  isThirdPartyOrder
                    ? ThirdPartyOrdersApi.reviewResponses.path({
                        id: reviewingOrderId,
                      }) + "?revise=true"
                    : adminRevisePayoutOrders(reviewingOrderId)
                }
              >
                Revise reviewed responses.
              </Link>
            )}
          </Text>
        )}
      </Stack>
    </Box>
  )
}

export default ResponseDurations

import { Flex, Text } from "@chakra-ui/react"
import { Z_INDEX_RECORDING_INDICATOR } from "Constants/zIndexes"
import { Beacon } from "UsabilityHub/components/Beacon"
import * as React from "react"
import { useEffect, useState } from "react"
import { useSectionRecordingContext } from "../UsabilityTest/context/SectionRecordingContext"

export const RecordingIndicator = () => {
  const { sectionRecordingTypes } = useSectionRecordingContext()

  if (!sectionRecordingTypes || sectionRecordingTypes.length < 1) return null

  return (
    <Flex
      borderRadius={100}
      background="#061D29CC"
      pl={2}
      pr={3}
      py={2}
      gap={2}
      zIndex={Z_INDEX_RECORDING_INDICATOR}
      justifyContent="space-between"
      alignItems="center"
    >
      <Beacon colorScheme="red" boxSize={5} />
      <StopwatchTimer />
    </Flex>
  )
}

const StopwatchTimer = () => {
  const [count, setCount] = useState(0)
  const [time, setTime] = useState("00:00")

  const initTime = new Date().getTime()

  const showTimer = (ms: number) => {
    const second = Math.floor((ms / 1000) % 60)
      .toString()
      .padStart(2, "0")
    const minute = Math.floor((ms / 1000 / 60) % 60)
      .toString()
      .padStart(2, "0")
    setTime(minute + ":" + second)
  }

  useEffect(() => {
    const id = setInterval(() => {
      const left: number = count + (Date.now() - initTime)
      setCount(left)
      showTimer(left)
      if (left <= 0) {
        setTime("00:00")
        clearInterval(id)
      }
    }, 1)
    return () => clearInterval(id)
  }, [])

  return (
    <Text
      color="ds.text.inverse"
      textAlign="right"
      minWidth="5ch"
      fontWeight="medium"
      fontSize="xs"
      style={{ fontVariantNumeric: "tabular-nums" }}
    >
      {time}
    </Text>
  )
}

import { Box, Flex, HStack, Select, Switch, Text } from "@chakra-ui/react"
import React from "react"
import { DraggableProvided } from "react-beautiful-dnd"
import { Fields, WrappedFieldsProps } from "redux-form"

import {
  ORDERED_QUESTION_TYPES,
  QuestionTypes,
} from "Constants/test-section-question-types"
import { QuestionType, UsabilityTestSectionType } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { containsURLs } from "UsabilityHub/components/TestForm/validators/validate-contains-urls"
import {
  useQuestionContext,
  useSectionContext,
  useSectionTypeContext,
} from "UsabilityHub/contexts"
import { isQuestionsSection } from "Utilities/usability-test-section"
import { isRequirable } from "Utilities/usability-test-section-question"

import { MarkdownFormLabel, MarkdownTextarea } from "../../../TestFormMarkdown"
import { useShouldAutoFocus } from "../../useShouldAutofocus"
import { DraggableRowHandle } from "../DragDrop/DraggableQuestionRow"

interface DraggableProps {
  draggable: DraggableProvided
}

const WrappedCommonFields: React.FC<
  React.PropsWithChildren<WrappedFieldsProps & DraggableProps>
> = ({ text, type, required, draggable }) => {
  const { section } = useSectionContext()
  const sectionType = useSectionTypeContext()
  const { question, questionIndex } = useQuestionContext()
  const { readOnly } = useUsabilityTestUserActivityContext()

  const isFirstQuestion = questionIndex === 0

  // Skip autoFocus if it's the first question and we’re not in a questions section
  const skipAutoFocus = isFirstQuestion && !isQuestionsSection(section)
  const autoFocus = useShouldAutoFocus(text.input.value) && !skipAutoFocus
  const placeholder =
    sectionType === UsabilityTestSectionType.PrototypeTask &&
    question.type === QuestionType.LinearScale
      ? "e.g. Overall, how easy did you find this task?"
      : ""

  return (
    <Box>
      <MarkdownFormLabel
        htmlFor={text.input.name}
        marginRight={0}
        flexGrow={0}
        showMarkdownHint={text.meta.active}
      >
        Question
      </MarkdownFormLabel>
      <HStack alignItems="flex-start">
        {!readOnly && (
          <Flex alignItems="center" height={10}>
            <DraggableRowHandle
              draggableProvided={draggable}
              isDragDisabled={section.questions.length === 1}
            />
          </Flex>
        )}
        <MarkdownTextarea
          flexGrow={1}
          rows={1}
          id={text.input.name}
          autoFocus={autoFocus}
          placeholder={placeholder}
          isRequired
          isReadOnly={readOnly}
          {...text.input}
        />
        <HStack spacing={3}>
          <Select
            isDisabled={readOnly}
            {...type.input}
            flexShrink={0}
            width={48}
          >
            {ORDERED_QUESTION_TYPES.map((questionType) => {
              const { label } = QuestionTypes[questionType]
              return (
                <option key={questionType} value={questionType}>
                  {label}
                </option>
              )
            })}
          </Select>
          {isRequirable(question.type) && (
            <HStack>
              <Text as="label" htmlFor={required.input.name}>
                Required
              </Text>
              <Switch
                id={required.input.name}
                display="inline-flex" // Fix for Chakra switch not being vertically aligned
                isChecked={required.input.value as boolean}
                isReadOnly={readOnly}
                {...required.input}
              />
            </HStack>
          )}
        </HStack>
      </HStack>
    </Box>
  )
}

export const CommonFields: React.FC<
  React.PropsWithChildren<DraggableProps>
> = ({ draggable }) => {
  return (
    <Fields
      names={["text", "type", "required"]}
      component={WrappedCommonFields}
      warn={containsURLs}
      draggable={draggable}
    />
  )
}

import { Box, BoxProps } from "@chakra-ui/react"
import React, { PropsWithChildren, useRef } from "react"

import { useScrollPercentage } from "Hooks/use-scroll-percentage"

type ScrollingWrapperProps = PropsWithChildren<BoxProps> & {
  axis?: "x" | "y"
  leftOffset?: string
  topOffset?: string
}

export const ScrollingWrapper: React.FC<ScrollingWrapperProps> = ({
  children,
  axis = "x",
  leftOffset = "0px",
  topOffset = "0px",
  ...props
}) => {
  const gridScrollingPanelRef = useRef<HTMLDivElement>(null)
  const scrollPercentage = useScrollPercentage(gridScrollingPanelRef, axis)

  return (
    <Box position="relative">
      {scrollPercentage !== null &&
        scrollPercentage > 5 &&
        (axis === "x" ? (
          <Box
            bg="linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)"
            position="absolute"
            top={topOffset}
            left={leftOffset}
            height={`calc(100% - ${topOffset})`}
            width={5}
            zIndex={1}
            pointerEvents="none"
          />
        ) : (
          <Box
            bg="linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%)"
            position="absolute"
            top={topOffset}
            left={leftOffset}
            width={`calc(100% - ${leftOffset})`}
            height={5}
            zIndex={1}
            pointerEvents="none"
          />
        ))}
      <Box ref={gridScrollingPanelRef} overflow="auto" {...props}>
        {children}
      </Box>
      {scrollPercentage !== null &&
        scrollPercentage < 95 &&
        (axis === "x" ? (
          <Box
            bg="linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%)"
            position="absolute"
            top={topOffset}
            right={0}
            height={`calc(100% - ${topOffset})`}
            width={5}
            zIndex={1}
            pointerEvents="none"
          />
        ) : (
          <Box
            bg="linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%)"
            position="absolute"
            bottom={topOffset}
            left={0}
            width={`calc(100% - ${topOffset})`}
            height={5}
            zIndex={1}
            pointerEvents="none"
          />
        ))}
    </Box>
  )
}

import useSize from "@react-hook/size"
import React, { useEffect, useRef } from "react"

import ConditionalScreenshotDeviceFrame from "UsabilityHub/components/UsabilityTestSectionTask/conditional-screenshot-device-frame"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"

import { SectionTask, SectionTaskContent } from "../SectionTask"

export const DesignQuestionsTask: React.FC<
  React.PropsWithChildren<InnerProps>
> = ({ deviceFrame, updateResponseSection, usabilityTestSection }) => {
  const {
    section_screenshots: [{ screenshot_id: screenshotId }],
  } = usabilityTestSection
  useEffect(() => {
    // Ensure that this fires *after* the outer component's componentDidMount
    // fires because that's where `startResponseSection` is called.
    // Fix this: https://gitlab.com/usabilityhub/app/issues/2238
    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms: 0,
      task_duration_ms: 0,
    })
  }, [updateResponseSection, usabilityTestSection.id])

  const contentRef = useRef(null)
  const [width, height] = useSize(contentRef)
  return (
    <SectionTask>
      <SectionTaskContent ref={contentRef}>
        <ConditionalScreenshotDeviceFrame
          screenshotId={screenshotId}
          deviceFrame={deviceFrame}
          maxPlayableSize={{ width, height }}
          canSkipAheadOnFirstWatch={false}
        />
      </SectionTaskContent>
    </SectionTask>
  )
}

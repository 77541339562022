import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Dispatch } from "Redux/app-store"
import { deleteResponse } from "Redux/reducers/current-response/action-creators"
import { ParticipantDeletionReason } from "Types"
import { differenceInMilliseconds } from "date-fns"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  TIMEOUT_WARNING_GRACE_PERIOD,
  useActivityMonitor,
} from "./useActivityMonitor"

type Props = {
  idleTimeoutMs: number | null
}
export const TimeoutGracePeriodModal: React.FC<Props> = ({ idleTimeoutMs }) => {
  const dispatch = useDispatch<Dispatch>()
  const { timeOutAt, clearTimeout } = useActivityMonitor(idleTimeoutMs)
  const gracePeriod = (idleTimeoutMs ?? 0) * TIMEOUT_WARNING_GRACE_PERIOD
  const [timeRemaining, setTimeRemaining] = useState<number>(gracePeriod)
  const timedOut = timeRemaining <= 0

  useEffect(() => {
    if (!timeOutAt) return

    const interval = setInterval(() => {
      const newTimeRemaining = differenceInMilliseconds(timeOutAt, Date.now())
      setTimeRemaining(newTimeRemaining)
      if (newTimeRemaining <= 0) {
        void dispatch(deleteResponse(ParticipantDeletionReason.Inactive))
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [timeOutAt, dispatch])

  const handleClose = () => {
    if (timedOut) return

    clearTimeout()
  }

  if (!timeOutAt) return null

  return (
    <Modal
      isOpen
      onClose={handleClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay backdropFilter={timedOut ? "blur(3px)" : undefined} />
      <ModalContent>
        <ModalHeader>Are you still there?</ModalHeader>

        <ModalBody>
          <Stack>
            {timedOut ? (
              <>
                <Text>
                  You did not respond within {Math.round(gracePeriod / 1000)}{" "}
                  seconds so your response has timed out.
                </Text>
                <Text>
                  Please make sure that you complete each test without
                  interruption.
                </Text>
              </>
            ) : (
              <>
                <Text>You don{"\u2019"}t appear to be active.</Text>
                <Text>Please confirm that you are still here.</Text>
              </>
            )}

            <Box pt={4}>
              <Progress
                value={((gracePeriod - timeRemaining) / gracePeriod) * 100}
                rounded="md"
              />
            </Box>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            disabled={timedOut}
            colorScheme="brand.primary"
            onClick={handleClose}
          >
            I{"\u2019"}m here
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import {
  Box,
  Center,
  Flex,
  FlexProps,
  Grid,
  Icon,
  Link,
  Switch,
  Text,
} from "@chakra-ui/react"
import { ExternalLinkIcon } from "@heroicons/react/solid"
import Constants from "Constants/shared.json"
import { useFeatureFlag } from "Hooks/use-feature-flag"
import { PlanDrawerFeatures } from "Types"
import React, { PropsWithChildren } from "react"
import { Plan } from "~/api/generated/usabilityhubSchemas"
import { EnterpriseInfoCard } from "./enterprise-info-card"
import { FEATURES } from "./features"
import { usePlanChangerContext } from "./plan-changer-context"
import { PlanHeader } from "./plan-header"
import { featureCellContents, featureCellLabel } from "./shared"

export const PlanComparisonGrid: React.FC<FlexProps> = (props) => {
  const { allPlans, currentPlan, showAnnual, requestedFeatures, dispatch } =
    usePlanChangerContext()
  const visiblePlans = allPlans.filter(
    (plan) =>
      !plan.discontinued &&
      plan.unique_id !== "enterprise" &&
      (plan.unique_id === Constants.FREE_PLAN_UNIQUE_ID ||
        plan.interval === (showAnnual ? "year" : "month"))
  )

  // Since we want to treat monthly and annual versions as the same thing, unify that here
  const indexOfCurrentPlan = visiblePlans.findIndex(
    (plan) =>
      plan.unique_id === currentPlan?.unique_id ||
      plan.alternate_plan_unique_id === currentPlan?.unique_id
  )

  return (
    <PlanComparisonGridContainer
      showAnnual={showAnnual}
      setShowAnnual={() => dispatch({ type: "toggle-show-annual" })}
      visiblePlans={visiblePlans}
      requestedFeatures={requestedFeatures}
      showEnterpriseInfo
      {...props}
    >
      {visiblePlans.map((plan, planIndex) => (
        <PlanHeader
          isActive={planIndex === indexOfCurrentPlan}
          isDowngrade={
            plan.amount === 0 ||
            !!(currentPlan && plan.monthly_price < currentPlan.monthly_price)
          }
          currentPlanDiscontinued={!!currentPlan?.discontinued}
          key={plan.unique_id}
          plan={plan}
          position={
            planIndex === 0
              ? "first"
              : planIndex === visiblePlans.length - 1
                ? "last"
                : "middle"
          }
        />
      ))}
    </PlanComparisonGridContainer>
  )
}

type PlanComparisonGridContainerProps = {
  showAnnual: boolean
  setShowAnnual: () => void
  visiblePlans: readonly Plan[]
  requestedFeatures?: PlanDrawerFeatures
  showEnterpriseInfo?: boolean
}

export const PlanComparisonGridContainer: React.FC<
  PropsWithChildren<FlexProps & PlanComparisonGridContainerProps>
> = ({
  showAnnual,
  setShowAnnual, //
  children,
  visiblePlans,
  requestedFeatures = [],
  showEnterpriseInfo = false,
  ...props
}) => {
  const recordingPlanLimit = useFeatureFlag(
    "usability_test_recording_plan_limit"
  )
  let lastShownGroup: string | null = null

  return (
    <Flex direction="column" gap={4} {...props}>
      <Grid bgColor="white" templateColumns="repeat(4, 1fr)">
        <Center>
          <Switch isChecked={showAnnual} onChange={setShowAnnual}>
            Pay annually
          </Switch>
        </Center>

        {children}

        {FEATURES.map((feature, featureIndex) => {
          const showGroup = feature.group !== lastShownGroup
          lastShownGroup = feature.group

          // Temporary before the recording official launch: hide Recordings feature until the commercial release
          if (feature.feature === "recordings" && !recordingPlanLimit) {
            return null
          }

          const isLastFeature = featureIndex === FEATURES.length - 1
          const highlightFeature = requestedFeatures.some(
            (rf) => rf.feature === feature.feature
          )

          return (
            <React.Fragment key={feature.group + feature.label}>
              {showGroup && (
                <Box
                  gridColumn="1 / -1"
                  roundedTopLeft={featureIndex === 0 ? "md" : undefined}
                  borderWidth="1px 1px 0px 1px"
                  p={3}
                >
                  <Text fontSize="lg" fontWeight="bold" color="text.primary">
                    {feature.group}
                  </Text>
                </Box>
              )}
              <Box
                borderWidth={`1px 0 ${isLastFeature ? 1 : 0}px 1px`}
                bg={highlightFeature ? "yellow.100" : undefined}
                borderColor="gray.200"
                roundedBottomLeft={isLastFeature ? "md" : undefined}
                p={3}
              >
                {featureCellLabel(feature)}
              </Box>

              {visiblePlans.map((plan, planIndex) => {
                return (
                  <Flex
                    key={`${plan.unique_id}-${feature.label}`}
                    align="center"
                    bg={highlightFeature ? "yellow.100" : undefined}
                    borderColor="gray.200"
                    borderWidth={`1px ${
                      planIndex === visiblePlans.length - 1 ? 1 : 0
                    }px ${isLastFeature ? 1 : 0}px 1px`}
                    p={3}
                    roundedBottomRight={
                      planIndex === visiblePlans.length - 1 && isLastFeature
                        ? "md"
                        : undefined
                    }
                  >
                    {featureCellContents(feature, plan)}
                  </Flex>
                )
              })}
            </React.Fragment>
          )
        })}
      </Grid>

      <Link
        href={Constants.PRICING_URL}
        target="_blank"
        rel="noopener noreferer"
        fontWeight="normal"
      >
        Learn more about our plans and compare features
        <Icon
          as={ExternalLinkIcon}
          boxSize={5}
          ms={0.5}
          verticalAlign="text-bottom"
        />
      </Link>

      {showEnterpriseInfo && <EnterpriseInfoCard />}
    </Flex>
  )
}

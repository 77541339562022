import { RailsForm } from "Components/form/form"
import React, { PropsWithChildren } from "react"
import UsabilityTestsApi from "~/api/UsabilityTestsApi"

export const ExportResultsForm: React.FC<
  PropsWithChildren<{
    usabilityTestUniqueId: string
    usabilityTestPrivateId: string
  }>
> = ({ usabilityTestUniqueId, usabilityTestPrivateId, children }) => (
  <RailsForm
    method="POST"
    action={UsabilityTestsApi.exportResults.path({
      id: usabilityTestUniqueId,
      privateId: usabilityTestPrivateId,
    })}
    // Prevent the <form> creating a box
    style={{ display: "contents" }}
  >
    {children}
  </RailsForm>
)

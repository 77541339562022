import { Box } from "@chakra-ui/react"
import { sum } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { useTestRecordingContext } from "../../context/TestRecordingContext"
import { captureUserMedias } from "../../useTestTakingRecordings"

export const SoundMeter: React.FC = () => {
  const [audioContext, setAudioContext] = useState<AudioContext>()
  const sourceNodeRef = useRef<MediaStreamAudioSourceNode>()
  const volumeIntervalRef = useRef<NodeJS.Timer>()
  const [volume, setVolume] = useState<number>(0)
  const streamRef = useRef<MediaStream>()

  const { audioDeviceId } = useTestRecordingContext()

  const stopStream = () =>
    streamRef.current &&
    streamRef.current.getTracks().forEach((track) => track.stop())

  useEffect(() => {
    const newAudioContext = new AudioContext()
    setAudioContext(newAudioContext)

    return () => {
      clearPreviousSource()
      newAudioContext.close()
    }
  }, [])

  const clearPreviousSource = () => {
    // Stop the previous stream
    stopStream()
    // Clear the previous interval
    if (volumeIntervalRef.current) clearInterval(volumeIntervalRef.current)
    // Disconnect the previous source node
    if (sourceNodeRef.current) sourceNodeRef.current.disconnect()
    setVolume(0)
  }

  useEffect(() => {
    if (audioDeviceId) {
      const constraints = {
        audio: {
          deviceId: { exact: audioDeviceId },
        },
      }

      const success = (stream: MediaStream) => {
        clearPreviousSource()
        streamRef.current = stream

        if (audioContext) {
          const audioSource = audioContext.createMediaStreamSource(stream)

          sourceNodeRef.current = audioSource

          const analyser = audioContext.createAnalyser()
          analyser.fftSize = 512
          audioSource.connect(analyser)
          const volumes = new Uint8Array(analyser.frequencyBinCount)
          const volumeCallback = () => {
            analyser.getByteFrequencyData(volumes)
            const volumeSum = sum(volumes)
            const averageVolume = volumeSum / volumes.length
            const volumeDecimal =
              averageVolume / (analyser.maxDecibels - analyser.minDecibels)
            setVolume(volumeDecimal)
          }

          volumeIntervalRef.current = setInterval(volumeCallback, 100)
        }
      }

      captureUserMedias(constraints, success, clearPreviousSource)
    }
  }, [audioDeviceId])

  return (
    <Box
      as="meter"
      width="100%"
      value={volume}
      sx={{
        "&::-webkit-meter-optimum-value": {
          background: "orange",
        },
      }}
    />
  )
}

import { UhType } from "Types"
import {
  ModeratedStudyOrder,
  Order,
  ThirdPartyOrder,
} from "~/api/generated/usabilityhubSchemas"

export type PanelOrThirdPartyTestOrder = Order | ThirdPartyOrder

export const isPanelTestOrder = (o: PanelOrThirdPartyTestOrder): o is Order =>
  o.uh_type === UhType.Order

export const isModeratedStudyOrder = (
  o: PanelOrThirdPartyTestOrder | ModeratedStudyOrder
): o is ModeratedStudyOrder => "moderated_study_id" in o

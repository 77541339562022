import React from "react"

import { ThemedButton } from "Components/button/themed-button"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import { useTranslate } from "Shared/hooks/useTranslate"
import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"
import {
  Body,
  Container,
  Footer,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"
import { sectionStartTime } from "Utilities/response"

export const InformationTask: React.FC<React.PropsWithChildren<InnerProps>> = ({
  responseSection,
  updateResponseSection,
  usabilityTestSection,
}) => {
  const translate = useTranslate()

  const handleContinue = () => {
    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms: 0,
      task_duration_ms: performance.now() - sectionStartTime(responseSection),
    })
  }

  return (
    <Container>
      <Body>
        <DisplayBlockMarkdownText text={usabilityTestSection.text} />
      </Body>
      <Footer>
        <ThemedButton onClick={handleContinue}>
          {translate("test.buttons.continue")}
        </ThemedButton>
      </Footer>
    </Container>
  )
}

import {
  Alert,
  AlertIcon,
  Flex,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"

import { Expand06OutlineIcon } from "Shared/icons/untitled-ui/Expand06OutlineIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import { AgreementMatrix } from "./AgreementMatrix/AgreementMatrix"
import { useCardSortResultsContext } from "./CardSortResultsContext"

export const CardSortSectionResultsAgreementMatrixTab: React.FC = () => {
  const { cardSortType, cards, categories, categoryGroups, sortData } =
    useCardSortResultsContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Flex gap={2} align="center">
        <Alert>
          <AlertIcon />
          This table shows the percentage of participants who sorted each card
          into the corresponding category.
        </Alert>

        <IconButton
          variant="outline"
          aria-label="Show full modal"
          icon={<Icon as={Expand06OutlineIcon} />}
          onClick={onOpen}
        />
      </Flex>

      <AgreementMatrix
        cardSortType={cardSortType}
        categories={categories}
        categoryGroups={categoryGroups}
        cards={cards}
        sortData={sortData}
        maxH="95vh"
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="fit-content" maxW="95vw">
          <ModalHeader>
            <Flex justify="space-between">
              <Heading>Agreement Matrix</Heading>
              <IconButton
                variant="outline"
                aria-label="close modal"
                icon={<Icon as={XOutlineIcon} />}
                onClick={onClose}
              />
            </Flex>
          </ModalHeader>
          <ModalBody pb={6}>
            <AgreementMatrix
              cardSortType={cardSortType}
              categories={categories}
              categoryGroups={categoryGroups}
              cards={cards}
              sortData={sortData}
              maxH="calc(80vh - 72px)"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

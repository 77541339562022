import { Box, Button, Collapse, HStack } from "@chakra-ui/react"
import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { addSection } from "Redux/reducers/test-builder-form/action-creators/sections"
import { getActiveSectionCount } from "Redux/reducers/test-builder-form/selectors/sections"
import { UsabilityTestSectionType } from "Types"

import { AddSectionCard } from "./TestFormCards/AddSectionCard/AddSectionCard"
import { useUsabilityTestUserActivityContext } from "./UsabilityTestUserActivityContext"

interface AddSectionProps {
  insertionIndex: number
}

export const AddSection: React.FC<React.PropsWithChildren<AddSectionProps>> = ({
  insertionIndex,
}) => {
  const dispatch: Dispatch = useDispatch()
  const activeSectionCount = useSelector(getActiveSectionCount)
  const { readOnly } = useUsabilityTestUserActivityContext()

  // Start expanded when there are no active sections
  const [isManuallyExpanded, setIsManuallyExpanded] = useState(
    activeSectionCount === 0
  )
  const boxRef = useRef<HTMLDivElement>(null)

  const onExpand = () => {
    setIsManuallyExpanded(true)
    if (boxRef.current) {
      boxRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  const onAddSection = (type: UsabilityTestSectionType) => {
    dispatch(addSection(type, insertionIndex))
    setIsManuallyExpanded(false)
  }

  // Disable cancel when there are no active sections
  const onCancel =
    activeSectionCount > 0 ? () => setIsManuallyExpanded(false) : undefined

  if (readOnly) return null

  // If there are no sections, force the card to be expanded
  const isExpanded = isManuallyExpanded || activeSectionCount === 0

  return (
    <Box ref={boxRef}>
      <Collapse in={!isExpanded}>
        <HStack>
          <Box
            flexGrow={1}
            h={0}
            borderBottom="1px dashed"
            borderColor="gray.500"
          />
          <Button
            id={`add-a-section-${insertionIndex}`}
            colorScheme="brand.primary"
            onClick={onExpand}
          >
            Add a section
          </Button>
          <Box
            flexGrow={1}
            h={0}
            borderBottom="1px dashed"
            borderColor="gray.500"
          />
        </HStack>
      </Collapse>
      <Collapse in={isExpanded}>
        <AddSectionCard
          id={`add-section-${insertionIndex}`}
          onAddSection={onAddSection}
          onCancel={onCancel}
        />
      </Collapse>
    </Box>
  )
}

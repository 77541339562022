import { Box } from "@chakra-ui/react"
import React, { PropsWithChildren, useRef } from "react"

interface VideoPlayerProps {
  url?: string
  canSkipAhead: boolean
  onMediaEnded: () => void
  onSkippedAhead: () => void
}

export const VideoPlayer: React.FC<PropsWithChildren<VideoPlayerProps>> = ({
  url,
  canSkipAhead,
  onMediaEnded,
  onSkippedAhead,
}) => {
  const progress = useRef(0)
  const seeking = useRef(false)
  const lastSentSkippedAhead = useRef(0)

  const handleTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    if (!seeking.current) {
      progress.current = Math.max(
        event.currentTarget.currentTime,
        progress.current
      )
    }
  }

  const handleSeeking = () => {
    seeking.current = true
  }

  const resetSeek = useRef<number | null>(null)

  const handleSeeked = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    seeking.current = false

    const video = event.currentTarget

    if (!canSkipAhead && video.currentTime > progress.current) {
      if (resetSeek.current) clearTimeout(resetSeek.current)

      resetSeek.current = window.setTimeout(() => {
        video.currentTime = progress.current
        if (Date.now() - lastSentSkippedAhead.current > 10000) {
          onSkippedAhead()
          lastSentSkippedAhead.current = Date.now()
        }
      }, 100)
    }
  }

  return (
    <Box
      as="video"
      w="100%"
      h="100%"
      controls
      controlsList="nodownload noplaybackrate"
      disablePictureInPicture
      onTimeUpdate={handleTimeUpdate}
      onSeeking={handleSeeking}
      onSeeked={handleSeeked}
      onEnded={onMediaEnded}
      data-qa={`screenshot-player-${url!}`}
    >
      <source src={url} />
    </Box>
  )
}

import { useSelector } from "react-redux"

import { getSelectedGoalNodeForSection } from "Redux/reducers/test-results/selectors"
import { useSectionContext } from "UsabilityHub/contexts"

import { useIsPrototypeTaskFlow } from "./use-prototype-type"

export function useTaskGoalNodeId() {
  const { section } = useSectionContext()

  const isTaskFlow = useIsPrototypeTaskFlow()
  const goalNodeId = useSelector(getSelectedGoalNodeForSection(section.id))

  if (!isTaskFlow) {
    // Only task flow goals are recognised when the feature flag is enabled
    return null
  }

  return goalNodeId
}

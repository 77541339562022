import { Flex, Text } from "@chakra-ui/react"
import React from "react"

export type OnboardingFlowPageContentProps = {
  image: React.ReactNode
  heading: React.ReactNode
  body: React.ReactNode
}

export const OnboardingFlowPageContent: React.FC<
  OnboardingFlowPageContentProps
> = ({ image, heading, body }) => (
  <>
    <Flex>{image}</Flex>
    <Flex
      flexDirection="column"
      gap={2}
      flexGrow={1}
      textAlign="center"
      px={4}
      maxH="125px"
    >
      <Text as="h3" textStyle="ds.display.primary">
        {heading}
      </Text>
      <Text textStyle="ds.paragraph.primary" flexGrow={1}>
        {body}
      </Text>
    </Flex>
  </>
)

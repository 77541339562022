import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  OrderedList,
} from "@chakra-ui/react"
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers"
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable"
import React, { useCallback } from "react"
import {
  ScreenerQuestion,
  ScreenerQuestionType,
} from "~/api/generated/usabilityhubSchemas"
import { SortableScreenerQuestionForm } from "./SortableScreenerQuestionForm"

type FormState = {
  isDirty: boolean
  isSubmitting: boolean
  errors: Record<string, unknown>
}

export type FormScreenerQuestions = {
  questionId: string
  formState: FormState
}[]

type ScreenerActions = {
  removeScreenerQuestion: (id: string) => void
  appendScreenerQuestion: () => void
  updateScreenerQuestion: (
    updatedScreenerQuestion: ScreenerQuestion,
    position: number
  ) => Promise<void>
  duplicateScreenerQuestion: (
    screenerQuestion: ScreenerQuestion
  ) => Promise<void>
}

type ScreenerFormProps = {
  screenerQuestions: ScreenerQuestion[]
  setFormScreenerQuestions?: React.Dispatch<
    React.SetStateAction<FormScreenerQuestions>
  >
  // Whether or not to use a <form> for each question.
  // Usability tests screeners are already inside a giant <form> so they shouldn't.
  renderFormElement?: boolean
  onFormChange?: (newState: unknown) => void
  validQuestionTypes: ScreenerQuestionType[]
  readOnly: boolean
  maxQuestionsAllowed?: number
} & ScreenerActions

export function ScreenerForm({
  screenerQuestions,
  appendScreenerQuestion,
  removeScreenerQuestion,
  updateScreenerQuestion,
  duplicateScreenerQuestion,
  setFormScreenerQuestions,
  onFormChange,
  renderFormElement,
  validQuestionTypes,
  readOnly,
  maxQuestionsAllowed = Infinity,
}: ScreenerFormProps) {
  const sensors = useSensors(useSensor(PointerSensor))

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const question = screenerQuestions.find((q) => q.id === active.id)
      if (!question) return
      const newPosition = screenerQuestions.findIndex((q) => q.id === over?.id)

      await updateScreenerQuestion(question, newPosition)
    }
  }

  // This is used only for mod studies screeners by a useEffect that calls this fn
  // whenever the form state changes so that we can update the status in the sidebar.
  const screenerFormStateCallback = setFormScreenerQuestions
    ? useCallback(
        (
          questionId: string,
          isDirty: boolean,
          isSubmitting: boolean,
          errors: Record<string, unknown>
        ) => {
          setFormScreenerQuestions((oldQuestions) => {
            const newQuestions = oldQuestions.slice()
            const questionIndex = newQuestions.findIndex(
              (q) => q.questionId === questionId
            )
            const updatedData = {
              questionId,
              formState: { isDirty, isSubmitting, errors },
            }

            if (questionIndex > -1) {
              newQuestions[questionIndex] = updatedData
            } else {
              newQuestions.push(updatedData)
            }

            return newQuestions
          })
        },
        []
      )
    : undefined

  return (
    <div>
      <OrderedList
        listStyleType="none"
        m={0}
        display="flex"
        flexDirection="column"
        gap={8}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={screenerQuestions}
            strategy={verticalListSortingStrategy}
          >
            {screenerQuestions.map((screenerQuestion, questionPosition) => (
              <SortableScreenerQuestionForm
                key={screenerQuestion.id}
                screenerQuestion={screenerQuestion}
                questionPosition={questionPosition}
                onRemove={() => removeScreenerQuestion(screenerQuestion.id)}
                onUpdate={(updatedScreenerQuestion) =>
                  updateScreenerQuestion(
                    updatedScreenerQuestion,
                    questionPosition
                  )
                }
                onDuplicate={() => duplicateScreenerQuestion(screenerQuestion)}
                formStateCallback={screenerFormStateCallback}
                onFormChange={onFormChange}
                renderFormElement={renderFormElement}
                validQuestionTypes={validQuestionTypes}
                readOnly={readOnly}
                maxScreenerQuestionsReached={
                  screenerQuestions.length >= maxQuestionsAllowed
                }
              />
            ))}
          </SortableContext>
        </DndContext>
      </OrderedList>

      {screenerQuestions.length >= maxQuestionsAllowed && (
        <Alert status="warning" mt={4}>
          <AlertIcon />
          <AlertDescription>
            You have reached the maximum of{" "}
            {maxQuestionsAllowed === 4 ? "four" : maxQuestionsAllowed} screener
            questions.
          </AlertDescription>
        </Alert>
      )}

      <Flex justifyContent="flex-end" gap={2} mt={8} me={8}>
        <Button
          size="sm"
          colorScheme="brand.primary"
          onClick={appendScreenerQuestion}
          isDisabled={
            readOnly || screenerQuestions.length >= maxQuestionsAllowed
          }
        >
          Add screener question
        </Button>
      </Flex>
    </div>
  )
}

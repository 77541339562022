import { FormControl, FormLabel, Input } from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"
import { Fields, WrappedFieldsProps } from "redux-form"

import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { containsURLs } from "UsabilityHub/components/TestForm/validators/validate-contains-urls"

import { MarkdownFormLabel, MarkdownTextarea } from "../../TestFormMarkdown"

const WrappedThankYouScreenFields: React.FC<
  React.PropsWithChildren<WrappedFieldsProps>
> = ({ thankyou_heading, thankyou_message }) => {
  const isEnabled = useSelector(getFormValue("customize_thankyou")) as boolean
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <>
      <FormControl id={thankyou_heading.input.name}>
        <FormLabel>Title</FormLabel>
        <Input
          isReadOnly={readOnly}
          isRequired={isEnabled}
          {...thankyou_heading.input}
        />
      </FormControl>
      <FormControl id={thankyou_message.input.name}>
        <MarkdownFormLabel>Message</MarkdownFormLabel>
        <MarkdownTextarea
          isReadOnly={readOnly}
          isRequired={isEnabled}
          {...thankyou_message.input}
        />
      </FormControl>
    </>
  )
}

export const ThankYouScreenFields: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Fields
    names={["thankyou_message", "thankyou_heading"]}
    component={WrappedThankYouScreenFields}
    warn={containsURLs}
  />
)

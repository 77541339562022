import { get } from "lodash"
import { arrayPush, arrayRemove, change } from "redux-form"

import { State } from "Redux/app-store"
import {
  getFormName,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import { createCheckpoint } from "Redux/reducers/test-builder/action-creators/undo"
import { SaveDeletedQuestionAction } from "Redux/reducers/test-builder/action-types"
import { Type } from "Redux/reducers/test-builder/constants"
import {
  cloneQuestion,
  createQuestion,
  isPersisted,
  moveElementToOtherArray,
} from "Redux/reducers/test-builder/helpers"
import {
  QuestionType,
  ThunkAction,
  UsabilityTestSectionQuestion,
  UsabilityTestSectionType,
} from "Types"
import { defaultRandomized } from "Utilities/usability-test-section-question"

export const addQuestion =
  (
    sectionIndex: number,
    sectionType: UsabilityTestSectionType
  ): ThunkAction<State> =>
  (dispatch) => {
    const question = createQuestion(sectionType)
    dispatch(
      arrayPush(getFormName(), `sections[${sectionIndex}].questions`, question)
    )
  }

export const deleteQuestion =
  (sectionIndex: number, questionIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const usabilityTest = getFormValues(getState())
    const section = usabilityTest.sections[sectionIndex]
    const field = `sections[${sectionIndex}].questions`
    const question = get(usabilityTest, field)[
      questionIndex
    ] as UsabilityTestSectionQuestion

    dispatch(
      createCheckpoint({
        type: "delete-question",
        sectionId: section.id,
        sectionIndex,
        questionIndex,
        question,
      })
    )

    if (isPersisted(question)) {
      dispatch<SaveDeletedQuestionAction>({
        type: Type.SAVE_DELETED_TEST_FORM_QUESTION,
        payload: {
          sectionId: section.id,
          questionId: question.id,
        },
      })
    }

    dispatch(arrayRemove(getFormName(), field, questionIndex))
  }

export const updateQuestionRandomized =
  (
    sectionIndex: number,
    questionIndex: number,
    questionType: QuestionType
  ): ThunkAction<State> =>
  (dispatch) => {
    dispatch(
      change(
        getFormName(),
        `sections[${sectionIndex}].questions[${questionIndex}].randomized`,
        defaultRandomized(questionType)
      )
    )
  }

export const moveQuestion =
  (
    fromQuestionPath: string,
    toQuestionPath: string,
    fromQuestionIndex: number,
    toQuestionIndex: number
  ): ThunkAction<State> =>
  (dispatch) => {
    dispatch(
      moveElementToOtherArray(
        fromQuestionPath,
        fromQuestionIndex,
        toQuestionPath,
        toQuestionIndex
      )
    )
  }

export const duplicateQuestion =
  (sectionIndex: number, questionIndex: number): ThunkAction<State> =>
  (dispatch, getState) => {
    const usabilityTest = getFormValues(getState())
    const field = `sections[${sectionIndex}].questions`
    const oldQuestion = get(usabilityTest, field)[questionIndex]

    const newQuestion = cloneQuestion(oldQuestion)
    dispatch(
      arrayPush(
        getFormName(),
        `sections[${sectionIndex}].questions`,
        newQuestion
      )
    )
  }

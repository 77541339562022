import { useEffect } from "react"

import { InnerProps } from "UsabilityHub/components/UsabilityTestSectionTask/props"

export const QuestionsTask: React.FC<React.PropsWithChildren<InnerProps>> = ({
  usabilityTestSection,
  updateResponseSection,
}) => {
  // Ensure that this fires *after* the outer component's componentDidMount
  // fires because that's where `startResponseSection` is called.
  // Fix this: https://gitlab.com/usabilityhub/app/issues/2238
  useEffect(() => {
    updateResponseSection(usabilityTestSection.id, {
      instructions_duration_ms: 0,
      task_duration_ms: 0,
    })
  }, [usabilityTestSection.id, updateResponseSection])

  // This component is hidden by the `UsabilityTestLayout`, which will focus
  // on the questions.
  return null
}

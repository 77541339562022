import { Grid, HStack, Stat, Text, Tooltip } from "@chakra-ui/react"
import React from "react"

import { DotIcon } from "Shared/icons/DotIcon"
import WithDot from "UsabilityHub/components/ModeratedStudy/WithDot"

type Props = {
  applicantCounts: {
    eligible: number
    ineligible: number
  }
  bookingCounts: {
    upcoming: number
    past: number
    short_notice_canceled?: number
  }
  maxBookings?: number
}
const Stats: React.FC<Props> = ({
  applicantCounts,
  bookingCounts,
  maxBookings,
}) => {
  const totalApplicants = applicantCounts.eligible + applicantCounts.ineligible
  const totalBookings =
    bookingCounts.past +
    bookingCounts.upcoming +
    (bookingCounts.short_notice_canceled ?? 0)

  return (
    <HStack
      gridArea="stats"
      justifyContent={["flex-start", null, "space-between"]}
      alignItems="start"
      gap={4}
      px={{ base: 0, lg: 6 }}
    >
      <Tooltip
        rounded="md"
        placement="top"
        label={
          <Grid
            templateColumns="auto max-content max-content"
            columnGap={2}
            alignItems="center"
            py={2}
            pl={2}
            pr={3}
          >
            <DotIcon color="green.500" boxSize="1em" />
            <Text pr={14}>Eligible</Text>
            <Text>{applicantCounts.eligible}</Text>
            <DotIcon color="red.500" boxSize="1em" />
            <Text pr={14}>Not Eligible</Text>
            <Text>{applicantCounts.ineligible}</Text>
          </Grid>
        }
      >
        <Stat flex={{ base: 0, lg: 1 }} data-group>
          {/* StatNumber and StatLabel do not support text styles, so we use
          a Text with an `as` prop to create the dd and dt instead */}
          <Text as="dd" textStyle="ds.heading.primary" w="max-content">
            {totalApplicants}
          </Text>
          <Text
            as="dt"
            color="ds.text.subtle"
            textStyle="ds.paragraph.secondary"
            _groupHover={{ color: "ds.text.default" }}
          >
            Applicants
          </Text>
        </Stat>
      </Tooltip>
      <Tooltip
        rounded="md"
        placement="top"
        label={
          <Grid
            templateColumns="repeat(2, max-content)"
            columnGap={14}
            py={2}
            px={3}
          >
            <Text>Upcoming</Text>
            <WithDot visible={!!bookingCounts.upcoming}>
              <Text>{bookingCounts.upcoming}</Text>
            </WithDot>
            <Text>Past</Text>
            <Text>{bookingCounts.past}</Text>
          </Grid>
        }
      >
        <Stat flex={{ base: 0, sm: 1 }} data-group>
          <WithDot visible={!!bookingCounts.upcoming}>
            <Text as="dd" textStyle="ds.heading.primary" w="max-content">
              {totalBookings}
              {maxBookings && ` of ${maxBookings}`}
            </Text>
          </WithDot>
          <Text
            as="dt"
            color="ds.text.subtle"
            textStyle="ds.paragraph.secondary"
            _groupHover={{ color: "ds.text.default" }}
          >
            Sessions
          </Text>
        </Stat>
      </Tooltip>
    </HStack>
  )
}

export default Stats

import { Flex, ListItem, OrderedList, Spinner } from "@chakra-ui/react"
import { record } from "Services/eventy"
import { CreateNewModeratedStudyModal } from "UsabilityHub/components/CreateNewModeratedStudyModal/CreateNewModeratedStudyModal"
import { ModeratedStudyEmptyState } from "UsabilityHub/components/ModeratedStudyEmptyState/ModeratedStudyEmptyState"
import { useModal } from "Utilities/modals/use-modal"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useListModeratedStudies } from "~/api/generated/usabilityhub-components"

import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import ModeratedStudyCard from "./ModeratedStudyCard/ModeratedStudyCard"

export function ModeratedStudyList() {
  const navigate = useNavigate()
  const { data, isLoading } = useListModeratedStudies({})

  const {
    open: openCreateNewModeratedStudyModal,
    close: closeCreateNewModeratedStudyModal,
  } = useModal(CreateNewModeratedStudyModal)

  const openCreateModal = () => {
    openCreateNewModeratedStudyModal({
      onCreate: (id) => {
        navigate(ROUTES.INTERVIEW.EDIT.buildPath({ moderatedStudyId: id }))
        closeCreateNewModeratedStudyModal()
      },
    })
  }

  const currentUser = useCurrentUser()

  useEffect(() => {
    if (data && currentUser.id) {
      void record("moderated_studies.viewed", {
        user_id: currentUser.id,
        number_of_moderated_studies: data.length,
      })
    }
  }, [data, currentUser.id])

  if (isLoading) {
    return (
      <Flex
        mt="32"
        justifyContent="center"
        alignItems="center"
        direction="column"
        gap="4"
      >
        <Spinner size="lg" thickness="4px" color="gray.500" />
        Fetching...
      </Flex>
    )
  }

  if (!data || data.length === 0) {
    return <ModeratedStudyEmptyState handleCreateStudy={openCreateModal} />
  }

  return (
    <OrderedList styleType="none" m="0" spacing={4}>
      {data.map((moderatedStudyListEntry) => (
        <ListItem key={moderatedStudyListEntry.id}>
          <ModeratedStudyCard study={moderatedStudyListEntry} />
        </ListItem>
      ))}
    </OrderedList>
  )
}

import { State } from "Redux/app-store"
import {
  SetIsEstimateValidAction,
  UpdateCreditsAndDurationFailureAction,
  UpdateCreditsAndDurationRequestAction,
  UpdateCreditsAndDurationSuccessAction,
} from "Redux/reducers/test-builder/action-types"
import { Type } from "Redux/reducers/test-builder/constants"
import { CreditsAndDuration } from "Redux/reducers/test-builder/types"
import { axios } from "Services/axios"
import { usabilityTestsEstimatePath } from "Shared/constants/routes"
import { AsyncThunkAction, ResponseDurationRange, UsabilityTest } from "Types"

interface EstimateResponse {
  credit_count: number
  estimated_duration_range: ResponseDurationRange
}

export function updateCreditsAndDuration(
  formValues: Readonly<UsabilityTest>
): AsyncThunkAction<State, Readonly<CreditsAndDuration>> {
  return async (dispatch) => {
    try {
      dispatch<UpdateCreditsAndDurationRequestAction>({
        type: Type.UPDATE_TEST_FORM_CREDITS_AND_DURATION_REQUEST,
      })

      const { data: response } = await axios.post<EstimateResponse>(
        usabilityTestsEstimatePath(),
        formValues
      )
      const creditsAndDuration: CreditsAndDuration = {
        creditCount: response.credit_count,
        estimatedDurationRange: response.estimated_duration_range,
      }
      dispatch<UpdateCreditsAndDurationSuccessAction>({
        type: Type.UPDATE_TEST_FORM_CREDITS_AND_DURATION_SUCCESS,
        payload: creditsAndDuration,
      })
      return creditsAndDuration
    } catch (error) {
      dispatch<UpdateCreditsAndDurationFailureAction>({
        type: Type.UPDATE_TEST_FORM_CREDITS_AND_DURATION_FAILURE,
        payload: error,
      })
      throw error
    }
  }
}

export function setIsEstimateValid(
  isEstimateValid: boolean
): SetIsEstimateValidAction {
  return {
    type: Type.SET_TEST_FORM_IS_ESTIMATE_VALID,
    payload: isEstimateValid,
  }
}

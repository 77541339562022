import {
  CurrentAccountProvider,
  useCurrentAccount,
  useCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import UserEmulationApi from "~/api/Admin/UserEmulationApi"
import { EmulationAlert } from "./EmulationAlert"

export const CustomerEmulationAlert: React.FC = () => {
  const { id: accountId } = useCurrentAccount()
  const { name, id } = useCurrentUser()

  return (
    <EmulationAlert
      name={name}
      id={id}
      accountId={accountId}
      switchBackUrl={UserEmulationApi.stopEmulatingUser.path()}
    />
  )
}

// If you want to use this component outside of the react component tree (e.g. using `mount_react`)
// you need to wrap it in a `CurrentAccountProvider`.
export const CustomerEmulationAlertWrapper: React.FC = () => {
  return (
    <CurrentAccountProvider>
      <CustomerEmulationAlert />
    </CurrentAccountProvider>
  )
}

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Icon,
  IconButton,
  Spacer,
  Spinner,
  Text,
} from "@chakra-ui/react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"
import {
  add,
  endOfDay,
  getDaysInMonth,
  isAfter,
  isWithinInterval,
  startOfDay,
} from "date-fns"
import { toDate, utcToZonedTime } from "date-fns-tz"
import { isEqual } from "lodash"
import React, { useEffect, useMemo, useRef, useState } from "react"

import { ScrollingWrapper } from "Components/test-results/section-results/SectionResultsCards/ScrollingWrapper"
import {
  ListModeratedStudyBookingSlotsQueryParams,
  ListModeratedStudyBookingSlotsResponse,
  useListModeratedStudyBookingSlots,
  usePreviewModeratedStudyBookingSlots,
} from "~/api/generated/usabilityhub-components"

import { matchPath } from "react-router"
import { useModeratedStudyApplicationContext } from "./ModeratedStudyApplicationContext"

const WEEKDAY_ORDER = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]

type DateType = [number, number, number] // year, month, date number

type BookingSlot =
  ListModeratedStudyBookingSlotsResponse["booking_slots"][number]

type Props = {
  moderatedStudyApplicationId: string
  timezone: string
  slot: string | null
  existingBookingId?: string
  onSlotChange: (slot: string | null) => void
  // For the booking page, we need to show a loading state for the whole page during the first time
  // retrieving the calendar data. If there is no available slots for the study, the page should be
  // redirected to the thank you page.
  //
  // If no available slots are found when users are browsing the page or when users are invited,
  // the page will show appropriate messages and a "Continue" button.
  //
  // For the reschedule page, it still show "Select a date to view available times" and "Book" button
  isFirstLoading: boolean
  setIsFirstLoading?: (isFirstLoading: boolean) => void
  setHasAvailableSlots?: (hasAvailableSlots: boolean) => void
  displayFullSlots?: boolean
}

// Total height of calendar is 48 per row (40 + 8 gap), fixed to always 6 rows
// plus 28 for the weekday headings and 48 for the title
export const calendarHeight = 48 * 6 + 28 + 48

export const BookingCalendar: React.FC<Props> = ({
  moderatedStudyApplicationId,
  timezone,
  slot,
  existingBookingId,
  onSlotChange,
  isFirstLoading,
  setIsFirstLoading,
  setHasAvailableSlots,
  displayFullSlots = false,
}) => {
  const isFullPreview = matchPath(
    { path: "/interviews/:moderatedStudyId/preview/*" },
    location.pathname
  )
  // For anywhere (like the drawer on the edit page) where we don't want to navigate away
  const isCalendarOnlyPreview = matchPath(
    { path: "/interviews/:moderatedStudyId/edit" },
    location.pathname
  )

  const isPreview = isFullPreview || isCalendarOnlyPreview

  // We only have this ID on the preview page, not the regular version so we can't use useTypedParams
  const moderatedStudyId = isPreview?.params["moderatedStudyId"]

  const { application, navigateToThankYouPage } =
    useModeratedStudyApplicationContext()

  // The year and month the calendar is currently displaying
  const [[year, month], setYearAndMonth] = useState<[number, number]>(() => {
    const now = slot ? utcToZonedTime(toDate(slot), timezone) : new Date()
    // Don't forget JS months are 0-indexed
    return [now.getFullYear(), now.getMonth() + 1]
  })

  // When we pass query parameters like {a_param: a}, the a_param is declared as a string,
  // while a is undefined. Consequently, the backend will receive the string “undefined” for a_param.
  const queryParams: ListModeratedStudyBookingSlotsQueryParams = {
    year,
    month,
    timezone,
  }

  const {
    isLoading: realIsLoading,
    isError: realIsError,
    data: realData,
    refetch: realRefetch,
  } = useListModeratedStudyBookingSlots(
    {
      pathParams: {
        moderatedStudyApplicationId,
      },
      queryParams: queryParams,
    },
    {
      keepPreviousData: true,
      enabled: !isPreview,
    }
  )

  const {
    isLoading: previewIsLoading,
    isError: previewIsError,
    data: previewData,
    refetch: previewRefetch,
  } = usePreviewModeratedStudyBookingSlots(
    {
      pathParams: {
        // Should always be present in this case, but the type system doesn't know
        moderatedStudyId: moderatedStudyId ?? "",
      },
      queryParams: queryParams,
    },
    {
      keepPreviousData: true,
      enabled: !!isPreview,
    }
  )

  // Only one or the other of these queries will be active depending on whether this is a preview
  const isLoading = isPreview ? previewIsLoading : realIsLoading
  const isError = isPreview ? previewIsError : realIsError
  const data = isPreview ? previewData : realData
  const refetch = isPreview ? previewRefetch : realRefetch

  const startOfMonthInLocal = useMemo(
    () =>
      toDate(`${year}-${month > 9 ? "" : "0"}${month}}-01T00:00:00`, {
        timeZone: timezone,
      }),
    [year, month, timezone]
  )

  const isRescheduling = useRef(!!existingBookingId)

  // The year, month, and date of the selected date by users.
  const [selectedDate, setSelectedDate] = useState<DateType>()

  // For the booking page, we want to pick the first available slot by default
  // when changing months (if any are available this month)
  // For the reschedule page, we want to show “Select a date to view available times”
  // by default when changing months.
  useEffect(() => {
    if (data) {
      // Don't do this the first time the data loads if we have an existing slot selected
      if (isRescheduling.current) {
        return
      }

      const firstSlotInMonth = data.booking_slots.find((slot) => {
        const slotStartTimeInLocal = toDate(slot.start_time)

        return isAfter(slotStartTimeInLocal, startOfMonthInLocal)
      })

      if (firstSlotInMonth?.start_time) {
        const slotTime = utcToZonedTime(firstSlotInMonth?.start_time, timezone)
        setSelectedDate([
          slotTime.getFullYear(),
          slotTime.getMonth() + 1,
          slotTime.getDate(),
        ])
        onSlotChange(firstSlotInMonth?.start_time)
      } else {
        setSelectedDate(undefined)
        onSlotChange(null)
      }
    } else {
      onSlotChange(null)
    }
  }, [data, startOfMonthInLocal, onSlotChange])

  useEffect(() => {
    // This is used for the booking page only
    if (isFirstLoading && !isLoading) {
      if (data) setHasAvailableSlots?.(data.has_available_booking_slots)
      if (
        data &&
        data.booking_slots.length === 0 &&
        !data.has_available_booking_slots &&
        !application.is_invited &&
        !isCalendarOnlyPreview
      ) {
        void navigateToThankYouPage()
      } else {
        setIsFirstLoading?.(false)
      }
    }
  }, [isLoading, isCalendarOnlyPreview])

  useEffect(
    () => data && setHasAvailableSlots?.(data.has_available_booking_slots),
    [data?.has_available_booking_slots, setHasAvailableSlots]
  )

  if (isFirstLoading || isLoading)
    return (
      <Center gridColumn="1 / -1">
        <Spinner />
      </Center>
    )

  if (isError || !data)
    return (
      <Alert status="error" gridColumn="1 / -1">
        <AlertIcon />
        <AlertTitle>There was an error fetching booking slots!</AlertTitle>
        <AlertDescription>
          <Button colorScheme="red" onClick={() => refetch()}>
            Refresh
          </Button>
        </AlertDescription>
      </Alert>
    )

  const daysInMonth = getDaysInMonth(new Date(year, month - 1))

  // We have a flat list of slots from the API, group them up by day
  // Starting array from 1 so we can use the real day number as the index
  const slotsByDay = new Array(daysInMonth + 1).fill(null).map((_, i) => {
    const day = new Date(year, month - 1, i)

    return data.booking_slots.filter((slot) => {
      const slotTime = utcToZonedTime(slot.start_time, timezone)
      return isWithinInterval(slotTime, {
        start: startOfDay(day),
        end: endOfDay(day),
      })
    })
  })

  const onDateChange = (start_time: string) => {
    if (!existingBookingId) {
      // On the booking page, we'll select the first available slot on the day by default
      onSlotChange(start_time)
    }

    const slotTime = utcToZonedTime(start_time, timezone)
    setSelectedDate([
      slotTime.getFullYear(),
      slotTime.getMonth() + 1,
      slotTime.getDate(),
    ])
  }

  return (
    <>
      <Box
        marginTop={isCalendarOnlyPreview ? 4 : 0}
        marginBottom={isCalendarOnlyPreview ? 8 : 0}
      >
        <CalendarDisplay
          year={year}
          month={month}
          slotsByDay={slotsByDay}
          earlierData={data.earlier_data}
          laterData={data.later_data}
          setYearAndMonth={setYearAndMonth}
          selectedDate={selectedDate}
          onDateChange={onDateChange}
          hasAvailableSlots={!!data.has_available_booking_slots}
        />
      </Box>

      {selectedDate &&
      isEqual([year, month], [selectedDate[0], selectedDate[1]]) ? (
        <AvailableSlotList
          slotsByDay={slotsByDay}
          selectedTimezone={timezone}
          selectedSlot={slot}
          selectedDate={selectedDate}
          setSelectedSlot={onSlotChange}
          leftAlignText={!!isCalendarOnlyPreview}
          displayFullSlots={displayFullSlots}
        />
      ) : (
        <Center flexGrow={isCalendarOnlyPreview ? "1" : "0"}>
          <Flex
            color="text.secondary"
            textAlign="center"
            flexFlow="column"
            w={isCalendarOnlyPreview ? "100%" : "auto"}
            h={isCalendarOnlyPreview ? "100%" : "auto"}
          >
            {isRescheduling.current ? (
              "Select a date to view available times"
            ) : data.has_available_booking_slots ? (
              "No time slots available"
            ) : isCalendarOnlyPreview ? (
              <Center
                borderRadius={6}
                padding={4}
                backgroundColor="bg.surface.callout"
                justifyContent="center"
                alignItems="center"
                flex="1"
              >
                <Text fontSize="md">No time slots available</Text>
              </Center>
            ) : (
              <>
                <Text fontWeight="medium" mb={1}>
                  No time slots available
                </Text>
                <Text fontSize="sm">
                  Currently, all our time slots are booked. Please continue to
                  finish your application.
                </Text>
              </>
            )}
          </Flex>
        </Center>
      )}
    </>
  )
}

const CalendarDisplay: React.FC<{
  year: number
  month: number
  slotsByDay: BookingSlot[][]
  earlierData: boolean
  laterData: boolean
  setYearAndMonth: (date: [number, number]) => void
  selectedDate: DateType | undefined
  onDateChange: (start_time: string) => void
  hasAvailableSlots: boolean
}> = ({
  year,
  month,
  slotsByDay,
  earlierData,
  laterData,
  setYearAndMonth,
  selectedDate,
  onDateChange,
  hasAvailableSlots,
}) => {
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay()
  const calendarPaddingDays = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1

  const changeMonth = (delta: number) => {
    return () => {
      const newDate = add(new Date(year, month - 1), { months: delta })

      setYearAndMonth([newDate.getFullYear(), newDate.getMonth() + 1])
    }
  }

  return (
    <Flex direction="column" data-qa="booking-calendar">
      <Flex align="center" mb={4}>
        <Text color="text.primary" fontSize="lg" fontWeight="normal">
          <Text as="span" fontWeight="semibold">
            {new Date(year, month - 1).toLocaleString(navigator.language, {
              month: "long",
            })}
          </Text>{" "}
          {year}
        </Text>

        <Spacer flexGrow={1} />

        <IconButton
          variant="ghost"
          size="sm"
          icon={<Icon boxSize={5} color="gray.500" as={ChevronLeftIcon} />}
          aria-label="Previous month"
          onClick={changeMonth(-1)}
          isDisabled={!earlierData || !hasAvailableSlots}
        />
        <IconButton
          variant="ghost"
          size="sm"
          icon={<Icon boxSize={5} color="gray.500" as={ChevronRightIcon} />}
          aria-label="Next month"
          onClick={changeMonth(1)}
          isDisabled={!laterData || !hasAvailableSlots}
        />
      </Flex>

      <Grid
        templateColumns="repeat(7, 42px)"
        templateRows="20px"
        autoRows="42px"
        gap={2}
      >
        {WEEKDAY_ORDER.map((day) => (
          <Text key={day} fontSize="xs" align="center">
            {day}
          </Text>
        ))}

        {new Array(calendarPaddingDays).fill(null).map((_, i) => (
          <div key={`padding-${i}`} />
        ))}

        {slotsByDay.map((slotsOnDay, dayNum) => {
          // There is no day zero
          if (dayNum === 0) return null

          const selected = isEqual([year, month, dayNum], selectedDate)

          if (slotsOnDay.length > 0) {
            return (
              <Button
                key={dayNum}
                data-qa="calendar-day"
                rounded="md"
                fontSize="md"
                fontWeight="semibold"
                color="brand.neutral.default"
                background="white"
                boxShadow="sm"
                borderWidth={1}
                borderColor="gray.200"
                outline={selected ? "2px solid" : undefined}
                outlineColor={selected ? "brand.primary.500" : undefined}
                _focusVisible={{
                  borderColor: "brand.primary.200",
                  background: "brand.primary.50",
                }}
                onClick={() => {
                  onDateChange(slotsOnDay[0].start_time)
                }}
              >
                {dayNum}
              </Button>
            )
          } else {
            return (
              <Center
                key={dayNum}
                fontSize="md"
                fontWeight="normal"
                color="blackAlpha.500"
              >
                {dayNum}
              </Center>
            )
          }
        })}
      </Grid>
    </Flex>
  )
}

const AvailableSlotList: React.FC<{
  slotsByDay: BookingSlot[][]
  selectedTimezone: string
  selectedDate: DateType | undefined
  selectedSlot: string | null
  setSelectedSlot: (slotTime: string) => void
  leftAlignText?: boolean
  displayFullSlots?: boolean
}> = ({
  slotsByDay,
  selectedTimezone,
  selectedDate,
  selectedSlot,
  setSelectedSlot,
  leftAlignText = false,
  displayFullSlots = false,
}) => {
  const selectedSlotParsed = selectedDate
    ? new Date(selectedDate[0], selectedDate[1] - 1, selectedDate[2])
    : null
  const slotsForSelectedDay = selectedDate
    ? slotsByDay[selectedDate[2]] ?? []
    : []

  const container = useRef<HTMLDivElement>(null)
  const [scrollContainerMaxHeight, setScrollContainerMaxHeight] = useState<
    number | null
  >(null)
  useEffect(() => {
    // Grab the whole container, find the first element (our text block) and take the height off it off to find the
    // maximum height for the inner scroll container
    if (
      container?.current!["offsetHeight"] &&
      container?.current?.children[0].clientHeight
    )
      setScrollContainerMaxHeight(
        container?.current!["offsetHeight"] -
          container?.current?.children[0].clientHeight
      )
  }, [selectedDate])

  return (
    <Grid gap={4} overflow="hidden" ref={container}>
      <Text
        color="text.primary"
        fontSize="lg"
        fontWeight="normal"
        whiteSpace="break-spaces"
      >
        <Text as="span" fontWeight="semibold">
          {selectedSlotParsed?.toLocaleString(navigator.language, {
            weekday: "long",
          })}
          ,
        </Text>{" "}
        {selectedSlotParsed?.toLocaleString(navigator.language, {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      </Text>

      <ScrollingWrapper
        key={selectedSlotParsed?.toDateString()}
        axis="y"
        maxH={
          displayFullSlots
            ? undefined
            : `calc(${scrollContainerMaxHeight}px - var(--chakra-space-4))`
        } // Matches a full height calendar minus the date header
      >
        <Flex direction="column" gap={2} p={1}>
          {slotsForSelectedDay.map((slot) => {
            const slotTime = slot.start_time
            const parsedSlotTime = utcToZonedTime(
              slot.start_time,
              selectedTimezone
            )
            // Chrome has a bug in some locales where 12pm
            // is displayed as '00:00 pm' instead of '12:00 pm'
            const humanizedSlotTime = parsedSlotTime?.toLocaleString(
              navigator.language,
              {
                timeStyle: "short",
                hourCycle: "h12",
              }
            )

            return (
              <Button
                key={slotTime}
                data-qa="time-slot"
                variant="outline"
                fontWeight="semibold"
                outline={selectedSlot === slotTime ? "2px solid" : undefined}
                outlineColor={
                  selectedSlot === slotTime ? "brand.primary.500" : undefined
                }
                flexShrink={0}
                _focusVisible={{
                  borderColor: "brand.primary.200",
                  background: "brand.primary.50",
                }}
                // TODO: we'll get rid of this once these buttons do something, so borrowing leftAlignText for now as it's used in the only place we want the hover disabled
                sx={
                  leftAlignText
                    ? {
                        pointerEvents: "none",
                      }
                    : {}
                }
                justifyContent={leftAlignText ? "flex-start" : "center"}
                onClick={() => setSelectedSlot(slotTime)}
              >
                {humanizedSlotTime}
              </Button>
            )
          })}
        </Flex>
      </ScrollingWrapper>
    </Grid>
  )
}

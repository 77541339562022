import { Input, InputProps } from "@chakra-ui/react"
import React from "react"

import { Omit } from "Types"

interface TextInputProps {
  type?: "text" | "email" | "password" | "number"
  autoSelect?: boolean
  valid?: boolean
  required?: boolean
  forwardedRef: React.Ref<HTMLInputElement>
}

const TextInputImpl: React.FC<InputProps & TextInputProps> = ({
  onFocus,
  required,
  pattern,
  valid,
  forwardedRef,
  autoSelect,
  ...props
}) => {
  // Extend `required` to reject empty strings (unless a `pattern` attribute
  // is provided).
  if (required && pattern === undefined) {
    pattern = ".*[^\\s].*"
  }

  return (
    <Input
      ref={forwardedRef}
      isInvalid={valid === false}
      height={10}
      required={required}
      pattern={pattern}
      bg="white"
      fontWeight="medium"
      p={2.5}
      color="gray.700"
      transition="color 0.2s"
      position="unset"
      outline=""
      onFocus={(e) => {
        if (autoSelect) e.currentTarget.select()
        if (onFocus !== undefined) onFocus(e)
      }}
      _focus={{
        borderColor: "brand.primary.400",
        boxShadow: "0 0 2px 0 var(--chakra-colors-blue-400) !important",
      }}
      _disabled={{
        color: "gray.400",
        borderColor: "gray.200",
        cursor: "not-allowed",
      }}
      _invalid={{
        _focus: {
          borderColor: "red.500",
        },
        borderColor: "red.500",
      }}
      {...props}
    ></Input>
  )
}

export type Props = InputProps & Omit<TextInputProps, "forwardedRef">

export const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => <TextInputImpl forwardedRef={ref} {...props} />
)

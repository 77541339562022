import { useEffect } from "react"

import { record } from "Services/eventy"

type testUpgradeSource = "test_builder_sidebar" | "recruitment_page"

export const useTrackTestUpgradeRequired = (
  isUpgradeRequired: boolean,
  usabilityTestId: number | string,
  source: testUpgradeSource
) => {
  return useEffect(() => {
    if (isUpgradeRequired) {
      void record("test.upgrade_required", {
        usability_test_id: usabilityTestId,
        source,
      })
    }
  }, [isUpgradeRequired])
}

import { useFilteredResponseSections } from "Components/test-results/hooks/use-filtered-response-sections"
import {
  ResponseSectionRecording,
  TEST_RECORDING_TYPES,
} from "JavaScripts/types/recording"
import { State } from "Redux/app-store"
import { getResponseSectionRecordingsForResponseSections } from "Redux/reducers/test-results/selectors"
import { useSectionContext } from "UsabilityHub/contexts"
import { useSelector } from "react-redux"

export const isSectionRecordingEnabled = (): boolean => {
  const { section } = useSectionContext()

  if (section.recording_attributes == null) return false

  return TEST_RECORDING_TYPES.some(
    (type) => section.recording_attributes![type]
  )
}

export const useSectionRecordings = (): ResponseSectionRecording[] => {
  if (!isSectionRecordingEnabled()) return []

  const { section } = useSectionContext()

  const filteredResponseSections = useFilteredResponseSections().filter(
    (responseSection) =>
      responseSection.usability_test_section_id === section.id
  )

  const recordings = useSelector((state: State) =>
    getResponseSectionRecordingsForResponseSections(
      state,
      filteredResponseSections
    )
  )

  return recordings
}

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  UseDisclosureReturn,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { OnboardingFlowPage } from "./OnboardingFlowPage"
import {
  OnboardingFlowPageContent,
  OnboardingFlowPageContentProps,
} from "./OnboardingFlowPageContent"

export type OnboardingFlowIndividualPage = {
  content: OnboardingFlowPageContentProps
  nextButtonLabel?: string
  previousButtonLabel?: string
}

type Props = {
  pages: OnboardingFlowIndividualPage[]
  onFinish?: () => void
  onCancel?: () => void
  allowOverlayClick?: boolean
} & UseDisclosureReturn

export type PageState = {
  idx: number
  direction: "next" | "previous"
}
const INITIAL_PAGE_STATE = {
  idx: 0,
  direction: "next",
} as const

/**
 * Simple onboarding slideshow/wizard component.
 *
 * It accepts:
 * - a `pages` array containing the content and optional text overrides
 * for the next/previous buttons
 * - the return value from Chakra's `useDisclosure` hook
 * - an optional `onFinish` function that runs when the onboarding is
 * completed (as opposed to `onClose` which is called if they escape out of it)
 *
 * If you need a custom `onClose` function, that can be passed to the
 * `useDisclosure` hook in the consumer.
 *
 * @example
 * const ExamplePage: OnboardingFlowIndividualPage = {
 *   content: {
 *     image: <Image src={SomeImage} />,
 *     heading: "This is the heading text",
 *     body: "This is the body text",
 *   },
 *   nextButtonLabel: "We're done here"
 * }
 *
 * // You can extract { isOpen, onOpen, onClose } here to control
 * // the onboarding from the consumer
 * const onboardingDisclosure = useDisclosure()
 *
 * // Render:
 * <OnboardingFlow
 *   pages={[ExamplePage]}
 *   {...onboardingDisclosure}
 *   onFinish={() => {console.log("done!")}}
 *   onCancel={() => {console.log("onboarding canceled!")}}
 * />
 *
 * // Note: in your implementation, you may want to preload the images
 * // used in each page so that they work smoothly, for example:
 * <Helmet>
 *   <link rel="preload" href={slide1} as="image" />
 *   <link rel="preload" href={slide2} as="image" />
 *   <link rel="preload" href={slide3} as="image" />
 *   <link rel="preload" href={slide4} as="image" />
 * </Helmet>
 */
// ts-prune-ignore-next
export const OnboardingFlow: React.FC<Props> = ({
  pages,
  isOpen,
  onClose,
  onFinish,
  onCancel,
  allowOverlayClick = false,
}) => {
  const [currentPageState, setCurrentPageState] =
    useState<PageState>(INITIAL_PAGE_STATE)
  const currentPage = pages[currentPageState.idx]

  useEffect(() => {
    return () => setCurrentPageState(INITIAL_PAGE_STATE)
  }, [isOpen])

  const handleNext = () => {
    if (currentPageState.idx === pages.length - 1) {
      onFinish?.()
    } else {
      changePage("next")
    }
  }

  const handleEsc = () => {
    setCurrentPageState(INITIAL_PAGE_STATE)
    onCancel?.()
    onClose()
  }

  const changePage = (direction: "next" | "previous") => {
    setCurrentPageState({
      direction,
      idx: newPageIndex({
        pages,
        currentPageIdx: currentPageState.idx,
        direction,
      }),
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      // We still let users hit Esc to close, but it should keep showing
      // on each visit until they've completed the onboarding
      closeOnOverlayClick={allowOverlayClick}
      onEsc={handleEsc}
    >
      <ModalOverlay>
        <ModalContent
          maxW="500px"
          w="full"
          h="580px"
          color="text.primary"
          overflow="hidden"
        >
          <ModalBody p={0}>
            <OnboardingFlowPage
              onNext={handleNext}
              onPrevious={() => changePage("previous")}
              content={<OnboardingFlowPageContent {...currentPage.content} />}
              pageCount={pages.length}
              currentPageState={currentPageState}
              nextButtonLabel={currentPage.nextButtonLabel}
              previousButtonLabel={currentPage.previousButtonLabel}
            />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

type NewPageIndexProps = {
  pages: OnboardingFlowIndividualPage[]
  currentPageIdx: number
  direction: "next" | "previous"
}

const newPageIndex = ({
  pages,
  direction,
  currentPageIdx,
}: NewPageIndexProps): number => {
  const proposed =
    direction === "next" ? currentPageIdx + 1 : currentPageIdx - 1
  if (!Object.keys(pages).includes(String(proposed))) return currentPageIdx
  return proposed
}

import { CardSortCardsImageData, CardSortCardsUploadImageResponse } from "Types"
import { apiCardSortCards } from "~/api"

import { isAxiosErrorWithMessage } from "./axios"

export async function uploadCardImageFile(
  file: File
): Promise<CardSortCardsUploadImageResponse> {
  const formData = new FormData()
  formData.append("file", file)

  try {
    // Upload the image file to S3 bucket via Shrine
    const upload_result =
      await apiCardSortCards.uploadCardImage<CardSortCardsUploadImageResponse>({
        data: formData,
      })
    return upload_result
  } catch (error) {
    const description = isAxiosErrorWithMessage(error)
      ? error.response.data.message
      : "Failed to upload the image. Please try again."
    throw new Error(description)
  }
}

export async function duplicateCardImage(
  image_data: CardSortCardsImageData
): Promise<CardSortCardsUploadImageResponse> {
  try {
    // Upload the image file to S3 bucket via Shrine
    const upload_result =
      await apiCardSortCards.duplicateCardImage<CardSortCardsUploadImageResponse>(
        {
          data: {
            image_data: image_data,
          },
        }
      )
    return upload_result
  } catch (error) {
    const description = isAxiosErrorWithMessage(error)
      ? error.response.data.message
      : "Failed to upload the image. Please try again."
    throw new Error(description)
  }
}

export async function deleteCardImageFile(
  image_data: string | CardSortCardsImageData
): Promise<string | null> {
  if (typeof image_data !== "string") {
    // If image_data is not string,
    // it's already saved into db.
    // No need to call this api for removing the image,
    // as it will be deleted automatically by shrinerb
    // when saving the usability test.
    image_data = JSON.stringify(image_data)
    return "The card image has been deleted."
  }

  try {
    // Upload the image file to S3 bucket via Shrine
    const { message } = await apiCardSortCards.removeTempCardImage<{
      message: string
    }>({
      data: { image_data: image_data },
    })

    return message
  } catch (error) {
    reportError(error)
  }
  return null
}

import {
  ButtonProps as BaseButtonProps,
  Button as ChakraButton,
  forwardRef,
  useStyleConfig,
  useToken,
} from "@chakra-ui/react"
import React from "react"

type IsSelectedProp = {
  isSelected?: boolean
}

export type ButtonProps = BaseButtonProps & IsSelectedProp

/**
 * - Use `variant` and `size` props to control appearance.
 * - Use the `isSelected` prop to access the selected state.
 *   - State management for selected buttons is handled by the consumer (similar to `isDisabled`).
 * - For buttons that are links under the hood, use a React Router link (so that
 * it navigates within the SPA rather than loading a new page) by doing
 * `as={ReactRouterLink} to={ROUTES.foo}`.
 *
 * @example
 * // Default variant and default size
 * <Button>Go</Button>
 * // Specific variant and size
 * <Button variant="primary" size="emphasized">Go</Button>
 * // Link that looks like a button
 * <Button as={ReactRouterLink} to={ROUTES.foo} variant="primary" size="emphasized">Go</Button>
 */
export const Button = forwardRef<ButtonProps, "button">(
  (props: ButtonProps, ref) => {
    const { variant, size, isSelected, ...rest } = props

    const styles = useStyleConfig("ButtonDS", { variant, size })

    const stylesWhenSelected = selectedStyles() // This calls a hook so it can't be used conditionally
    const styleOverrides = isSelected ? stylesWhenSelected : undefined

    return (
      <ChakraButton ref={ref} {...rest} __css={styles} sx={styleOverrides} />
    )
  }
)

// These are also used in the IconButton
export const selectedStyles = () => {
  const selectedBorderColor = useToken(
    "ds.border.selected",
    "var(--chakra-colors-ds-border-selected)"
  )
  return {
    bg: "ds.background.selected.subtle.resting",
    color: "ds.text.selected",
    // boxShadow because we want an inside border, so selected buttons don't push their siblings around
    boxShadow: `inset 0px 0px 0px 1px ${selectedBorderColor}`,
    _hover: {
      bg: "ds.background.selected.subtle.resting",
      color: "ds.text.selected",
    },
    "& svg": { color: "ds.icon.selected" },
  }
}

Button.displayName = "Button"

import { useQueryClient } from "@tanstack/react-query"
import React, { useCallback } from "react"

import { GetModeratedStudySummaryResponse } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { ModeratedStudy } from "~/api/generated/usabilityhubSchemas"

interface ModeratedStudyDetails {
  moderatedStudyId: string
  moderatedStudy: ModeratedStudy
  moderatedStudySummary?: GetModeratedStudySummaryResponse
  invalidateStudySummaryQuery: () => Promise<void>
  invalidateModeratedStudyQuery: () => Promise<void>
}

const ModeratedStudyContext = React.createContext<ModeratedStudyDetails | null>(
  null
)

type ModeratedStudyProviderProps = {
  moderatedStudy: ModeratedStudy
  moderatedStudySummary?: GetModeratedStudySummaryResponse
}

export const ModeratedStudyContextProvider: React.FC<
  React.PropsWithChildren<ModeratedStudyProviderProps>
> = ({ moderatedStudy, moderatedStudySummary, children }) => {
  const { queryKeyFn } = useUsabilityhubContext()

  const queryClient = useQueryClient()

  const moderatedStudyId = moderatedStudy.id

  // The moderated study summary query should be invalidated after:
  // 1. changing availability or team members (including google calendars) in ModeratedStudyEditPage
  // 2. creating a recruitment lin in ModeratedStudyRecruitPage
  // 3. disabling/enabling a recruitment link in ModeratedStudyRecruitmentLinkEntry
  // 4. deleting applicants in ModeratedStudyApplicationsPage
  // 5. updating/canceling sessions in useBookingActions
  const invalidateStudySummaryQuery = useCallback(async () => {
    const summaryKey = queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}/summary",
      operationId: "getModeratedStudySummary",
      variables: {
        pathParams: {
          moderatedStudyId,
        },
      },
    })

    await queryClient.invalidateQueries(summaryKey, { exact: true })
  }, [moderatedStudyId, queryClient, queryKeyFn])

  const invalidateModeratedStudyQuery = useCallback(async () => {
    const moderatedStudyKey = queryKeyFn({
      path: "/api/moderated_studies/{moderated_study_id}",
      operationId: "getModeratedStudy",
      variables: {
        pathParams: {
          moderatedStudyId,
        },
      },
    })
    await queryClient.invalidateQueries(moderatedStudyKey, { exact: true })
  }, [moderatedStudyId, queryClient, queryKeyFn])

  return (
    <ModeratedStudyContext.Provider
      value={{
        moderatedStudyId,
        moderatedStudy,
        moderatedStudySummary,
        invalidateStudySummaryQuery,
        invalidateModeratedStudyQuery,
      }}
    >
      {children}
    </ModeratedStudyContext.Provider>
  )
}

export const useModeratedStudyContext = (): ModeratedStudyDetails => {
  const context = React.useContext(ModeratedStudyContext)

  if (!context) {
    throw new Error(
      `useModeratedStudyContext must be rendered within the ModeratedStudyProvider`
    )
  }

  return context
}

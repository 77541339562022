import UhType from "./uh-type"

// biome-ignore lint/suspicious/noConstEnum: legacy code
export const enum RecruitmentLinkState {
  Disabled,
  Collecting,
  Complete,
  Canceled,
}

export interface RecruitmentLinkable {
  is_recruitment_link_limit_enabled: boolean
  is_recruitment_link_enabled: boolean
  recruited_response_count: number
  recruitment_link_limit: number
  uh_type: UhType
}

import { ListItem, Text, UnorderedList } from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"

import { useTrackTestUpgradeRequired } from "Hooks/use-track-test-upgrade-required"
import {
  getFormSyncWarnings,
  getFormValue,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import {
  getIsRecruitmentLinkEnabled,
  getTestBuilderState,
} from "Redux/reducers/test-builder/selectors/test-form"
import { SidebarAlert } from "UsabilityHub/components/TestForm/sidebar/SidebarAlert"
import { useRestrictedFeatures } from "UsabilityHub/components/TestForm/sidebar/useRestrictedFeatures"
import { CONTAINS_URLS_WARNING } from "UsabilityHub/components/TestForm/validators/validate-contains-urls"
import { flattenNested } from "Utilities/object"

export const SidebarWarnings: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const restrictedFeatures = useRestrictedFeatures()

  const isRecruitmentLinkEnabled = useSelector(getIsRecruitmentLinkEnabled)
  const usabilityTestUuid = useSelector(getTestBuilderState).uuid
  const testId = useSelector(getFormValue("id")) as number | null
  const usabilityTestId = testId || usabilityTestUuid

  // Show the URL warning if any relevant field has a URL in it.
  const warnings = useSelector(getFormSyncWarnings) as Record<string, any>
  const showTestContainsLinkWarning = flattenNested(warnings).includes(
    CONTAINS_URLS_WARNING
  )

  // Warn when the test has features that an upgrade is required for
  const showPaidFeaturesWarning = restrictedFeatures.length > 0
  // Additionally warn that the recruitment link will be disabled if it's currently enabled
  const showLinkDisableWarning =
    showPaidFeaturesWarning && isRecruitmentLinkEnabled

  // If there's a paid features warning, track it.
  useTrackTestUpgradeRequired(
    showPaidFeaturesWarning,
    usabilityTestId,
    "test_builder_sidebar"
  )

  return (
    <>
      {showPaidFeaturesWarning && (
        <SidebarAlert status="warning" title="You'll need to upgrade">
          <Text>This test uses these paid features:</Text>
          <UnorderedList styleType="disc" mb={2}>
            {restrictedFeatures.map((feature) => (
              <ListItem key={feature}>{feature}</ListItem>
            ))}
          </UnorderedList>
          <Text>
            You&apos;ll need to upgrade your plan before you can recruit
            participants.
          </Text>
        </SidebarAlert>
      )}
      {showTestContainsLinkWarning && (
        <SidebarAlert status="warning" title="Test includes an external URL">
          <Text mb={2}>
            Tests with external URLs in the instructions are ineligible for
            panel recruitment. To enable panel recruitment, remove any URLs.
          </Text>
          <Text>
            You’ll still able to share this test with your own participants if
            you continue.
          </Text>
        </SidebarAlert>
      )}
      {showLinkDisableWarning && (
        <SidebarAlert status="info" title="Changes will disable link">
          <Text>
            <strong>Heads up</strong>, this test’s recruitment link is currently
            enabled. Saving these changes will disable the recruitment link.
          </Text>
        </SidebarAlert>
      )}
    </>
  )
}

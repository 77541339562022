import { useContext } from "react"

import { TestResultViewState } from "../context/test-result-view-state"

export const useIndividualResponseFilter = (): [
  number | null,
  (responseId: number | null) => void,
] => {
  const { viewState, setViewState } = useContext(TestResultViewState)

  const setResponseId = (responseId: number | null) => {
    setViewState((viewState) => ({
      ...viewState,
      individualResponseView: responseId,
    }))
  }
  return [viewState.individualResponseView, setResponseId]
}

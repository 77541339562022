import { State } from "Redux/app-store"
import { ActionType } from "Redux/reducers/current-response/action-type"
import {
  getCurrentResponse,
  isConnected,
  isInProgress,
} from "Redux/reducers/current-response/selectors"
import { showErrorMessage } from "Redux/reducers/flash"
import { axios } from "Services/axios"
import {
  AsyncThunkAction,
  BasicAction,
  Meta,
  Omit,
  ParticipantDeletionReason,
  PayloadAction,
  RawParticipantResponse,
  Response,
  ResponseAnswer,
  ResponseDemographicProfile,
  ResponseSection,
  ThunkAction,
  Unpersisted,
  UnpersistedResponseSection,
  UnpersistedScreenshotClick,
} from "Types"
import { logDebugError, reportError } from "Utilities/error"
import { delay } from "Utilities/promise"
import {
  isPreview,
  requiresTaskFlowSuccessAcknowledged,
  shouldAppearInTest,
} from "Utilities/response"
import ResponsesApi from "~/api/ResponsesApi"
import { ListUsercrowdOrderAssignmentsResponse } from "~/api/generated/usabilityhub-components"

// -- AJAX types --

interface SubmitResponseResponseBody {
  response: Response
  credits_earned: number
  profile_completeness: number
  assignments: ListUsercrowdOrderAssignmentsResponse["panelist_order_assignments"]
}

// -- Action types --

// This was previously serialized into the page at load time, but we're now getting it via an API
// so we need an action to get it into the store asynchronously.
export type InitResponseData = PayloadAction<
  ActionType.INIT_RESPONSE_DATA,
  RawParticipantResponse
>

export type SubmitResponseSuccessAction = PayloadAction<
  ActionType.SUBMIT_RESPONSE_SUCCESS,
  SubmitResponseResponseBody
>
type SubmitResponseRequestAction =
  BasicAction<ActionType.SUBMIT_RESPONSE_REQUEST>
type SubmitResponseFailureAction =
  BasicAction<ActionType.SUBMIT_RESPONSE_FAILURE>

type KeepaliveRequestAction = BasicAction<ActionType.KEEPALIVE_REQUEST>
type KeepaliveSuccessAction = PayloadAction<
  ActionType.KEEPALIVE_SUCCESS,
  Pick<Response, "deleted_at" | "deletion_reason">
>
type KeepaliveFailureAction = PayloadAction<ActionType.KEEPALIVE_FAILURE, Error>
type UpdateResponseAction = PayloadAction<
  ActionType.UPDATE_RESPONSE,
  Partial<Response>
>
type AddResponseDemographicsAction = PayloadAction<
  ActionType.ADD_RESPONSE_DEMOGRAPHICS,
  ResponseDemographicProfile
>
type CancelResponseRequestAction =
  BasicAction<ActionType.CANCEL_RESPONSE_REQUEST>
type CancelResponseSuccessAction = PayloadAction<
  ActionType.CANCEL_RESPONSE_SUCCESS,
  Response
>
type CancelResponseFailureAction =
  BasicAction<ActionType.CANCEL_RESPONSE_FAILURE>
type AddResponseSectionAction = PayloadAction<
  ActionType.ADD_RESPONSE_SECTION,
  UnpersistedResponseSection
>
type JumpToSectionAction = PayloadAction<ActionType.JUMP_TO_SECTION, number[]>

type AddToSectionMeta = Meta<{
  usabilityTestSectionId: number
  isSectionComplete: boolean
}>
type AddResponseAnswerAction = PayloadAction<
  ActionType.ADD_RESPONSE_ANSWER,
  Unpersisted<ResponseAnswer>
> &
  AddToSectionMeta
type AddScreenshotClickAction = PayloadAction<
  ActionType.ADD_SCREENSHOT_CLICK,
  UnpersistedScreenshotClick
>
type AddResponseSectionRecordingAction = PayloadAction<
  ActionType.ADD_RESPONSE_SECTION_RECORDING,
  { usabilityTestSectionId: number; recordingId: string }
>
type UpdateResponseSectionAction = PayloadAction<
  ActionType.UPDATE_RESPONSE_SECTION,
  Partial<Omit<ResponseSection, "usability_test_section_id">>
> &
  AddToSectionMeta

export type Actions =
  | SubmitResponseRequestAction
  | SubmitResponseSuccessAction
  | SubmitResponseFailureAction
  | KeepaliveRequestAction
  | KeepaliveSuccessAction
  | KeepaliveFailureAction
  | UpdateResponseAction
  | AddResponseDemographicsAction
  | AddResponseSectionAction
  | AddScreenshotClickAction
  | AddResponseSectionRecordingAction
  | UpdateResponseSectionAction
  | CancelResponseRequestAction
  | CancelResponseSuccessAction
  | CancelResponseFailureAction
  | AddResponseAnswerAction
  | InitResponseData
  | JumpToSectionAction

export function addResponseDemographics(
  payload: ResponseDemographicProfile
): AddResponseDemographicsAction {
  return { type: ActionType.ADD_RESPONSE_DEMOGRAPHICS, payload }
}

export const addResponseAnswer =
  (payload: Unpersisted<ResponseAnswer>): ThunkAction<State> =>
  (dispatch, getState) => {
    const {
      currentResponse: response,
      participantUsabilityTest: usabilityTest,
    } = getState()
    if (usabilityTest === null) {
      throw new TypeError("Cannot add response answer when test is null")
    }
    if (response === null) {
      throw new TypeError("Cannot add response answer when response is null")
    }

    // Find the section that this question belongs to.
    const testSection = usabilityTest.sections.find((s) =>
      s.questions.some(
        ({ id }) => id === payload.usability_test_section_question_id
      )
    )

    if (testSection === undefined) {
      throw new TypeError(
        `Invalid usability_test_section_id: ${payload.usability_test_section_question_id}`
      )
    }

    const otherQuestions = testSection.questions.filter(
      ({ id }) => id !== payload.usability_test_section_question_id
    )

    const responseSection = response.sections.find(
      (rs) => rs.usability_test_section_id === testSection.id
    )
    // Check that all other questions have been answered.
    const isSectionComplete =
      otherQuestions.every(
        // Each question must have an answer, or not need to be shown based on test logic.
        (q) =>
          response.answers.some(
            (a) => q.id === a.usability_test_section_question_id
          ) ||
          // We need to include the latest answer when checking for test logic,
          // since the most recent answer might affect the choices.
          !shouldAppearInTest(
            q,
            {
              ...response,
              answers: [...response.answers, payload],
            },
            usabilityTest
          )
      ) &&
      // and that any success has been acknowledged
      !requiresTaskFlowSuccessAcknowledged(
        testSection,
        responseSection?.total_duration_ms,
        responseSection?.figma_file_version_answer,
        responseSection?._taskFlowSuccessAcknowledged
      )

    dispatch<AddResponseAnswerAction>({
      type: ActionType.ADD_RESPONSE_ANSWER,
      payload,
      meta: {
        usabilityTestSectionId: testSection.id,
        isSectionComplete,
      },
    })
  }

export function startResponseSection(
  usabilityTestSectionId: number
): AddResponseSectionAction {
  return {
    type: ActionType.ADD_RESPONSE_SECTION,
    payload: {
      _startTime: performance.now(),
      _taskFlowSuccessAcknowledged: false,
      instructions_duration_ms: null,
      selected_usability_test_section_screenshot_id: null,
      task_duration_ms: null,
      total_duration_ms: null,
      usability_test_section_id: usabilityTestSectionId,
      figma_file_version_answer: null,
      card_sort_categories_cards_attributes: null,
      cards_sort_time: null,
    },
  }
}

export function addScreenshotClick(
  payload: UnpersistedScreenshotClick
): AddScreenshotClickAction {
  return { type: ActionType.ADD_SCREENSHOT_CLICK, payload }
}

export function updateResponse(
  payload: Partial<Response>
): UpdateResponseAction {
  return { type: ActionType.UPDATE_RESPONSE, payload }
}

export function jumpToSection(payload: number[]): JumpToSectionAction {
  return { type: ActionType.JUMP_TO_SECTION, payload }
}

export const addResponseSectionRecording =
  (usabilityTestSectionId: number, recordingId: string): ThunkAction<State> =>
  (dispatch) => {
    dispatch<AddResponseSectionRecordingAction>({
      type: ActionType.ADD_RESPONSE_SECTION_RECORDING,
      payload: {
        usabilityTestSectionId: usabilityTestSectionId,
        recordingId: recordingId,
      },
    })
  }

export const updateResponseSection =
  (
    usabilityTestSectionId: number,
    payload: UpdateResponseSectionAction["payload"]
  ): ThunkAction<State> =>
  (dispatch, getState) => {
    let isSectionComplete = false

    // If the task is complete, and the section has no more reachable questions, then the whole
    // section is complete.
    if (payload.task_duration_ms != null) {
      const {
        currentResponse: response,
        participantUsabilityTest: usabilityTest,
      } = getState()

      if (usabilityTest === null) {
        throw new TypeError("usabilityTest === null")
      }
      if (response === null) {
        throw new TypeError("response === null")
      }
      const section = usabilityTest.sections.find(
        (s) => s.id === usabilityTestSectionId
      )
      if (section === undefined) {
        throw new TypeError("section === undefined")
      }

      // Section is complete if there are no follow-up questions, or if all follow-up questions are
      // not shown based on test logic.
      isSectionComplete =
        (section.questions.length === 0 ||
          section.questions.every((question) => {
            // We need to include the latest answer when checking for test logic,
            // since the most recent answer might affect the choices.
            const nextSections = response.sections.map((responseSection) => {
              if (responseSection.usability_test_section_id !== section.id) {
                return responseSection
              } else {
                return { ...responseSection, ...payload }
              }
            })
            return !shouldAppearInTest(
              question,
              {
                ...response,
                sections: nextSections,
              },
              usabilityTest
            )
          })) &&
        // And that any success has been acknowledged
        !requiresTaskFlowSuccessAcknowledged(
          section,
          payload.total_duration_ms,
          payload.figma_file_version_answer,
          payload._taskFlowSuccessAcknowledged
        )
    }

    dispatch<UpdateResponseSectionAction>({
      type: ActionType.UPDATE_RESPONSE_SECTION,
      payload,
      meta: { usabilityTestSectionId, isSectionComplete },
    })
  }

export const deleteResponse =
  (reason: ParticipantDeletionReason): AsyncThunkAction<State> =>
  async (dispatch, getState) => {
    const { id, _isSubmitting: isBusy } = getCurrentResponse(getState())
    if (id == null) {
      reportError(new TypeError("Cannot cancel response that has no id"))
      return
    }
    if (isBusy) {
      reportError(
        new TypeError(`Cannot cancel response while it${"\u2019"}s busy`)
      )
      // We can continue here in production.
    }
    try {
      dispatch<CancelResponseRequestAction>({
        type: ActionType.CANCEL_RESPONSE_REQUEST,
      })
      const { data: updatedResponse } = await axios.put(
        ResponsesApi.cancel.path({ id }),
        {
          deletion_reason: reason,
        }
      )
      dispatch<CancelResponseSuccessAction>({
        type: ActionType.CANCEL_RESPONSE_SUCCESS,
        payload: updatedResponse,
      })
      return updatedResponse
    } catch (error) {
      dispatch<CancelResponseFailureAction>({
        type: ActionType.CANCEL_RESPONSE_FAILURE,
      })
      throw error
    }
  }

export const cancelResponse =
  (reason: ParticipantDeletionReason): ThunkAction<State> =>
  (dispatch) => {
    try {
      void dispatch(deleteResponse(reason))
    } catch (error) {
      dispatch(
        showErrorMessage(
          "Sorry, we weren't able to skip the test. Please try again."
        )
      )
    }
  }

export const submitResponse =
  (): AsyncThunkAction<State> => async (dispatch, getState) => {
    const responseData = getCurrentResponse(getState())

    // Response should have its duration set *first* so that subsequent retries
    // operate on the exact same data.
    //
    // Maybe this is bad... shrug.
    if (responseData.duration_ms === null)
      throw new TypeError("Response has no duration")

    // Don't do anything in a preview.
    if (isPreview(responseData)) return

    dispatch<SubmitResponseRequestAction>({
      type: ActionType.SUBMIT_RESPONSE_REQUEST,
    })

    // Construct complete payload for saving the response, including data for the device
    const data = {
      response: responseData,
      device: {
        width: Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        ),
        height: Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        ),
      },
    }

    try {
      const { data: body } = await axios.patch<SubmitResponseResponseBody>(
        ResponsesApi.update.path({ id: responseData.id }),
        data
      )
      dispatch<SubmitResponseSuccessAction>({
        type: ActionType.SUBMIT_RESPONSE_SUCCESS,
        payload: body,
      })
    } catch (error) {
      dispatch<SubmitResponseFailureAction>({
        type: ActionType.SUBMIT_RESPONSE_FAILURE,
      })
      throw error
    }
  }

const postKeepalive =
  (responseId: number): AsyncThunkAction<State> =>
  async (dispatch, getState) => {
    if (getCurrentResponse(getState())._isSubmitting) {
      // Just skip the keepalive if the response is currently being submitted or
      // deleted (the keepalive is irrelevant in this case, and its response
      // payload might cause issues if it comes back out of order as it updates
      // the response in the store).
      return
    }

    try {
      dispatch<KeepaliveRequestAction>({ type: ActionType.KEEPALIVE_REQUEST })

      // We're still active, so keep the response alive on the server
      const {
        data: { response },
      } = await axios.put<{ response: Response }>(
        ResponsesApi.keepalive.path({ id: responseId })
      )

      // We received a response.
      dispatch<KeepaliveSuccessAction>({
        type: ActionType.KEEPALIVE_SUCCESS,
        payload: {
          deleted_at: response.deleted_at,
          deletion_reason: response.deletion_reason,
        },
      })
    } catch (error) {
      dispatch<KeepaliveFailureAction>({
        type: ActionType.KEEPALIVE_FAILURE,
        payload: error,
      })

      if (isConnected(getState())) {
        // We should only get here due to lack of connectivity. Otherwise report
        // an error.
        reportError(error)
      }
    }
  }

const MinReconnectInterval = 5000

// Keep the response alive on the server and stop the test if the server ever
// indicates that the response has been deleted.
export const keepResponseAlive =
  (): AsyncThunkAction<State> => async (dispatch, getState) => {
    const response = getCurrentResponse(getState())
    const interval = response.keepalive_interval_ms

    if (interval === null) {
      reportError(
        new TypeError(
          `keepResponseAlive was called on response ${response.id}, which had no ` +
            `timeout interval specified`
        ),
        { extra: response }
      )
      return
    }

    /**
     * Connection check interval while disconnected from the server. Since these
     * will only hit the server once we can do it quite frequently without
     * worrying about DDOSing ourselves.
     *
     * Usually we'll do this every five seconds, but in tests we reduce the
     * interval by changing the {@link Response#keepalive_interval_ms}.
     */
    const disconnectedInterval = Math.min(interval, MinReconnectInterval)

    while (true) {
      // Stop checking and keeping alive if the response has been submitted or
      // has become deleted.
      if (!isInProgress(getState())) {
        break
      }

      // If the user has been idle too long, stop the test. Otherwise let the
      // server know that we're still here.
      try {
        await dispatch(postKeepalive(getCurrentResponse(getState()).id))
      } catch (error) {
        logDebugError(error)
        // Ignore errors here--just continue to the next loop.
      }

      // Delay before repeating.
      await delay(isConnected(getState()) ? interval : disconnectedInterval)
    }
  }

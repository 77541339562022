import { AdminDashboardContextProvider } from "Shared/components/AdminDashboardContextProvider/AdminDashboardContextProvider"
import { ResultDataProvider } from "UsabilityHub/views/tests/$uniqueId/results/ResultDataProvider"
import { ModalContextProvider } from "Utilities/modals/modal-context"
import React from "react"
import { TestResults } from "./test-results/test-results-container"

type Props = {
  usabilityTestUniqueId: string
}

export const AdminDashboardTestResults: React.FC<Props> = ({
  usabilityTestUniqueId,
}) => {
  return (
    <AdminDashboardContextProvider>
      <ModalContextProvider>
        <ResultDataProvider
          usabilityTestUniqueId={usabilityTestUniqueId}
          privateId={null}
          useSharedApi={false}
          isAdmin
          orderId={null}
          isThirdPartyOrder={false}
          responseId={null}
          revising={false}
        >
          <TestResults />
        </ResultDataProvider>
      </ModalContextProvider>
    </AdminDashboardContextProvider>
  )
}

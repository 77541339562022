import { Flex, Text } from "@chakra-ui/react"
import { Button, Tag } from "DesignSystem/components"
import { MetricsCard } from "Shared/components/MetricsCard"
import { ArrowCircleRightSolidIcon } from "Shared/icons/untitled-ui/ArrowCircleRightSolidIcon"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import { Lightning01SolidIcon } from "Shared/icons/untitled-ui/Lightning01SolidIcon"
import { MessageSquare02SolidIcon } from "Shared/icons/untitled-ui/MessageSquare02SolidIcon"
import { Users01SolidIcon } from "Shared/icons/untitled-ui/Users01SolidIcon"
import { Beacon } from "UsabilityHub/components/Beacon"
import { ROUTES } from "UsabilityHub/views/routes"
import React from "react"
import { Link } from "react-router-dom"
import { StatusTags, SummaryStatus } from "../TestResultsSummary"

const statusIcons: Record<SummaryStatus, React.ReactNode> = {
  ready: <ArrowCircleRightSolidIcon boxSize={6} color="ds.icon.subtle" />,
  collecting: <Beacon colorScheme="green" boxSize={6} />,
  complete: <CheckCircleSolidIcon boxSize={6} color="ds.icon.success" />,
}

const statusLabels: Record<SummaryStatus, string> = {
  ready: "Ready",
  collecting: "Collecting",
  complete: "Complete",
}

export const StatusCard = ({
  status,
  statusTags,
  usabilityTestUniqueId,
}: {
  status: SummaryStatus | null
  statusTags: StatusTags
  usabilityTestUniqueId: string
}) => {
  return (
    <MetricsCard isRaised={false}>
      <MetricsCard.TagAndAction
        tag={<Tag label="Status" leftIcon={Lightning01SolidIcon} />}
        action={
          <Button
            variant="secondary"
            size="compact"
            as={Link}
            to={ROUTES.TEST.RECRUIT.buildPath({
              testId: usabilityTestUniqueId,
            })}
          >
            {status === "collecting" ? "Manage" : "Recruit"}
          </Button>
        }
      />
      <MetricsCard.Info
        value={
          status ? (
            <Flex gap={2} alignItems="center" py={1.5}>
              {statusIcons[status]}
              <Text textStyle="ds.display.primary">{statusLabels[status]}</Text>
            </Flex>
          ) : null
        }
        description={statusTags.join(", ")}
      />
    </MetricsCard>
  )
}

export const ParticipantsCard = ({ count }: { count: number }) => (
  <MetricsCard isRaised={false}>
    <MetricsCard.TagAndAction
      tag={<Tag label="Participants" leftIcon={Users01SolidIcon} />}
    />
    <MetricsCard.Info value={count} description="Total participants" />
  </MetricsCard>
)

export const ResponsesCard = ({
  count,
  responseCap,
}: { count: number; responseCap: number }) => {
  const value = count < responseCap ? `${count} of ${responseCap}` : count
  const description =
    count < responseCap ? "Responses shown (filtered)" : "Responses shown"

  return (
    <MetricsCard isRaised={false}>
      <MetricsCard.TagAndAction
        tag={<Tag label="Responses" leftIcon={MessageSquare02SolidIcon} />}
      />
      <MetricsCard.Info value={value} description={description} />
    </MetricsCard>
  )
}

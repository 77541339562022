import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import { CollectionIcon } from "@heroicons/react/outline"
import { sortBy } from "lodash"
import React, { useState } from "react"

import { CardSortCard, CardSortCategoryCard } from "Types"

import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { SearchMdOutlineIcon } from "Shared/icons/untitled-ui/SearchMdOutlineIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import {
  humanizeGroupLabel,
  isItalicCategoryGroupNames,
  notUnsortedGroupLabel,
} from "./CardSortCategories/grouping"
import { useCardSortResultsContext } from "./CardSortResultsContext"
import { CategoryCardPairCountItem } from "./CategoryCardPairCountItem"
import { ExpandablePanel } from "./ExpandablePanel"

type SortType = "position" | "alphabetically" | "numCategories"

const SORT_BY_POSITION = () => {
  return (c: CardSortCard) => {
    return c.position ?? 0
  }
}

const SORT_ALPHABETICALLY = () => {
  return (c: CardSortCard) => {
    return c.label.toLowerCase()
  }
}

const SORT_BY_NUM_CATEGORIES = (
  sortData: CardSortCategoryCard[],
  groupLabelByCategoryId: Record<string, string>
) => {
  return (c: CardSortCard) => {
    const relatedSortData = sortData.filter((s) => s.card_sort_card_id === c.id)
    // Sort by the number of related category groups
    return new Set(
      relatedSortData
        .map(
          (s) =>
            groupLabelByCategoryId[
              Number(s.card_sort_category_id ?? s.card_sort_open_category_id)
            ]
        )
        .filter(notUnsortedGroupLabel)
    ).size
  }
}

const SORT_FUNCTIONS: Record<
  SortType,
  Array<
    (
      sortData: CardSortCategoryCard[],
      groupLabelByCategoryId?: Record<string, string> // This param will be used by SORT_BY_NUM_CATEGORIES only
    ) => (c: CardSortCard) => number | string
  >
> = {
  position: [SORT_BY_POSITION],
  alphabetically: [SORT_ALPHABETICALLY],
  numCategories: [SORT_BY_NUM_CATEGORIES, SORT_BY_POSITION],
}

const SORT_TYPE_LABELS: { [t in SortType]: string } = {
  position: "Default",
  alphabetically: "Alphabetically",
  numCategories: "Number of categories",
}

export const CardSortSectionResultsCardsTab: React.FC = () => {
  const { sectionId, cards, groupedCategories, sortData } =
    useCardSortResultsContext()
  const [searchTerm, setSearchTerm] = useState("")
  const [sortType, setSortType] = useState<SortType>("position")

  const filteredCards = cards.filter((c) =>
    c.label.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const groupLabelByCategoryId = Object.entries(groupedCategories).reduce(
    (categoriesGroups, [groupLabel, categories]) => {
      categories.forEach((c) => {
        categoriesGroups[Number(c.id)] = groupLabel
      })
      return categoriesGroups
    },
    {} as Record<string, string>
  )

  const sortedCards = sortBy(
    filteredCards,
    SORT_FUNCTIONS[sortType].map((f) => f(sortData, groupLabelByCategoryId))
  )

  return (
    <>
      <Flex gap={2} position="sticky" top={0} zIndex={4} bg="white" py={4}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchMdOutlineIcon} boxSize={5} color="gray.500" />
          </InputLeftElement>
          {searchTerm !== "" && (
            <InputRightElement>
              <IconButton
                variant="ghost"
                size="sm"
                icon={<Icon as={XOutlineIcon} boxSize={5} color="gray.500" />}
                aria-label="Clear search"
                onClick={() => setSearchTerm("")}
              />
            </InputRightElement>
          )}
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search cards"
          />
        </InputGroup>

        <Menu>
          <MenuButton
            as={Button}
            variant="outline"
            rightIcon={<Icon as={ChevronDownOutlineIcon} />}
            fontWeight="normal"
            flexShrink={0}
            data-testid="sort-by-menu"
          >
            Sort: {SORT_TYPE_LABELS[sortType]}
          </MenuButton>
          <MenuList>
            {Object.entries(SORT_TYPE_LABELS).map(
              ([sortTypeValue, sortTypeLabel]) => {
                return (
                  <MenuItem
                    value={sortTypeValue}
                    key={sortTypeValue}
                    onClick={() => setSortType(sortTypeValue as SortType)}
                    fontWeight={sortType === sortTypeValue ? "bold" : "normal"}
                  >
                    {sortTypeLabel}
                  </MenuItem>
                )
              }
            )}
          </MenuList>
        </Menu>
      </Flex>

      {sortedCards.length > 0 ? (
        <Stack spacing={3} divider={<StackDivider />}>
          {sortedCards.map((card) => {
            const relatedSortData = sortData.filter(
              (cardSort) => cardSort.card_sort_card_id === card.id
            )

            const categoryGroupFrequencies = relatedSortData.reduce(
              (acc, cardSortCategoryCard) => {
                const categoryId = Number(
                  cardSortCategoryCard.card_sort_category_id ??
                    cardSortCategoryCard.card_sort_open_category_id
                )

                const groupLabel = groupLabelByCategoryId[categoryId]

                return {
                  ...acc,
                  [groupLabel]: acc[groupLabel] + 1,
                }
              },
              Object.fromEntries(
                Object.keys(groupedCategories).map((groupLabel) => [
                  groupLabel,
                  0,
                ])
              ) as Record<string, number>
            )

            const categoryGroupsByFreq = Object.entries(
              categoryGroupFrequencies
            )
              .filter(([, freq]) => freq > 0)
              .sort(([, freqA], [, freqB]) => freqB - freqA)

            const categoryCountExcludingUnsorted = categoryGroupsByFreq.filter(
              ([label]) => notUnsortedGroupLabel(label)
            ).length

            return (
              <Grid
                templateColumns="repeat(2, minmax(0,1fr))"
                key={card.id}
                gap={4}
              >
                <Box>
                  <Flex
                    align="center"
                    rounded="md"
                    borderWidth="1px"
                    p={4}
                    data-testid="card-sort-card"
                    gap={2}
                  >
                    <Icon as={CollectionIcon} boxSize={4} />

                    {card.uploaded_image_url && (
                      <Center
                        bg="white"
                        borderColor="gray.200"
                        borderWidth={1}
                        rounded="sm"
                        minW="100px"
                        w="100px"
                        h="80px"
                      >
                        <Image
                          borderRadius="sm"
                          maxH="100%"
                          maxW="100%"
                          src={card.uploaded_image_url}
                        />
                      </Center>
                    )}
                    <Text wordBreak="break-word">{card.label}</Text>
                    <Text
                      fontSize="xs"
                      color="gray.500"
                      whiteSpace="nowrap"
                      ms="auto"
                    >
                      {categoryCountExcludingUnsorted}{" "}
                      {categoryCountExcludingUnsorted === 1
                        ? "category"
                        : "categories"}
                    </Text>
                  </Flex>
                </Box>
                <Box>
                  <ExpandablePanel
                    numberToShow={3}
                    maxH="188px"
                    pluralEntityName="categories"
                    items={categoryGroupsByFreq.map(([groupLabel, freq]) => {
                      const categories = groupedCategories[groupLabel]
                      return (
                        <CategoryCardPairCountItem
                          key={groupLabel}
                          sectionId={sectionId}
                          categoryIds={categories.map((c) => Number(c.id))}
                          cardId={card.id!}
                          count={freq}
                          total={relatedSortData.length}
                        >
                          <Text
                            textOverflow="ellipsis"
                            overflow="hidden"
                            fontStyle={
                              isItalicCategoryGroupNames(groupLabel, categories)
                                ? "italic"
                                : "normal"
                            }
                            pe={1}
                          >
                            {humanizeGroupLabel(groupLabel)}
                          </Text>
                        </CategoryCardPairCountItem>
                      )
                    })}
                  />
                </Box>
              </Grid>
            )
          })}
        </Stack>
      ) : (
        <Alert wordBreak="break-all">
          <AlertIcon />
          {searchTerm === "" ? (
            <>No category data.</>
          ) : (
            <>
              No cards matched the search &ldquo;{searchTerm}&rdquo;.
              <Link flexShrink={0} onClick={() => setSearchTerm("")} ml={1}>
                Clear search
              </Link>
            </>
          )}
        </Alert>
      )}
    </>
  )
}

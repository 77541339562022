import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  Stack,
  Text,
  usePrevious,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Fields, WrappedFieldsProps } from "redux-form"

import { Dispatch } from "Redux/app-store"
import {
  addQuestionOption,
  spliceQuestionOption,
} from "Redux/reducers/test-builder-form/action-creators/options"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useQuestionContext,
  useSectionIndexContext,
} from "UsabilityHub/contexts"
import {
  isCheckboxQuestion,
  isOtherAnswerValid,
  maximumMultipleChoiceOptionCount,
} from "Utilities/usability-test-section-question"

import { DroppableQuestionRows } from "../../DragDrop/DroppableQuestionRows"

import { useMarkdownUsage } from "Hooks/use-markdown-usage"
import { CheckboxLimitFields } from "./CheckboxLimitFields"
import { ChoiceField } from "./ChoiceField"

const WrappedMultipleChoiceFields: React.FC<
  React.PropsWithChildren<WrappedFieldsProps>
> = ({ has_other_option, randomized }) => {
  const dispatch: Dispatch = useDispatch()
  const markdownHint = useMarkdownUsage("inline")
  const { question, questionIndex } = useQuestionContext()
  const sectionIndex = useSectionIndexContext()
  const [renderKey, setRenderKey] = useState(0)
  const { readOnly } = useUsabilityTestUserActivityContext()

  // Skip autoFocus when we change between question types
  const prevQuestionType = usePrevious(question.type)
  const skipAutoFocus = prevQuestionType !== question.type
  const optionCount = question.multiple_choice_options.length
  const maxOptionCount = maximumMultipleChoiceOptionCount(question.type)

  const onAddOption = () => {
    if (optionCount < maxOptionCount) {
      dispatch(addQuestionOption(sectionIndex, questionIndex))
    }
  }

  const onEnterPress = (optionIndex: number) => {
    if (optionIndex === optionCount - 1) {
      onAddOption()
    } else {
      // Force re-render to autofocus first blank
      setRenderKey(renderKey + 1)
    }
  }

  // When a user pastes text with newlines in it, we'll split by newline and insert
  // or replace each line as its own option starting from the point they pasted.
  const onMultilinePaste = (lines: string[], startIndex: number) => {
    const cappedLines = lines.slice(0, maxOptionCount - startIndex)

    cappedLines.forEach((line, lineIndex) => {
      dispatch(
        spliceQuestionOption(
          sectionIndex,
          questionIndex,
          startIndex + lineIndex,
          line
        )
      )
    })
  }

  return (
    <Stack spacing={6}>
      <Box data-group>
        <Flex>
          <FormLabel>Choices (Press ⏎ for new line or paste a list)</FormLabel>
          <Text
            fontSize="xs"
            lineHeight={5}
            color="gray.500"
            opacity={0}
            _groupFocusWithin={{ opacity: 1 }}
            transition="opacity 200ms"
          >
            {markdownHint}
          </Text>
        </Flex>
        <Box px={6} mt={-2} key={renderKey}>
          <DroppableQuestionRows
            droppableId={`sections[${sectionIndex}].questions[${questionIndex}].multiple_choice_options`}
            droppableType="option"
          >
            {question.multiple_choice_options.map((_option, index) => (
              <ChoiceField
                key={index}
                optionIndex={index}
                skipAutoFocus={skipAutoFocus}
                onEnterPress={onEnterPress}
                onMultilinePaste={(lines) => onMultilinePaste(lines, index)}
              />
            ))}
          </DroppableQuestionRows>
        </Box>
        {optionCount < maxOptionCount ? (
          <Button
            alignSelf="flex-start"
            size="sm"
            colorScheme="brand.primary"
            mt={2}
            ml={6}
            onClick={onAddOption}
            isDisabled={readOnly}
          >
            Add another choice
          </Button>
        ) : (
          <Text>Maximum options reached ({maxOptionCount})</Text>
        )}
      </Box>
      <HStack justifyContent="space-between" alignItems="flex-start">
        <Stack ml={6}>
          {isOtherAnswerValid(question.type) && (
            <Checkbox
              variant="mdWithSmFont"
              isChecked={has_other_option.input.value as boolean}
              isReadOnly={readOnly}
              {...has_other_option.input}
            >
              Show “Other” option
            </Checkbox>
          )}
          <Checkbox
            variant="mdWithSmFont"
            isChecked={randomized.input.value as boolean}
            isReadOnly={readOnly}
            {...randomized.input}
          >
            Randomize the order of choices
          </Checkbox>
        </Stack>
        {isCheckboxQuestion(question) && <CheckboxLimitFields />}
      </HStack>
    </Stack>
  )
}

export const MultipleChoiceFields: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Fields
      names={["has_other_option", "randomized"]}
      component={WrappedMultipleChoiceFields}
    />
  )
}

import { captureException } from "@sentry/browser"

/**
 * Throw an error in development/test environment, but just report to Sentry
 * in production.
 *
 * @example
 *
 * handleEvent(event) {
 *   const { shouldBeDefined } = this.state
 *   const { doAThing } = this.props
 *   if (shouldBeDefined == null) {
 *     reportError(new TypeError(`shouldBeDefined is ${shouldBeDefined}`), { extra: { props: this.props }})
 *     return
 *   }
 *   doAThing(shouldBeDefined)
 * }
 */
export function reportError(
  error: Error,
  captureContext?: { extra: { [key: string]: any } }
): void {
  if (process.env.DEBUG) {
    console.error(error, captureContext)
  } else {
    captureException(error, captureContext)
  }
}

export function logDebugError(error: Error): void {
  if (process.env.DEBUG) {
    console.error(error)
  }
}

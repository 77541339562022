import { Circle, Step, StepStatus, Stepper } from "@chakra-ui/react"
import React from "react"

const InactiveStep: React.FC = () => (
  <Circle size="0.5rem" border="1px" borderColor="gray.400" />
)

const ActiveStep: React.FC = () => <Circle size="0.5rem" bg="gray.400" />

export const OnboardingFlowStepper: React.FC<{
  currentPageIdx: number
  pageCount: number
}> = ({ currentPageIdx, pageCount }) => (
  <Stepper index={currentPageIdx} size="xs" gap={0.5}>
    {[...Array(pageCount)].map((_, idx) => (
      <Step key={idx}>
        <StepStatus
          active={ActiveStep}
          complete={InactiveStep}
          incomplete={InactiveStep}
        />
      </Step>
    ))}
  </Stepper>
)

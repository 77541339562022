import { ParticipantDeletionReason, ResponseSection } from "Types"
import { SectionRecordingPrompt } from "UsabilityHub/components/UsabilityTestSectionTask/SectionRecordingPrompt"
import {
  InnerProps,
  OuterProps,
} from "UsabilityHub/components/UsabilityTestSectionTask/props"
import { requiresTaskFlowSuccessAcknowledged } from "Utilities/response"
import React, { createContext, useCallback, useEffect } from "react"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"
import { useTestTakingRecordings } from "../useTestTakingRecordings"
import { useTestRecordingContext } from "./TestRecordingContext"

type SectionRecordingType = Pick<InnerProps, "updateResponseSection"> & {
  sectionRecordingTypes: RecordingType[]
  startRecording: () => void
  addResponseSectionRecording: (
    usabilityTestSectionId: number,
    recordingId: string
  ) => void
  isValidRecordingState: boolean
}

export const SectionRecordingContext = createContext<SectionRecordingType>({
  sectionRecordingTypes: [],
  updateResponseSection: () => {
    throw new Error("Not within SectionRecordingContextProvider")
  },
  startRecording: () => {
    throw new Error("Not within SectionRecordingContextProvider")
  },
  addResponseSectionRecording: () => {
    throw new Error("Not within SectionRecordingContextProvider")
  },
  isValidRecordingState: false,
})

export const SectionRecordingContextProvider: React.FC<
  React.PropsWithChildren<
    OuterProps &
      Pick<InnerProps, "updateResponseSection"> &
      Pick<SectionRecordingType, "addResponseSectionRecording">
  >
> = ({
  usabilityTestSection,
  updateResponseSection,
  addResponseSectionRecording,
  children,
}) => {
  const [continueSection, setContinueSection] = React.useState(false)
  const [disableContinueButton, setDisableContinueButton] = React.useState(true)
  const [finishAction, setFinishAction] = React.useState<() => void>()

  const {
    audioDeviceId,
    videoDeviceId,
    screenStream,
    setScreenStream,
    allowedRecordingTypes,
    recordingEnabled,
    setError,
    dispatchUploadRecordingRequests,
  } = useTestRecordingContext()

  const uploadCallback = useCallback(
    (recordingId: string, isSectionRecordingStopped: boolean) => {
      if (isSectionRecordingStopped) {
        dispatchUploadRecordingRequests({
          type: "finish-section-recording",
          usabilityTestSectionId: usabilityTestSection.id,
        })
      }
      addResponseSectionRecording(usabilityTestSection.id, recordingId)
      dispatchUploadRecordingRequests({ type: "finish-upload-request" })
    },
    [addResponseSectionRecording]
  )
  const addUploadRequest = useCallback(() => {
    dispatchUploadRecordingRequests({ type: "make-upload-request" })
  }, [])

  const {
    prepareSectionRecordings,
    startSectionRecordings,
    stopSectionRecordings,
    allUploadRequestsSent,
    isValidRecordingState,
    areAllStreamsActive,
  } = useTestTakingRecordings({
    audioDeviceId,
    videoDeviceId,
    screenStream,
    setScreenStream,
    uploadCallback,
    addUploadRequest,
  })

  const sectionRecordingTypes =
    recordingEnabled && usabilityTestSection.recording_attributes
      ? allowedRecordingTypes.filter(
          (type) => usabilityTestSection.recording_attributes![type]
        )
      : []
  const shouldRecord = sectionRecordingTypes.length > 0

  // This function is used to start streams
  const prepareRecording = () => {
    if (shouldRecord) {
      prepareSectionRecordings(sectionRecordingTypes)
      dispatchUploadRecordingRequests({
        type: "start-section-recording",
        usabilityTestSectionId: usabilityTestSection.id,
      })
    }
  }

  // This function is used to start recording
  const startRecording = () => shouldRecord && startSectionRecordings()

  const updateResponseSectionWithRecording = useCallback(
    (
      usabilityTestSectionId: number,
      payload: Partial<Omit<ResponseSection, "usability_test_section_id">>
    ) => {
      // When a prototype task flow should show success screen, we'll stop recording only when users click the `Continue` button. Otherwise, we'll stop recording when users reach the goal screen or click the `Continue/End` button.
      const prototypeSuccessNotAcknowledged =
        usabilityTestSection.type === "prototype_task" &&
        requiresTaskFlowSuccessAcknowledged(
          usabilityTestSection,
          payload.total_duration_ms,
          payload.figma_file_version_answer,
          payload._taskFlowSuccessAcknowledged
        )

      if (finishAction) {
        // Nothing to do as we're waiting for recording to stop
      } else if (
        shouldRecord &&
        payload.task_duration_ms &&
        payload.task_duration_ms > 0
      ) {
        if (prototypeSuccessNotAcknowledged) {
          // This will happen when users reach the goal screen and this section is set to show the success screen after that. We'll stop recording only when users click the `Continue` button in the success screen. Thus just run the `updateResponseSection` function here.
          updateResponseSection(usabilityTestSectionId, payload)
        } else {
          setFinishAction(
            () => () => updateResponseSection(usabilityTestSectionId, payload)
          )
        }
      } else {
        updateResponseSection(usabilityTestSectionId, payload)
      }
    },
    [
      updateResponseSection,
      setFinishAction,
      shouldRecord,
      usabilityTestSection.type,
      // usabilityTestSection.prototype_type will affect the result of `requiresTaskFlowSuccessAcknowledged` function
      usabilityTestSection.prototype_type,
    ]
  )

  useEffect(() => {
    prepareRecording()
  }, [])

  useEffect(() => {
    if (finishAction) {
      // Stop and upload section recordings
      if (isValidRecordingState) {
        stopSectionRecordings()
      } else {
        // Because Firefox doesn't support MediaStream.oninactive() event, we don't know if the user stops the recording before the task is done on Firefox.
        // Thus, we check here if the recording is stopped before the task is done.
        setError(ParticipantDeletionReason.RecordingPrematureStop)
      }
    }
  }, [finishAction])

  useEffect(() => {
    if (allUploadRequestsSent) {
      // Update section response and jump to next section by calling the saved updateResponseSection function
      // This will happen only once for each section
      finishAction && finishAction()
    }
  }, [allUploadRequestsSent])

  useEffect(() => {
    if (areAllStreamsActive) {
      setDisableContinueButton(false)
    }
  }, [areAllStreamsActive])

  useEffect(() => {
    // When the recording types include microphone, we'll start recording when the user closes the ThinkAloud modal instead of here
    if (!sectionRecordingTypes.includes("microphone")) {
      continueSection && startRecording()
    }
  }, [continueSection])

  if (shouldRecord && !continueSection) {
    return (
      <SectionRecordingPrompt
        sectionRecordingTypes={sectionRecordingTypes}
        isDisabled={disableContinueButton}
        onContinue={() => setContinueSection(true)}
      />
    )
  }

  return (
    <SectionRecordingContext.Provider
      value={{
        sectionRecordingTypes,
        updateResponseSection: updateResponseSectionWithRecording,
        addResponseSectionRecording,
        startRecording,
        isValidRecordingState,
      }}
    >
      {children}
    </SectionRecordingContext.Provider>
  )
}

export const useSectionRecordingContext = () => {
  const context = React.useContext(SectionRecordingContext)

  if (context === null) {
    throw new Error(
      `useSectionRecordingContext must be rendered within the SectionRecordingContextProvider`
    )
  }

  return context
}

import React from "react"

import {
  DesktopOnly,
  MobileOnly,
} from "Components/break-points/desktop-and-mobile-break-points"
import {
  AnswerForm,
  Props,
} from "UsabilityHub/components/UsabilityTestSectionQuestion/answer-form"

import { TextareaField } from "../../../application/javascripts/components/redux-form/textarea"

export function LongAnswerQuestion(props: Readonly<Props>) {
  return (
    <AnswerForm {...props}>
      <DesktopOnly>
        <TextareaField name="answer" rows={4} autoFocus />
      </DesktopOnly>
      <MobileOnly>
        <TextareaField name="answer" rows={4} />
      </MobileOnly>
    </AnswerForm>
  )
}

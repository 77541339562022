import { kebabCase } from "lodash"
import { createSelector } from "reselect"

import { State } from "Redux/app-store"
import { FigmaFileFlow, FigmaFileVersion } from "Types"

export const getFigmaFileVersions = (state: State) => {
  return state.figmaFileVersions
}

const getFigmaFileVersionById = (id: number) =>
  createSelector(getFigmaFileVersions, (ffvs): FigmaFileVersion => {
    const ffv = ffvs.find((ffv) => ffv.id === id)
    if (ffv == null) {
      throw new Error(`No figma file version with id: ${id}`)
    } else {
      return ffv
    }
  })

export const getNullableFigmaFileVersionById = (id: number) =>
  createSelector(getFigmaFileVersions, (ffvs): FigmaFileVersion | undefined => {
    return ffvs.find((ffv) => ffv.id === id)
  })

const createFigmaFlowPrototypeUrl = (
  figmaFileVersion: FigmaFileVersion,
  start_node_id: string,
  scaling: string
): string => {
  const { figma_file_key, version } = figmaFileVersion
  const queryString = new URLSearchParams({
    "version-id": version,
    "starting-point-node-id": start_node_id,
    "node-id": start_node_id, // For multi-page files
    scaling: kebabCase(scaling),
    "hotspot-hints": "0", // Remove hitzone highlighting on misclick
    "hide-ui": "1", // Hide figma controls
  }).toString()
  return `https://www.figma.com/proto/${figma_file_key}?${queryString}`
}

export const getFigmaFlowPrototypeUrl = (figmaFileFlow: FigmaFileFlow) =>
  createSelector(
    getFigmaFileVersionById(figmaFileFlow.figma_file_version_id),
    (figmaFileVersion) =>
      createFigmaFlowPrototypeUrl(
        figmaFileVersion,
        figmaFileFlow.start_node_id,
        figmaFileFlow.scaling
      )
  )

export const getNullableFigmaFlowPrototypeUrl = (
  figmaFileFlow: FigmaFileFlow
) =>
  createSelector(
    getNullableFigmaFileVersionById(figmaFileFlow.figma_file_version_id),
    (figmaFileVersion) => {
      if (figmaFileVersion) {
        return createFigmaFlowPrototypeUrl(
          figmaFileVersion,
          figmaFileFlow.start_node_id,
          figmaFileFlow.scaling
        )
      } else {
        return null
      }
    }
  )

import { useToast } from "@chakra-ui/react"
import { useCallback, useEffect } from "react"

import { getEnvState } from "JavaScripts/state"

type MessageHandler = (
  this: Window,
  ev: MessageEvent<{
    type: "OAUTH_SUCCESS" | "OAUTH_ERROR"
    integration: string
  }>
) => unknown

export const useOAuth = (
  onSuccess: () => void,
  oauthName: string,
  oauthPath: string,
  hasAuthCredentials: boolean
) => {
  const toast = useToast()

  const handleWithAuth = () => {
    if (!hasAuthCredentials) {
      window.open(
        oauthPath,
        "popup",
        "toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=600,height=600,left=40,top=40"
      )
    } else {
      onSuccess()
    }
  }

  const messageHandler: MessageHandler = useCallback(
    (event) => {
      // We only care about messages coming from us
      // TODO: Might not need this filter if we're ignoring everything that's not oauth.
      if (event.origin.includes(getEnvState().APP_DOMAIN)) {
        if (event.data.integration && event.data.integration !== oauthName) {
          // Avoid picking up messages intended for other OAuth integrations
          return
        }

        switch (event.data?.type) {
          case "OAUTH_SUCCESS":
            onSuccess()
            break
          case "OAUTH_ERROR":
            toast({
              description: `We couldn${"\u2019"}t connect Lyssna to your ${oauthName} account. Please try again.`,
              status: "error",
            })
            break
          default:
            // Ignore any non-OAuth messages.
            break
        }
      }
    },
    [oauthName, onSuccess, toast]
  )

  // Listen to all messages so we can do things as a result of the auth flow.
  useEffect(() => {
    window.addEventListener("message", messageHandler, false)
    return () => {
      window.removeEventListener("message", messageHandler)
    }
  }, [messageHandler])

  return { hasAuthCredentials, handleWithAuth }
}

import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react"

import { ClickSelection } from "Types"
import {
  getParsedQueryStringByPath,
  pushQueryStringPath,
} from "Utilities/query-string"

export const QUERY_PATH = "view-state"

type ViewState = {
  screenshotClientHitzones: ClickSelection[]
  individualResponseView: number | null
}

type TestResultViewStateType = {
  isReviewing: boolean
  isThirdPartyOrder: boolean
  viewState: ViewState
  setViewState: Dispatch<SetStateAction<ViewState>>
}

export const TestResultViewState = createContext<TestResultViewStateType>({
  isReviewing: false,
  isThirdPartyOrder: false,
  viewState: {
    screenshotClientHitzones: [],
    individualResponseView: null,
  },
  setViewState: () => null,
})

const getInitialState = () => {
  const partialViewState =
    getParsedQueryStringByPath<Partial<ViewState>>(QUERY_PATH)
  return {
    screenshotClientHitzones: [],
    individualResponseView: null,
    ...partialViewState,
  }
}

export const TestResultViewStateProvider: FC<
  React.PropsWithChildren<{
    isReviewing?: boolean
    isThirdPartyOrder?: boolean
  }>
> = ({ isReviewing = false, isThirdPartyOrder = false, children }) => {
  const [viewState, setViewState] = useState(getInitialState())

  // Keep the URL query params updated
  useEffect(() => {
    pushQueryStringPath(QUERY_PATH, viewState)
  }, [viewState])

  return (
    <TestResultViewState.Provider
      value={{ isReviewing, isThirdPartyOrder, viewState, setViewState }}
    >
      {children}
    </TestResultViewState.Provider>
  )
}

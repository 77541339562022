import { Box, Button, Flex, FlexProps, Icon, Text } from "@chakra-ui/react"
import { ArrowLeftIcon } from "@heroicons/react/solid"
import React from "react"

import Constants from "Constants/shared.json"

import { useFeatureFlag } from "Hooks/use-feature-flag"
import { XCircleSolidIcon } from "Shared/icons/untitled-ui/XCircleSolidIcon"
import { ROUTES } from "UsabilityHub/views/routes"
import { useInNoPlanState } from "Utilities/account"
import { isFree } from "Utilities/plan"
import { useMatch } from "react-router"
import { FEATURES } from "./features"
import { usePlanChangerContext } from "./plan-changer-context"
import { PlanHeaderContent } from "./plan-header"
import { featureCellContents, featureCellLabel } from "./shared"

export const PlanDetails: React.FC<FlexProps> = (props) => {
  const { dispatch, currentPlan, allPlans, onClose, ...state } =
    usePlanChangerContext()

  const isInNoPlanState = useInNoPlanState()
  const onDashboard = useMatch(ROUTES.DASHBOARD.path)

  // Show the free plan if no plan is selected
  const planToShow =
    "selectedPlan" in state && state.selectedPlan
      ? state.selectedPlan
      : allPlans.find((p) => p.unique_id === Constants.FREE_PLAN_UNIQUE_ID)

  const planToCompareTo =
    currentPlan ??
    allPlans.find(
      (p) => p.unique_id === Constants.PRO_V3_MONTHLY_PLAN_UNIQUE_ID
    )!

  const recordingPlanLimit = useFeatureFlag(
    "usability_test_recording_plan_limit"
  )

  if (!planToShow) return null

  const featuresYouWillLose = FEATURES.filter((feature) => {
    // Temporary before the recording official launch: hide Recordings feature until the commercial release
    if (feature.feature === "recordings" && !recordingPlanLimit) {
      return false
    }

    const oldValue = feature.value(planToCompareTo)
    const newValue = feature.value(planToShow)

    if (typeof oldValue === "boolean" || typeof newValue === "boolean") {
      return oldValue !== false && newValue === false
    }

    if (oldValue === "Unlimited" && newValue !== "Unlimited") {
      return true
    }

    if (feature.feature === "white_labeled_tests") {
      return (
        (oldValue === "Custom" && newValue !== "Custom") ||
        (oldValue === "No branding" && newValue === "Lyssna")
      )
    }

    if (oldValue.match(/^\d+/) && newValue.match(/^\d+/)) {
      return parseInt(oldValue, 10) > parseInt(newValue, 10)
    }

    return false
  })

  // In this view we only show features that apply to the plan we're viewing
  const featuresOnPlan = FEATURES.filter((feature) => {
    // Temporary before the recording official launch: hide Recordings feature until the commercial release
    if (feature.feature === "recordings" && !recordingPlanLimit) {
      return false
    }

    return feature.value(planToShow) !== false
  })

  const backAction = () => {
    if (isInNoPlanState && onDashboard) {
      onClose()
    } else {
      dispatch({ type: "back-to-grid" })
    }
  }

  return (
    <Flex direction="column" align="flex-start" {...props}>
      <Button
        variant="ghost"
        color="brand.primary.500"
        leftIcon={<Icon as={ArrowLeftIcon} />}
        onClick={backAction}
        mb={2}
      >
        All plans
      </Button>

      <Box>
        <PlanHeaderContent plan={planToShow} />
      </Box>

      <Flex direction="column" w="full" mt={3}>
        {featuresOnPlan.map((feature) => {
          return (
            <Flex
              key={feature.group + feature.label}
              borderTopWidth={1}
              borderColor="gray.200"
            >
              <Box flexBasis={1} flexGrow={1} p={3}>
                {featureCellLabel(feature)}
              </Box>
              <Box flexBasis={1} flexGrow={1} p={3}>
                {featureCellContents(feature, planToShow)}
              </Box>
            </Flex>
          )
        })}
      </Flex>

      {featuresYouWillLose.length > 0 && (
        <Flex direction="column" mt={6}>
          <Text fontSize="sm" fontWeight="normal" color="text.secondary" mb={2}>
            {isFree(planToShow) && isInNoPlanState
              ? "Paid features not included in the Free plan:"
              : "You will lose:"}
          </Text>
          {featuresYouWillLose.map((feature) => (
            <Flex key={feature.group + feature.label} py={1}>
              <Icon as={XCircleSolidIcon} boxSize={6} color="red.500" me={2} />
              {featureCellLabel(feature, {
                fontWeight: "normal",
                // Override the label if the feature has a custom comparison label
                // For example, when reducing the number of seats we will show the difference
                children: feature.comparedLabel?.(planToCompareTo, planToShow),
              })}
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  )
}

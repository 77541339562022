import { Icon, Text } from "@chakra-ui/react"
import { getFormValue } from "Redux/reducers/test-builder-form/selectors/formValues"
import {
  getCreditCount,
  getHasEstimationError,
} from "Redux/reducers/test-builder/selectors/estimate"
import { CreditIcon } from "Shared/icons/CreditIcon"
import { pluralizeWithCount } from "Utilities/string"
import React from "react"
import { useSelector } from "react-redux"
import { EstimateTooltip } from "./EstimateTooltip"

export const CreditEstimate: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const error = useSelector(getHasEstimationError)
  const creditCount = useSelector(getCreditCount)
  const screenerEnabled = useSelector(
    getFormValue("screener.enabled")
  ) as boolean

  // Loading will not be handled in this component, but rather by the DurationEstimate component

  // If fetching the estimations resulted in an error, dont display anything.
  // The durationEstimate component is displaying the error
  if (error) return null

  // If is there is no estimations for credits, dont show anything
  if (creditCount === null) {
    return null
  }

  // Otherwise show the details.
  const displayCreditCount = creditCount

  const message = `The cost of Lyssna panel responses is based on the test length${
    screenerEnabled ? " and the use of a screener" : ""
  }.`

  return (
    <EstimateTooltip label={message}>
      <Icon as={CreditIcon} />
      <Text>{pluralizeWithCount(displayCreditCount, "credit", "credits")}</Text>
    </EstimateTooltip>
  )
}

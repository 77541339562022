import { Button, GridItem, Stack } from "@chakra-ui/react"
import React, { useRef } from "react"

import { FigmaTaskScreenResult } from "Types"
import { useSectionContext } from "UsabilityHub/contexts/SectionContext"

import { usePrototypeNodeFilter } from "../hooks/use-prototype-node-filter"

import { ScreenThumbnail } from "./ScreenThumbnail"
import { ScreenThumbnailTag } from "./ScreenThumbnailTag"

interface ScreenStartGoalSelectionProps {
  screenResult: FigmaTaskScreenResult
  isSelected: boolean
  tabNodeIndex: number
  goalNodeId: string | null
  setGoalNodeId: (nodeId: string | null) => void
  onSelect?: (element: HTMLElement | null) => void
}

export const ScreenStartGoalSelection: React.FC<
  React.PropsWithChildren<ScreenStartGoalSelectionProps>
> = ({
  screenResult,
  isSelected,
  tabNodeIndex,
  goalNodeId,
  setGoalNodeId,
  onSelect,
  ...props
}) => {
  const { section } = useSectionContext()

  const { isNodeFilteredIn, toggleIsFiltered } = usePrototypeNodeFilter(
    section.id,
    screenResult.nodeId
  )
  const tabNodeId = screenResult.nodeId
  const isGoal = tabNodeId === goalNodeId
  const isStart = tabNodeIndex === 0

  const screenRef = useRef<HTMLButtonElement>(null)
  const totalClicksCount = screenResult.meta.clicks.flat().length

  return (
    <GridItem
      textAlign="inherit"
      data-group-name="screen"
      data-screen-grid-item={`screen-${screenResult.nodeId}`}
      {...props}
    >
      <Stack mt={2} spacing={1} outline="none" key={tabNodeId}>
        <ScreenThumbnailTag isStartNode={isStart} />
        <Button
          variant="unstyled"
          ref={screenRef}
          role="group"
          outline="none"
          _hover={{ cursor: "pointer" }}
          onClick={() => onSelect?.(screenRef.current)}
        >
          <ScreenThumbnail
            key={screenResult.nodeId}
            figmaImage={screenResult.figmaImage}
            isSelected={isSelected}
            isGoal={isGoal}
            isFilterActive={isNodeFilteredIn}
            onFilterButtonClick={toggleIsFiltered}
            showFilterButton
            time={screenResult.meta.duration}
            totalClicks={totalClicksCount}
            participantsCount={screenResult.meta.participants.length}
          />
        </Button>
      </Stack>
    </GridItem>
  )
}

ScreenStartGoalSelection.displayName = "ScreenStartGoalSelection"

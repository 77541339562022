import { fromPairs, sortBy, toPairs } from "lodash"

import { CardSortCategory, CardSortCategoryCard } from "Types"

import { EitherCategory, categoryIsOpen, humanizeGroupLabel } from "./grouping"

export type SortType = "position" | "alphabetically" | "numCards"

const SORT_BY_POSITION = () => {
  return (categoriesGroup: [string, EitherCategory[]]) => {
    const categories = categoriesGroup[1]

    // A group contains only one `CardSortCategory` for closed card sorts.
    // Sorting by earliest `CardSortOpenCategory` within a group for open card sorts
    const minCategory = categories.reduce((prev, current) => {
      return Number(prev.id) < Number(current.id) ? prev : current
    })

    if (Number(minCategory.id) === 0) {
      // "Unsorted" category should always be the last
      return Infinity
    }

    if (categoryIsOpen(minCategory)) {
      // Open categories should be always after the pre-defined categories,
      // if it's a hybrid card sort.
      // Sorting by open category id for open card sorts
      return 1000 + Number(minCategory.id)
    }

    // If it doesn't have a position, sort it to the end.
    // Sorting by position for closed card sorts
    return (minCategory as CardSortCategory).position ?? Infinity
  }
}

const SORT_ALPHABETICALLY = () => {
  return (categoriesGroup: [string, EitherCategory[]]) => {
    return humanizeGroupLabel(categoriesGroup[0]).toLowerCase()
  }
}

const SORT_BY_NUM_CARDS = (sortData: CardSortCategoryCard[]) => {
  return (categoriesGroup: [string, EitherCategory[]]) => {
    const categoryIds = categoriesGroup[1].map((c) => Number(c.id))

    const relatedSortData = sortData.filter((s) => {
      const categoryId = s.card_sort_open_category_id ?? s.card_sort_category_id
      return categoryIds.includes(Number(categoryId))
    })

    const relatedCardIds = relatedSortData.map((s) => s.card_sort_card_id)
    const numCards = new Set(relatedCardIds).size

    return -numCards // sort in descending order
  }
}

export const SORT_TYPE_LABELS: { [t in SortType]: string } = {
  position: "Default",
  alphabetically: "Alphabetically",
  numCards: "Number of cards",
}

const SORT_FUNCTIONS: {
  [t in SortType]: Array<
    (
      sortData: CardSortCategoryCard[]
    ) => (categoriesGroup: [string, EitherCategory[]]) => number | string
  >
} = {
  alphabetically: [SORT_ALPHABETICALLY],
  position: [SORT_BY_POSITION],
  numCards: [SORT_BY_NUM_CARDS, SORT_BY_POSITION],
}

export const sortCategories = (
  categoryGroups: Record<string, EitherCategory[]>,
  sortType: SortType,
  sortData: CardSortCategoryCard[]
) => {
  return fromPairs(
    sortBy(
      toPairs(categoryGroups),
      SORT_FUNCTIONS[sortType].map((f) => f(sortData))
    )
  )
}

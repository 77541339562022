import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  ListItem,
  OrderedList,
  Text,
  usePrefersReducedMotion,
} from "@chakra-ui/react"
import React, { MutableRefObject, useRef, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { CustomerSupportMailtoLink } from "Shared/components/Links/CustomerSupportMailtoLink"
import { TimezoneChanger } from "Shared/components/TimezoneChanger/TimezoneChanger"
import { useModeratedStudyPreviewData } from "UsabilityHub/hooks/useModeratedStudyPreviewData"
import { ROUTES } from "UsabilityHub/views/routes"
import { FunctionalModal } from "Utilities/modals/types"
import { useModal } from "Utilities/modals/use-modal"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import { usePreviewModeratedStudyApplication } from "~/api/generated/usabilityhub-components"
import { BookingCalendar } from "../../applicant/BookingCalendar"
import { ModeratedStudyApplicationProvider } from "../../applicant/ModeratedStudyApplicationContext"

interface Props {
  onAvailabilityCalendarPreviewModalOpen?: () => void
}

export const AvailabilityCalendarPreviewModalTrigger: React.FC<
  Props & Omit<ButtonProps, "onClick">
> = ({ onAvailabilityCalendarPreviewModalOpen, ...rest }) => {
  const triggerRef = useRef<HTMLButtonElement | null>(null)
  const { open } = useModal(AvailabilityCalendarPreviewModal, "plan-drawer")

  return (
    <Button
      ref={triggerRef}
      onClick={() => {
        open({
          triggerRef,
        })
        onAvailabilityCalendarPreviewModalOpen?.()
      }}
      {...rest}
    />
  )
}

const AvailabilityCalendarPreviewModal: FunctionalModal<
  Props & { triggerRef?: MutableRefObject<HTMLButtonElement | null> }
> = ({ triggerRef, isOpen, onClose }) => {
  const { moderatedStudyId } = useTypedParams(ROUTES.INTERVIEW.EDIT)
  const queryClient = useQueryClient()
  const {
    error,
    data: application,
    isLoading,
  } = usePreviewModeratedStudyApplication({
    pathParams: {
      moderatedStudyId,
    },
  })

  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null)
  const [selectedTimezone, setSelectedTimezone] = useState(() => {
    // Try to guess default timezone based on the browser
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  })

  const updatePreviewData = useModeratedStudyPreviewData()

  const existingBooking = application?.booking

  const handleBooking = async (
    startTime: string,
    participantTimezone: string
  ) => {
    const startTimeAsDate = new Date(Date.parse(startTime))

    if (application?.moderated_study_application_id === "preview") {
      updatePreviewData({
        booking: {
          id: "preview",
          state: "booked",
          starts_at: startTimeAsDate.toISOString(),
          ends_at: new Date(
            startTimeAsDate.getTime() +
              application.moderated_study.event_duration_mins * 60 * 1000
          ).toISOString(),
          hosts: [{ name: "Host name", email: "host@example.com" }],
          participant_timezone: participantTimezone,
          declined_reschedule_request: false,
          incentive_text: application.recruitment_link?.incentive_text ?? "",
          incentive_type:
            application.recruitment_link?.incentive_type ?? "none",
        },
      })
    }
  }

  handleBooking(new Date().toDateString(), selectedTimezone)

  // We don't want to treat the canceled states as an existing booking
  const existingBookingId =
    existingBooking?.state === "rescheduled_by_researcher"
      ? existingBooking?.id
      : undefined

  const prefersReducedMotion = usePrefersReducedMotion()

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={triggerRef}
      >
        <DrawerOverlay />
        <DrawerContent
          maxW="400px"
          data-qa="change-plan-drawer"
          motionProps={
            prefersReducedMotion
              ? { transformTemplate: () => "none" }
              : undefined
          }
        >
          <DrawerCloseButton />
          <DrawerHeader fontSize="xl" fontWeight="bold" pb={0}>
            Preview time slots
          </DrawerHeader>

          <DrawerBody
            paddingTop={2}
            paddingLeft={6}
            paddingRight={6}
            display="flex"
            flexFlow="column"
          >
            <Box bg="bg.surface.callout" borderRadius={6} padding={3} gap={4}>
              <Text fontSize={14}>
                This calendar takes into account your weekly hours, any date
                overrides, and the availability on any connected calendar
              </Text>
            </Box>

            {!isLoading && error && (
              <Alert status="error" mt={8}>
                <AlertIcon />
                <AlertDescription>
                  We encountered an issue loading the availability preview.
                  Please try the following steps:
                  <OrderedList m={2}>
                    <ListItem>Refresh the page and attempt again.</ListItem>
                    <ListItem>
                      If the problem persists, contact our{" "}
                      <CustomerSupportMailtoLink>
                        support team
                      </CustomerSupportMailtoLink>
                      .
                    </ListItem>
                  </OrderedList>
                  We apologize for any inconvenience.
                </AlertDescription>
              </Alert>
            )}

            {!isLoading && !error && (
              <ModeratedStudyApplicationProvider
                application={application}
                invalidateApplicationQuery={async () => {
                  queryClient.invalidateQueries([
                    "api",
                    "moderated_study_applications",
                    application.moderated_study_application_id,
                  ])
                }}
              >
                <Box mt={8} mb={8}>
                  {" "}
                  <Text
                    color="text.primary"
                    fontSize="md"
                    fontWeight="medium"
                    mb={2}
                  >
                    Participant timezone
                  </Text>
                  <TimezoneChanger
                    fontSize="md"
                    lineHeight={6}
                    fontWeight="normal"
                    variant="outline"
                    iconInside
                    value={selectedTimezone}
                    onChange={(e) => {
                      setSelectedTimezone(e.target.value)
                    }}
                  />
                </Box>
                <Flex flex={1} flexFlow="column" paddingInlineStart={1}>
                  <BookingCalendar
                    moderatedStudyApplicationId={
                      application.moderated_study_application_id
                    }
                    slot={selectedSlot}
                    onSlotChange={setSelectedSlot}
                    timezone={selectedTimezone}
                    isFirstLoading={isFirstLoading}
                    setIsFirstLoading={setIsFirstLoading}
                    existingBookingId={existingBookingId}
                    displayFullSlots
                  />
                </Flex>
              </ModeratedStudyApplicationProvider>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

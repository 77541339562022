import React from "react"
import { Link, useMatch } from "react-router-dom"

import { Step, Steps, StepsDivider } from "Shared/components/Steps/Steps"

export const UsabilityTestSteps: React.FC<{
  responseCount: number
}> = ({ responseCount }) => {
  const newTestRouteMatch = useMatch("/tests/new")

  const existingTestMatch = useMatch("/tests/:testId/*")
  const editTestRouteMatch = useMatch("/tests/:testId/edit")
  const recruitTestRouteMatch = useMatch("/tests/:testId/recruit/*")
  const resultsTestRouteMatch = useMatch("/tests/:testId/results")

  if (newTestRouteMatch) {
    return (
      <Steps>
        <Step label="Build" isActive isComplete={false} />
        <StepsDivider />
        <Step label="Recruit" isDisabled />
        <StepsDivider />
        <Step label="Results" isDisabled />
      </Steps>
    )
  }

  const testId = existingTestMatch!.params.testId!

  return (
    <Steps>
      <Link to={`${testId}/edit`}>
        <Step
          label="Build"
          isActive={!!editTestRouteMatch}
          isComplete={false}
        />
      </Link>
      <StepsDivider />

      <Link to={`${testId}/recruit`}>
        <Step
          label="Recruit"
          isActive={!!recruitTestRouteMatch}
          isComplete={false}
        />
      </Link>
      <StepsDivider />

      <Link to={`${testId}/results`}>
        <Step
          label="Results"
          isActive={!!resultsTestRouteMatch}
          count={responseCount}
        />
      </Link>
    </Steps>
  )
}

import React, { FunctionComponent, TextareaHTMLAttributes } from "react"
import { BaseFieldProps, Field, WrappedFieldProps } from "redux-form"

import { Textarea } from "Components/form/textarea"

const TextareaAdapter: FunctionComponent<
  React.PropsWithChildren<WrappedFieldProps>
> = ({ input, ...rest }) => <Textarea {...input} {...rest} />

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> &
  BaseFieldProps<HTMLTextAreaElement>

export const TextareaField: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ warn, ...props }) => (
  <Field
    component={TextareaAdapter}
    name={props.name}
    props={props}
    warn={warn}
  />
)

import { HStack, Link, Tag, VStack } from "@chakra-ui/react"
import { ROUTES } from "UsabilityHub/views/routes"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { AutoUploadRecordingSwitch } from "./AutoUploadRecordingSwitch"
import { Booking } from "./types"

type Props = {
  moderatedStudyId: string
  booking: Booking
  autoUploadRecordings: boolean
  toggleAutoUploadRecordings: () => void
}

export const SessionRecording: React.FC<Props> = ({
  moderatedStudyId,
  booking,
  autoUploadRecordings,
  toggleAutoUploadRecordings,
}) => {
  const recordingHref = ROUTES.INTERVIEW.RECORDINGS.SESSION.buildPath({
    moderatedStudyId,
    sessionId: booking.id,
  })

  const uploaded =
    booking.recordings.filter(
      (recording) =>
        recording.status === "manually_uploaded" ||
        recording.status === "complete"
    ).length > 0
  const showAutoUploadSetting = booking.location_type === "zoom"

  return (
    <VStack alignItems="flex-start">
      <HStack>
        <Tag
          color="text.primary"
          bg="brand.background.light"
          rounded={4}
          fontSize="xs"
          px={1.5}
          py={2}
        >
          {uploaded
            ? "Uploaded"
            : showAutoUploadSetting && autoUploadRecordings
              ? "Fetch queued"
              : "Not uploaded"}
        </Tag>
        <Link as={RouterLink} to={recordingHref} variant="noUnderline">
          {uploaded ? "View recording" : "Upload recording"}
        </Link>
      </HStack>
      {showAutoUploadSetting && !uploaded && (
        <AutoUploadRecordingSwitch
          id={`auto-upload-recordings-${booking.id}`}
          autoUploadRecordings={autoUploadRecordings}
          toggleAutoUploadRecordings={toggleAutoUploadRecordings}
        />
      )}
    </VStack>
  )
}

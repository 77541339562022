import { useToast } from "@chakra-ui/react"
import { axios } from "Services/axios"
import { usersSignInPath, usersSignOutPath } from "Shared/constants/routes"
import { channel } from "Utilities/broadcast"

export const useSignout = () => {
  const toast = useToast()

  return async () => {
    try {
      // TODO: Bring this into OpenAPI
      await axios.delete(usersSignOutPath())

      /**
       * Ensure Segment cookies/localstorage are cleared on log out.
       *
       * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-logout
       * @see https://gitlab.com/usabilityhub/app/-/issues/7228
       */
      if (window.analytics) {
        window.analytics.reset()
      }

      /**
       * Handle closing of Intercom session. Will restart once a user logs in.
       *
       * @see https://gitlab.com/usabilityhub/app/-/merge_requests/6011
       * @see https://gitlab.com/usabilityhub/app/-/merge_requests/6063
       */
      if (window.Intercom) {
        window.Intercom("shutdown")
      }

      /**
       * Broadcast a sign out message across all tabs subscribed to the channel.
       * This will handle the redirection of all other tabs.
       *
       * @see https://gitlab.com/usabilityhub/app/-/merge_requests/7335
       */
      await channel.postMessage("SIGN_OUT")

      /**
       * The current tab won't receive the broadcast message
       * Redirect to sign in page for the current tab
       */
      window.location.href = usersSignInPath()
    } catch (error) {
      toast({
        status: "error",
        title: "Sign out failed. Please try again",
      })
    }
  }
}

import { Alert, AlertTitle, Grid, GridItem, Stack } from "@chakra-ui/react"
import { RecordingPlayer } from "Components/test-recording/RecordingPlayer"
import { ResponseSectionRecording } from "JavaScripts/types/recording"
import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { RecordingContent } from "./RecordingContent"
import { useSectionRecordings } from "./useSectionRecordings"

export const RecordingsTab: React.FC = () => {
  const recordings = useSectionRecordings()

  if (recordings.length === 0) {
    return (
      <Alert status="info">
        <AlertTitle>No recording data available</AlertTitle>
      </Alert>
    )
  }

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
      {recordings.map((recording) => (
        // When a signle recording container has errors, we'll ignore it and continue rendering the rest of the recordings.
        <ErrorBoundary key={`error-${recording.id}`} fallback={null}>
          <GridItem boxShadow="base" rounded="md" key={recording.id}>
            <RecordingContainer recording={recording} />
          </GridItem>
        </ErrorBoundary>
      ))}
    </Grid>
  )
}

const RecordingContainer: React.FC<{ recording: ResponseSectionRecording }> = ({
  recording,
}) => {
  const [hasRecordingError, setHasRecordingError] = React.useState(false)

  return (
    <Stack gap={0} h="full">
      <RecordingPlayer
        recording={recording}
        setHasRecordingError={setHasRecordingError}
      />
      <RecordingContent
        responseSection={recording.responseSection}
        status={recording.status}
        hasRecordingError={hasRecordingError}
      />
    </Stack>
  )
}

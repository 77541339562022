import classNames from "classnames"
import React, { PureComponent, TextareaHTMLAttributes } from "react"
import TextareaAutosize from "react-autosize-textarea"

import styles from "./textarea.module.scss"

// see: https://github.com/buildo/react-autosize-textarea/issues/104#issuecomment-460883506
type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>
interface Props extends TextareaProps {
  // `value` and `onChange` are required.
  onChange: TextareaProps["onChange"]
  value: string
  pattern?: string
}

/**
 * Our `Textarea` implementation.
 *
 * Notable differences from default React `textarea` component:
 *  - Has default styles.
 *  - Supports `pattern`, modeled on `HTMLInputElement`'s behaviour.
 *  - If `required`, will fail validation on any whitespace input. To use
 *    standard `required` behaviour provide `pattern=""`.
 */
export class Textarea extends PureComponent<Props> {
  private elementRef = React.createRef<HTMLTextAreaElement>()

  // -- Private interface --

  private validate() {
    const {
      elementRef: { current: element },
    } = this
    if (element === null) return
    const { required, pattern, value } = this.props

    if (required && value !== "" && pattern === undefined) {
      // We extend `required`'s default behaviour to prevent whitespace only
      // strings. (Only when a value is present, otherwise `required` will work
      // fine.)
      //
      // NOTE: "Please fill out this field." is the message that is shown in
      // Firefox when a `required` validation is failed. However, Firefox would
      // localise this string, this extension will always show the message in
      // English.
      element.setCustomValidity(
        /[^\s]/.test(value) ? "" : "Please fill out this field."
      )
    } else if (pattern !== undefined && pattern.length > 0) {
      // Emulate `HTMLInputElement#pattern` on `textarea` (normally
      // unsupported).
      element.setCustomValidity(
        // NOTE: The pattern is an exact match, so it must be surrounded with
        // boundary symbols to correctly emulate `pattern`.
        new RegExp(`^${pattern}$`).test(value) ? "" : "Invalid input."
      )
    } else {
      // Clear invalidity.
      element.setCustomValidity("")
    }
  }

  // -- React lifecycle --

  componentDidMount() {
    this.validate()
  }

  componentDidUpdate() {
    this.validate()
  }

  render() {
    const {
      className,
      pattern, // unused
      onResize,
      ...rest
    } = this.props
    return (
      // @ts-ignore
      <TextareaAutosize
        className={classNames(styles.textarea, className)}
        ref={this.elementRef}
        // The package is not being actively maintained
        // The package has typed the textarea component itself, but the types for the native textarea are updated, which leads to a mismatch
        onResize={onResize as unknown as (e: Event) => void}
        {...rest}
      />
    )
  }
}

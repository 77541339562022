import { Button, Flex, Heading, Icon, Text, useToast } from "@chakra-ui/react"
import { Send01OutlineIcon } from "Shared/icons/untitled-ui/Send01OutlineIcon"
import React from "react"
import {
  SendModeratedStudyPreviewEmailPathParams,
  useSendModeratedStudyPreviewEmail,
} from "~/api/generated/usabilityhub-components"
import { useModeratedStudyContext } from "./ModeratedStudyContext"

type EmailType = SendModeratedStudyPreviewEmailPathParams["email"]

const PREVIEWABLE_EMAILS = [
  {
    name: "Invitation",
    description:
      "Participants receive this email when you invite them to book a time for a session.",
    email: "invited",
  },
  {
    name: "Confirmation",
    description:
      "Participants receive this email when they successfully book themselves for a session.",
    email: "booking_confirmation",
  },
  {
    name: "Reminder",
    description:
      "Participants receive this email 24 hours and 15 minutes before the session.",
    email: "reminder_15m",
  },
  {
    name: "Rescheduled",
    description:
      "Participants receive this email when a session is rescheduled.",
    email: "rescheduled",
  },
  {
    name: "Canceled",
    description:
      "Participants receive this email when when a session is canceled.",
    email: "canceled_by_researcher",
  },
] as const

export const EmailPreviewSection: React.FC = () => {
  const toast = useToast()
  const { moderatedStudy } = useModeratedStudyContext()

  const { mutate: sendEmailMutation } = useSendModeratedStudyPreviewEmail({
    onSuccess: () => {
      toast({
        title: "Email sent",
        description: "A preview has been sent to your email address.",
        status: "success",
      })
    },
    onError: () => {
      toast({
        title: "Error sending email",
        description:
          "There was a problem sending the email. Contact support if this persists.",
        status: "error",
      })
    },
  })

  const triggerEmail = (email: EmailType) => {
    sendEmailMutation({
      pathParams: {
        moderatedStudyId: moderatedStudy.id,
        email,
      },
    })
  }

  return (
    <Flex direction="column" gap={6}>
      <Heading color="text.primary" fontSize="lg" fontWeight="semibold">
        Automated emails
      </Heading>

      {PREVIEWABLE_EMAILS.map(({ name, email, description }) => {
        return (
          <Flex key={email}>
            <Flex flexGrow={1} direction="column" gap={0.5}>
              <Text color="text.primary" fontWeight="medium">
                {name}
              </Text>
              <Text color="text.secondary" fontSize="sm" fontWeight="normal">
                {description}
              </Text>
            </Flex>

            <Button
              variant="outline"
              size="sm"
              leftIcon={<Icon as={Send01OutlineIcon} />}
              flexShrink={0}
              onClick={() => triggerEmail(email)}
              ml={2}
            >
              Send preview
            </Button>
          </Flex>
        )
      })}
    </Flex>
  )
}

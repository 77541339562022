import { Box, BoxProps } from "@chakra-ui/react"
import React from "react"

import { DotIcon } from "Shared/icons/DotIcon"

type WithDotProps = BoxProps & {
  dotColor?: string
  visible?: boolean
}

const WithDot: React.FC<WithDotProps> = ({
  dotColor = "green.500",
  visible,
  children,
  ...boxProps
}) => (
  <Box {...boxProps} position="relative">
    {visible && (
      <DotIcon
        color={dotColor}
        position="absolute"
        top="50%"
        right="100%"
        my="-0.25rem"
        mr="0.125rem"
        boxSize="0.5rem"
      />
    )}

    {children}
  </Box>
)

export default WithDot

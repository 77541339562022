import {
  Alert,
  AlertDescription,
  AlertIcon,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Spinner,
  usePrefersReducedMotion,
} from "@chakra-ui/react"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import { getUsabilityTest } from "Redux/reducers/test-results/selectors"
import React from "react"
import { useSelector } from "react-redux"
import { useGetTestResultsIndividualResponse } from "~/api/generated/usabilityhub-components"
import { IndividualResponse } from "./IndividualResponse"
import { IndividualResponseFooter } from "./IndividualResponseFooter"
import { IndividualResponseHeader } from "./IndividualResponseHeader"

export const IndividualResponseDrawer: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const usabilityTest = useSelector(getUsabilityTest)
  const [responseId, setResponseId] = useIndividualResponseFilter()
  const prefersReducedMotion = usePrefersReducedMotion()

  // If we're on the shared version of the results page we need to proxy the privateId
  // from the URL to the API calls we're making here.
  // We might not be in a Router context so we need to parse the URL manually.
  // (Note that there is very similar code in response-durations.tsx, might need to abstract it
  // if we find ourselves using this privateId in other places)
  const lastPathElement = window.location.pathname.split("/").pop()
  const privateId = lastPathElement === "results" ? undefined : lastPathElement

  const {
    data: response,
    isLoading,
    isError,
  } = useGetTestResultsIndividualResponse(
    {
      pathParams: {
        usabilityTestUniqueId: usabilityTest.unique_id,
        responseId: responseId ?? -1, // Query is disabled when responseId is null, but ts doesn't believe it
      },
      queryParams: privateId
        ? {
            private_id: privateId,
          }
        : {},
    },
    {
      enabled: responseId !== null,
      cacheTime: Infinity, // We'll only refetch these when manually invalidating
      staleTime: Infinity,
    }
  )

  if (responseId === null) return null

  const onClose = () => setResponseId(null)

  return (
    <Drawer isOpen onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent
        maxW="45rem"
        data-qa="individual-response-modal"
        motionProps={
          prefersReducedMotion ? { transformTemplate: () => "none" } : undefined
        }
      >
        <DrawerHeader p={0}>
          {response && <IndividualResponseHeader response={response} />}

          <DrawerCloseButton />
        </DrawerHeader>

        <DrawerBody p={0}>
          {isLoading && (
            <Center h="full">
              <Spinner />
            </Center>
          )}

          {isError && (
            <Center h="full">
              <Alert status="error" mx={4}>
                <AlertIcon />
                <AlertDescription>Failed to load response</AlertDescription>
              </Alert>
            </Center>
          )}

          {!isLoading &&
            !isError &&
            (response ? (
              <IndividualResponse response={response} />
            ) : (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  Response not found&mdash;it may have been deleted.
                </AlertDescription>
              </Alert>
            ))}
        </DrawerBody>

        {response && (
          <DrawerFooter>
            <IndividualResponseFooter response={response} onClose={onClose} />
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}

import { FormControl } from "@chakra-ui/react"
import React from "react"
import { Field, WrappedFieldProps } from "redux-form"

import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { containsURLs } from "UsabilityHub/components/TestForm/validators/validate-contains-urls"
import { useSectionIndexContext } from "UsabilityHub/contexts"

import { MarkdownFormLabel, MarkdownTextarea } from "../../TestFormMarkdown"
import { useShouldAutoFocus } from "../useShouldAutofocus"

const WrappedInstructionSectionMessage: React.FC<
  React.PropsWithChildren<WrappedFieldProps>
> = ({ input }) => {
  const autoFocus = useShouldAutoFocus(input.value)
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <FormControl id={input.name}>
      <MarkdownFormLabel>Message</MarkdownFormLabel>
      <MarkdownTextarea
        isRequired
        isReadOnly={readOnly}
        autoFocus={autoFocus}
        {...input}
      />
    </FormControl>
  )
}

export const InstructionSectionMessage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const sectionIndex = useSectionIndexContext()
  return (
    <Field
      name={`sections[${sectionIndex}].text`}
      component={WrappedInstructionSectionMessage}
      warn={containsURLs}
    />
  )
}

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { SubmitButton } from "Components/button/submit-button"
import { JsForm } from "Components/form/form"
import { useFeatureFlag } from "Hooks/use-feature-flag"
import { useLanguage } from "Hooks/use-language"
import { Dispatch, State } from "Redux/app-store"
import { deleteResponse } from "Redux/reducers/test-results/action-creators"
import {
  getResponseOrNull,
  getUsabilityTest,
} from "Redux/reducers/test-results/selectors"
import { Option, OwnerDeletionReason as Reason, Response } from "Types"
import { memoizeOnce } from "Utilities/memoization"
import { FunctionalModal } from "Utilities/modals/types"

interface Props {
  onSuccess?: () => void
  responseId: number
}

const deletionOptions = memoizeOnce(
  (
    englishLanguageName: string,
    featureFlagEnabled: boolean
  ): ReadonlyArray<Option<Reason>> => [
    {
      value: Reason.Spam,
      label: `Answers are spammy or didn${"\u2019"}t make sense`,
    },
    { value: Reason.Malicious, label: "Answers are offensive or profane" },
    {
      value: Reason.WeakLanguage,
      label: `Answers are not in ${englishLanguageName} or are of very poor language quality`,
    },
    {
      value: Reason.LowEffort,
      label: `Answers are rushed or show little effort`,
    },
    // Only show reasons related to test recordings if the user has access to the feature
    ...(featureFlagEnabled
      ? [
          {
            value: Reason.DidNotSpeak,
            label: `Participant did not speak aloud`,
          },
          {
            value: Reason.RecordingQuality,
            label: `Participant's recording has poor audio or video quality`,
          },
        ]
      : []),
  ]
)

export const ReportUserModal: FunctionalModal<Props> = ({
  responseId,
  onClose,
  onSuccess,
}) => {
  const dispatch = useDispatch<Dispatch>()

  const languageCode = useSelector(getUsabilityTest).language_code
  const language = useLanguage(languageCode)
  const response = useSelector<State, Response | null>((state) =>
    getResponseOrNull(state, responseId)
  )

  const [reason, setReason] = useState<Reason>(Reason.Unspecified)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const featureFlagEnabled = useFeatureFlag("usability_test_recording_orders")

  const handleSubmit = () => {
    setIsSubmitting(true)
    try {
      void dispatch(deleteResponse(responseId, reason))
      onSuccess?.()
      onClose()
    } catch (error) {
      setIsSubmitting(false)
    }
  }

  if (response === null) return null

  return (
    <Modal isOpen size="xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <JsForm onSubmit={handleSubmit}>
          <ModalHeader>
            <Text>Report this participant{"\u2019"}s response</Text>
          </ModalHeader>
          <ModalBody>
            <Stack>
              <>
                <Text>
                  Please tell us what was wrong with this participant{"\u2019"}s
                  response to assist our quality assurance team.
                </Text>
                <RadioGroup
                  value={reason}
                  onChange={(value: Reason) => setReason(value)}
                >
                  <Stack gap="2" marginBlockStart="1">
                    {deletionOptions(
                      language?.english_name ?? "",
                      featureFlagEnabled
                    ).map(({ value, label, disabled }) => (
                      <Radio
                        key={value}
                        value={value}
                        disabled={disabled || undefined}
                        alignItems="flex-start"
                      >
                        <Box marginBlockStart="-1">{label}</Box>
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
                <Alert status="info">
                  <AlertIcon />
                  This response will be permanently removed from your test
                  results. You'll automatically receive a replacement from the
                  Lyssna panel.
                </Alert>
              </>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <SubmitButton
                colorScheme="red"
                isDisabled={reason === Reason.Unspecified}
                isLoading={isSubmitting}
              >
                Report and remove
              </SubmitButton>
            </HStack>
          </ModalFooter>
        </JsForm>
      </ModalContent>
    </Modal>
  )
}

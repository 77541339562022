import { Box, Flex, Text } from "@chakra-ui/react"
import { cq, cqContainer } from "Shared/helpers/containerQuerySx"
import { ClockOutlineIcon } from "Shared/icons/untitled-ui/ClockOutlineIcon"
import { Globe01OutlineIcon } from "Shared/icons/untitled-ui/Globe01OutlineIcon"
import { Tag01OutlineIcon } from "Shared/icons/untitled-ui/Tag01OutlineIcon"
import { Users01OutlineIcon } from "Shared/icons/untitled-ui/Users01OutlineIcon"
import React, { FC } from "react"
import { useGetExperiments } from "~/api/generated/usabilityhub-components"

export const PanelRecruitmentSummary: FC = () => {
  const { isLoading, data: experiments } = useGetExperiments({})

  const newSummary = experiments?.panel_order_discount === "banner"

  if (isLoading) {
    return null
  }

  return (
    <Flex sx={cqContainer()} flexGrow="1">
      {newSummary ? (
        <Box
          display="grid"
          flexGrow="1"
          alignItems="center"
          columnGap={6}
          rowGap={3}
          sx={cq("32rem", { gridTemplateColumns: "auto auto" })}
        >
          <Flex gap={2}>
            <Users01OutlineIcon boxSize="1.375rem" />
            <Text textStyle="ds.paragraph.emphasized">530,000+ panelists</Text>
          </Flex>
          <Flex gap={2}>
            <Globe01OutlineIcon boxSize="1.375rem" />
            <Text textStyle="ds.paragraph.emphasized" mr={4}>
              Covering 100+ countries
            </Text>
          </Flex>
          <Flex gap={2}>
            <Tag01OutlineIcon boxSize="1.375rem" />
            <Text textStyle="ds.paragraph.emphasized" mr={4}>
              395+ attributes
            </Text>
          </Flex>
          <Flex gap={2}>
            <ClockOutlineIcon boxSize="1.375rem" />
            <Text textStyle="ds.paragraph.emphasized" mr={4}>
              ~30 min average turnaround
            </Text>
          </Flex>
        </Box>
      ) : (
        <Text textStyle="ds.paragraph.emphasized">
          Access our global panel of 530k+ participants.
        </Text>
      )}
    </Flex>
  )
}

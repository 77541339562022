import {
  Avatar,
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from "@chakra-ui/react"
import {
  LockClosedIcon,
  LogoutIcon,
  ShieldCheckIcon,
  UserCircleIcon,
} from "@heroicons/react/outline"
import { Z_INDEX_MAIN_NAV_MENUS } from "Constants/zIndexes"
import { getEnvState } from "JavaScripts/state"
import { RoutedLink } from "Shared/components/Links/RoutedLink"
import { useSignout } from "Shared/hooks/useSignout"
import { Briefcase01OutlineIcon } from "Shared/icons/untitled-ui/Briefcase01OutlineIcon"
import { CreditCard02OutlineIcon } from "Shared/icons/untitled-ui/CreditCard02OutlineIcon"
import { Flag02OutlineIcon } from "Shared/icons/untitled-ui/Flag02OutlineIcon"
import { Settings04OutlineIcon } from "Shared/icons/untitled-ui/Settings04OutlineIcon"
import { Users01OutlineIcon } from "Shared/icons/untitled-ui/Users01OutlineIcon"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import { ROUTES } from "UsabilityHub/views/routes"
import { useModal } from "Utilities/modals/use-modal"
import React from "react"
import { FeatureFlagModal } from "../FeatureFlagModal/FeatureFlagModal"

export const SettingsMenu = () => {
  const signout = useSignout()
  const currentUser = useCurrentUser()

  const showFeatureFlagLink = getEnvState().ANYONE_CAN_EDIT_THEIR_FEATURE_FLAGS
  const { open: showFeatureFlagModal } = useModal(FeatureFlagModal)

  const featureAccessItem = (
    <MenuItem
      as={RoutedLink}
      to={ROUTES.SETTINGS.FEATURE_ACCESS.path}
      icon={<Icon as={Settings04OutlineIcon} />}
    >
      Feature access
    </MenuItem>
  )

  return (
    <Menu placement="bottom-end" isLazy>
      <MenuButton
        as={Button}
        size="sm"
        variant="round"
        aria-label="View profile and more"
        data-qa="settings-menu-button"
        data-intercom-target="user-avatar"
      >
        <Avatar
          size="sm"
          name={currentUser.name}
          src={currentUser.avatar_url_medium}
        />
      </MenuButton>
      <Portal>
        <MenuList zIndex={Z_INDEX_MAIN_NAV_MENUS}>
          <MenuItem as={RoutedLink} to={ROUTES.SETTINGS.PROFILE.path}>
            <Box>
              <Text>{currentUser.name}</Text>
              <Text color="gray.500">{currentUser.email}</Text>
            </Box>
          </MenuItem>
          <MenuDivider my={0} />
          <MenuItem
            as={RoutedLink}
            to={ROUTES.SETTINGS.PROFILE.path}
            icon={<Icon as={UserCircleIcon} />}
          >
            Profile
          </MenuItem>
          {currentUser.role !== "admin" && featureAccessItem}
          {currentUser.role === "admin" && (
            <>
              <MenuItem
                as={RoutedLink}
                to={ROUTES.SETTINGS.ORGANIZATION.path}
                icon={<Icon as={Briefcase01OutlineIcon} />}
              >
                Organization
              </MenuItem>
              <MenuItem
                as={RoutedLink}
                to={ROUTES.SETTINGS.TEAM.path}
                icon={<Icon as={Users01OutlineIcon} />}
              >
                Team members
              </MenuItem>
              <MenuItem
                as={RoutedLink}
                to={ROUTES.SETTINGS.BILLING.path}
                icon={<Icon as={CreditCard02OutlineIcon} />}
              >
                Plan &amp; billing
              </MenuItem>
              {featureAccessItem}
              <MenuItem
                as={RoutedLink}
                to={ROUTES.SETTINGS.SECURITY.path}
                icon={<Icon as={LockClosedIcon} />}
              >
                Security
              </MenuItem>
            </>
          )}
          <MenuItem
            as={RoutedLink}
            to={ROUTES.LEGAL.TERMS.path}
            icon={<Icon as={ShieldCheckIcon} />}
          >
            Privacy &amp; terms
          </MenuItem>

          {showFeatureFlagLink && (
            <MenuItem
              onClick={showFeatureFlagModal}
              icon={<Icon as={Flag02OutlineIcon} />}
            >
              Feature flags
            </MenuItem>
          )}

          <MenuDivider my={0} />
          <MenuItem onClick={signout} icon={<Icon as={LogoutIcon} />}>
            Sign out
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}

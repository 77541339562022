import { Box, Flex, Spinner, Stack, Text } from "@chakra-ui/react"
import { HelpPopover } from "Shared/components/HelpPopover/HelpPopover"
import { pluralizeWithCount } from "Utilities/string"
import React from "react"
import { CreditPricePopover } from "./CreditPricePopover"
import { Quote, QuoteState } from "./types"

type Props = {
  quote: Quote["quote"] | null
  quoteState: QuoteState
}

export const QuoteTable: React.FC<Props> = ({ quote, quoteState }) => {
  const fromYourBalance = quote
    ? quote.credit_cost - quote.credits_to_be_purchased
    : 0

  const hasQuoteData = quote && (!("available" in quote) || quote.available)

  const quoteHasScreenerBreakdown = quote && "screener_cost" in quote

  const totalTestCost = quoteHasScreenerBreakdown
    ? quote.test_cost
    : quote?.credit_cost ?? 0
  const totalScreenerCost = quoteHasScreenerBreakdown ? quote.screener_cost : 0

  const singleResponseTestCost = quoteHasScreenerBreakdown
    ? quote.test_cost / quote.requested_response_count
    : quote?.credit_cost_per_response ?? 0
  const singleResponseScreenerCost = quoteHasScreenerBreakdown
    ? quote.screener_cost / quote.requested_response_count
    : 0

  return (
    <Stack color={quoteState === "loading" ? "gray.400" : "text.primary"}>
      {hasQuoteData && (
        <>
          <Flex
            justify="space-between"
            data-intercom-target="credit-cost-calculation"
          >
            <Box display="inline-flex" alignItems="center">
              {pluralizeResponses(quote.requested_response_count)} &times;{" "}
              {pluralizeCredits(singleResponseTestCost)}{" "}
              <HelpPopover>
                Based on the length of this test each response will cost{" "}
                {pluralizeCredits(singleResponseTestCost)}.
              </HelpPopover>
            </Box>
            <Text>{pluralizeCredits(totalTestCost)}</Text>
          </Flex>

          {totalScreenerCost > 0 && (
            <Flex
              justify="space-between"
              data-intercom-target="credit-cost-calculation"
            >
              <Box display="inline-flex" alignItems="center">
                Screener fee
                <HelpPopover>
                  Screeners incur an additional cost of{" "}
                  {pluralizeCredits(singleResponseScreenerCost)} per response.
                </HelpPopover>
              </Box>
              <Text>{pluralizeCredits(totalScreenerCost)}</Text>
            </Flex>
          )}

          <Flex
            justify="space-between"
            data-intercom-target="from-your-balance"
          >
            <Text>From your balance</Text>
            <Text>{pluralizeCredits(fromYourBalance)}</Text>
          </Flex>

          <Flex justify="space-between" data-intercom-target="credits-required">
            <Text>Credits required</Text>
            <Text>{pluralizeCredits(quote.credits_to_be_purchased)}</Text>
          </Flex>

          {quote.credits_to_be_purchased > 0 ? (
            <Flex justify="space-between">
              <Box display="inline-flex" alignItems="center">
                Cost per credit{" "}
                <HelpPopover>
                  <CreditPricePopover scale={quote.credits_scale} />
                </HelpPopover>
              </Box>
              <Text>{quote.price_per_credit}</Text>
            </Flex>
          ) : null}

          <Flex justify="space-between" data-intercom-target="total-cost">
            <Text>
              <strong>Total cost</strong>
            </Text>
            <Text>
              <strong>{quote.formatted_price}</strong>
            </Text>
          </Flex>
        </>
      )}

      {/* Show a spinner for the initial load, after that the old data continues to display but grayed out */}
      {quoteState === "loading" && !hasQuoteData && (
        <Stack justify="center" align="center" h="192px">
          <Spinner />
        </Stack>
      )}
    </Stack>
  )
}

const pluralizeResponses = (n: number) =>
  pluralizeWithCount(n, "response", "responses")

const pluralizeCredits = (n: number) =>
  pluralizeWithCount(n, "credit", "credits")

import {
  Box,
  Flex,
  Grid,
  Icon,
  Link,
  Spinner,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import { InfoCircleIcon } from "Shared/icons/InfoCircleIcon"
import React from "react"

import { ShareField } from "Components/share-field/share-field"
import Constants from "Constants/shared.json"
import { Button, Heading } from "DesignSystem/components"
import { RecruitmentLinkCompositeIcon } from "Shared/components/CompositeIcons/CompositeIcons"
import { CheckOutlineIcon } from "Shared/icons/untitled-ui/CheckOutlineIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import { PermittedDeviceType, RecruitmentLinkState } from "Types"
import {
  permittedDeviceTypeDescriptions,
  permittedDeviceTypeIcons,
} from "Utilities/device-types"
import { recruitmentLinkStateWithRecruitmentLink } from "Utilities/recruitment-linkable"
import type { RecruitmentLink } from "~/api/generated/usabilityhubSchemas"
import { TEST_ORDER_GRID_COLUMNS } from "./cardGridStyles"

const friendlyLinkState = (state: RecruitmentLinkState) => {
  switch (state) {
    case RecruitmentLinkState.Disabled:
      return "Link disabled"
    case RecruitmentLinkState.Collecting:
      return "Collecting"
    case RecruitmentLinkState.Complete:
      return "Complete"
    case RecruitmentLinkState.Canceled:
      return "Canceled"
  }
}

const linkStateIcon = (state: RecruitmentLinkState) => {
  switch (state) {
    case RecruitmentLinkState.Collecting:
      return <Spinner size="xs" />
    case RecruitmentLinkState.Canceled:
      return <Icon as={XOutlineIcon} color="red.500" />
    case RecruitmentLinkState.Complete:
      return <Icon as={CheckOutlineIcon} color="green.500" />
    default:
      return null
  }
}

interface Props {
  showScreenInRate: boolean
  recruitedResponseCount: number
  screenedOutResponseCount: number
  recruitmentLink: RecruitmentLink | null
  deviceType: PermittedDeviceType | null
  onManageLink?: () => void
}

export const RecruitmentLinkSummaryCard: React.FC<Props> = ({
  showScreenInRate,
  recruitedResponseCount,
  screenedOutResponseCount,
  deviceType,
  recruitmentLink,
  onManageLink,
}) => {
  if (
    recruitmentLink === null ||
    (!recruitmentLink.active && recruitedResponseCount === 0)
  )
    return null

  const linkState = recruitmentLinkStateWithRecruitmentLink(
    recruitedResponseCount,
    recruitmentLink
  )

  return (
    <Box bg="ds.surface.raised.resting" rounded="16px" shadow="ds.raised">
      <Grid
        w="100%"
        templateColumns={TEST_ORDER_GRID_COLUMNS}
        alignItems="center"
        gap={6}
        p={6}
      >
        <Flex gap={4}>
          <RecruitmentLinkCompositeIcon />

          <Stack spacing={3} flexGrow="1">
            <Heading as="h3" textStyle="ds.heading.primary">
              Test link
            </Heading>
            <Flex maxW="25rem">
              <ShareField text={recruitmentLink.url || ""} />
            </Flex>

            <Text
              color="ds.text.subtle"
              textStyle="ds.paragraph.secondary"
              display={["none", null, "block"]}
            >
              Include additional information using{" "}
              <Link
                variant="noUnderline"
                target="_blank"
                href={Constants.HELP_CENTER_QUERY_PARAMETERS_URL}
              >
                custom variables
              </Link>
            </Text>
          </Stack>
        </Flex>

        <Flex align="center">
          {linkStateIcon(linkState)}
          <Stack spacing={1} ml={3}>
            <Flex align="center" gap={2}>
              <Text textStyle="ds.heading.secondary">
                {recruitedResponseCount}
                {recruitmentLink.enable_response_limit
                  ? ` of ${recruitmentLink.response_limit!} `
                  : " "}
                responses
              </Text>
              <Tooltip label="The number of screened-in responses collected with this link.">
                <Icon
                  as={InfoCircleIcon}
                  color="icon.subtle"
                  boxSize="0.875rem"
                />
              </Tooltip>
            </Flex>

            <Text textStyle="ds.paragraph.primary">
              {friendlyLinkState(linkState)}
            </Text>
          </Stack>
        </Flex>

        {showScreenInRate && (
          <ScreenInRate
            recruitedResponseCount={recruitedResponseCount}
            screenedOutResponseCount={screenedOutResponseCount}
          />
        )}

        <Button
          display={["none", null, "inline-flex"]}
          variant="primary"
          size="emphasized"
          isDisabled={!onManageLink}
          onClick={onManageLink}
          gridColumn="3 / 5"
          justifySelf="flex-end"
        >
          Manage
        </Button>

        {deviceType && deviceType !== PermittedDeviceType.Any ? (
          <Flex align="center" color="gray.500">
            <Icon as={permittedDeviceTypeIcons[deviceType]} />
            <Text fontSize="sm" ml={2}>
              {permittedDeviceTypeDescriptions[deviceType]}
            </Text>
          </Flex>
        ) : null}
      </Grid>
    </Box>
  )
}

const ScreenInRate: React.FC<{
  recruitedResponseCount: number
  screenedOutResponseCount: number
}> = ({ recruitedResponseCount, screenedOutResponseCount }) => {
  const total = recruitedResponseCount + screenedOutResponseCount
  const actualScreenInRate =
    total <= 0 ? 0 : (recruitedResponseCount / total) * 100

  return (
    <VStack alignItems="left">
      <Flex alignItems="center" gap={2}>
        <Text fontWeight="semibold">Screen-in rate</Text>
        <Tooltip label="The actual percentage of participants that met your screener criteria.">
          <Icon as={InfoCircleIcon} color="icon.subtle" boxSize="0.875rem" />
        </Tooltip>
      </Flex>
      <Text>{actualScreenInRate.toFixed()}%</Text>
    </VStack>
  )
}

import { Box, HStack, Select, Stack, Text } from "@chakra-ui/react"
import React from "react"
import {
  Field,
  FieldArray,
  WrappedFieldArrayProps,
  WrappedFieldProps,
} from "redux-form"

import { getSectionTypeRules } from "Constants/test-section-types"
import { ScreenshotMediaType } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import {
  useDeviceFramesContext,
  useSectionContext,
  useSectionIndexContext,
  useSectionTypeContext,
} from "UsabilityHub/contexts"
import { pluralize } from "Utilities/string"

import { FormFieldError } from "../FormFieldError"

import { MediaFileInputButton } from "./MediaFileInputButton"
import { SectionMediaDropZone } from "./SectionMediaDropZone"
import { SectionMediaEditor } from "./SectionMediaEditor"

interface SectionMediaProps {
  allowedMediaTypes: ScreenshotMediaType[]
}

const WrappedSectionMedia: React.FC<
  SectionMediaProps & WrappedFieldArrayProps
> = ({ allowedMediaTypes, meta }) => {
  const { section } = useSectionContext()
  const sectionType = useSectionTypeContext()
  const { readOnly } = useUsabilityTestUserActivityContext()
  const sectionMediaCount = section.section_screenshots.length
  const maxFileCount = getSectionTypeRules(sectionType).maxScreenshots
  const remainingFileCount = maxFileCount - sectionMediaCount
  const canAddAnotherFile =
    !readOnly && sectionMediaCount > 0 && remainingFileCount > 0

  return (
    <Stack spacing={5}>
      <HStack justifyContent="space-between">
        <Text as="label" textStyle="label" flexGrow={1}>
          {pluralize(maxFileCount, "Design", "Designs")}
        </Text>
        <DeviceFrameField />
      </HStack>
      <Box>
        {sectionMediaCount === 0 ? (
          <SectionMediaDropZone
            allowedMediaTypes={allowedMediaTypes}
            maxFiles={remainingFileCount}
          />
        ) : (
          <SectionMediaEditor />
        )}
        {canAddAnotherFile && (
          <MediaFileInputButton
            alignSelf="flex-start"
            allowedMediaTypes={allowedMediaTypes}
            maxFiles={remainingFileCount}
          >
            Add another file
          </MediaFileInputButton>
        )}
        <FormFieldError {...meta} />
      </Box>
    </Stack>
  )
}

export const SectionMedia: React.FC<
  React.PropsWithChildren<SectionMediaProps>
> = (props) => {
  const sectionIndex = useSectionIndexContext()
  const sectionPath = `sections[${sectionIndex}]`
  return (
    <FieldArray
      name={`${sectionPath}.section_screenshots`}
      component={WrappedSectionMedia}
      props={props}
    />
  )
}

const DeviceFrameSelect: React.FC<
  React.PropsWithChildren<WrappedFieldProps>
> = ({ input }) => {
  const deviceFrames = useDeviceFramesContext()
  const { readOnly } = useUsabilityTestUserActivityContext()
  const options = [{ id: "", name: "No frame" }, ...deviceFrames]
  return (
    <HStack>
      <Text as="label" textStyle="label" htmlFor={input.name} flexShrink={0}>
        Device frame
      </Text>
      <Select isDisabled={readOnly} size="sm" {...input}>
        {options.map((deviceFrame) => {
          return (
            <option key={deviceFrame.id} value={deviceFrame.id}>
              {deviceFrame.name}
            </option>
          )
        })}
      </Select>
    </HStack>
  )
}
const DeviceFrameField: React.FC = () => {
  const sectionIndex = useSectionIndexContext()
  const sectionPath = `sections[${sectionIndex}]`
  return (
    <Field
      name={`${sectionPath}.device_frame_id`}
      component={DeviceFrameSelect}
      parse={(frameId: string) => (frameId ? Number(frameId) : null)} // Cast to number so we can === with IDs in the store
    />
  )
}

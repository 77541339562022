import { Icon, Spinner } from "@chakra-ui/react"
import { CheckOutlineIcon } from "Shared/icons/untitled-ui/CheckOutlineIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import React from "react"

import { UsdIcon } from "Shared/icons/UsdIcon"
import { ThirdPartyOrderState } from "Types"
import { OrderState } from "~/api/generated/usabilityhubSchemas"

interface Props {
  state: OrderState | ThirdPartyOrderState
}

export const OrderStateIcon: React.FC<React.PropsWithChildren<Props>> = ({
  state,
}) => {
  switch (state) {
    case "awaiting_payment":
      return <Icon as={UsdIcon} />
    case "complete":
      return <Icon as={CheckOutlineIcon} color="green.500" />
    case "canceled":
      return <Icon as={XOutlineIcon} color="red.500" />
    case "active":
    case "tentatively_complete":
    case "awaiting_placement":
    case "complete_pending_review":
    case "replacements_required":
      return <Spinner size="xs" />
  }
}

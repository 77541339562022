import { Flex, Td, Text, Th, Tr } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import React from "react"

export const DemographicTargetSummaryTableRow: React.FC<{
  heading: string
  value: React.ReactNode
  isSubHeading?: boolean
  hasTags?: boolean
}> = ({ heading, value, isSubHeading, hasTags }) => {
  const valueContent =
    typeof value === "string" ? (
      <Text as="span" textStyle="ds.paragraph.emphasized">
        {value}
      </Text>
    ) : (
      value
    )

  return (
    <Tr>
      <Th>
        <Heading
          as="h4"
          textStyle={
            isSubHeading ? "ds.heading.secondary" : "ds.heading.primary"
          }
        >
          {heading}
        </Heading>
      </Th>
      <Td>
        {hasTags ? (
          <Flex wrap="wrap" gap={2}>
            {valueContent}
          </Flex>
        ) : (
          valueContent
        )}
      </Td>
    </Tr>
  )
}

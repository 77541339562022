import { UniqueIdentifier } from "@dnd-kit/core"
import { get, isArray, isPlainObject, keyBy, keys, uniqueId } from "lodash"
import { arrayInsert, arrayMove, arrayRemove } from "redux-form"

import { State } from "Redux/app-store"
import {
  getFormName,
  getFormValues,
} from "Redux/reducers/test-builder-form/selectors/formValues"
import {
  QuestionType,
  RawUsabilityTest,
  Screenshot,
  TestLogicStatement,
  ThunkAction,
  TreeTest,
  UsabilityTest,
  UsabilityTestSection,
  UsabilityTestSectionQuestion,
  UsabilityTestSectionType,
} from "Types"
import { getClientId, nextClientId } from "Utilities/client-id"

export function addUsabilityTestClientFields(
  usabilityTest: Readonly<RawUsabilityTest>,
  screenshots: ReadonlyArray<Readonly<Screenshot>>
): UsabilityTest {
  const screenshotsById = keyBy(screenshots, "id")

  const testWithClientFields = {
    ...usabilityTest,
    tests_by_creator_count: 1,
    sections: usabilityTest.sections.map((section) => {
      return {
        ...section,
        _clientId: nextClientId(),
        section_screenshots: section.section_screenshots.map((ss) => ({
          ...ss,
          _clientId: nextClientId(),
          _screenshotClientId: getClientId(screenshotsById[ss.screenshot_id]),
          screenshot_hitzones: ss.screenshot_hitzones.map((hitzone) => ({
            ...hitzone,
            _clientId: nextClientId(),
          })),
        })),
        questions: section.questions.map((question) => ({
          ...question,
          _clientId: nextClientId(),
        })),
      }
    }),
  }

  return addTargetClientIdsToTestLogicStatements(testWithClientFields)
}

// Since clientIds are generated on the FE we need to map existing TLS
// to the relevant field, we should only need to do this once on init
const addTargetClientIdsToTestLogicStatements = (
  usabilityTest: UsabilityTest
): UsabilityTest => {
  const targets = {
    UsabilityTestSection: keyBy(usabilityTest.sections, "id"),
    UsabilityTestSectionQuestion: keyBy(
      usabilityTest.sections.flatMap((section) => section.questions),
      "id"
    ),
  }

  const addTargetClientIdToTestLogicStatement = (
    testLogicStatement: TestLogicStatement
  ): TestLogicStatement => {
    if (testLogicStatement.target_id && testLogicStatement.target_type) {
      const target =
        targets[testLogicStatement.target_type][testLogicStatement.target_id]
      return {
        ...testLogicStatement,
        _targetClientId: target ? target._clientId : null,
      }
    }
    return testLogicStatement
  }

  return {
    ...usabilityTest,
    sections: usabilityTest.sections.map((section) => ({
      ...section,
      test_logic_statement:
        section.test_logic_statement &&
        addTargetClientIdToTestLogicStatement(section.test_logic_statement),
      questions: section.questions.map((question) => ({
        ...question,
        test_logic_statement:
          question.test_logic_statement &&
          addTargetClientIdToTestLogicStatement(question.test_logic_statement),
      })),
    })),
  }
}

export function isPersisted(maybePersisted: { id: number | null }): boolean {
  return maybePersisted.id != null
}

export function stripTestLogicStatement(
  section: UsabilityTestSection
): UsabilityTestSection {
  return {
    ...section,
    questions: section.questions.map((question) => ({
      ...question,
      test_logic_statement: null,
    })),
    test_logic_statement: null,
  }
}

// Replaces all keys id with new client IDs
export const replaceIds = (value: any) => {
  let newValue = value

  if (isPlainObject(value)) {
    newValue = keys(value).reduce<any>((acum, key) => {
      if (key === "id") {
        acum[key] = null
      } else if (key === "_clientId") {
        acum[key] = nextClientId()
      } else {
        acum[key] = replaceIds(value[key])
      }
      return acum
    }, {})

    if (!newValue._clientId) {
      newValue._clientId = nextClientId()
    }
  } else if (isArray(value)) {
    newValue = value.map((v) => replaceIds(v))
  }

  return newValue
}

// TODO: Create UnpersistedUsabilityTestSectionQuestion
export function createQuestion(
  type: UsabilityTestSectionType
): UsabilityTestSectionQuestion {
  return {
    id: null,
    text: "",
    type:
      type === UsabilityTestSectionType.PrototypeTask
        ? QuestionType.LinearScale
        : QuestionType.ShortAnswer,
    randomized: false,
    required: false,
    multiple_choice_options: ["", ""],
    archived: false,
    _clientId: nextClientId(),
    max_selected_options: null,
    min_selected_options: null,
    min_value: 1,
    max_value: 5,
    position: 0,
    test_logic_statement: null,
  } as any as UsabilityTestSectionQuestion
}

export function cloneQuestion(
  question: UsabilityTestSectionQuestion
): UsabilityTestSectionQuestion {
  return {
    ...question,
    test_logic_statement: question.test_logic_statement
      ? { ...question.test_logic_statement, id: null }
      : null,

    id: null,
    _clientId: nextClientId(),
  } as any as UsabilityTestSectionQuestion
}

export const moveElementToOtherArray =
  (
    fromPath: string,
    fromIndex: number,
    toPath: string,
    toIndex: number
  ): ThunkAction<State> =>
  (dispatch, getState) => {
    if (fromPath === toPath) {
      dispatch(arrayMove(getFormName(), fromPath, fromIndex, toIndex))
    } else {
      const element = get(
        getFormValues(getState()),
        `${fromPath}[${fromIndex}]`
      )
      dispatch(arrayRemove(getFormName(), fromPath, fromIndex))
      dispatch(arrayInsert(getFormName(), toPath, toIndex, element))
    }
  }

export const duplicateTreeTestAttributes = (treeTestAttributes: TreeTest) => {
  const newIds = new Map<UniqueIdentifier, UniqueIdentifier>()

  const newNodes = treeTestAttributes.nodes.map((node) => {
    const id = uniqueId("new_")
    newIds.set(node.id, id)
    return {
      ...node,
      id,
      parent_id: (node.parent_id && newIds.get(node.parent_id)) || null,
    }
  })

  return {
    nodes: newNodes,
    correct_nodes: treeTestAttributes.correct_nodes
      ? (treeTestAttributes.correct_nodes
          .map((id) => newIds.get(id))
          .filter(Boolean) as UniqueIdentifier[])
      : [],
  }
}

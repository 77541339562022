import React from "react"

import { Link, Text } from "@chakra-ui/react"
import { Banner } from "Components/banner/banner"
import { Link as RouterLink } from "react-router-dom"
import SettingsApi from "~/api/SettingsApi"

interface Props {
  currentUserIsAdmin: boolean
  planName: string
}

export const StripeSubscriptionPastDueBanner: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ currentUserIsAdmin, planName }) => {
  return (
    <Banner type="warning">
      <Text>
        We are not able to bill your credit card for your subscription to the{" "}
        {planName} plan.{" "}
        {currentUserIsAdmin && (
          <>
            Visit your{" "}
            <Link as={RouterLink} to={SettingsApi.billing.path()}>
              billing settings page
            </Link>{" "}
            to resolve this issue.
          </>
        )}
        {!currentUserIsAdmin && (
          <>
            Ask an account admin to visit the billing settings page to resolve
            this issue.
          </>
        )}
      </Text>
    </Banner>
  )
}

import {
  Alert,
  AlertIcon,
  Box,
  Link as ChakraLink,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react"
import { PageMain } from "Shared/components/Page/Page"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { useTypedParams } from "react-router-typesafe-routes/dom"
import {
  useGetNewOrderPage,
  useListDemographics,
} from "~/api/generated/usabilityhub-components"
import { ROUTES } from "../routes"
import { DemographicsPanel } from "./DemographicsPanel"
import { NoOfPanelistsSection } from "./NoOfPanelistsSection"
import { OrderFormProvider } from "./OrderFormProvider"
import { OrderSummaryPanel } from "./OrderSummaryPanel"
import { DEFAULT_NUM_PARTICIPANTS, RESPONSE_SCALE } from "./constants"
import { Panel } from "./types"

/**
 * Note:
 *
 * You can use the `enable_submit=true` query param to force the submit
 * button to be enabled for local dev purposes.
 */
export const NewOrderPage: React.FC = () => {
  const { testId: usabilityTestUniqueId } = useTypedParams(ROUTES.TEST.RECRUIT)
  const [panel, setPanel] = useState<Panel>("usabilityhub")

  const {
    data: newOrderPageData,
    isLoading: isNewOrderPageLoading,
    isError: isNewOrderPageError,
  } = useGetNewOrderPage({
    pathParams: { usabilityTestId: usabilityTestUniqueId },
  })

  const {
    data: demographics,
    isLoading: isDemographicsLoading,
    isError: isDemographicsError,
  } = useListDemographics({})

  if (isDemographicsLoading || isNewOrderPageLoading) {
    return (
      <Flex mt="32" justifyContent="center" alignItems="center">
        <Helmet>
          <title>Order responses</title>
        </Helmet>
        <Spinner />
      </Flex>
    )
  }

  if (isDemographicsError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <Text fontWeight="bold">
          There was an error loading the demographics. Try refreshing the page.
        </Text>
      </Alert>
    )
  }

  if (isNewOrderPageError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <Text fontWeight="bold">
          There was an error loading the test. Try refreshing the page.
        </Text>
      </Alert>
    )
  }

  return (
    <PageMain>
      <Stack align="center">
        <Stack align="center" my={10}>
          <Heading fontSize="2xl" fontWeight="normal" mb={2}>
            Order responses from the Lyssna Panel
          </Heading>
          <ChakraLink
            as={Link}
            variant="noUnderline"
            to={ROUTES.TEST.RECRUIT.buildPath({
              testId: usabilityTestUniqueId,
            })}
          >
            &larr; Back to recruitment options
          </ChakraLink>
        </Stack>

        <OrderFormProvider
          context="usability_tests"
          demographics={demographics}
          testHasScreener={newOrderPageData.has_screener}
          testHasRecordings={newOrderPageData.has_recordings}
          defaultNumberOfPanelists={DEFAULT_NUM_PARTICIPANTS}
        >
          <SimpleGrid
            w={["90%", null, "min(90vw, 928px)"]}
            columns={[1, null, 2]}
            gap={4}
            pb={10}
          >
            <Flex direction="column" gap={4}>
              <Box
                borderWidth="1px"
                bg="white"
                borderColor="gray.200"
                borderRadius="md"
              >
                <NoOfPanelistsSection responseScale={RESPONSE_SCALE}>
                  Number of participants
                </NoOfPanelistsSection>
              </Box>

              <Box
                borderWidth="1px"
                bg="white"
                borderColor="gray.200"
                borderRadius="md"
              >
                <DemographicsPanel
                  showPresets
                  demographics={demographics}
                  activePanel={panel}
                />
              </Box>
            </Flex>

            <OrderSummaryPanel
              demographics={demographics}
              panel={panel}
              setPanel={setPanel}
            />
          </SimpleGrid>
        </OrderFormProvider>
      </Stack>
    </PageMain>
  )
}

import Constants from "Constants/shared.json"
import { Features } from "Types"
import { Plan } from "~/api/generated/usabilityhubSchemas"

export const TEST_BUILDER_FEATURES: Array<{
  label: (plan: Plan) => string | null
  tooltip?: string
  feature: Extract<
    Features[number]["feature"],
    | "max_test_duration"
    | "test_logic"
    | "custom_welcome_and_thankyou_messages"
    | "recordings"
  >
  identifier:
    | Extract<
        Features[number]["feature"],
        "max_test_duration" | "test_logic" | "recordings"
      >
    | "custom_welcome"
    | "custom_thankyou"
  minRequiredPlan?: string
}> = [
  {
    label: (plan) =>
      plan.max_test_duration
        ? `Tests up to ${plan.max_test_duration} mins`
        : "︎Unlimited test duration",
    tooltip: "Your test length exceeds 5 mins",
    feature: "max_test_duration",
    identifier: "max_test_duration",
  },
  {
    label: (plan) => (plan.recordings ? "Recordings" : null),
    tooltip: "Your test has recording enabled",
    feature: "recordings",
    identifier: "recordings",
  },
  {
    label: (plan) => (plan.test_logic ? "Conditional logic" : null),
    tooltip: "Conditional logic is not included in Basic plan",
    feature: "test_logic",
    identifier: "test_logic",
  },
  {
    label: (plan) =>
      plan.custom_welcome_and_thankyou_messages
        ? "︎Custom welcome screen"
        : null,
    feature: "custom_welcome_and_thankyou_messages",
    identifier: "custom_welcome",
    minRequiredPlan: Constants.BASIC_V3_MONTHLY_PLAN_UNIQUE_ID,
  },
  {
    label: (plan) =>
      plan.custom_welcome_and_thankyou_messages
        ? "︎︎Custom thank you screen"
        : null,
    feature: "custom_welcome_and_thankyou_messages",
    identifier: "custom_thankyou",
    minRequiredPlan: Constants.BASIC_V3_MONTHLY_PLAN_UNIQUE_ID,
  },
]

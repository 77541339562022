import { Application } from "./ParticipantInfoDrawerData"

type DeclinableStatus = Exclude<
  Application["status"],
  | "booked"
  | "invited"
  | "complete"
  | "auto_complete"
  | "no_show"
  | "declined_by_applicant"
  | "declined_by_researcher"
  | "declined_by_automatic"
  | "canceled"
  | "reported"
  | "reschedule_requested"
  | "invitation_revoked"
>

// when an application has one of these statuses, the researcher will see an
// enabled "decline" button for them, but not otherwise
const DECLINABLE_STATUSES = ["applied"] as const

export const isDeclinableStatus = (
  status: Application["status"] | undefined
): status is DeclinableStatus => {
  // if a new status is added to the Application["status"] union, we want
  // TypeScript to complain until it's explicitly listed above as either an
  // excluded status or one of the DECLINABLE_STATUSES so that all statuses are
  // correctly handled
  return DECLINABLE_STATUSES.includes(status as DeclinableStatus)
}

import { Box, Button, Flex, Link } from "@chakra-ui/react"
import { RailsForm } from "Components/form/form"
import { State } from "Redux/app-store"
import { pluralizeWithCount } from "Utilities/string"
import { formatDistanceToNow } from "date-fns/esm"
import { compact, uniq } from "lodash"
import React, { PropsWithChildren } from "react"
import { useSelector } from "react-redux"
import OrdersApi from "~/api/Admin/OrdersApi"
import PayoutsApi from "~/api/Admin/PayoutsApi"
import ThirdPartyOrdersApi from "~/api/Admin/ThirdPartyOrdersApi"

type Props = {
  isThirdPartyOrder: boolean
  orderId: number
}

export const ReviewingBanner: React.FC<Props> = ({
  isThirdPartyOrder,
  orderId,
}) => {
  const data = useSelector((state: State) => state.testResults)

  const responseSectionsWithRecording = data?.responseSectionRecordings.map(
    (item) => item.response_section_id
  )
  const usabilityTestSectionsWithRecording = data?.responseSections.map((rs) =>
    responseSectionsWithRecording?.includes(rs.id)
      ? rs.usability_test_section_id
      : null
  )

  const noOfTestSectionWithRecording = compact(
    uniq(usabilityTestSectionsWithRecording)
  ).length

  if (!data) return null

  const isRevising = data.isRevising
  const responseCount = data.responses.length
  const totalResponses = data.responsesCount

  const { allReviewerNames, reviewerName, reviewerIsSelf, reviewerAssignedAt } =
    data.reviewInfo! // This should never be null on the review page

  const orderLink = isThirdPartyOrder
    ? ThirdPartyOrdersApi.get.path({ id: orderId })
    : OrdersApi.get.path({ id: orderId })

  return (
    <Flex pos="fixed" top={0} left={0} width="full" justify="center" zIndex="1">
      <Flex
        width="800px"
        bg="white"
        border="1px solid #329af0"
        p={1}
        direction="column"
      >
        <Box textAlign="center">
          {isRevising ? "Revising" : "Reviewing"} {responseCount} /{" "}
          {totalResponses} responses from{" "}
          <Link href={orderLink}>order {orderId}</Link>.{" "}
          {reviewerName && reviewerAssignedAt && (
            <>
              Assigned to {reviewerIsSelf ? "you" : reviewerName}{" "}
              {formatDistanceToNow(Date.parse(reviewerAssignedAt))} ago.{" "}
            </>
          )}
          {isRevising ? (
            <>
              Reviewers involved: {allReviewerNames.join(", ")} You can override
              the review below.
            </>
          ) : isThirdPartyOrder ? (
            <>
              {!isRevising && (
                <Link
                  href={
                    ThirdPartyOrdersApi.reviewResponses.path({
                      id: orderId,
                    }) + "?revise=true"
                  }
                >
                  Revise reviewed responses
                </Link>
              )}{" "}
              <Link href={PayoutsApi.orders.path()}>
                Back to order review queue.
              </Link>
            </>
          ) : (
            <>
              <PostLink
                action={PayoutsApi.cancelOrderReview.path({
                  id: orderId,
                })}
              >
                Unassign {reviewerIsSelf ? "me" : "them"}
              </PostLink>

              {!reviewerIsSelf && (
                <PostLink
                  action={PayoutsApi.cancelOrderReview.path({
                    id: orderId,
                    query: {
                      reassign: true,
                    },
                  })}
                >
                  Reassign to me
                </PostLink>
              )}
            </>
          )}
        </Box>
        {noOfTestSectionWithRecording > 0 && (
          <Box textAlign="center">
            This test contains{" "}
            {pluralizeWithCount(
              noOfTestSectionWithRecording,
              "recorded section",
              "recorded sections"
            )}
            . Please review each video/audio recording for quality.
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

type PostLinkProps = { action: string }

const PostLink: React.FC<PropsWithChildren<PostLinkProps>> = ({
  action,
  children,
}) => {
  return (
    <RailsForm action={action} method="POST" style={{ display: "inline" }}>
      <Button type="submit" variant={"link"}>
        {children}
      </Button>
    </RailsForm>
  )
}

import { memoize } from "lodash"

import { getJsonFromScriptElement } from "Utilities/get-json-from-script-element"

// Transforms state from server into local JS data. This is a very simple module
// because front end responsibilities are small.

export interface ENVState {
  ASSET_HOST: string
  HOSTNAME: string
  JS_SEGMENT_WRITE_KEY: string
  LYSSNA_INTERCOM_APP_ID: string
  USERCROWD_INTERCOM_APP_ID: string
  APP_DOMAIN: string
  APP_OLD_DOMAIN: string
  GOOGLE_SIGN_IN_ENABLED: boolean
  GOOGLE_CLIENT_ID: string
  ORTTO_TRACKING_CODE: string
  ORTTO_TRACKING_API_URL: string
  ORTTO_TRACKING_CDN_URL: string
  PANELIST_DOMAIN: string
  PANELIST_ORDER_ASSIGNMENT_POLL_INTERVAL_IN_SECONDS: number
  RELEASE: string
  S3_RECORDINGS: boolean
  SENTRY_ENABLED: boolean
  SENTRY_FRONTEND_DSN: string
  STRIPE_SCRIPT_URI: string
  STRIPE_PUBLIC_KEY: string
  VAPID_PUBLIC_KEY: number
  FIGMA_DOMAIN: string
  CLIENT_VERSION: string
  GOOGLE_PLACES_API_KEY: string
  GOOGLE_PLACES_DOMAIN: string
  ANYONE_CAN_EDIT_THEIR_FEATURE_FLAGS: boolean
  LATEST_VERSION: string
}

export const getEnvState = memoize(() => {
  const envState = getJsonFromScriptElement<ENVState | null>("env", null)
  if (envState === null) {
    throw Error("No env set")
  }
  return envState
})

interface ApplicationState {
  isEmulatingUser: boolean
  recaptchaSiteKey: string | null
  resetPasswordToken: string | null
}

export const getApplicationState = memoize(() => {
  const applicationState = getJsonFromScriptElement<ApplicationState | null>(
    "application_state",
    null
  )

  if (applicationState === null) {
    throw Error("No application state set")
  }
  return applicationState
})

import {
  Box,
  Flex,
  Icon,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import { DisplayBlockMarkdownText } from "Components/display-markdown-text/display-markdown-text"
import React, { PropsWithChildren } from "react"

// These are legacy icons but we use them here because they match the ones in
// app/frontend/application/javascripts/components/question-type-icon/index.tsx
import { CheckboxIcon } from "Shared/icons/CheckboxIcon"
import { LongTextIcon } from "Shared/icons/LongTextIcon"
import { RadioIcon } from "Shared/icons/RadioIcon"
import { ShortTextIcon } from "Shared/icons/ShortTextIcon"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"

import { ScreenerQuestion } from "~/api/generated/usabilityhubSchemas"
import { ScreenerAnswer } from "./ParticipantInfoDrawerData"

export interface Props {
  screenerQuestion: ScreenerQuestion
  screenerAnswer: ScreenerAnswer
  questionIndex: number
}

export const ParticipantDrawerScreenerAnswer = ({
  screenerQuestion,
  screenerAnswer,
  questionIndex,
}: Props) => {
  return (
    <ScreenerAnswerContainer
      {...{ screenerAnswer, screenerQuestion }}
      questionIndex={questionIndex}
    >
      {isChoiceAnswer(screenerAnswer) ? (
        screenerAnswer.type === "multi_select" ? (
          <UnorderedList ps={3}>
            {screenerAnswer.selected_options?.map((n) => (
              <ListItem key={n.screener_question_option_id}>
                <Flex alignItems="center" gap={2}>
                  <Text as="span">
                    {
                      screenerQuestion.options?.find(
                        (q) => q.id === n.screener_question_option_id
                      )?.value
                    }
                  </Text>
                  {n.rule === "qualify" && (
                    <CheckCircleSolidIcon color={"green.400"} />
                  )}
                </Flex>
              </ListItem>
            ))}
          </UnorderedList>
        ) : (
          // single select
          screenerAnswer.selected_options?.map((n) => (
            <Flex
              alignItems="center"
              gap={2}
              key={n.screener_question_option_id}
            >
              <Text as="span">
                {
                  screenerQuestion.options?.find(
                    (q) => q.id === n.screener_question_option_id
                  )?.value
                }
              </Text>
              {n.rule === "qualify" && (
                <CheckCircleSolidIcon color={"green.400"} />
              )}
            </Flex>
          ))
        )
      ) : (
        // short_text or long_text
        <Text>{screenerAnswer.answer}</Text>
      )}
    </ScreenerAnswerContainer>
  )
}

const ScreenerAnswerContainer: React.FC<
  {
    screenerQuestion: ScreenerQuestion
    screenerAnswer: ScreenerAnswer
    questionIndex: number
  } & PropsWithChildren
> = ({ screenerQuestion, screenerAnswer, questionIndex, children }) => (
  <Box px={2} color="text.primary">
    <Flex gap={2} mb={3} color="text.secondary" alignItems="center">
      <ScreenerQuestionTypeIcon type={screenerAnswer.type} />
      <Text as="span" fontSize="sm">
        {questionIndex}. {getScreenerQuestionTypeLabel(screenerAnswer.type)}
      </Text>
    </Flex>
    <DisplayBlockMarkdownText text={screenerQuestion.text} />
    <Box mt={2} mb={8} color="text.secondary">
      {children}
    </Box>
  </Box>
)

type ChoiceAnswer = Extract<ScreenerAnswer, { selected_options: any }>

const isChoiceAnswer = (answer: ScreenerAnswer): answer is ChoiceAnswer => {
  return "selected_options" in answer
}

const ScreenerQuestionTypeIcon: React.FC<{ type: ScreenerAnswer["type"] }> = ({
  type,
}) => {
  const icon = {
    short_text: ShortTextIcon,
    long_text: LongTextIcon,
    single_select: RadioIcon,
    multi_select: CheckboxIcon,
  }[type]

  return <Icon as={icon} boxSize={3.5} color="gray.400" />
}

export const getScreenerQuestionTypeLabel: React.FC<string> = (type) =>
  ({
    short_text: "Short text question",
    long_text: "Long text question",
    single_select: "Single Choice question",
    multi_select: "Multi Choice question",
  })[type]

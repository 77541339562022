import { Button, Flex, FlexProps, Icon } from "@chakra-ui/react"
import { ArrowLeftIcon } from "@heroicons/react/solid"
import { useCurrentUser } from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import { usePlanChangerContextOnScreen } from "./plan-changer-context"

const HUBSPOT_EMBED_CODE =
  "https://meetings.hubspot.com/daniel965/salesteam-rr-enterprise-pricing-cta?embed=true"

export const EnterpriseContact: React.FC<FlexProps> = (props) => {
  const { dispatch } = usePlanChangerContextOnScreen("enterprise-contact")
  const currentUser = useCurrentUser()

  const embedUrl =
    HUBSPOT_EMBED_CODE + (currentUser ? `&email=${currentUser.email}` : "")

  return (
    <Flex direction="column" align="flex-start" h="full" {...props}>
      <Button
        variant="ghost"
        color="brand.primary.500"
        leftIcon={<Icon as={ArrowLeftIcon} />}
        onClick={() => dispatch({ type: "back-to-grid" })}
      >
        All plans
      </Button>

      <iframe
        title="Enterprise contact form"
        style={{ flexGrow: 1 }}
        width="100%"
        height="100%"
        src={embedUrl}
      />
    </Flex>
  )
}

import classNames from "classnames"
import React from "react"

import styles from "Components/assignment-invitation/assignment-invitation.module.scss"
import { LinkButton } from "Components/button/link-button"
import { CreditIcon } from "Shared/icons/CreditIcon"
import { ClockOutlineIcon } from "Shared/icons/untitled-ui/ClockOutlineIcon"
import { InvitationSource } from "Types"
import {
  centsToDollars,
  creditsToCents,
  formatDollars,
} from "Utilities/currency"
import { formatMinutes } from "Utilities/duration"
import { capitalizeFirstLetter } from "Utilities/string"
import TestInterfaceApi from "~/api/TestInterfaceApi"
import { ListUsercrowdOrderAssignmentsResponse } from "~/api/generated/usabilityhub-components"

interface Props {
  readonly assignment: ListUsercrowdOrderAssignmentsResponse["panelist_order_assignments"][0]
  readonly source: InvitationSource
}

export function AssignmentInvitation({ assignment, source }: Props) {
  const moneyValue = formatDollars(
    centsToDollars(creditsToCents(assignment.credit_value))
  )

  return (
    <div className={styles.invitation}>
      <ul className={styles.invitationDetailsList}>
        <li>
          <span className={classNames(styles.icon, styles.timeIcon)}>
            <ClockOutlineIcon />
          </span>
          {capitalizeFirstLetter(
            formatMinutes(assignment.estimated_duration_minutes)
          )}
        </li>
        <li>
          <span className={classNames(styles.icon, styles.creditsIcon)}>
            <CreditIcon />
          </span>
          Earns {moneyValue}
        </li>
      </ul>
      <LinkButton
        colorScheme="brand.primary"
        href={TestInterfaceApi.doOrderedTest.path({
          panelist_order_assignment_id: assignment.id,
          query: {
            source,
          },
        })}
      >
        Take the test
      </LinkButton>
    </div>
  )
}

import { Box, ChakraProps, Grid, Text, Tooltip } from "@chakra-ui/react"
import { sortBy } from "lodash"
import React from "react"

import {
  CardSortCard,
  CardSortCategory,
  CardSortCategoryCard,
  CardSortCategoryGroup,
  CardSortOpenCategory,
  CardSortType,
} from "Types"

import {
  EitherCategory,
  groupCategories,
  humanizeGroupLabel,
  isItalicCategoryGroupNames,
} from "../CardSortCategories/grouping"
import { ScrollingWrapper } from "../ScrollingWrapper"

import { Cell } from "./Cell"

interface AgreementMatrixProps {
  cardSortType: CardSortType
  cards: CardSortCard[]
  categories: (CardSortCategory | CardSortOpenCategory)[]
  categoryGroups: CardSortCategoryGroup[]
  sortData: CardSortCategoryCard[]
  maxH?: ChakraProps["maxH"]
}

export const AgreementMatrix: React.FC<AgreementMatrixProps> = ({
  cardSortType,
  cards,
  categories,
  categoryGroups,
  sortData,
  maxH,
}) => {
  const groupedCategories = Object.entries(
    groupCategories(categories as EitherCategory[], categoryGroups)
  )

  const processedCategories: [string, EitherCategory[]][] = []

  if (cardSortType === "closed") {
    processedCategories.push(...groupedCategories)
  } else {
    // The agreement matrix only shows categories that have been grouped together.
    // (technically all open categories are grouped, so we're looking for groups of size > 1)
    // All other categories will be rolled together into a single "Ungrouped" column.
    processedCategories.push(
      ...groupedCategories.filter(([, categories]) => categories.length > 1)
    )

    // Merge all "ungrouped" categories into a single column.
    const ungroupedCategories = groupedCategories
      .filter(([, cats]) => cats.length === 1 && cats.every((c) => c.id))
      .flatMap(([, categories]) => categories)

    if (ungroupedCategories.length > 0) {
      processedCategories.push(["Ungrouped", ungroupedCategories])
    }

    // Separate out "Unsorted" into its own category.
    const unsortedCategory = groupedCategories.find(
      ([, cats]) => cats.length === 1 && cats[0].id === null
    )

    if (unsortedCategory) {
      processedCategories.push(unsortedCategory)
    }
  }

  // Pre-process the sortData so we can determine the order of cards to show.
  // (Categories are always shown in Test Builder order)
  const matrix = cards.map((card) => {
    const categoryValues = processedCategories.map(([, categories]) => {
      const categoryIds = categories.map((c) => Number(c.id!))

      return sortData.filter((s) => {
        const computedCategoryId =
          s.card_sort_category_id ?? s.card_sort_open_category_id ?? 0
        return (
          s.card_sort_card_id === card.id &&
          categoryIds.includes(computedCategoryId)
        )
      }).length
    })

    // Ignore the "unsorted" category when determining max.
    const categoriesWithoutUnsorted = categoryValues.filter(
      (_, i) => categories[i].id !== null
    )
    const max = Math.max(...categoriesWithoutUnsorted)

    return {
      id: card.id,
      label: card.label,
      values: categoryValues,
      max,
      positionOfMax: categoryValues.indexOf(max),
      maxIsShared:
        categoriesWithoutUnsorted.filter((v) => v === max).length > 1,
      total: categoryValues.reduce((a, b) => a + b, 0),
    }
  })

  const sortedMatrix = sortBy(matrix, [
    (row) => row.max === 0,
    (row) => row.positionOfMax,
    (row) => -row.max / row.total,
  ])

  return (
    <ScrollingWrapper mt={4} leftOffset="150px" topOffset="40px" maxH={maxH}>
      <Grid
        templateColumns={`150px repeat(${processedCategories.length}, 150px)`}
        width="max-content"
      >
        <Box bg="white" position="sticky" left={0} top={0} zIndex={1} />
        {processedCategories.map(([groupLabel], i) => (
          <Tooltip
            key={groupLabel}
            hasArrow
            label={humanizeGroupLabel(groupLabel)}
          >
            <Text
              position="sticky"
              top={0}
              align="center"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              bg="white"
              p={2}
              css={{
                [`&:has(~ div [data-column-index='${i}']:hover)`]: {
                  fontWeight: "bold",
                },
              }}
              fontStyle={
                isItalicCategoryGroupNames(groupLabel, []) ? "italic" : "normal"
              }
            >
              {humanizeGroupLabel(groupLabel)}
            </Text>
          </Tooltip>
        ))}

        {sortedMatrix.map((matrixRow) => {
          return (
            <Box key={matrixRow.id} display="contents" role="group">
              <Tooltip hasArrow label={matrixRow.label}>
                <Text
                  position="sticky"
                  left={0}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  bg="white"
                  p={2}
                  _groupHover={{ fontWeight: "bold" }}
                >
                  {matrixRow.label}
                </Text>
              </Tooltip>
              {matrixRow.values.map((value, i) => {
                return (
                  <Cell
                    key={i}
                    value={value}
                    max={matrixRow.max}
                    total={matrixRow.total}
                    maxIsShared={matrixRow.maxIsShared}
                    cardLabel={matrixRow.label}
                    categoryLabel={humanizeGroupLabel(
                      processedCategories[i][0]
                    )}
                    data-column-index={i}
                    m="2px"
                  />
                )
              })}
            </Box>
          )
        })}
      </Grid>
    </ScrollingWrapper>
  )
}

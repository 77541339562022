import {
  Box,
  Center,
  Flex,
  Image,
  useBreakpointValue,
  useSteps,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"

import { ThemedButton } from "Components/button/themed-button"
import {
  AppearanceProps,
  LayoutState,
} from "UsabilityHub/components/UsabilityTestLayout/UsabilityTestLayout"
import {
  Body,
  Container,
  Footer,
  Header,
  Title,
} from "UsabilityHub/components/UsabilityTestTextLayout/usability-test-text-layout"

import micAndCameraPermissionSrc from "Images/app-illustrations/lyssna/enable_mic_and_camera_permission_upscale.gif"
import micPermissionSrc from "Images/app-illustrations/lyssna/enable_mic_permission_upscale.gif"
import { useTranslate } from "Shared/hooks/useTranslate"
import { ParticipantDeletionReason } from "Types"
import { MultiStepsProgress } from "UsabilityHub/components/progress/MultiStepsProgress"
import { isFunction } from "lodash"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"
import { useTestRecordingContext } from "../../context/TestRecordingContext"
import { AllSetContent } from "./AllSetContent"
import { DeviceSelection } from "./DeviceSelection"
import { MicrophonePermissionRequest } from "./MicrophonePermissionRequest"
import { ScreenSharingRequest } from "./ScreenSharingRequest"

type SetPassableFn = (passable: boolean) => void
export type StepContentProps = { setPassable: SetPassableFn }

type Step = {
  titleKey: string | ((recordingTypes: RecordingType[]) => string)
  content: (setPassable: SetPassableFn) => React.ReactElement
  filter?: RecordingType | ((recordingTypes: RecordingType[]) => boolean)
  buttonTextKey?: string // If not set, default button text "Continue" will be used
  redirectOnPassable?: boolean
  leftImage?: string
}

const STEPS: Step[] = [
  {
    titleKey: "test.recording.microphone.heading",
    content: (setPassable) => (
      <MicrophonePermissionRequest setPassable={setPassable} />
    ),
    filter: (types) =>
      types.includes("microphone") && !types.includes("camera"),
    buttonTextKey: "test.buttons.continue",
    leftImage: micPermissionSrc,
  },
  {
    titleKey: "test.recording.camera.heading",
    content: (setPassable) => (
      <MicrophonePermissionRequest setPassable={setPassable} />
    ),
    filter: "camera",
    buttonTextKey: "test.buttons.continue",
    leftImage: micAndCameraPermissionSrc,
  },
  {
    titleKey: (recordingTypes) =>
      `test.recording.${recordingTypes.includes("camera") ? "camera" : "microphone"}.selection_heading`,
    content: (setPassable) => <DeviceSelection setPassable={setPassable} />,
    filter: "microphone",
    buttonTextKey: "test.buttons.continue",
  },
  {
    titleKey: "test.recording.screen.heading",
    content: (setPassable) => (
      <ScreenSharingRequest setPassable={setPassable} />
    ),
    filter: "screen",
    redirectOnPassable: true,
  },
  {
    titleKey: "test.recording.all_set",
    content: (setPassable) => <AllSetContent setPassable={setPassable} />,
    buttonTextKey: "test.recording.start_test",
  },
]

const RecordingSetupGuide: React.FC = () => {
  const translate = useTranslate()
  const { allowedRecordingTypes, setRecordingSetupGuideFinished, setError } =
    useTestRecordingContext()
  const [passable, setPassable] = useState(false)

  const validSteps = STEPS.filter(
    (s) =>
      !s.filter ||
      (isFunction(s.filter)
        ? s.filter(allowedRecordingTypes)
        : allowedRecordingTypes.includes(s.filter))
  )
  const totalSteps = validSteps.length
  const { activeStep: activeStepIndex, goToNext } = useSteps({
    index: 0,
    count: totalSteps,
  })

  const currentStep = validSteps[activeStepIndex]
  const isSecondLastStep = activeStepIndex === totalSteps - 2
  const isFinalStep = activeStepIndex === totalSteps - 1

  useEffect(() => {
    if (passable && currentStep.redirectOnPassable) {
      goToNext()
    }
    // We always set passable at start of final step as you have
    // to have enabled all permissions to get there, so if it's
    // no longer passable, you've revoked a permission
    if (!passable && isFinalStep) {
      setError(ParticipantDeletionReason.RecordingPermissionDenied)
    }
  }, [passable, currentStep.redirectOnPassable])

  const isMobile = useBreakpointValue<boolean>(
    {
      base: true,
      lg: false,
    },
    { ssr: false }
  )

  const titleKey = isFunction(currentStep.titleKey)
    ? currentStep.titleKey(allowedRecordingTypes)
    : currentStep.titleKey

  return (
    <Flex
      position="absolute"
      w="100%"
      h="full"
      p={isMobile ? 8 : 0}
      overflowY="auto"
    >
      <Box
        bg="ds.surface.sunken"
        w="50%"
        h="full"
        display={isMobile ? "none" : "block"}
      >
        {currentStep.leftImage && (
          <Center h="full" p={10}>
            <Image src={currentStep.leftImage} maxWidth="min(100%, 700px)" />
          </Center>
        )}
      </Box>
      <Container>
        <Box mb={8}>
          <MultiStepsProgress
            stepIndex={activeStepIndex + 1}
            totalSteps={totalSteps}
          />
        </Box>
        {isMobile && currentStep.leftImage && (
          <Image src={currentStep.leftImage} maxWidth="full" mb={8} />
        )}
        <Header>
          <Title>{translate(titleKey)}</Title>
        </Header>
        <Body>
          <Flex direction="column" gap={8} color="gray.900">
            {currentStep.content(setPassable)}
          </Flex>
        </Body>
        {!currentStep.redirectOnPassable && (
          <Footer>
            <Box>
              <ThemedButton
                onClick={() => {
                  if (isFinalStep) {
                    setRecordingSetupGuideFinished(true)
                  } else {
                    goToNext()
                  }
                  if (!isSecondLastStep) {
                    setPassable(false)
                  }
                }}
                isDisabled={!passable}
              >
                {currentStep.buttonTextKey
                  ? translate(currentStep.buttonTextKey)
                  : "Continue"}
              </ThemedButton>
            </Box>
          </Footer>
        )}
      </Container>
    </Flex>
  )
}

export default function recordingSetupGuideContent(): AppearanceProps {
  return {
    isReportButtonVisible: true,
    layoutState: LayoutState.FocusQuestion,
    mediaContent: null,
    questionContent: <RecordingSetupGuide />,
  }
}

import domtoimage, { Options } from "dom-to-image"
import isSafari from "is-safari"

export const downloadPng = async (
  node: Node,
  filename: string,
  options?: Options
): Promise<void> => {
  let blob: Blob

  // In Safari, the first attempt to generate a click map or heat map export results in an export of
  // just the overlay and highlight areas. Second and subsequent exports work as expected. This is a
  // horrible hack to make it work for now without burning more time figuring out the underlying
  // problem. I'm very sorry.
  // See https://gitlab.com/usabilityhub/app/-/merge_requests/4569#note_400757752 for more details.
  if (isSafari) {
    await domtoimage.toBlob(node, { cacheBust: true, ...options })
    blob = await domtoimage.toBlob(node, { cacheBust: true, ...options })
  } else {
    blob = await domtoimage.toBlob(node, { cacheBust: true, ...options })
  }
  const link = document.createElement("a")
  link.download = `${filename}.png`
  link.href = URL.createObjectURL(blob)
  link.click()
}

import { HStack } from "@chakra-ui/react"
import { Dispatch } from "Redux/app-store"
import { getFormValues } from "Redux/reducers/test-builder-form/selectors/formValues"
import { getAreScreenshotsUploaded } from "Redux/reducers/test-builder-form/selectors/screenshots"
import { updateCreditsAndDuration } from "Redux/reducers/test-builder/action-creators/estimate"
import { getIsEstimateValid } from "Redux/reducers/test-builder/selectors/estimate"
import { debounce } from "lodash"
import React, { useCallback, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CreditEstimate } from "./CreditEstimate"
import { DurationEstimate } from "./DurationEstimate"

const UPDATE_TEST_DURATION_ESTIMATE_DEBOUNCE_DELAY = 2000

export const TestDurationAndCostEstimate: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch: Dispatch = useDispatch()
  const isInitialized = useRef(false)
  const isEstimateValid = useSelector(getIsEstimateValid)
  const areScreenshotsUploaded = useSelector(getAreScreenshotsUploaded)
  const formValues = useSelector(getFormValues)

  const debouncedUpdateCreditsAndDuration = useCallback(
    debounce(
      // Pass the valid form values to the action creator, as getting them
      // via getState can result in sending an invalid test to the estimator
      (validFormValues) => dispatch(updateCreditsAndDuration(validFormValues)),
      UPDATE_TEST_DURATION_ESTIMATE_DEBOUNCE_DELAY,
      { leading: false }
    ),
    []
  )

  // If the test length changed we need to get a new estimate but only once
  // the form is valid, and all screenshots are uploaded, otherwise the
  // server may crash due to invalid logic or missing screenshots
  useEffect(() => {
    if (isInitialized.current === false) return

    if (!isEstimateValid && areScreenshotsUploaded) {
      void debouncedUpdateCreditsAndDuration(formValues)
    } else {
      // Cancel any existing requests as they are stale
      debouncedUpdateCreditsAndDuration.cancel()
    }
  }, [
    isEstimateValid,
    areScreenshotsUploaded,
    debouncedUpdateCreditsAndDuration,
    formValues,
  ])

  // to fetch estimations immediately we keep a reference if the estimations have been initialized
  useEffect(() => {
    if (isInitialized.current === false) {
      void dispatch(updateCreditsAndDuration(formValues))
      isInitialized.current = true
    }
  }, [])

  return (
    <HStack justifyContent="space-between" color="gray.500">
      <DurationEstimate />
      <CreditEstimate />
    </HStack>
  )
}

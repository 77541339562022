import { v4 as uuidv4 } from "uuid"

import { Dispatch } from "Redux/app-store"
import { Screenshot } from "Types"

import { InitUsabilityTestAction } from "../action-types"
import { Type } from "../constants"
import { addUsabilityTestClientFields } from "../helpers"
import { DefinedRawTestBuilderState, TestBuilderState } from "../reducer"

export const initializeUsabilityTest =
  (testBuilder: DefinedRawTestBuilderState, screenshots: Screenshot[]) =>
  (dispatch: Dispatch) => {
    const { creditCount, estimatedDurationRange, ...restTestBuildState } =
      testBuilder

    const state: TestBuilderState = {
      ...restTestBuildState,
      initialValues: addUsabilityTestClientFields(
        testBuilder.initialValues,
        screenshots
      ),
      questionDeletions: [],
      sectionScreenshotDeletions: [],
      sectionDeletions: [],
      testSetId: testBuilder.testSetId,
      isPreviewClicked: false,
      uuid: uuidv4(),
      estimations: {
        isLoading: false,
        isValid: false,
        creditCount: creditCount,
        estimatedDurationRange: estimatedDurationRange,
      },
      comments: [],
      lastOperation: null,
    }

    dispatch({
      type: Type.INIT_USABILITY_TEST,
      payload: state,
    } as InitUsabilityTestAction)
  }

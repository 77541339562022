import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import React, { useContext } from "react"

import { DemographicsContext } from "../context/demographics"
import { ParticipantList } from "../participant-list/participant-list"

import { ParticipantFiltersActiveAlert } from "./participant-filters-active-alert"
import { TestResultsFiltersForm } from "./test-results-filters-form"

export const TestResultsSidebar: React.FC = () => {
  const { isLoading } = useContext(DemographicsContext)

  // TODO: Improve loading state
  if (isLoading) return <div>Loading</div>

  return (
    <Box
      bg="ds.surface.raised.resting"
      rounded="16px"
      shadow="ds.raised"
      w="267px"
    >
      <Tabs>
        <TabList justifyContent="center">
          <Tab py={5} data-qa="filters-tab">
            Filters
          </Tab>
          <Tab
            py={5}
            data-qa="participants-tab"
            data-intercom-target="participants-tab"
          >
            Participants
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0} data-qa="filters-panel">
            <TestResultsFiltersForm />
          </TabPanel>
          <TabPanel p={0} data-qa="participants-panel">
            <ParticipantFiltersActiveAlert />
            <ParticipantList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query"
import { useInfiniteScroll } from "Hooks/use-infinite-scroll"
import {
  ListFilterOptionsError,
  ListFilterOptionsQueryParams,
  ListFilterOptionsResponse,
  fetchListFilterOptions,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

type FilterOption = { id: string; name: string } | { id: number; name: string }

type FilterOptions<T extends FilterOption> = Readonly<Pick<T, "id" | "name">[]>

type UseFilterOptionsParams = {
  filter: "projects" | "variation_sets" | "usability_tests"
  search?: string
  sortBy?: "name" | "created_at" | "updated_at"
  sortDirection?: "asc" | "desc"
  pagination?: boolean
}

type UseFilterOptionsReturn<T extends FilterOption> = {
  allOptions: FilterOptions<T>
  allDone: boolean
  loaderRef:
    | React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
    | undefined
  isFetching: boolean
  isFetchingNextPage: boolean
}

export const useFilterOptions = <T extends FilterOption>({
  filter,
  search,
  sortBy = "name",
  sortDirection = "asc",
  pagination = false,
}: UseFilterOptionsParams): UseFilterOptionsReturn<T> | undefined => {
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isFetching } =
    useInfiniteListFilterOptions({
      filter,
      search: search ?? "",
      sort_by: sortBy,
      sort_direction: sortDirection,
      pagination,
    })
  const loaderRef = useInfiniteScroll<HTMLDivElement>(fetchNextPage)

  const allOptions =
    (data?.pages?.flatMap((page) => page.options) as FilterOptions<T>) ?? []
  const pagesShown = data?.pages?.length ?? 0
  const allDone = !hasNextPage && pagesShown > 0

  return {
    allOptions,
    allDone,
    loaderRef: hasNextPage ? loaderRef : undefined,
    isFetching,
    isFetchingNextPage,
  }
}

const useInfiniteListFilterOptions = (
  queryParams: Omit<ListFilterOptionsQueryParams, "cursor">,
  options?: Omit<
    UseInfiniteQueryOptions<ListFilterOptionsResponse, ListFilterOptionsError>,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useUsabilityhubContext()

  return useInfiniteQuery<ListFilterOptionsResponse, ListFilterOptionsError>(
    queryKeyFn({
      path: "/api/filters/options",
      operationId: "listFilterOptions",
      variables: {
        queryParams: {
          ...fetcherOptions.queryParams,
          ...queryParams,
        },
      },
    }),
    (context) =>
      fetchListFilterOptions({
        ...fetcherOptions,
        queryParams: {
          ...queryParams,
          ...(context.pageParam ? { cursor: context.pageParam as string } : {}),
        },
      }),
    {
      ...queryOptions,
      ...options,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.next_page ?? undefined,
      retry: false,
    }
  )
}

import { Link, Text } from "@chakra-ui/react"
import { Banner } from "Components/banner/banner"
import {
  useCurrentAccount,
  useCurrentPlan,
  useCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import React from "react"
import SettingsApi from "~/api/SettingsApi"

export const AccountNotInGoodStandingBanner: React.FC = () => {
  const currentUser = useCurrentUser()
  const currentAccount = useCurrentAccount()
  const currentUserInReadonlyMode = currentUser.id !== currentAccount.owner_id
  const currentPlan = useCurrentPlan()

  if (!currentPlan) return null

  return (
    <Banner type="danger">
      <Text>
        {currentUserInReadonlyMode ? (
          <>
            Your account doesn{"\u2019"}t include additional collaborators, so
            only the account owner is able to create tests.
          </>
        ) : (
          <>
            Your subscription to the {currentPlan.name} plan has lapsed, and you
            are not currently able to create tests.{" "}
          </>
        )}
        {currentUser.role === "admin" ? (
          <>
            Visit your{" "}
            <Link
              href={SettingsApi.billing.path()}
              _hover={{ color: "inherit" }}
            >
              billing settings page
            </Link>{" "}
            to resolve this issue.
          </>
        ) : (
          <>
            Ask an account admin to visit the billing settings page to resolve
            this issue.
          </>
        )}
      </Text>
    </Banner>
  )
}

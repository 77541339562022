import {
  Button,
  Flex,
  Heading,
  Icon,
  Img,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react"
import { ArrowRightIcon } from "@heroicons/react/solid"
import React from "react"
import { useSelector } from "react-redux"
import { Field } from "redux-form"
import FigmaFileVersionsApi from "~/api/Api/FigmaFileVersionsApi"

import { PrototypeType } from "JavaScripts/types/figma-file-flow"
import { getNullableFigmaFileVersionById } from "Redux/reducers/figma-file-versions/selectors"
import { FigmaFileFlow } from "Types"
import { useUsabilityTestUserActivityContext } from "UsabilityHub/components/TestForm/UsabilityTestUserActivityContext"
import { getNodeTitle } from "Utilities/figma-file-version"

import { WrappedCheckbox } from "../../WrappedCheckbox"

import { StartAndGoalSelectionModal } from "./StartAndGoalSelectionModal"

interface StartAndGoalSelectionProps {
  prototypeType: PrototypeType
  figmaFileFlow: FigmaFileFlow
  sectionIndex: number
  goalScreen?: unknown
  changeScreen?: (val: boolean) => void
}

export const StartAndGoalSelection: React.FC<StartAndGoalSelectionProps> = ({
  prototypeType,
  figmaFileFlow,
  sectionIndex,
}) => {
  const { readOnly } = useUsabilityTestUserActivityContext()
  const figmaFileVersion = useSelector(
    getNullableFigmaFileVersionById(figmaFileFlow.figma_file_version_id)
  )
  if (!figmaFileVersion) {
    return null
  }

  const getScreenTitle = (nodeId: string) =>
    getNodeTitle(figmaFileVersion, nodeId)

  return (
    <>
      <Flex
        rounded="md"
        border="1px"
        borderColor="gray.200"
        bgColor="gray.50"
        overflowX="auto"
      >
        <Flex
          flexBasis="full"
          direction="column"
          align="center"
          p={8}
          pb={{ task_flow: 4, free_roam: 8 }[prototypeType]}
          gap={4}
        >
          <Flex w="full" justify="center">
            <StartAndGoalSelectionItem
              type="start"
              screen={{
                title: getScreenTitle(figmaFileFlow.start_node_id),
                imgPath: FigmaFileVersionsApi.image.path({
                  figma_file_version_id: figmaFileFlow.figma_file_version_id,
                  figma_node_id: figmaFileFlow.start_node_id,
                }),
              }}
              figmaFileFlow={figmaFileFlow}
            />
            {prototypeType === "task_flow" && (
              <>
                <Flex align="center" justify="center" flexGrow={1}>
                  <Icon
                    as={ArrowRightIcon}
                    boxSize={5}
                    mx={2}
                    color="gray.500"
                  />
                </Flex>
                <StartAndGoalSelectionItem
                  type="goal"
                  screen={
                    figmaFileFlow.goal_node_id
                      ? {
                          title: getScreenTitle(figmaFileFlow.goal_node_id),
                          imgPath: FigmaFileVersionsApi.image.path({
                            figma_file_version_id:
                              figmaFileFlow.figma_file_version_id,
                            figma_node_id: figmaFileFlow.goal_node_id,
                          }),
                        }
                      : undefined
                  }
                  figmaFileFlow={figmaFileFlow}
                />
              </>
            )}
          </Flex>

          {prototypeType === "task_flow" && (
            <Text fontSize="sm" color="text.secondary">
              A test is successful when the participant reaches a goal screen.
            </Text>
          )}
        </Flex>
      </Flex>
      {prototypeType === "task_flow" && (
        // This div is to take the content out of the flexbox above
        // Otherwise the element would be full width and the tooltip would be pointing to empty space
        <div>
          <Tooltip
            hasArrow
            placement="top"
            label="Let participants know when they complete the task"
          >
            <span>
              <Field
                name={`sections[${sectionIndex}].figma_file_flow.show_success_screen`}
                label="Show success screen"
                component={WrappedCheckbox}
                disabled={readOnly}
              />
            </span>
          </Tooltip>
        </div>
      )}
    </>
  )
}

interface ScreenProps {
  imgPath: string
  title: string | undefined
}

export type StartOrGoal = "start" | "goal"

type StartAndGoalSelectionItemBaseProps = {
  type: StartOrGoal
}

type StartAndGoalSelectionItemProps =
  | (StartAndGoalSelectionItemBaseProps & {
      type: "start"
      screen: ScreenProps
      figmaFileFlow: FigmaFileFlow
    })
  | (StartAndGoalSelectionItemBaseProps & {
      type: "goal"
      screen?: ScreenProps
      figmaFileFlow: FigmaFileFlow
    })

const StartAndGoalSelectionItem: React.FC<StartAndGoalSelectionItemProps> = ({
  type,
  screen,
  figmaFileFlow,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { readOnly } = useUsabilityTestUserActivityContext()

  return (
    <>
      {!screen ? (
        <PickAGoalScreenPlaceholder onClick={onOpen} />
      ) : (
        <Flex
          bgColor="white"
          border="1px"
          borderColor="gray.200"
          rounded="md"
          p={2}
          flexBasis="400px"
          flexShrink={1}
          gap={2}
          align="center"
        >
          <Thumbnail imagePath={screen.imgPath} />
          <VStack alignItems="flex-start" spacing={2} flexGrow={1}>
            <Text
              fontSize="sm"
              color={type === "goal" ? "green.500" : "link"}
              textTransform="capitalize"
            >
              {type}
            </Text>
            <Heading
              as="h4"
              fontSize="md"
              fontWeight="semibold"
              color="text.primary"
              noOfLines={1}
            >
              {screen.title ? (
                screen.title
              ) : (
                <Text color="gray.400">Title loading</Text>
              )}
            </Heading>
          </VStack>
          {type === "goal" && (
            <Button
              onClick={onOpen}
              size="xs"
              fontWeight="semibold"
              variant="outline"
              data-qa="change-figma-flow-screen-button"
              flexShrink={0}
              isDisabled={readOnly}
            >
              Change
            </Button>
          )}
        </Flex>
      )}
      <StartAndGoalSelectionModal
        startOrGoal={type}
        figmaFileFlow={figmaFileFlow}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

interface ThumbnailProps {
  imagePath: string
}

const Thumbnail: React.FC<ThumbnailProps> = ({ imagePath }) => (
  <Flex
    border="1px"
    borderColor="gray.200"
    rounded="md"
    flexBasis="120px"
    flexShrink={0}
    h="92px"
  >
    <Img
      src={imagePath}
      objectFit="contain"
      w="100%" // object-fit requires width and height
      h="100%" // ^
    ></Img>
  </Flex>
)

interface PickAGoalScreenPlaceholderProps {
  onClick: () => void
}

const PickAGoalScreenPlaceholder: React.FC<PickAGoalScreenPlaceholderProps> = ({
  onClick,
}) => (
  <Button
    onClick={onClick}
    flexBasis="400px"
    flexShrink={0}
    height="full"
    bgColor="brand.primary.50"
    rounded="md"
    border="1px"
    borderStyle="dashed"
    borderColor="link"
    _hover={{ cursor: "pointer", bgColor: "brand.primary.100" }}
  >
    <Text fontSize="md" fontWeight="semibold" color="link">
      Pick a goal screen
    </Text>
  </Button>
)

import classNames from "classnames"
import React, { LabelHTMLAttributes } from "react"

import styles from "./label.module.scss"

type Props = Readonly<LabelHTMLAttributes<HTMLLabelElement>>

export const Label = ({ children, className, ...props }: Props) => (
  <label className={classNames(styles.label, className)} {...props}>
    {children}
  </label>
)

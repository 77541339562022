import { Button, Flex } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import React, { useRef } from "react"
import { PageState } from "./OnboardingFlow"
import { OnboardingFlowStepper } from "./OnboardingFlowStepper"

type OnboardingFlowPageProps = {
  onNext: () => void
  onPrevious: () => void
  currentPageState: PageState
  pageCount: number
  content: React.ReactNode
  nextButtonLabel?: string
  previousButtonLabel?: string
}

const MotionFlex = motion(Flex)

const variants = {
  initial: ({ direction, width }: CustomMotionProps) => {
    return {
      x: direction === "next" ? width : -width,
    }
  },
  center: { x: 0 },
  exit: ({ direction, width }: CustomMotionProps) => {
    return {
      x: direction === "next" ? -width : width,
    }
  },
}

type CustomMotionProps = {
  direction: PageState["direction"]
  width: number
}

export const OnboardingFlowPage: React.FC<OnboardingFlowPageProps> = ({
  content,
  pageCount,
  currentPageState: { idx: currentPageIdx, direction },
  onNext,
  onPrevious,
  nextButtonLabel = "Next",
  previousButtonLabel = "Previous",
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const width = ref.current ? ref.current.offsetWidth : 0
  const customMotionProps = { direction, width }

  return (
    <Flex flexDirection="column" h="full" ref={ref}>
      <AnimatePresence
        initial={false}
        mode="popLayout"
        custom={customMotionProps}
      >
        <MotionFlex
          flexDirection="column"
          gap={4}
          flexGrow={1}
          variants={variants}
          custom={customMotionProps}
          key={`onboarding-slide-${currentPageIdx}`}
          initial="initial"
          animate="center"
          exit="exit"
        >
          {content}
        </MotionFlex>
      </AnimatePresence>

      <Flex justifyContent="space-between" p={4}>
        <Flex basis="50%">
          {currentPageIdx === 0 || (
            <Button size="sm" variant="outline" onClick={onPrevious}>
              {previousButtonLabel}
            </Button>
          )}
        </Flex>

        <OnboardingFlowStepper
          currentPageIdx={currentPageIdx}
          pageCount={pageCount}
        />

        <Flex basis="50%" justifyContent="flex-end">
          <Button size="sm" colorScheme="brand.primary" onClick={onNext}>
            {nextButtonLabel}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

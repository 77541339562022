import React, { PropsWithChildren } from "react"
import { BrowserRouter } from "react-router-dom"

import { AdminDashboardContext } from "Shared/contexts/AdminDashboardContext"

export function AdminDashboardContextProvider({ children }: PropsWithChildren) {
  return (
    <AdminDashboardContext.Provider value={{ isAdminDashboard: true }}>
      {/* Without a BrowserRouter parent, any react-router links on a page
      viewed via the admin dashboard (such as when you go to "View test results"
      on a test from the admin dashboard) will crash the page, because the link
      isn't wrapped in a Router - the Router is part of the main SPAs and isn't
      used here because Admin-viewed pages are a kind of "headless" view of a
      page. This is a workaround to stop the page crashing when admins view
      test results. Any react-router links in the test results UI will not work
      when viewed as an admin since this this Router is just an empty wrapper. */}
      <BrowserRouter>{children}</BrowserRouter>
    </AdminDashboardContext.Provider>
  )
}

import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import illustration from "Images/app-illustrations/think-aloud.png"
import { useTranslate } from "Shared/hooks/useTranslate"
import { useEffect, useState } from "react"
import React from "react"
import { useSectionRecordingContext } from "../UsabilityTest/context/SectionRecordingContext"

export const ThinkAloud: React.FC = () => {
  const { sectionRecordingTypes, startRecording } = useSectionRecordingContext()

  const speakAloud = sectionRecordingTypes.includes("microphone")

  const [dismissed, setDismissed] = useState(false)

  const translate = useTranslate()

  useEffect(() => {
    dismissed && startRecording()
  }, [dismissed])

  if (!speakAloud) {
    return null
  }

  return (
    <Modal
      size={{ base: "xs", sm: "sm" }}
      isCentered
      isOpen={!dismissed}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => setDismissed(true)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={6}>
          <Flex direction="column" align="stretch" gap={4}>
            <Image
              src={illustration}
              alt="Think aloud"
              maxW="12rem"
              alignSelf="center"
            />
            <Text
              textStyle="ds.display.primary"
              textAlign="center"
              style={{ textWrap: "balance" }}
            >
              {translate("test.recording.microphone.prompt_must_speak_aloud")}
            </Text>
            <ThemedButton onClick={() => setDismissed(true)}>
              {translate("test.buttons.understand")}
            </ThemedButton>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

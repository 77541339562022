import { ResponseSectionRecording } from "JavaScripts/types/recording"
import { getResponseSectionRecording } from "Redux/reducers/test-results/selectors"
import { ResponseSection } from "Types"
import { useSelector } from "react-redux"
import { isSectionRecordingEnabled } from "./useSectionRecordings"

export const useResponseSectionRecording = (
  responseSection: ResponseSection
): ResponseSectionRecording | null => {
  const responseSectionRecording = useSelector(
    getResponseSectionRecording(responseSection.id)
  )

  if (!isSectionRecordingEnabled() || !responseSectionRecording) {
    return null
  }

  const { types } = responseSectionRecording
  const isAudio = types.length === 1 && types[0] === "microphone"

  return {
    ...responseSectionRecording,
    isAudio,
    responseSection: responseSection,
  }
}

import { Box, Flex, HStack, Heading, Spinner, Text } from "@chakra-ui/react"
import { NoPermissionWarning } from "Components/not-allowed-warning/not-allowed-warning"
import Constants from "Constants/shared.json"
import { useCanCurrentUserDo } from "Hooks/use-can-current-user-do"
import { ExternalLink } from "Shared/components/Links/ExternalLink"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"
import { CreditIcon } from "Shared/icons/CreditIcon"
import { UsabilityHubNavbar } from "UsabilityHub/components/UsabilityHubNavbar/UsabilityHubNavbar"
import { useCurrentAccount } from "UsabilityHub/hooks/useCurrentAccount"
import { useRecordSegmentEvent } from "UsabilityHub/hooks/useRecordSegmentEvent"
import { pluralizeWithCount } from "Utilities/string"
import React from "react"
import { useGetAccountTotalCredits } from "~/api/generated/usabilityhub-components"
import { Subnav } from "../Subnav"
import { BuyCredits } from "./BuyCredits"
import { FAQs } from "./FAQs"

type Purchase = React.FC<React.PropsWithChildren<unknown>>

const PurchasePageContent: React.FC = () => {
  const currentAccount = useCurrentAccount()
  const { data } = useGetAccountTotalCredits({})

  useRecordSegmentEvent("viewed_buy_credits_page")

  const canUserManageTests = useCanCurrentUserDo("purchase_credits")

  if (!canUserManageTests) {
    return (
      <Box maxW={1024} mx="auto" my="7">
        <NoPermissionWarning permission="purchase_credits" />
      </Box>
    )
  }

  return (
    <Flex flexWrap="wrap">
      <Box flex="1 1" flexBasis={{ base: "100%", lg: "60%" }}>
        <HStack
          alignItems="baseline"
          justifyContent="space-between"
          spacing={4}
          mb={5}
        >
          <Heading as="h2" size="lg">
            Buy credits
          </Heading>
          <HStack alignItems="center">
            <Text>Your balance is</Text>
            <CreditIcon color="orange.500" />
            {data ? (
              <Text fontWeight="semibold">
                {pluralizeWithCount(data.total_credits, "credit", "credits")}
              </Text>
            ) : (
              <Spinner size="xs" />
            )}
          </HStack>
        </HStack>
        <Text mb={5}>
          Credits are used to order responses from the Lyssna panel. Credits get
          cheaper the more you buy.{" "}
          <ExternalLink href={Constants.HELP_CENTER_BUYING_CREDITS_URL}>
            More info
          </ExternalLink>
        </Text>
        <BuyCredits currentAccount={currentAccount} />
      </Box>
      <Box
        flex="0 1"
        flexBasis={{ base: "100%", lg: "40%" }}
        mt={{ base: 8, lg: 0 }}
        pl={{ base: 0, lg: 10 }}
      >
        <Heading as="h2" size="lg" mb={5}>
          FAQs
        </Heading>
        <FAQs baseCreditPrice={currentAccount.base_credit_price} />
      </Box>
    </Flex>
  )
}

export const PurchaseRoute: Purchase = () => {
  return (
    <Page title="Purchase credits">
      <UsabilityHubNavbar />
      <Subnav />
      <PageMain>
        <PageContent maxW="6xl">
          <PurchasePageContent />
        </PageContent>
      </PageMain>
    </Page>
  )
}

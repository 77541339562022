import { Box } from "@chakra-ui/react"
import React from "react"
import { Droppable } from "react-beautiful-dnd"

interface DroppableQuestionRowsProps {
  droppableId: string
  droppableType: "question" | "option" | "card" | "category"
}

export const DroppableQuestionRows: React.FC<
  React.PropsWithChildren<DroppableQuestionRowsProps>
> = ({ children, droppableId, droppableType }) => {
  // `type` defines what types of Draggable can be dropped where.
  return (
    <Droppable droppableId={droppableId} key={droppableId} type={droppableType}>
      {(provided) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          bg="gray.300"
          borderRadius="sm"
          mx={-8} // Make the drop target box go all the way to the section edge
        >
          {children}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  )
}

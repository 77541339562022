import { Badge, BadgeProps, Tooltip } from "@chakra-ui/react"
import React from "react"

import { useTaskGoalNodeId } from "../../hooks/use-goal-node-id"
import { useIsPrototypeFreeRoam } from "../../hooks/use-prototype-type"

interface GoalScreenBadgeProps {
  goalScreenHasBeenReached: boolean
}

export function GoalScreenBadge({
  goalScreenHasBeenReached,
  ...props
}: GoalScreenBadgeProps & BadgeProps) {
  const goalNodeId = useTaskGoalNodeId()
  const prototypeIsFreeRoam = useIsPrototypeFreeRoam()

  if (prototypeIsFreeRoam) {
    return null
  }

  if (!goalNodeId) {
    return (
      <Badge variant="solid" {...props}>
        No goal screen set
      </Badge>
    )
  }

  if (!goalScreenHasBeenReached) {
    return (
      <Tooltip
        hasArrow
        placement="top"
        label={`Participants who took this path and didn${"\u2019"}t reach the goal screen`}
      >
        <Badge variant="solid" {...props}>
          Didn{"\u2019"}t reach goal screen
        </Badge>
      </Tooltip>
    )
  }

  return (
    <Tooltip
      hasArrow
      placement="top"
      label="Participants who took this path to reach the goal screen"
    >
      <Badge variant="solid" colorScheme="green" {...props}>
        Reached goal screen
      </Badge>
    </Tooltip>
  )
}

import { useToast } from "@chakra-ui/react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Dispatch } from "Redux/app-store"
import { updateFigmaFileVersion } from "Redux/reducers/figma-file-versions/actions"
import { getNullableFigmaFileVersionById } from "Redux/reducers/figma-file-versions/selectors"
import { getSections } from "Redux/reducers/test-builder-form/selectors/sections"
import { isAxiosErrorWithMessage } from "Services/axios"
import { FigmaFileFlow, FigmaFileVersion } from "Types"
import { ConfirmFigmaFileVersionOverwriteModal } from "UsabilityHub/components/ConfirmFigmaFileVersionOverwriteModal/ConfirmFigmaFileVersionOverwriteModal"
import { useModal } from "Utilities/modals/use-modal"

import { useSyncFigma } from "../SyncFigma/useSyncFigma"

export const useSyncFigmaFlow = (figmaFileFlow: FigmaFileFlow) => {
  const dispatch: Dispatch = useDispatch()
  const [isSyncing, setIsSyncing] = useState(false)

  const syncFigma = useSyncFigma()

  const sections = useSelector(getSections)
  const oldFfv = useSelector(
    getNullableFigmaFileVersionById(figmaFileFlow.figma_file_version_id)
  )

  const { open: openConfirmModal, close } = useModal(
    ConfirmFigmaFileVersionOverwriteModal
  )
  const closeConfirmModal = () => {
    setIsSyncing(false)
    close()
  }
  const toast = useToast()

  const onChannelResponse = (newFfv: FigmaFileVersion) => {
    try {
      if (oldFfv && newFfv.id > oldFfv.id) {
        const sectionsContainingOldFfvCount = sections.filter(
          (section) =>
            section.figma_file_flow?.figma_file_version_id === oldFfv.id
        ).length

        // If more than one section points to the old figma file version, require
        // the user to confirm the update
        if (sectionsContainingOldFfvCount > 1) {
          openConfirmModal({
            newFigmaFileVersion: newFfv,
            oldFigmaFileVersion: oldFfv,
            handleClose: closeConfirmModal,
          })
        } else {
          // Only this section, update it in Redux
          dispatch(updateFigmaFileVersion(oldFfv, newFfv))
          setIsSyncing(false)
        }
      } else {
        // Nothing new to update
        setIsSyncing(false)
      }
    } catch (error) {
      const description = isAxiosErrorWithMessage(error)
        ? error.response.data.message
        : "Sorry, we were unable to sync your prototype."

      toast({
        title: description,
        status: "error",
      })
      setIsSyncing(false)
    }
  }

  const syncFigmaFlow = () => {
    if (!oldFfv) return

    setIsSyncing(true)

    syncFigma(
      oldFfv.figma_file_key,
      figmaFileFlow.start_node_id,
      onChannelResponse,
      () => {
        setIsSyncing(false)
      }
    )
  }

  return { isSyncing, syncFigmaFlow }
}

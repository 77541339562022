import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  usePrefersReducedMotion,
} from "@chakra-ui/react"
import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"

import { UseInfiniteQueryResult, useQueryClient } from "@tanstack/react-query"
import { useFilterContext } from "Components/filter-controls/filter-context"
import { serializeAppliedFilter } from "Components/filter-controls/utils"
import {
  PanelOrderCompositeIcon,
  RecruitmentLinkCompositeIcon,
} from "Shared/components/CompositeIcons/CompositeIcons"
import { ChevronDownOutlineIcon } from "Shared/icons/untitled-ui/ChevronDownOutlineIcon"
import { ChevronUpOutlineIcon } from "Shared/icons/untitled-ui/ChevronUpOutlineIcon"
import { Send01SolidIcon } from "Shared/icons/untitled-ui/Send01SolidIcon"
import { RevokeInvitationButton } from "UsabilityHub/components/ModeratedStudy/ApplicationsTable/RevokeInvitationButton"
import { SendInvitationButton } from "UsabilityHub/components/ModeratedStudy/ApplicationsTable/SelectedApplicationsFloatingActions"
import { useFlatLocations } from "UsabilityHub/hooks/useFlatLocations"
import { useTeamMembers } from "UsabilityHub/hooks/useTeamMembers"
import UserBadge from "UsabilityHub/views/ModeratedStudy/interviewer/BookingCard/UserBadge"
import ParticipantBookingStateBadge from "UsabilityHub/views/ModeratedStudy/interviewer/ParticipantBookingStateBadge"
import { getActiveDemographicAttributes } from "Utilities/demographics"
import { useLocation, useNavigate } from "react-router"
import {
  GetModeratedStudyApplicationsError,
  GetModeratedStudyApplicationsResponse,
  useGetModeratedStudy,
  useGetModeratedStudyApplications,
  useGetModeratedStudyBookings,
  useListDemographics,
} from "~/api/generated/usabilityhub-components"
import { cycleThroughArray } from "../../cycleThroughRecords"
import { useModeratedStudyContext } from "../ModeratedStudyContext"
import {
  DetailsGrid,
  DrawerSection,
  EligibilityStatus,
  FieldTitle,
  FieldValueGeneric,
  FieldValueText,
  ParticipantDrawerAccordionItemHeading,
  ParticipantDrawerHeader,
  formatRecruitmentMethod,
} from "./ParticipantInfoDrawerAtoms"
import { DrawerData, extractDrawerData } from "./ParticipantInfoDrawerData"
import { DeclineButton } from "./ParticipantInfoDrawerDeclineButton"
import { ParticipantDrawerScreenerAnswer } from "./ParticipantInfoDrawerScreenerAnswer"
import { isDeclinableStatus } from "./isDeclinableStatus"

type ParticipantInfoDrawerAccordionState = number[]

type AccordionState = [
  openIndexes: ParticipantInfoDrawerAccordionState,
  setOpenIndexes: React.Dispatch<
    React.SetStateAction<ParticipantInfoDrawerAccordionState>
  >,
]

interface Props {
  currentlyVisibleApplicationIds: string[] | null
}

export interface ParticipantInfoDrawerContextState {
  disclosure: Pick<
    ReturnType<typeof useDisclosure>,
    "isOpen" | "onOpen" | "onClose"
  >
  participantDrawerAccordionState: AccordionState
}

export const ParticipantInfoDrawerContext =
  React.createContext<ParticipantInfoDrawerContextState | null>(null)

const INITIAL_OPEN_ACCORDION_INDEXES = [0]

export const ParticipantInfoDrawerContextProvider: React.FC<
  PropsWithChildren
> = ({ children }) => {
  const participantDrawerAccordionState =
    useState<ParticipantInfoDrawerAccordionState>(
      INITIAL_OPEN_ACCORDION_INDEXES
    )
  const participantDrawerContextState: ParticipantInfoDrawerContextState = {
    disclosure: useDisclosure(),
    participantDrawerAccordionState,
  }

  return (
    <ParticipantInfoDrawerContext.Provider
      value={participantDrawerContextState}
    >
      {children}
    </ParticipantInfoDrawerContext.Provider>
  )
}

export const useParticipantInfoDrawerContext = () => {
  const navigate = useNavigate()
  const context = useContext(ParticipantInfoDrawerContext)

  if (!context) {
    throw new Error("ParticipantInfoDrawerContext is null")
  }

  const {
    disclosure: { onOpen },
  } = context

  const openParticipantInfoDrawer = useCallback(
    (applicationId: string) => {
      const currentUrl = new URL(window.location.href)
      currentUrl.hash = applicationId

      navigate(currentUrl.pathname + currentUrl.search + currentUrl.hash)
      onOpen()
    },
    [navigate, onOpen]
  )

  return {
    openParticipantInfoDrawer,
  }
}

const REFETCH_INTERVAL = 30_000

const accordionButtonStyles = {
  height: "3.5rem",
  justifyContent: "space-between",
  px: 4,
}

export const ParticipantInfoDrawer: React.FC<Props> = ({
  currentlyVisibleApplicationIds,
}) => {
  const { moderatedStudyId } = useModeratedStudyContext()
  const { openParticipantInfoDrawer } = useParticipantInfoDrawerContext()
  const drawerContext = useContext(ParticipantInfoDrawerContext)
  if (!drawerContext) {
    throw new Error("ParticipantInfoDrawerContext is null")
  }
  const { isOpen, onClose } = drawerContext.disclosure ?? {
    isOpen: false,
    onClose: () => {},
  }
  const {
    participantDrawerAccordionState: [
      accordionOpenIndexes,
      setAccordionOpenIndexes,
    ],
  } = drawerContext

  const { data: moderatedStudyData } = useGetModeratedStudy(
    { pathParams: { moderatedStudyId } },
    { refetchInterval: REFETCH_INTERVAL }
  )

  // If the anchor looks like a UUID, it's probably an application ID
  const anchor = useLocation().hash.replace("#", "")
  const currentApplicationId = anchor.match(/^[0-9a-f-]{36}$/) ? anchor : null
  const currentApplication = useSingleApplication(
    moderatedStudyId,
    currentApplicationId
  )

  const { data: bookingsData } = useGetModeratedStudyBookings(
    { pathParams: { moderatedStudyId: moderatedStudyId } },
    { refetchInterval: REFETCH_INTERVAL }
  )

  const teamMembers = useTeamMembers({
    onlyActive: true,
    ignoreRoles: ["guest", "archived"],
  })

  // Show/hide the drawer based on whether there is a current application id in the URL
  // This can get out of sync when using the back button to navigate history
  // (or on initial page load)
  useEffect(() => {
    if (drawerContext.disclosure.isOpen && !currentApplicationId) {
      drawerContext.disclosure.onClose()
    } else if (!drawerContext.disclosure.isOpen && currentApplicationId) {
      drawerContext.disclosure.onOpen()
    }
  }, [drawerContext.disclosure.isOpen, currentApplicationId])

  if (currentlyVisibleApplicationIds === null) return null

  const currentBooking = bookingsData?.find(
    (b) => b.moderated_study_application_id === currentApplicationId
  )

  const { nextId: nextApplicationId, prevId: previousApplicationId } =
    cycleThroughArray({
      list: currentlyVisibleApplicationIds,
      currentId: currentApplicationId,
    })

  const drawerData =
    !currentApplication || !moderatedStudyData
      ? undefined
      : extractDrawerData({
          currentApplication,
          currentBooking,
          moderatedStudyData,
          teamMembers,
        })

  const canDecline = isDeclinableStatus(currentApplication?.status)
  const prefersReducedMotion = usePrefersReducedMotion()

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={() => {
        // Clear hash just to be tidy, but don't trigger a navigation or re-render
        window.location.hash = ""
        onClose()
      }}
      returnFocusOnClose={false}
      blockScrollOnMount={false}
    >
      <DrawerOverlay />
      <DrawerContent
        textColor="text.primary"
        maxW="45rem"
        data-qa="participant-info-drawer"
        motionProps={
          prefersReducedMotion ? { transformTemplate: () => "none" } : undefined
        }
      >
        <ParticipantDrawerHeader>
          <Flex gap={2}>
            <IconButton
              aria-label="View previous participant"
              icon={<ChevronUpOutlineIcon boxSize={6} />}
              variant="outline"
              color="brand.neutral.light"
              onClick={() => {
                if (previousApplicationId) {
                  openParticipantInfoDrawer(previousApplicationId)
                }
              }}
            />
            <IconButton
              aria-label="View previous participant"
              icon={<ChevronDownOutlineIcon boxSize={6} />}
              variant="outline"
              color="brand.neutral.light"
              onClick={() => {
                if (nextApplicationId) {
                  openParticipantInfoDrawer(nextApplicationId)
                }
              }}
            />
          </Flex>
          <DrawerCloseButton
            position="static"
            size="md"
            color="brand.neutral.light"
            boxSize={10}
            border="1px solid"
            borderColor="gray.300"
            shadow="sm"
          />
        </ParticipantDrawerHeader>

        <DrawerBody p={0}>
          {!drawerData ? (
            <Flex height="full" justify="center" align="center">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <>
              <NameAndAvatar {...drawerData} />
              <Accordion
                index={accordionOpenIndexes}
                allowMultiple
                onChange={(expandedIndex) => {
                  setAccordionOpenIndexes(expandedIndex as number[])
                }}
              >
                <Attributes {...drawerData} />
                <SessionDetails {...drawerData} />
                <ScreenerAnswers {...drawerData} />
              </Accordion>
            </>
          )}
        </DrawerBody>
        <DrawerFooter
          py={0}
          px={6}
          h="4.5rem"
          boxShadow="0 -6px 10px rgb(0 0 0 / 5%)"
          clipPath="inset(-16px 0 0 0)"
          justifyContent="flex-start"
        >
          {drawerData && currentApplicationId && (
            <Flex gap={4}>
              {/* If the applicant is already invited, show a revoke button instead of invite button */}
              {currentApplication &&
                currentApplication.status === "invited" && (
                  <RevokeInvitationButton
                    selectedApplication={currentApplication}
                  />
                )}

              {/* Otherwise, show the invite button (which might be disabled for a variety of reasons) */}
              {currentApplication &&
                currentApplication.status !== "invited" && (
                  <SendInvitationButton
                    selectedApplications={[currentApplication]}
                    leftIcon={<Send01SolidIcon />}
                  />
                )}

              <DeclineButton
                applicationToDecline={{
                  id: currentApplicationId,
                  is_panelist: drawerData.attributes.isPanelist,
                }}
                canDecline={canDecline}
                bookingStatus={drawerData.attributes.bookingStatus}
              />
            </Flex>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

const NameAndAvatar: React.FC<Pick<DrawerData, "attributes">> = ({
  attributes,
}) => (
  <DrawerSection>
    <Flex grow={1} gap={4} flexDirection="column" minH={12}>
      <Flex align="baseline" gap={2}>
        <Heading fontSize="2xl" fontWeight="bold">
          {attributes.isPanelist
            ? attributes.preferredName
            : attributes.fullName}
        </Heading>
        <Tooltip
          label={
            attributes.isPanelist
              ? "Recruited from panel"
              : "Recruited via link"
          }
        >
          {attributes.isPanelist ? (
            <PanelOrderCompositeIcon size={6} isRounded />
          ) : (
            <RecruitmentLinkCompositeIcon size={6} isRounded />
          )}
        </Tooltip>
      </Flex>
      {attributes.isPanelist || (
        <DetailsGrid>
          <FieldTitle>Preferred name</FieldTitle>
          <FieldValueText>{attributes.preferredName}</FieldValueText>
          {attributes.email && (
            <>
              <FieldTitle>Email</FieldTitle>
              <FieldValueText>{attributes.email}</FieldValueText>
            </>
          )}
        </DetailsGrid>
      )}
    </Flex>
  </DrawerSection>
)

const Attributes: React.FC<Pick<DrawerData, "attributes">> = ({
  attributes,
}) => {
  const { data: demographics } = useListDemographics({})
  const allLocations = useFlatLocations("interviews")
  const panelistLocation = attributes.location
  const locationData =
    panelistLocation && allLocations[panelistLocation.type][panelistLocation.id]

  return (
    <AccordionItem>
      <AccordionButton {...accordionButtonStyles}>
        <ParticipantDrawerAccordionItemHeading>
          Attributes
        </ParticipantDrawerAccordionItemHeading>
        <AccordionIcon color="brand.neutral.light" boxSize={7} />
      </AccordionButton>
      <AccordionPanel>
        <DetailsGrid px={2}>
          <FieldTitle>Eligibility</FieldTitle>
          <FieldValueGeneric>
            <EligibilityStatus status={attributes.eligibilityStatus} />
          </FieldValueGeneric>
          <FieldTitle>Status</FieldTitle>
          <FieldValueGeneric>
            <ParticipantBookingStateBadge status={attributes.bookingStatus} />
          </FieldValueGeneric>
          {locationData && (
            <>
              <FieldTitle>Location</FieldTitle>
              <FieldValueText>{locationData.qualifiedName}</FieldValueText>
            </>
          )}
          {attributes.age !== null && (
            <>
              <FieldTitle>Age</FieldTitle>
              <FieldValueText>{attributes.age}</FieldValueText>
            </>
          )}

          {demographics &&
            getActiveDemographicAttributes(
              attributes.demographic_attribute_option_ids,
              demographics
            ).map((attribute) => (
              <Fragment key={attribute.name}>
                <FieldTitle>{attribute.name}</FieldTitle>
                <FieldValueText>
                  {attribute.options.map((o) => o.value).join(", ")}
                </FieldValueText>
              </Fragment>
            ))}
          <FieldTitle>Source</FieldTitle>
          <FieldValueText>
            {[
              attributes.source.name,
              formatRecruitmentMethod(attributes.method),
            ]
              .join(" ")
              .trim()}
          </FieldValueText>
          {attributes.incentive && (
            <>
              <FieldTitle>Incentive</FieldTitle>
              <FieldValueText>{attributes.incentive}</FieldValueText>
            </>
          )}
        </DetailsGrid>
      </AccordionPanel>
    </AccordionItem>
  )
}

const SessionDetails: React.FC<Pick<DrawerData, "sessionDetails">> = ({
  sessionDetails,
}) =>
  sessionDetails && (
    <AccordionItem>
      <AccordionButton {...accordionButtonStyles}>
        <ParticipantDrawerAccordionItemHeading>
          Session details
        </ParticipantDrawerAccordionItemHeading>
        <AccordionIcon color="brand.neutral.light" boxSize={7} />
      </AccordionButton>
      <AccordionPanel>
        <DetailsGrid px={2}>
          <FieldTitle>Date & time</FieldTitle>
          <FieldValueText>{sessionDetails.bookingTime}</FieldValueText>
          <FieldTitle>Location</FieldTitle>
          <Flex gap={3} as="dd" alignItems="center">
            <Text color={sessionDetails.location ? "text.primary" : "red.500"}>
              {sessionDetails.location ? "Online meeting" : "No meeting link"}
            </Text>
            {/* TODO make this editable */}
            {/* <Pencil02SolidIcon color="brand.neutral.light" boxSize={3.5} /> */}
          </Flex>
          <FieldTitle>Main host</FieldTitle>
          <FieldValueGeneric gap={2}>
            {/* TODO make the host editable/removable */}
            {sessionDetails.hosts?.map((h, i) => (
              <UserBadge
                key={h?.id ?? `user-badge-${i}`}
                user={h}
                handleRemove={undefined}
                showRemoveButton={false}
              />
            ))}
            {/* <IconButton
          aria-label="Add host"
          variant="outline"
          size="sm"
          color="brand.neutral.light"
          icon={<PlusSolidIcon boxSize={5} />}
        /> */}
          </FieldValueGeneric>
        </DetailsGrid>
      </AccordionPanel>
    </AccordionItem>
  )

const ScreenerAnswers: React.FC<Pick<DrawerData, "screenerAnswers">> = ({
  screenerAnswers,
}) =>
  screenerAnswers && (
    <AccordionItem>
      <AccordionButton {...accordionButtonStyles}>
        <ParticipantDrawerAccordionItemHeading>
          Screener answers
        </ParticipantDrawerAccordionItemHeading>
        <AccordionIcon color="brand.neutral.light" boxSize={7} />
      </AccordionButton>
      <AccordionPanel>
        {screenerAnswers.map((a, i) => {
          return a ? (
            <ParticipantDrawerScreenerAnswer
              key={i}
              {...a}
              questionIndex={i + 1}
            />
          ) : null
        })}
      </AccordionPanel>
    </AccordionItem>
  )

// This hook returns information about a single application.
// It uses the applications list API to retrieve the application, but if possible we are pre-populating
// the query data with information from the query cache for the visible applications.
const useSingleApplication = (
  moderatedStudyId: string,
  applicationId: string | null
) => {
  const queryClient = useQueryClient()
  let serializedFilters: ReturnType<typeof serializeAppliedFilter>[]

  // We are inside a FilterContext here if we're on the Applicants page, but not if
  // we're on the sessions page. In the latter case we won't have any applicant data
  // in the cache to use, so we'll fetch each one individually.
  try {
    const { filters } = useFilterContext()
    serializedFilters = filters.map(serializeAppliedFilter)
  } catch (e) {
    serializedFilters = []
  }

  const { data: applicationsData } = useGetModeratedStudyApplications(
    {
      pathParams: { moderatedStudyId },
      body: {
        filters: [
          {
            attribute: "id",
            comparator: "eq",
            // The query is only enabled if this is non-null, but typescript doesn't know that
            values: [applicationId ?? ""],
          },
        ],
      },
    },
    {
      enabled: applicationId !== null,
      refetchInterval: REFETCH_INTERVAL,
      staleTime: REFETCH_INTERVAL,
      initialData: () => {
        const queryDataFromApplicationsTable = queryClient.getQueryData<
          UseInfiniteQueryResult<
            GetModeratedStudyApplicationsResponse,
            GetModeratedStudyApplicationsError
          >["data"]
        >([
          "api",
          "moderated_studies",
          moderatedStudyId,
          "applications",
          {},
          { filters: serializedFilters },
        ])

        if (!queryDataFromApplicationsTable) return undefined

        const application = queryDataFromApplicationsTable.pages
          .flatMap((page) => page.applications)
          .find((application) => application.id === applicationId)

        return application
          ? {
              applications: [application],
              next_page: null,
              total_records: 1,
              screener_questions: [],
              total_records_without_filters: 1,
            }
          : undefined
      },
    }
  )

  return applicationsData?.applications.find((a) => a.id === applicationId)
}

import { groupBy } from "lodash"
import React from "react"

import { NoResponse } from "Components/individual-response/individual-section-result/no-data"
import { useFilteredClicks } from "Components/test-results/hooks/use-filtered-clicks"
import {
  SummaryItem,
  SummaryList,
} from "Components/test-results/section-results/section-screenshot-summary-list"
import { minZip } from "Utilities/array"

import { TaskProps } from "../task-props"

import { NavigationTestResultsStepSummary } from "./individual-navigation-test-step-summary"

export const NavigationSectionIndividualResults = ({
  responseSection,
  usabilityTestSection,
}: TaskProps) => {
  const filteredClicksBySectionScreenshotId = groupBy(
    useFilteredClicks(),
    (c) => c.usability_test_section_screenshot_id
  )
  const mappedClicks =
    responseSection === null
      ? []
      : usabilityTestSection.section_screenshots.map(
          (ss) =>
            (filteredClicksBySectionScreenshotId[ss.id] || []).find(
              (click) => click.response_id === responseSection.response_id
            ) || null
        )

  const sectionScreenshots = usabilityTestSection.section_screenshots
  const summaryNodes =
    responseSection &&
    minZip(sectionScreenshots, mappedClicks).map(
      ([sectionScreenshot, click], index) => {
        return (
          <SummaryItem key={sectionScreenshot.id}>
            <NavigationTestResultsStepSummary
              sectionScreenshot={sectionScreenshot}
              deviceFrameId={usabilityTestSection.device_frame_id}
              click={click}
              stepNumber={index + 1}
            />
          </SummaryItem>
        )
      }
    )

  return responseSection === null ? (
    <NoResponse />
  ) : (
    <SummaryList>{summaryNodes}</SummaryList>
  )
}

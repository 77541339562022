import { Box, IconButton, ListItem } from "@chakra-ui/react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import React from "react"

import { DragHandleIcon } from "Shared/icons/DragHandleIcon"
import {
  ScreenerQuestion,
  ScreenerQuestionType,
} from "~/api/generated/usabilityhubSchemas"

import { ScreenerQuestionForm } from "./ScreenerQuestionForm"

type Props = {
  screenerQuestion: ScreenerQuestion
  questionPosition: number
  onRemove: () => void
  onUpdate: (
    updatedScreenerQuestion: ScreenerQuestion,
    position: number
  ) => Promise<void>
  onDuplicate: () => void
  formStateCallback?: (
    questionId: string,
    isDirty: boolean,
    isSubmitting: boolean,
    errors: Record<string, unknown>
  ) => void
  onFormChange?: (newState: unknown) => void
  renderFormElement?: boolean
  validQuestionTypes: ScreenerQuestionType[]
  readOnly: boolean
  maxScreenerQuestionsReached: boolean
}

export const SortableScreenerQuestionForm: React.FC<Props> = ({
  screenerQuestion,
  questionPosition,
  onRemove,
  onUpdate,
  onDuplicate,
  formStateCallback,
  onFormChange,
  renderFormElement,
  validQuestionTypes,
  readOnly,
  maxScreenerQuestionsReached,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: screenerQuestion.id, disabled: readOnly })

  const style = {
    // Using `CSS.Translate` instead of `CSS.Transform` since the items can be variable heights and
    // we don't want them to warp.
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1 : 0,
  }

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      display="flex"
      data-qa={`screener-question-question-form-${questionPosition}`}
      borderBottomWidth={1}
      borderColor="gray.200"
    >
      <Box flexGrow={1}>
        <ScreenerQuestionForm
          screenerQuestion={screenerQuestion}
          onRemove={onRemove}
          onUpdate={onUpdate}
          onDuplicate={onDuplicate}
          questionPosition={questionPosition}
          formStateCallback={formStateCallback}
          onFormChange={onFormChange}
          renderFormElement={renderFormElement}
          validQuestionTypes={validQuestionTypes}
          readOnly={readOnly}
          maxScreenerQuestionsReached={maxScreenerQuestionsReached}
        >
          <IconButton
            minW="auto"
            {...listeners}
            {...attributes}
            variant="ghost"
            size="sm"
            aria-label="drag question"
            icon={<DragHandleIcon w={5} h={5} />}
            _hover={{ cursor: "grab" }}
          />
        </ScreenerQuestionForm>
      </Box>
    </ListItem>
  )
}

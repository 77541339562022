import { isEmpty } from "lodash"

import { FigmaFileVersion, Screenshot, UsabilityTest } from "Types"

import { validateSection } from "./validate-section"
import { validateSections } from "./validate-sections"

export const validateTest = (
  test: UsabilityTest,
  props: {
    screenshots: readonly Screenshot[]
    figmaFileVersions: readonly FigmaFileVersion[]
  }
): Record<string, unknown> => {
  if (isEmpty(test)) return {}
  return {
    sections: {
      _error: validateSections(test.sections),
      ...test.sections.map((section) =>
        validateSection(
          section,
          test,
          props.screenshots,
          props.figmaFileVersions
        )
      ),
    },
  }
}

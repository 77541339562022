import { ReactNode } from "react"

import {
  ClickSelection,
  QuestionType,
  ResponseSection,
  ResponseSectionFigmaTask,
  ResultsViewState,
  TestResultsFigmaFileVersion,
  UsabilityTest,
  UsabilityTestSectionType,
} from "Types"

import {
  categoryHasId,
  getLabel,
} from "../section-results/SectionResultsCards/CardSortCategories/grouping"
import { getCommonPathIndex } from "../utils/response-section-figma-task/get-common-path-index"

import { getClientHitzoneByForSameScreenshotSection } from "./client-hitzone"
import { createFilterOption } from "./create-filter-option"
import { FilterValue } from "./filter-option-label"
import { AnswerTagFilter } from "./filters/answer-tags"
import { CardSortFilter } from "./filters/card-sort"
import { ClientHitzoneFilter } from "./filters/client-hitzone"
import { CommonPathFilter } from "./filters/common-path"
import { ExactAnswerFilter } from "./filters/exact-answer"
import { HitzoneFilter } from "./filters/hitzone"
import { OtherAnswerFilter } from "./filters/other-answer"
import { OtherHitzoneFilter } from "./filters/other-hitzone"
import { PreferenceAnswerFilter } from "./filters/preference-answer"
import { PrototypeNodeFilter } from "./filters/prototype-node"
import { RankedAnswerFilter } from "./filters/ranked-answer"
import { ReachedGoalScreenFilter } from "./filters/reached-goal-screen"
import { TreeTestFilter } from "./filters/tree-test"
import { ResponseFilter } from "./filters/types"
import { getFilterMeta } from "./get-filter-meta"
import {
  getHitzoneIndexById,
  getScreenshotSectionByHitzoneId,
  getSectionByHitzoneId,
} from "./hitzone"
import { getQuestionByQuestionId } from "./question"
import {
  getScreenshotSectionByScreenshotId,
  getSectionByScreenshotSectionId,
  getSectionIndexByScreenshotSectionId,
} from "./screenshot-section"

interface FilterOption {
  label: ReactNode
  clearAction: () => void
}

type RelatedData = {
  test: UsabilityTest
  responseSections: ResponseSection[]
  clientHitzones: ClickSelection[]
  tasks: ResponseSectionFigmaTask[]
  figmaFileVersions: TestResultsFigmaFileVersion[]
}

const getSectionIndex = (sectionId: number, test: UsabilityTest) => {
  return test.sections.findIndex((section) => section.id === sectionId)
}

const getQuestion = (questionId: number, test: UsabilityTest) => {
  return test.sections.reduce((result, section) => {
    if (result) return result

    return section.questions.find((question) => question.id === questionId)
  }, undefined)
}

const getQuestionMeta = (questionId: number, test: UsabilityTest) => {
  return test.sections.reduce((result, section, index) => {
    if (result) return result

    const questionIndex = section.questions.findIndex(
      (question) => question.id === questionId
    )

    if (questionIndex >= 0) {
      return {
        sectionIndex: index,
        questionIndex: questionIndex,
      }
    }

    return undefined
  }, undefined)
}

const getExactAnswerFilterValues = (
  exactAnswerFilter: ExactAnswerFilter,
  { test }: RelatedData
): FilterValue => {
  const { questionId } = getFilterMeta(exactAnswerFilter.category)
  const { sectionIndex, questionIndex } = getQuestionMeta(questionId, test)!

  const question = getQuestionByQuestionId(questionId, test)!

  const type: FilterValue["type"] =
    question.type === QuestionType.CheckboxMultipleChoice
      ? "answer-checkbox"
      : "answer-radio"

  return {
    filterId: exactAnswerFilter.id,
    type,
    sectionIndex,
    questionIndex,
    label: exactAnswerFilter.value ?? "Passed",
  }
}

const getOtherAnswerFilterValues = (
  otherAnswerFilter: OtherAnswerFilter,
  { test }: RelatedData
): FilterValue => {
  const { questionId } = getFilterMeta(otherAnswerFilter.category)
  const { sectionIndex, questionIndex } = getQuestionMeta(questionId, test)!

  const question = getQuestionByQuestionId(questionId, test)!

  const type: FilterValue["type"] =
    question.type === QuestionType.CheckboxMultipleChoice
      ? "answer-checkbox"
      : "answer-radio"

  return {
    filterId: otherAnswerFilter.id,
    type,
    sectionIndex,
    questionIndex,
    label: "Other",
  }
}

const getCommonPathFilterValues = (
  commonPathFilter: CommonPathFilter,
  relatedData: RelatedData,
  resultsViewState: ResultsViewState
): FilterValue => {
  const { sectionId } = getFilterMeta(commonPathFilter.category)
  const { test, tasks } = relatedData
  const commonPathIndex = getCommonPathIndex(tasks, commonPathFilter.value)

  const commonPathName =
    resultsViewState.commonPathNames[sectionId][commonPathFilter.value] ||
    `Common path ${commonPathIndex + 1}`

  return {
    filterId: commonPathFilter.id,
    type: "common-path",
    sectionIndex: getSectionIndex(sectionId, test)!,
    label: commonPathName,
  }
}

const getRankedAnswersFilterValues = (
  rankedAnswersFilter: RankedAnswerFilter,
  { test }: RelatedData
): FilterValue => {
  const { questionId } = getFilterMeta(rankedAnswersFilter.category)
  const { sectionIndex, questionIndex } = getQuestionMeta(questionId, test)!

  return {
    filterId: rankedAnswersFilter.id,
    type: "ranked",
    sectionIndex,
    questionIndex,
    label: `${
      rankedAnswersFilter.value.answer
    } (${rankedAnswersFilter.value.ranks.map((rank) => rank + 1).join(",")})`,
  }
}

const getAnswerTagFilterValues = (
  answerTagFilter: AnswerTagFilter,
  { test }: RelatedData
): FilterValue => {
  const { questionId } = getFilterMeta(answerTagFilter.category)

  const { sectionIndex, questionIndex } = getQuestionMeta(questionId, test)!
  const question = getQuestion(questionId, test)!

  // We use the -1 tag id to represent the "Untagged" tag
  const tagName =
    answerTagFilter.value === -1
      ? "Untagged"
      : question.question_tags.find((tag) => tag.id === answerTagFilter.value)!
          .name

  return {
    filterId: answerTagFilter.id,
    type: "tag",
    sectionIndex,
    questionIndex,
    label: tagName,
  }
}

const getPreferenceAnswerFilterValues = (
  preferenceAnswerFilter: PreferenceAnswerFilter,
  { test }: RelatedData
): FilterValue => {
  const { sectionId } = getFilterMeta(preferenceAnswerFilter.category)
  const sectionIndex = getSectionIndex(sectionId, test)!

  const section = test.sections.find((section) => section.id === sectionId)!

  const screenshot = section.section_screenshots.find(
    (sectionScreenshot) => sectionScreenshot.id === preferenceAnswerFilter.value
  )!

  return {
    filterId: preferenceAnswerFilter.id,
    type: "preference",
    sectionIndex,
    label: screenshot.screenshot_name,
  }
}

const getScreenshotHitzonesFilterValues = (
  screenshotHitzones: HitzoneFilter,
  { test }: RelatedData
): FilterValue => {
  const section = getSectionByHitzoneId(screenshotHitzones.value, test)!
  const sectionIndex = getSectionIndex(section.id, test)!
  const sectionScreenshot = getScreenshotSectionByHitzoneId(
    screenshotHitzones.value,
    test
  )!
  const hitzoneIndex = getHitzoneIndexById(screenshotHitzones.value, test)!

  const isNavigationTest =
    section.type === UsabilityTestSectionType.NavigationTest

  return {
    filterId: screenshotHitzones.id,
    type: "hitzone",
    sectionIndex,
    stepIndex: isNavigationTest ? sectionScreenshot.position : undefined,
    label: `Highlight #${hitzoneIndex + 1}`,
  }
}

const getScreenshotClientHitzonesFilterValues = (
  screenshotClientHitzones: ClientHitzoneFilter,
  { clientHitzones, test }: RelatedData
): FilterValue => {
  const { screenshotId } = getFilterMeta(screenshotClientHitzones.category)

  const section = getSectionByScreenshotSectionId(screenshotId, test)!

  const screenshotSection = getScreenshotSectionByScreenshotId(
    screenshotId,
    test
  )!

  // get a list of client hitzone id, which refer to the same screenshot section
  const clientHitzoneIds = getClientHitzoneByForSameScreenshotSection(
    screenshotClientHitzones.value,
    test,
    clientHitzones
  )!

  // find where in the list the current client hitzone is
  const clientHitzoneIndex = clientHitzoneIds.findIndex(
    (id) => id === screenshotClientHitzones.value
  )

  const isNavigationTest =
    section.type === UsabilityTestSectionType.NavigationTest

  const areaNumber =
    screenshotSection.screenshot_hitzones.length + clientHitzoneIndex + 1

  const sectionIndex = getSectionIndex(
    screenshotSection.usability_test_section_id,
    test
  )

  return {
    filterId: screenshotClientHitzones.id,
    type: "hitzone",
    sectionIndex,
    stepIndex: isNavigationTest ? screenshotSection.position : undefined,
    label: `Highlight #${areaNumber}`,
  }
}

const getScreenshotOtherFilterValues = (
  screenshotOther: OtherHitzoneFilter,
  { test }: RelatedData
): FilterValue => {
  const { screenshotId } = getFilterMeta(screenshotOther.category)
  const section = getSectionByScreenshotSectionId(screenshotId, test)!

  const screenshotSection = getScreenshotSectionByScreenshotId(
    screenshotId,
    test
  )!

  const sectionIndex = getSectionIndexByScreenshotSectionId(screenshotId, test)

  const isNavigationTest =
    section.type === UsabilityTestSectionType.NavigationTest

  return {
    filterId: screenshotOther.id,
    type: "hitzone",
    sectionIndex,
    stepIndex: isNavigationTest ? screenshotSection.position : undefined,
    label: "Other",
  }
}

const getCardSortFilterValues = (
  cardSortFilter: CardSortFilter,
  { test }: RelatedData
): FilterValue => {
  const { sectionId } = getFilterMeta(cardSortFilter.category)
  const sectionIndex = getSectionIndex(sectionId, test)

  const section = test.sections.find((section) => section.id === sectionId)
  const { cardId, categoryIds } = cardSortFilter.value
  const card = section?.card_sort_attributes?.card_sort_cards_attributes.find(
    (card) => card.id === cardId
  )
  const category =
    section?.card_sort_attributes?.card_sort_categories_attributes.find(
      (category) => Number(category.id) === categoryIds[0]
    ) ??
    section?.card_sort_attributes?.card_sort_open_categories_attributes.find(
      (category) => Number(category.id) === categoryIds[0]
    )

  const groups = section?.card_sort_attributes?.card_sort_category_groups ?? []
  const categoryLabel =
    category && categoryHasId(category) ? getLabel(category, groups) : "?"

  return {
    filterId: cardSortFilter.id,
    type: "card-sort",
    sectionIndex,
    label:
      category === undefined
        ? ""
        : `${card?.label ?? cardId} + ${categoryLabel}`,
  }
}

const getTreeTestFilterValues = (
  cardSortFilter: TreeTestFilter,
  { test }: RelatedData
): FilterValue => {
  const { sectionId } = getFilterMeta(cardSortFilter.category)
  const sectionIndex = getSectionIndex(sectionId, test)

  const section = test.sections.find((section) => section.id === sectionId)
  const { finalNodeId } = cardSortFilter.value

  return {
    filterId: cardSortFilter.id,
    type: "tree-test",
    sectionIndex,
    label: finalNodeId
      ? section?.tree_test_attributes?.nodes?.find(
          (node) => node.id === finalNodeId
        )?.label ?? `Node ${finalNodeId}`
      : "(Skipped)",
  }
}

const getReachedGoalScreenFilterValues = (
  reachedGoalScreenFilter: ReachedGoalScreenFilter,
  { test }: RelatedData
): FilterValue => {
  const { sectionId } = getFilterMeta(reachedGoalScreenFilter.category)

  return {
    filterId: reachedGoalScreenFilter.id,
    type: "reached-goal-screen",
    sectionIndex: getSectionIndex(sectionId, test)!,
    label: "Reached goal screen",
  }
}

const getPrototypeNodeFilterValues = (
  filter: PrototypeNodeFilter,
  { test, figmaFileVersions }: RelatedData
): FilterValue => {
  const { sectionId } = getFilterMeta(filter.category)

  const section = test.sections.find((section) => section.id === sectionId)
  const figmaFileVersion = figmaFileVersions.find(
    (figmaFileVersion) =>
      section?.figma_file_flow?.figma_file_version_id === figmaFileVersion.id
  )

  const figmaImage = figmaFileVersion?.images.find(
    (figmaImage) => figmaImage.figma_node_id === filter.value
  )

  const nodeTitle = figmaImage?.title ?? "Untitled Screen"

  return {
    filterId: filter.id,
    type: "prototype-node",
    sectionIndex: getSectionIndex(sectionId, test)!,
    label: `Screen: ${nodeTitle}`,
  }
}

type UpdateFilterFn = (
  updateFilter: (filters: ResponseFilter[]) => ResponseFilter[]
) => void

const getFilterOption = (
  filterValue: FilterValue,
  updateFilter: UpdateFilterFn
) => {
  return createFilterOption(filterValue, updateFilter)
}

const bySection = (a: FilterValue, b: FilterValue) => {
  const indexDelta = a.sectionIndex - b.sectionIndex

  if (indexDelta === 0) return (a.questionIndex ?? 0) - (b.questionIndex ?? 0)

  return indexDelta
}

export const getOptionsFromFilter = (
  filters: ResponseFilter[],
  relatedData: RelatedData,
  updateFilter: UpdateFilterFn,
  resultsViewState: ResultsViewState
): FilterOption[] => {
  const answerFilters = filters.filter((filter) =>
    filter.category.startsWith("answer/")
  )
  const filterValues = answerFilters.map((filter) => {
    if (filter.type === "answer/exact-answer") {
      return getExactAnswerFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/other-answer") {
      return getOtherAnswerFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/common-path") {
      return getCommonPathFilterValues(filter, relatedData, resultsViewState)
    }

    if (filter.type === "answer/ranked") {
      return getRankedAnswersFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/tag") {
      return getAnswerTagFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/preference") {
      return getPreferenceAnswerFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/hitzone") {
      return getScreenshotHitzonesFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/client-hitzone") {
      return getScreenshotClientHitzonesFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/other-hitzone") {
      return getScreenshotOtherFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/card-sort") {
      return getCardSortFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/tree-test") {
      return getTreeTestFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/reached-goal-screen") {
      return getReachedGoalScreenFilterValues(filter, relatedData)
    }

    if (filter.type === "answer/prototype-node") {
      return getPrototypeNodeFilterValues(filter, relatedData)
    }
  })

  return filterValues
    .sort(bySection)
    .map((filterValue) => getFilterOption(filterValue!, updateFilter))
}

import { Box, Divider, Grid, Stack, Tabs } from "@chakra-ui/react"
import React, { useState } from "react"

import { FigmaTaskScreenResult } from "Types"

import { useTaskGoalNodeId } from "../hooks/use-goal-node-id"
import { useSetTaskGoalNodeId } from "../hooks/use-set-goal-node-id"

import { ClickOccurrenceFilterProvider } from "./ClickOccurrenceFilterProvider"
import { ScreenResultsScreenDetailsBar } from "./ScreenResultsScreenDetailsBar"
import { ScreenStartGoalSelection } from "./ScreenStartGoalSelection"
import { useArrowKeys } from "./useArrowKeys"

import { ScreenResultDetails } from "./ScreenResultDetails"

export type ScreenView = "IMAGE" | "HEAT_MAP" | "CLICK_MAP"

interface ScreenResultsProps {
  screenResults: FigmaTaskScreenResult[]
  totalNumberOfParticipants: number
  showDetailsBar: boolean
}

export const ScreenResults: React.FC<
  React.PropsWithChildren<ScreenResultsProps>
> = ({ screenResults, totalNumberOfParticipants, showDetailsBar }) => {
  const taskGoalNodeId = useTaskGoalNodeId()
  const setTaskGoalId = useSetTaskGoalNodeId()

  const [selectedScreenResultIndex, setSelectedScreenResultIndex] = useState(0)
  const [hasKeyNavigationEnabled, setHasKeyNavigationEnabled] = useState(false)

  useArrowKeys(hasKeyNavigationEnabled, {
    onLeftArrow: () => {
      setSelectedScreenResultIndex((current) => Math.max(current - 1, 0))
    },
    onRightArrow: () => {
      setSelectedScreenResultIndex((current) =>
        Math.min(current + 1, screenResults.length - 1)
      )
    },
  })

  const selectedScreenResult = screenResults[selectedScreenResultIndex]

  return (
    <ClickOccurrenceFilterProvider>
      <Stack
        spacing={5}
        rounded="md"
        borderWidth={1}
        borderColor="gray.200"
        onFocus={() => setHasKeyNavigationEnabled(true)}
        onBlur={() => setHasKeyNavigationEnabled(false)}
      >
        <Tabs
          index={selectedScreenResultIndex}
          onChange={setSelectedScreenResultIndex}
        >
          <Box position="relative" py={2} maxHeight="23rem" overflowY="scroll">
            <Box overflowX="auto" display="block" pb={2}>
              <Grid
                paddingX="4"
                columnGap="4"
                templateColumns="repeat(auto-fit, 160px)"
                autoRows="minmax(164px, auto)"
              >
                {screenResults.map((screenResult, screenResultIndex) => {
                  return (
                    <ScreenStartGoalSelection
                      key={screenResult.nodeId}
                      tabNodeIndex={screenResultIndex}
                      screenResult={screenResult}
                      isSelected={
                        selectedScreenResultIndex === screenResultIndex
                      }
                      goalNodeId={taskGoalNodeId}
                      setGoalNodeId={setTaskGoalId}
                      onSelect={(selectedDomElement: HTMLElement | null) => {
                        setSelectedScreenResultIndex(screenResultIndex)
                        selectedDomElement?.focus()
                      }}
                    />
                  )
                })}
              </Grid>
            </Box>
          </Box>
          {showDetailsBar && (
            <>
              <Divider />
              <ScreenResultsScreenDetailsBar
                screenTitle={selectedScreenResult.figmaImage.title ?? null}
                selectedNodeId={selectedScreenResult.nodeId}
                numberOfParticipants={
                  selectedScreenResult.meta.participants.length
                }
                totalNumberOfParticipants={totalNumberOfParticipants}
              />
              <Divider />
            </>
          )}

          <ScreenResultDetails.Provider screenResult={selectedScreenResult}>
            <ScreenResultDetails.Tabs />
            <ScreenResultDetails.ExpandablePanel />
            <ScreenResultDetails.StatusBar />
          </ScreenResultDetails.Provider>
        </Tabs>
      </Stack>
    </ClickOccurrenceFilterProvider>
  )
}

import { Image, useDisclosure } from "@chakra-ui/react"
import { Link, Text } from "@chakra-ui/react"
import Image1 from "Images/app-illustrations/usercrowd-recordings/uc-recordings-onboarding-1.png"
import Image2 from "Images/app-illustrations/usercrowd-recordings/uc-recordings-onboarding-2.png"
import Image3 from "Images/app-illustrations/usercrowd-recordings/uc-recordings-onboarding-3.png"
import Image4 from "Images/app-illustrations/usercrowd-recordings/uc-recordings-onboarding-4.png"
import {
  OnboardingFlow,
  OnboardingFlowIndividualPage,
} from "Shared/components/OnboardingFlow/OnboardingFlow"
import { ROUTES } from "UserCrowd/views/routes"
import React, { forwardRef, useImperativeHandle } from "react"
import { Helmet } from "react-helmet"

const PAGES: OnboardingFlowIndividualPage[] = [
  {
    content: {
      image: <Image src={Image1} />,
      heading: "Recordings are coming soon!",
      body: "We’re offering more ways to participate in research. Soon, you’ll have the option to take part in tests that record audio, video, and screen to capture your thoughts, actions, and emotions as you engage in research.",
    },
  },
  {
    content: {
      image: <Image src={Image2} />,
      heading: "What do I need to take part?",
      body: "Your device must have a webcam, microphone, and screen-sharing capability, which is available in most modern browsers.",
    },
  },
  {
    content: {
      image: <Image src={Image3} />,
      heading: "How is my privacy protected?",
      body: "Your privacy is important to us. Recordings are for research only, not public sharing. Any violation of our terms will result in the company being removed from the platform. You'll always know if recording is needed, and participation is entirely your choice.",
    },
  },
  {
    content: {
      image: <Image src={Image4} />,
      heading: "Unlock more rewards",
      body: (
        <>
          <Text mb={4}>
            We understand that recorded tests require a little more from
            participants. That’s why we offer a higher incentive to participate
            in these tests.
          </Text>
          <Link
            href={ROUTES.SETTINGS.NOTIFICATIONS.path}
            color="ds.text.subtle"
            textDecoration="none"
            _hover={{ color: "ds.text.default", textDecoration: "underline" }}
          >
            Opt out of tests like these
          </Link>
        </>
      ),
    },
    nextButtonLabel: "Looks good!",
  },
]

type RecordingsOnboardingProps = {
  onSeen: () => void
}

export type RecordingsOnboardingHandle = ReturnType<typeof useDisclosure>

export const RecordingsOnboarding = forwardRef<
  RecordingsOnboardingHandle,
  RecordingsOnboardingProps
>(({ onSeen }, ref) => {
  const disclosure = useDisclosure()

  useImperativeHandle(ref, () => disclosure)

  return (
    <>
      <Helmet>
        <link rel="preload" href={Image1} as="image" />
        <link rel="preload" href={Image2} as="image" />
        <link rel="preload" href={Image3} as="image" />
        <link rel="preload" href={Image4} as="image" />
      </Helmet>
      <OnboardingFlow
        pages={PAGES}
        allowOverlayClick
        {...disclosure}
        onOpen={() => {
          onSeen()
          disclosure.onOpen()
        }}
        onFinish={disclosure.onClose}
      />
    </>
  )
})

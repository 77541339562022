import React from "react"

import {
  DesktopOnly,
  MobileOnly,
} from "Components/break-points/desktop-and-mobile-break-points"
import { TextField } from "Components/redux-form/text"
import {
  AnswerForm,
  Props,
} from "UsabilityHub/components/UsabilityTestSectionQuestion/answer-form"

export function ShortAnswerQuestion(props: Readonly<Props>) {
  return (
    <AnswerForm {...props}>
      <DesktopOnly>
        <TextField name="answer" autoFocus />
      </DesktopOnly>
      <MobileOnly>
        <TextField name="answer" />
      </MobileOnly>
    </AnswerForm>
  )
}

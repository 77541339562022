import {
  Box,
  Center,
  ComponentWithAs,
  Flex,
  Icon,
  IconProps,
  Text,
} from "@chakra-ui/react"
import { ThemedButton } from "Components/button/themed-button"
import { Heading } from "DesignSystem/components"
import { useTranslate } from "Shared/hooks/useTranslate"
import { MicrophoneIcon } from "Shared/icons/MicrophoneIcon"
import { MonitorIcon } from "Shared/icons/MonitorIcon"
import { CheckCircleSolidIcon } from "Shared/icons/untitled-ui/CheckCircleSolidIcon"
import React from "react"
import { RecordingType } from "~/api/generated/usabilityhubSchemas"
import { RecordingVolumeIndicator } from "./RecordingVolumeIndicator"

type RecordingPrompt = {
  labelKey: string
  promptKeys: string[]
  type: RecordingType
  icon: ComponentWithAs<"svg", IconProps>
}

const RECORDING_PROMPTS: RecordingPrompt[] = [
  {
    type: "microphone",
    labelKey: "test.recording.microphone.label",
    promptKeys: [
      "test.recording.microphone.prompt_environment",
      "test.recording.microphone.prompt_speak_aloud",
    ],
    icon: MicrophoneIcon,
  },
  {
    type: "screen",
    labelKey: "test.recording.screen.label",
    promptKeys: ["test.recording.screen.prompt"],
    icon: MonitorIcon,
  },
]

type Props = {
  sectionRecordingTypes: RecordingType[]
  onContinue: () => void
  isDisabled: boolean
}

export const SectionRecordingPrompt: React.FC<Props> = ({
  sectionRecordingTypes,
  onContinue,
  isDisabled,
}) => {
  const prompts = RECORDING_PROMPTS.filter((prompt) =>
    sectionRecordingTypes.includes(prompt.type)
  )

  const translate = useTranslate()

  const hasAudio = sectionRecordingTypes.includes("microphone")

  return (
    <Flex
      pos="absolute"
      w="100%"
      h="full"
      left={0}
      justifyContent="flex-end"
      transition="all 0.3s ease"
    >
      {hasAudio && (
        <Flex
          display={{ base: "none", lg: "flex" }}
          w="50%"
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <RecordingVolumeIndicator size="emphasized" />
        </Flex>
      )}
      <Box
        bg="white"
        w={{ base: "full", lg: "50%" }}
        h="full"
        p="0 min(50px, 8vw)"
      >
        <Center h="full">
          <Flex direction="column" gap={8} maxW="500px" color="gray.900">
            <Flex direction="column" gap={8}>
              <Flex
                direction="column"
                display={{ base: "flex", lg: "none" }}
                alignItems="center"
                py={8}
              >
                {hasAudio && <RecordingVolumeIndicator size="emphasized" />}
              </Flex>

              <Heading as="h2" textStyle="ds.display.primary">
                {translate("test.recording.section_prompt")}
              </Heading>
              <Flex direction="column" gap={8} role="list">
                {prompts.map((prompt, pindex) =>
                  prompt.promptKeys.map((line, lindex) => (
                    <Flex
                      key={`${pindex}-${lindex}`}
                      align="start"
                      gap={4}
                      role="listitem"
                    >
                      <Icon
                        as={CheckCircleSolidIcon}
                        boxSize="1.375rem"
                        my="1px"
                        color="ds.icon.success"
                        aria-hidden
                      />
                      <Text>{translate(line)}</Text>
                    </Flex>
                  ))
                )}
              </Flex>
            </Flex>
            <ThemedButton
              onClick={onContinue}
              width="fit-content"
              isDisabled={isDisabled}
            >
              {translate("test.buttons.continue_and_start_recording")}
            </ThemedButton>
          </Flex>
        </Center>
      </Box>
    </Flex>
  )
}

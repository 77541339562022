import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  keyframes,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { RecruitmentLinkCompositeIcon } from "Shared/components/CompositeIcons/CompositeIcons"
import copy from "copy-to-clipboard"
import React from "react"
import { useNavigate } from "react-router"

import { DotIcon } from "Shared/icons/DotIcon"
import { EllipsisVerticalIcon } from "Shared/icons/EllipsisVerticalIcon"
import { EyeOutlineIcon } from "Shared/icons/untitled-ui/EyeOutlineIcon"
import { Link02OutlineIcon } from "Shared/icons/untitled-ui/Link02OutlineIcon"
import { Pencil02OutlineIcon } from "Shared/icons/untitled-ui/Pencil02OutlineIcon"
import { PlayCircleSolidIcon } from "Shared/icons/untitled-ui/PlayCircleSolidIcon"
import { Settings04OutlineIcon } from "Shared/icons/untitled-ui/Settings04OutlineIcon"
import { StopCircleSolidIcon } from "Shared/icons/untitled-ui/StopCircleSolidIcon"
import Created from "UsabilityHub/components/Created"
import Stats from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyCard/Stats"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import { ROUTES } from "UsabilityHub/views/routes"
import { capitalizeFirstLetter } from "Utilities/string"
import { useUpdateModeratedStudiesRecruitmentLink } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { ModeratedStudyRecruitmentLink } from "~/api/generated/usabilityhubSchemas"
import { InPlaceEditor } from "~/usabilityhub/components/InPlaceEditor/InPlaceEditor"

const pulse = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`

export function getRecruitmentLinkLink(token: string) {
  const url = new URL(window.location.href)
  url.pathname = `/apply/${token}`

  return url.toString()
}

interface ModeratedStudyRecruitmentLinkEntryProps {
  recruitmentLink: ModeratedStudyRecruitmentLink
  moderatedStudyId: string
  showMenu?: boolean
}

export function ModeratedStudyRecruitmentLinkEntry({
  recruitmentLink,
  moderatedStudyId,
  showMenu = true,
}: ModeratedStudyRecruitmentLinkEntryProps) {
  const toast = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const [nameEditTrigger, setNameEditTrigger] = React.useState(0)

  const { invalidateStudySummaryQuery } = useModeratedStudyContext()

  const { mutateAsync: updateLink } = useUpdateModeratedStudiesRecruitmentLink({
    onSuccess: () => {
      const linkQueryKey = queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/recruitment_links/{moderated_study_recruitment_link_id}",
        operationId: "getModeratedStudyRecruitmentLink",
        variables: {
          pathParams: {
            moderatedStudyId,
            moderatedStudyRecruitmentLinkId: recruitmentLink.id,
          },
        },
      })

      const listQueryKey = queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/recruitment_links",
        operationId: "getApiModeratedStudiesModeratedStudyIdRecruitmentLinks",
        variables: {
          pathParams: {
            moderatedStudyId: moderatedStudyId,
          },
        },
      })

      return Promise.all([
        queryClient.invalidateQueries(linkQueryKey, { exact: true }),
        queryClient.invalidateQueries(listQueryKey, { exact: true }),
      ])
    },
    onError: () => {
      toast({
        title: "Recruitment link could not be updated",
        status: "error",
      })
    },
  })

  const handleCopyRecruitmentLink = () => {
    const link = getRecruitmentLinkLink(recruitmentLink.token)
    copy(link)

    toast({
      title: "Link copied to clipboard",
      status: "success",
      duration: 3000,
    })
  }

  const setLinkActive = (active: boolean) => {
    return updateLink(
      {
        pathParams: {
          moderatedStudyId,
          moderatedStudyRecruitmentLinkId: recruitmentLink.id,
        },
        body: {
          active,
        },
      },
      {
        onSuccess: (_, variables) => {
          void invalidateStudySummaryQuery()
          toast({
            title: variables.body?.active
              ? "Recruitment link enabled"
              : "Recruitment link disabled",
            status: "success",
            duration: 3000,
          })
        },
      }
    )
  }

  const handleRename = async (newName: string) => {
    await updateLink({
      pathParams: {
        moderatedStudyId,
        moderatedStudyRecruitmentLinkId: recruitmentLink.id,
      },
      body: {
        name: newName,
      },
    })
  }

  return (
    <Flex alignItems="center" gap="4">
      <RecruitmentLinkCompositeIcon />
      <Flex direction="column" align="flex-start" w={0} flexGrow={1}>
        <InPlaceEditor
          as="h3"
          fontSize="md"
          fontWeight="semibold"
          lineHeight="24px" // Match the editor height so it doesn't jump
          inputProps={{ size: "xs" }}
          buttonProps={{ size: "xs" }}
          value={recruitmentLink.name}
          handleSave={handleRename}
          defaultIsEditing={nameEditTrigger > 0}
          key={nameEditTrigger}
        />
        <Tooltip hasArrow rounded="sm" label={recruitmentLink.incentive_text}>
          <Text w="full" fontSize="sm" textColor="text.secondary" isTruncated>
            {capitalizeFirstLetter(recruitmentLink.recruitment_mode)}
            {recruitmentLink.incentive_type !== "none"
              ? ` • ${recruitmentLink.incentive_text}`
              : null}
          </Text>
        </Tooltip>
      </Flex>

      <Flex gap={4} alignItems="center">
        <Box
          onClick={(e) => {
            // These controls can be nested inside a clickable (<a> tag) card, so we need to stop
            // click events bubbling up past this point.
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Menu>
            <MenuButton
              as={Button}
              w="140px"
              variant="outline"
              size="sm"
              flexShrink={0}
            >
              {recruitmentLink.disabled_at ? (
                "Disabled"
              ) : (
                <>
                  <DotIcon
                    color="green.500"
                    boxSize="1em"
                    animation={`${pulse} infinite 2s ease`}
                    mr={2}
                  />
                  Enabled
                </>
              )}
            </MenuButton>
            <MenuList>
              <MenuItem
                icon={<Icon as={Link02OutlineIcon} color="gray.400" />}
                onClick={handleCopyRecruitmentLink}
              >
                Copy link
              </MenuItem>

              <MenuItem
                icon={<EyeOutlineIcon color="gray.400" />}
                onClick={() => {
                  window.open(
                    ROUTES.INTERVIEW.PREVIEW.APPLY.buildPath(
                      {
                        moderatedStudyId,
                      },
                      {
                        recruitmentLinkId: recruitmentLink.id,
                      }
                    ),
                    "_blank"
                  )
                }}
              >
                Preview
              </MenuItem>

              <MenuItem
                icon={
                  recruitmentLink.disabled_at ? (
                    <Icon as={PlayCircleSolidIcon} color="green.500" />
                  ) : (
                    <Icon as={StopCircleSolidIcon} color="red.500" />
                  )
                }
                onClick={() => setLinkActive(!!recruitmentLink.disabled_at)}
              >
                {recruitmentLink.disabled_at ? "Enable link" : "Disable link"}
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>

        <Stats
          applicantCounts={recruitmentLink.applicant_counts}
          bookingCounts={recruitmentLink.booking_counts}
          maxBookings={
            recruitmentLink.recruitment_mode === "automatic"
              ? recruitmentLink.maximum_number_of_bookings ?? undefined
              : undefined
          }
        />

        <Created
          createdAt={recruitmentLink.created_at}
          name={recruitmentLink.creator?.name ?? ""}
          email={recruitmentLink.creator?.email ?? ""}
          avatarUrl={recruitmentLink.creator?.avatar_url ?? ""}
        />

        {showMenu && (
          <Box
            onClick={(e) => {
              // These controls can be nested inside a clickable (<a> tag) card, so we need to stop
              // click events bubbling up past this point.
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<EllipsisVerticalIcon />}
                fontSize="2xl"
                variant="outline"
              />
              <MenuList overflow="hidden">
                <MenuItem
                  icon={<Icon as={EyeOutlineIcon} color="gray.400" />}
                  onClick={() => {
                    window.open(
                      ROUTES.INTERVIEW.PREVIEW.APPLY.buildPath(
                        {
                          moderatedStudyId,
                        },
                        {
                          recruitmentLinkId: recruitmentLink.id,
                        }
                      ),
                      "_blank"
                    )
                  }}
                >
                  Preview
                </MenuItem>

                <MenuItem
                  onClick={handleCopyRecruitmentLink}
                  icon={<Icon as={Link02OutlineIcon} color="gray.400" />}
                >
                  Copy link
                </MenuItem>
                <MenuItem
                  onClick={() => setNameEditTrigger((v) => v + 1)}
                  icon={<Icon as={Pencil02OutlineIcon} color="gray.400" />}
                >
                  Rename
                </MenuItem>

                <MenuItem
                  icon={<Icon as={Settings04OutlineIcon} color="gray.400" />}
                  onClick={() =>
                    navigate(
                      ROUTES.INTERVIEW.RECRUIT.LINK.buildPath({
                        moderatedStudyId,
                        moderatedStudyRecruitmentLinkId: recruitmentLink.id,
                      })
                    )
                  }
                >
                  Manage
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

import { Button, Image, Link, Text, VStack } from "@chakra-ui/react"
import React, { useEffect } from "react"

import LyssnaLogoGeneric from "Images/lyssna-logo.png"
import { Page, PageContent, PageMain } from "Shared/components/Page/Page"

type ResultType = "success" | "error"

interface OAuthResponsePage {
  /** Metadata for page title */
  title: string
  /** Type to handle message to post and present */
  type: ResultType
}

function getIntegrationName() {
  const queryParams = new URLSearchParams(window.location.search)
  switch (queryParams.get("integration")) {
    case "figma":
      return "Figma"
    case "google":
      return "Google"
    case "zoom":
      return "Zoom"
    case "microsoft":
      return "Microsoft"
  }
}

function getMessage(type: ResultType) {
  const integrationName = getIntegrationName() ?? ""

  switch (type) {
    case "success":
      return `We connected Lyssna and your ${integrationName} account. Close this window to return to your study.`
    case "error":
      return `We couldn${"\u2019"}t connect Lyssna to your ${integrationName} account. Please close this window and try again.`
  }
}

export const OAuthPageContent: React.FC<
  React.PropsWithChildren<Pick<OAuthResponsePage, "type">>
> = ({ type }) => {
  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      spacing={8}
      p={12}
    >
      <Link href="/">
        <Image src={LyssnaLogoGeneric} aria-label="Lyssna logo" width="200px" />
      </Link>
      <Text fontSize="lg">{getMessage(type)}</Text>
      <Button colorScheme="brand.primary" onClick={() => window.close()}>
        Close
      </Button>
    </VStack>
  )
}

export const OAuthResponsePage: React.FC<
  React.PropsWithChildren<OAuthResponsePage>
> = ({ title, type }) => {
  const integration = getIntegrationName() ?? ""

  useEffect(() => {
    if (window.opener) {
      switch (type) {
        case "success":
          window.opener.postMessage({ type: "OAUTH_SUCCESS", integration }, "*")
          break
        case "error":
          window.opener.postMessage({ type: "OAUTH_ERROR", integration }, "*")
      }
    }
  }, [type])

  return (
    <Page title={title}>
      <PageMain>
        <PageContent maxW="8xl">
          <OAuthPageContent type={type} />
        </PageContent>
      </PageMain>
    </Page>
  )
}

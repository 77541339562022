import { Button } from "DesignSystem/components"
import { ThumbsUpOutlineIcon } from "Shared/icons/untitled-ui/ThumbsUpOutlineIcon"
import React from "react"

export const RateResponsesButton = ({
  isRatingFormVisible,
  hasRatedAlready,
  ...props
}: {
  onClick: () => void
  isDisabled: boolean
  isRatingFormVisible: boolean
  hasRatedAlready: boolean
}) => (
  <Button
    leftIcon={<ThumbsUpOutlineIcon color="ds.icon.default" />}
    isSelected={isRatingFormVisible}
    {...props}
  >
    {hasRatedAlready ? "Update rating" : "Rate responses"}
  </Button>
)

import {
  Button,
  ButtonProps,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { useModeratedStudyContext } from "UsabilityHub/views/ModeratedStudy/interviewer/ModeratedStudyContext"
import { pluralize, pluralizeWithCount } from "Utilities/string"
import { capitalize, partition } from "lodash"
import React from "react"
import {
  useDeleteModeratedStudyApplicants,
  useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks,
  useInviteApplicationsToModeratedStudy,
  useReinviteApplicationsToModeratedStudy,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

import { Trash01SolidIcon } from "Shared/icons/untitled-ui/Trash01SolidIcon"
import { XOutlineIcon } from "Shared/icons/untitled-ui/XOutlineIcon"
import { getDateString } from "Utilities/date-formats"
import { InvitationsSentModal } from "./InvitationsSentModal"
import { InviteApplicantsModal } from "./InviteApplicantsModal"
import { ModeratedStudyApplication } from "./Types"
import {
  NON_INVITABLE_PANEL_ORDER_STATUSES,
  NON_INVITABLE_SELF_RECRUITED_STATUSES,
} from "./nonInvitableStatuses"
import { useGetInvitationInfo } from "./useGetInvitationInfo"

interface SelectedApplicationsFloatingActionsBarProps {
  selectedApplications: ModeratedStudyApplication[]
  onResetSelections: () => void
  source: ModeratedStudyApplication["source"]
}

export function SelectedApplicationsFloatingActionsBar({
  selectedApplications,
  onResetSelections,
  source,
}: SelectedApplicationsFloatingActionsBarProps) {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  const { moderatedStudyId, invalidateStudySummaryQuery } =
    useModeratedStudyContext()

  const invalidateApplicationsQuery = async () => {
    await queryClient.invalidateQueries(
      queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/applications",
        operationId: "getModeratedStudyApplications",
        variables: {
          pathParams: {
            moderatedStudyId: moderatedStudyId,
          },
        },
      })
    )
  }

  const { mutateAsync: deleteApplicants } = useDeleteModeratedStudyApplicants({
    onSuccess: async (_data, variables) => {
      const appCount = variables.body.moderated_study_applicant_ids.length

      toast({
        title: `Deleted ${pluralizeWithCount(appCount, "application")}`,
        status: "success",
      })

      onResetSelections()
      await invalidateStudySummaryQuery()
      return invalidateApplicationsQuery()
    },
  })

  const applicantIds = (
    applications: ModeratedStudyApplication[]
  ): string[] => {
    return (
      applications
        .map((application) => application.moderated_study_applicant_id)
        // Panelist applications don't have a moderated_study_applicant_id
        // (deleting them is currently disabled in the UI)
        .filter((a): a is string => a !== null)
    )
  }

  const handleDeleteClick = () => {
    // TODO when we want to decline instead of delete, use this modal
    // declineDisclosure.onOpen()
    handleDeleteSelectedApplications()
  }

  const handleDeleteSelectedApplications = () => {
    const toDeleteApplicantIds = applicantIds(selectedApplications)

    void deleteApplicants({
      pathParams: {
        moderatedStudyId: moderatedStudyId,
      },
      body: {
        moderated_study_applicant_ids: toDeleteApplicantIds,
      },
    })
  }

  return (
    <>
      <Flex
        position="fixed"
        left={0}
        right={0}
        bottom={2}
        justify="center"
        pointerEvents="none"
      >
        <Flex
          gap={4}
          direction={["column", null, "row"]}
          basis={["20rem", null, "auto"]}
          py={4}
          px={4}
          bg="gray.50"
          rounded="lg"
          shadow="2xl"
          pointerEvents="auto"
          color="text.primary"
        >
          <Flex
            gap={4}
            alignItems={["stretch", null, "center"]}
            shrink={0}
            direction={["column", null, "row"]}
          >
            <Flex gap={1} justify="center" align="center" shrink={0}>
              <IconButton
                icon={<Icon as={XOutlineIcon} boxSize="5" color="gray.500" />}
                aria-label="deselect all"
                variant="ghost"
                onClick={onResetSelections}
              />
              <Text fontWeight="medium">
                {selectedApplications.length} selected
              </Text>
            </Flex>

            <SendInvitationButton
              selectedApplications={selectedApplications}
              onInviteCallback={onResetSelections}
            />
          </Flex>

          <Button
            variant="outline"
            color="red.500"
            leftIcon={
              <Trash01SolidIcon transform="translateY(-1px)" color="red.500" />
            }
            onClick={handleDeleteClick}
            isDisabled={source.type === "order"}
          >
            Delete
          </Button>
        </Flex>
      </Flex>
    </>
  )
}

type SendInvitationButtonProps = {
  selectedApplications: ModeratedStudyApplication[]
  onInviteCallback?: () => void
} & ButtonProps

export const SendInvitationButton: React.FC<SendInvitationButtonProps> = ({
  selectedApplications,
  onInviteCallback,
  ...buttonProps
}) => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const moderatedStudy = useModeratedStudyContext()
  const { moderatedStudyId } = moderatedStudy

  const inviteDisclosure = useDisclosure()
  const invitesSentDisclosure = useDisclosure()

  const { mutateAsync: inviteApplicantsToModeratedStudy } =
    useInviteApplicationsToModeratedStudy()
  const { mutateAsync: reinviteApplicantsToModeratedStudy } =
    useReinviteApplicationsToModeratedStudy()

  const {
    data: { moderated_study_orders: orders } = {},
  } = useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks({
    pathParams: {
      moderatedStudyId,
    },
  })

  const invalidateApplicationsQuery = async () => {
    await queryClient.invalidateQueries(
      queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/applications",
        operationId: "getModeratedStudyApplications",
        variables: {
          pathParams: {
            moderatedStudyId,
          },
        },
      })
    )
  }

  const {
    hasReachedSessionLimit,
    renewalDate,
    selfRecruitedSessionsAvailable,
  } = useGetInvitationInfo(moderatedStudy)

  if (selectedApplications.length === 0) {
    return null
  }

  // Implicit assumption here that all selected applications have the same source
  const source = selectedApplications[0].source
  const currentOrder = source && orders?.find((o) => o.id === source.id)
  const panelOrderSessionsAvailable = currentOrder?.empty_slots ?? 0

  const [toInviteApplications, toReinviteApplications] = partition(
    selectedApplications,
    (application) => !application.has_been_invited
  )

  const applicationIds = (
    applications: ModeratedStudyApplication[]
  ): string[] => {
    return applications.map((application) => application.id)
  }

  const handleInvitesSent = () => {
    invitesSentDisclosure.onOpen()
  }

  const handleSendInvitationsClick = () => {
    inviteDisclosure.onOpen()
  }

  const handleInviteSelectedApplications = async () => {
    const sentSuccessfully = await inviteSelectedApplications()
    if (!sentSuccessfully) return

    inviteDisclosure.onClose()
    handleInvitesSent()
  }

  const inviteSelectedApplications = async () => {
    const toInviteApplicationIds = applicationIds(toInviteApplications)
    const toReinviteApplicationIds = applicationIds(toReinviteApplications)

    const invitePromise =
      toInviteApplicationIds.length > 0
        ? inviteApplicantsToModeratedStudy({
            pathParams: {
              moderatedStudyId,
            },
            body: {
              moderated_study_application_ids: toInviteApplicationIds,
            },
          })
        : Promise.resolve()

    const reinvitePromise =
      toReinviteApplicationIds.length > 0
        ? reinviteApplicantsToModeratedStudy({
            pathParams: {
              moderatedStudyId,
            },
            body: {
              moderated_study_application_ids: toReinviteApplicationIds,
            },
          })
        : Promise.resolve()

    try {
      await Promise.all([invitePromise, reinvitePromise])
      await invalidateApplicationsQuery()

      const invitedText =
        toInviteApplications.length > 0
          ? `invited ${pluralizeWithCount(
              toInviteApplications.length,
              "participant"
            )}`
          : null
      const reinvitedText =
        toReinviteApplications.length > 0
          ? `reinvited ${pluralizeWithCount(
              toReinviteApplications.length,
              "participant"
            )}`
          : null

      toast({
        title: capitalize(
          `${[invitedText, reinvitedText].filter((t) => t).join(" and ")}`
        ),
        status: "success",
      })

      return true
    } catch (e) {
      // Invalidate the applications cache if we got an error as the client
      // likely has old data
      await invalidateApplicationsQuery()

      toast({
        title: `Error inviting/reinviting ${pluralize(
          selectedApplications.length,
          "participant"
        )}`,
        status: "error",
      })

      return false
    }
  }

  const shouldDisableInviteBecauseNoMoreSessionsLeft =
    source.type === "recruitment_link" && hasReachedSessionLimit
  const containsNonInvitableSelfRecruitedStatus =
    source.type === "recruitment_link" &&
    selectedApplications.some((a) =>
      NON_INVITABLE_SELF_RECRUITED_STATUSES.includes(a.status)
    )
  const containsNonInvitablePanelOrderStatus =
    source.type === "order" &&
    selectedApplications.some((a) =>
      NON_INVITABLE_PANEL_ORDER_STATUSES.includes(a.status)
    )
  const isInviteButtonDisabled =
    shouldDisableInviteBecauseNoMoreSessionsLeft ||
    containsNonInvitablePanelOrderStatus ||
    containsNonInvitableSelfRecruitedStatus
  const disabledInviteButtonTooltip = !isInviteButtonDisabled
    ? undefined
    : shouldDisableInviteBecauseNoMoreSessionsLeft
      ? `You\u2019ve reached this month\u2019s session limit for interview sessions from your network. No new sessions can be booked until your balance renews${
          renewalDate ? " on " + getDateString(renewalDate) : ""
        }.`
      : getNonInvitableTooltipMessage(selectedApplications.length)

  const sessionsAvailableCount =
    source.type === "order"
      ? panelOrderSessionsAvailable
      : selfRecruitedSessionsAvailable

  return (
    <>
      <Tooltip
        label={disabledInviteButtonTooltip}
        textAlign="center"
        maxW="380px"
        hasArrow
        isDisabled={!isInviteButtonDisabled}
      >
        <Button
          onClick={handleSendInvitationsClick}
          isDisabled={isInviteButtonDisabled}
          colorScheme="brand.primary"
          flexShrink={0}
          {...buttonProps}
        >
          Send invite
        </Button>
      </Tooltip>
      <InviteApplicantsModal
        source={source}
        sessionsAvailableCount={sessionsAvailableCount}
        applicantsSelectedCount={selectedApplications.length}
        handleSubmit={handleInviteSelectedApplications}
        {...inviteDisclosure}
      />
      <InvitationsSentModal
        {...invitesSentDisclosure}
        applicantsSelectedCount={selectedApplications.length}
        sessionsAvailableCount={sessionsAvailableCount}
        onClose={() => {
          invitesSentDisclosure.onClose()
          onInviteCallback?.()
        }}
      />
    </>
  )
}
const getNonInvitableTooltipMessage = (selectedCount: number) =>
  selectedCount === 1
    ? `This participant can\u2019t be invited because of their current status.`
    : `One or more of the selected participants can\u2019t be invited because of their current status.`

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react"
import { useSelector } from "react-redux"

import { ResponseWithAttributes } from "Components/test-results/types/response"
import {
  getAllClicks,
  getAllOrders,
  getAllResponseAnswers,
  getAllResponseSectionCardSorts,
  getAllResponseSectionFigmaTasks,
  getAllResponseSectionTreeTestPaths,
  getAllResponseSections,
  getAllResponses,
  getAnswerTags,
  getUsabilityTest,
  hitzonesSelector,
} from "Redux/reducers/test-results/selectors"
import {
  AnswerTag,
  Response,
  ResponseAnswer,
  ResponseSection,
  ResponseSectionFigmaTask,
  ResponseSectionTreeTestPath,
  ScreenshotClick,
} from "Types"

import { filterResponses } from "../filters/filter-responses"
import { getResponseAttributes } from "../filters/get-response-attributes"

import { TestResultsFilterState } from "./test-result-filter-state"
import { TestResultViewState } from "./test-result-view-state"

type FilteredResponsesContext = {
  filteredResponses: Response[]
}

export const FilteredResponsesContext = createContext<FilteredResponsesContext>(
  {
    filteredResponses: [],
  }
)

export function FilteredResponsesContextProvider({
  children,
}: PropsWithChildren<unknown>) {
  const { testResultsFilters } = useContext(TestResultsFilterState)
  const { viewState } = useContext(TestResultViewState)

  const responses = useSelector(getAllResponses)
  const orders = useSelector(getAllOrders)
  const usabilityTest = useSelector(getUsabilityTest)
  const responseAnswers = useSelector(getAllResponseAnswers)
  const responseSections = useSelector(getAllResponseSections)
  const cardSortCategoryCards = useSelector(getAllResponseSectionCardSorts)
  const answerTags = useSelector(getAnswerTags)
  const responseSectionFigmaTasks = useSelector(getAllResponseSectionFigmaTasks)
  const responseSectionTreeTestPaths = useSelector(
    getAllResponseSectionTreeTestPaths
  )

  const allScreenshotClicks = useSelector(getAllClicks)

  const hitzones = useSelector(hitzonesSelector)

  const responsesWithAttributes: ResponseWithAttributes[] = useMemo(
    () =>
      responses.map((response) => ({
        ...response,
        attributes: getResponseAttributes(orders, response),
      })),
    [responses]
  )

  const filteredResponses = useMemo(() => {
    return filterResponses(responsesWithAttributes, testResultsFilters, {
      answerTags: answerTags as AnswerTag[],
      clientHitzones: viewState.screenshotClientHitzones,
      hitzones,
      responseAnswers: responseAnswers as ResponseAnswer[],
      responseSections: responseSections as ResponseSection[],
      responseSectionFigmaTasks:
        responseSectionFigmaTasks as ResponseSectionFigmaTask[],
      screenshotClicks: allScreenshotClicks as ScreenshotClick[],
      usabilityTest,
      cardSortCategoryCards: cardSortCategoryCards,
      treeTestPaths:
        responseSectionTreeTestPaths as ResponseSectionTreeTestPath[],
    })
  }, [
    responsesWithAttributes,
    usabilityTest,
    testResultsFilters,
    responseAnswers,
    responseSections,
    responseSectionFigmaTasks,
    answerTags,
    allScreenshotClicks,
    viewState,
    hitzones,
    cardSortCategoryCards,
  ])

  return (
    <FilteredResponsesContext.Provider value={{ filteredResponses }}>
      {children}
    </FilteredResponsesContext.Provider>
  )
}

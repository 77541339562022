import { useEffect } from "react"

import { apiSegmentEvents } from "~/api"

type PermittedSegmentEvent = "viewed_buy_credits_page"

export const useRecordSegmentEvent = (event: PermittedSegmentEvent) => {
  useEffect(() => {
    async function recordEvent() {
      await apiSegmentEvents.create({ data: { event } })
    }
    void recordEvent()
  }, [event])
}

import {
  AbsoluteCenter,
  Box,
  Center,
  Icon,
  Spinner,
  Stack,
} from "@chakra-ui/react"
import { useIndividualResponseFilter } from "Components/test-results/hooks/use-individual-response-filter"
import { usePrefersReducedMotion } from "Components/trial-widget/usePrefersReducedMotion"
import { IconButton } from "DesignSystem/components"
import { ResponseSectionRecording } from "JavaScripts/types/recording"
import { SpinnerDynamicIcon } from "Shared/icons/SpinnerDynamicIcon"
import { SpinnerStaticIcon } from "Shared/icons/SpinnerStaticIcon"
import { UserResponseIcon } from "Shared/icons/UserResponseIcon"
import { PlaySolidIcon } from "Shared/icons/untitled-ui/PlaySolidIcon"
import { formatPreciseDuration } from "Utilities/time"
import { debounce } from "lodash"
import React, { useEffect, useRef, useState } from "react"
// @ts-ignore
import AudioSpectrum from "react-audio-spectrum"

type Props = {
  recording: ResponseSectionRecording
  bottomBorder?: boolean
  hideParticipantButton?: boolean
  setHasRecordingError?: (hasError: boolean) => void
}

export const RecordingPlayer: React.FC<Props> = ({
  recording,
  bottomBorder = false,
  hideParticipantButton = false,
  setHasRecordingError = () => {},
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [videoDuration, setVideoDuration] = useState<
    string | null | undefined
  >()

  useEffect(() => {
    if (recording.status === "failed") {
      setVideoDuration(null)
    }
  }, [recording.status])

  const [, setResponseId] = useIndividualResponseFilter()

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      const duration = formatPreciseDuration(videoRef.current.duration * 1000)

      setVideoDuration(duration)
    }
  }

  const handleRecordingError = () => {
    setHasRecordingError(true)
    setVideoDuration(null)
    setIsPlaying(false)
  }

  const debouncedSetIsPlaying = debounce(setIsPlaying, 100)

  const prefersReducedMotion = usePrefersReducedMotion()

  const participantButton = !hideParticipantButton && (
    <IconButton
      position="absolute"
      display="none"
      _groupHover={{ display: "block" }}
      right={0}
      aria-label="View participant"
      icon={<UserResponseIcon />}
      size="compact"
      bgColor="ds.background.input.resting"
      _hover={{ bgColor: "ds.background.input.hovered" }}
      m={2}
      onClick={() => setResponseId(recording.responseSection.response_id)}
    />
  )

  const height = "150px"

  const ProcessingView = (
    <Stack w="full" position="relative" role="group">
      <Center h={height} bgColor="ds.surface.sunken" borderTopRadius="md">
        <Icon
          as={prefersReducedMotion ? SpinnerStaticIcon : SpinnerDynamicIcon}
          boxSize={14}
          color="ds.icon.disabled"
        />
      </Center>
      {participantButton}
    </Stack>
  )

  const RecordingView = (
    <Stack
      w="full"
      position="relative"
      role="group"
      sx={{
        "> canvas": {
          position: "absolute",
          width: "100%",
          height: "90px",
          display: isPlaying ? "block" : "none",
        },
      }}
    >
      <Box
        id={recording.id}
        ref={videoRef}
        as="video"
        height={height}
        objectFit="contain"
        bgColor="ds.text.default"
        controls={isPlaying}
        preload="metadata"
        src={recording.url!}
        borderTopRadius="md"
        borderBottomRadius={bottomBorder ? "md" : 0}
        crossOrigin="anonymous"
        onPlay={() => debouncedSetIsPlaying(true)}
        onPause={() => debouncedSetIsPlaying(false)}
        onSeeking={() => debouncedSetIsPlaying(true)}
        onError={handleRecordingError}
        onLoadedMetadata={handleLoadedMetadata}
      />
      {videoDuration === undefined && (
        <AbsoluteCenter>
          <Spinner
            color="ds.text.subtle"
            size="xl"
            thickness="4px"
            speed="0.65s"
            animation={prefersReducedMotion ? "none" : undefined}
          />
        </AbsoluteCenter>
      )}
      {!isPlaying && videoDuration && (
        <Box
          w="full"
          height="150px"
          position="absolute"
          display="none"
          bgGradient="linear-gradient(180deg, rgba(99, 99, 99, 0) 0%, rgba(0, 0, 0, 0.39) 34.38%, #000000 100%)"
          _groupHover={{ display: "block" }}
        >
          <AbsoluteCenter>
            <Icon
              as={PlaySolidIcon}
              color="ds.background.input.resting"
              boxSize={10}
              onClick={() => videoRef.current?.play()}
              cursor="pointer"
            />
          </AbsoluteCenter>
        </Box>
      )}
      {!isPlaying && videoDuration !== undefined && participantButton}
      {!isPlaying && videoDuration !== undefined && (
        <Box
          position="absolute"
          bottom={0}
          right={0}
          bgColor="ds.surface.backdrop"
          fontSize="xs"
          color="ds.background.input.resting"
          px={1}
          borderRadius="30px"
          m={2}
        >
          {videoDuration ? videoDuration : "0 sec"}
        </Box>
      )}
      {recording.isAudio && (
        <AudioSpectrum
          id={`audio-canvas-${recording.id}`}
          height={90}
          audioId={recording.id}
          capColor={"red"}
          meterColor={[
            { stop: 0, color: "#f00" },
            { stop: 0.5, color: "#0CD7FD" },
            { stop: 1, color: "red" },
          ]}
          gap={4}
        />
      )}
    </Stack>
  )

  return recording.status === "processed" || recording.status === "failed"
    ? RecordingView
    : ProcessingView
}

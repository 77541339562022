import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react"
import { DotsVerticalIcon } from "@heroicons/react/outline"
import { useQueryClient } from "@tanstack/react-query"
import { Alert } from "DesignSystem/components/Alert"
import zoomIcon from "Images/zoom-logo.png"
import { Card, CardBody, CardHeader } from "Shared/components/Card/Card"
import { GoogleCalendarIcon } from "Shared/icons/GoogleCalendarIcon"
import { MicrosoftIcon } from "Shared/icons/MicrosoftIcon"
import { ConflictCalendarModal } from "UsabilityHub/components/ConflictCalendarModal/ConflictCalendarModal"
import { ZoomSettingsModal } from "UsabilityHub/components/ZoomSettingsModal"
import { useGoogleOAuth } from "UsabilityHub/hooks/useGoogleOAuth"
import { useIntegrations } from "UsabilityHub/hooks/useIntegrations"
import { useMicrosoftOAuth } from "UsabilityHub/hooks/useMicrosoftOAuth"
import { useZoomOAuth } from "UsabilityHub/hooks/useZoomOAuth"
import { getDateString } from "Utilities/date-formats"
import { useModal } from "Utilities/modals/use-modal"
import React, { useCallback } from "react"
import { useDeleteIntegration } from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"

type Integrations = React.FC<React.PropsWithChildren<unknown>>

export const Integrations: Integrations = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()
  const { data } = useIntegrations()
  const { open: openConflictCalendarModal } = useModal(ConflictCalendarModal)
  const { open: openZoomSettingsModal } = useModal(ZoomSettingsModal)

  const invalidateIntegrationsQuery = useCallback(() => {
    return queryClient.invalidateQueries(
      queryKeyFn({
        path: "/api/integrations",
        operationId: "getIntegrations",
        variables: {},
      })
    )
  }, [queryClient, queryKeyFn])

  const { handleGoogleOAuth, disconnectGoogleOAuth } = useGoogleOAuth(
    !!data?.google,
    async () => {
      await invalidateIntegrationsQuery()
      openConflictCalendarModal({
        initialSelectedCalendars: data?.conflict_calendars ?? [],
        singleProvider: "google",
        hasGoogleCalendarError: !!data?.google?.error,
      })
    }
  )

  const { handleMicrosoftOAuth, disconnectMicrosoftOAuth } = useMicrosoftOAuth(
    !!data?.microsoft,
    async () => {
      await invalidateIntegrationsQuery()
      openConflictCalendarModal({
        initialSelectedCalendars: data?.conflict_calendars ?? [],
        singleProvider: "outlook",
        hasGoogleCalendarError: false,
      })
    }
  )

  const { handleZoomOAuth, disconnectZoomOAuth } = useZoomOAuth(
    !!data?.zoom,
    async () => {
      await invalidateIntegrationsQuery()
      openZoomSettingsModal({})
    }
  )

  const { mutateAsync: deleteIntegration, isLoading: isDeletingIntegration } =
    useDeleteIntegration({
      onSuccess: async (_, variables) => {
        toast({
          title: "Disconnected integration",
          status: "success",
        })
        await invalidateIntegrationsQuery()

        if (variables.body?.integration === "google") disconnectGoogleOAuth()
        if (variables.body?.integration === "zoom") disconnectZoomOAuth()
        if (variables.body?.integration === "microsoft")
          disconnectMicrosoftOAuth()
      },
    })

  return (
    <Card>
      <CardHeader>Integrations</CardHeader>
      <CardBody>
        <List spacing={4}>
          <ListItem>
            <Flex gap={4}>
              <Icon as={GoogleCalendarIcon} boxSize={10} mt={2} />
              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  Google Calendar
                </Text>
                <Text>
                  Connect your calendar to check your availability and sync
                  events
                </Text>
                {data?.google?.error && (
                  <Alert
                    mt="2"
                    status="danger"
                    title={`We\u2019ve lost connection to your Google Calendar`}
                    description="We can no longer connect to your Google Calendar due to
                      authentication or permissions issues. Please reconnect to
                      allow Interview applicants to make bookings using this
                      calendar."
                  />
                )}
              </Box>
              <Spacer />

              {data === undefined ? (
                <Spinner size="sm" />
              ) : data.google === null ? (
                <Button
                  flexShrink={0}
                  colorScheme="brand.primary"
                  onClick={handleGoogleOAuth}
                >
                  Connect
                </Button>
              ) : data.google.error ? (
                <Button
                  flexShrink={0}
                  colorScheme="brand.primary"
                  isDisabled={isDeletingIntegration}
                  onClick={async () => {
                    await deleteIntegration({
                      body: { integration: "google" },
                    })
                    handleGoogleOAuth()
                  }}
                >
                  Reconnect
                </Button>
              ) : (
                <>
                  <Text
                    fontSize="xs"
                    color="text.secondary"
                    textAlign="right"
                    flexShrink={0}
                  >
                    Connected on
                    <br />
                    {getDateString(data.google.created_at, "medium")}
                  </Text>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<Icon as={DotsVerticalIcon} color="gray.500" />}
                    />
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          openConflictCalendarModal({
                            initialSelectedCalendars: data.conflict_calendars,
                            singleProvider: "google",
                            hasGoogleCalendarError: !!data?.google?.error,
                          })
                        }}
                      >
                        Configure
                      </MenuItem>
                      <MenuItem
                        color="red"
                        isDisabled={isDeletingIntegration}
                        onClick={() => {
                          deleteIntegration({
                            body: { integration: "google" },
                          })
                        }}
                      >
                        Disconnect
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}
            </Flex>
          </ListItem>

          <ListItem>
            <Flex gap={4}>
              <Image src={zoomIcon} boxSize={10} mt={2} />
              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  Zoom
                </Text>
                <Text>
                  Connect to Zoom to synchronize meetings on your Interview
                  studies
                </Text>
              </Box>
              <Spacer />

              {data === undefined ? (
                <Spinner size="sm" />
              ) : data.zoom === null ? (
                <Button
                  flexShrink={0}
                  colorScheme="brand.primary"
                  onClick={handleZoomOAuth}
                >
                  Connect
                </Button>
              ) : (
                <>
                  <Text
                    fontSize="xs"
                    color="text.secondary"
                    textAlign="right"
                    flexShrink={0}
                  >
                    Connected on
                    <br />
                    {getDateString(data.zoom.created_at, "medium")}
                  </Text>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<Icon as={DotsVerticalIcon} color="gray.500" />}
                    />
                    <MenuList>
                      <MenuItem onClick={openZoomSettingsModal}>
                        Configure
                      </MenuItem>
                      <MenuItem
                        color="red"
                        isDisabled={isDeletingIntegration}
                        onClick={() => {
                          deleteIntegration({
                            body: { integration: "zoom" },
                          })
                        }}
                      >
                        Disconnect
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}
            </Flex>
          </ListItem>

          <ListItem>
            <Flex gap={4}>
              <MicrosoftIcon boxSize={10} mt={2} />
              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  Microsoft
                </Text>
                <Text>
                  Connect your Microsoft account to check your Outlook Calendar
                  availability and sync events and meetings on your Interview
                  studies via Microsoft Teams
                </Text>
              </Box>
              <Spacer />

              {data === undefined ? (
                <Spinner size="sm" />
              ) : data.microsoft === null ? (
                <Button
                  flexShrink={0}
                  colorScheme="brand.primary"
                  onClick={handleMicrosoftOAuth}
                >
                  Connect
                </Button>
              ) : (
                <>
                  <Text
                    fontSize="xs"
                    color="text.secondary"
                    textAlign="right"
                    flexShrink={0}
                  >
                    Connected on
                    <br />
                    {getDateString(data.microsoft.created_at, "medium")}
                  </Text>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      icon={<Icon as={DotsVerticalIcon} color="gray.500" />}
                    />
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          openConflictCalendarModal({
                            initialSelectedCalendars: data.conflict_calendars,
                            singleProvider: "outlook",
                            hasGoogleCalendarError: false,
                          })
                        }}
                      >
                        Configure
                      </MenuItem>
                      <MenuItem
                        color="red"
                        isDisabled={isDeletingIntegration}
                        onClick={() => {
                          deleteIntegration({
                            body: { integration: "microsoft" },
                          })
                        }}
                      >
                        Disconnect
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}
            </Flex>
          </ListItem>
        </List>
      </CardBody>
    </Card>
  )
}

import { Box, Stack, StackDivider, Text, useToast } from "@chakra-ui/react"
import { isAxiosError } from "Services/axios"
import { AdminNote } from "Types"
import {
  CurrentAccountProvider,
  useCurrentUser,
} from "UsabilityHub/hooks/useCurrentAccount"
import React, { useEffect, useState } from "react"
import AdminNotesApi from "~/api/Admin/AdminNotesApi"
import { AdminNotesActions } from "./AdminNotesActions"
import { SingleAdminNote } from "./SingleAdminNote"

interface AdminNotesResponse {
  admin_notes: ReadonlyArray<AdminNote>
}

type AdminNotes = React.FC<{
  adminNotableId: number
  adminNotableType: string
}>

const sortNotes = (notes: AdminNote[]) => {
  return notes.sort((a, b) => {
    if (a.pinned_at && !b.pinned_at) {
      return -1
    } else if (!a.pinned_at && b.pinned_at) {
      return 1
    } else if (a.pinned_at && b.pinned_at) {
      return Date.parse(b.pinned_at) - Date.parse(a.pinned_at)
    } else {
      return Date.parse(b.created_at) - Date.parse(a.created_at)
    }
  })
}

export const AdminNotes: AdminNotes = (props) => {
  return (
    <CurrentAccountProvider>
      <AdminNotesInner {...props} />
    </CurrentAccountProvider>
  )
}

const AdminNotesInner: AdminNotes = ({ adminNotableId, adminNotableType }) => {
  const toast = useToast()
  const [isLoaded, setIsLoaded] = useState(false)
  const [notes, setNotes] = useState<ReadonlyArray<AdminNote>>([])
  const user = useCurrentUser()

  useEffect(() => {
    const getNotes = async () => {
      try {
        const data = await AdminNotesApi.list<AdminNotesResponse>({
          query: {
            admin_notable_id: adminNotableId,
            admin_notable_type: adminNotableType,
          },
        })
        setIsLoaded(true)
        setNotes(data.admin_notes)
      } catch (e) {
        if (isAxiosError(e) && e.response) {
          toast({
            description: `There was an error fetching the notes for this ${adminNotableType.toLowerCase()}.`,
            status: "error",
            title: `Couldn${"\u2019"}t fetch notes`,
          })
          setIsLoaded(true)
        } else {
          throw e
        }
      }
    }
    void getNotes()
  }, [])

  const addNote = async (note: AdminNote) => {
    const { admin_note: newNote } = await AdminNotesApi.create<{
      admin_note: AdminNote
    }>({
      data: {
        admin_note: {
          admin_notable_id: adminNotableId,
          admin_notable_type: adminNotableType,
          ...note,
        },
      },
    })

    setNotes(sortNotes([newNote, ...notes]))
  }

  const updateNote = async (note: AdminNote) => {
    const { admin_note: newNote } = await AdminNotesApi.update<{
      admin_note: AdminNote
    }>({
      data: {
        id: note.id,
        admin_note: note,
      },
    })
    setNotes(sortNotes(notes.map((n) => (n.id === note.id ? newNote : n))))
  }

  const deleteNote = async (noteId: number) => {
    await AdminNotesApi.destroy({ id: noteId })
    setNotes(notes.filter((n) => n.id !== noteId))
  }

  return (
    <Box>
      <AdminNotesActions addNote={addNote} />
      <Box
        mt={3}
        maxH="600px"
        overflow="auto"
        border="1px"
        borderRadius={4}
        borderColor="gray.200"
      >
        {!isLoaded && <Text p={3}>Loading&hellip;</Text>}
        {notes.length > 0 ? (
          <Stack spacing={0} divider={<StackDivider />}>
            {notes.map((note) => (
              <SingleAdminNote
                key={note.id}
                note={note}
                updateNote={updateNote}
                deleteNote={deleteNote}
                editable={user.id === note.creator_id}
              />
            ))}
          </Stack>
        ) : (
          <Text p={3}>No notes yet.</Text>
        )}
      </Box>
    </Box>
  )
}

import {
  Box,
  Flex,
  ListItem,
  OrderedList,
  Spinner,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { Button, Heading } from "DesignSystem/components"
import {
  RecruitMethodCard,
  RecruitMethodSelection,
} from "UsabilityHub/components/RecruitMethodSelection/RecruitMethodSelection"
import { ModeratedStudyRecruitmentLinkEntry } from "UsabilityHub/views/ModeratedStudy/interviewer/moderated-study-recruitment/moderated-study-recruitment-link-entry"
import { ROUTES } from "UsabilityHub/views/routes"
import { getDateString } from "Utilities/date-formats"
import { pluralize, pluralizeWithCount } from "Utilities/string"
import React from "react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import {
  useCreateModeratedStudiesRecruitmentLink,
  useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks,
  useGetModeratedStudyQuota,
} from "~/api/generated/usabilityhub-components"
import { useUsabilityhubContext } from "~/api/generated/usabilityhub-context"
import { useModeratedStudyContext } from "./ModeratedStudyContext"
import { PanelOrderList } from "./PanelOrderList"
import { SessionQuotaExhaustedBanner } from "./SessionQuotaExhaustedBanner"

export function ModeratedStudyRecruitPage() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { queryKeyFn } = useUsabilityhubContext()

  const { moderatedStudyId, invalidateStudySummaryQuery } =
    useModeratedStudyContext()

  const { data: recruitmentLinksData, isLoading } =
    useGetApiModeratedStudiesModeratedStudyIdRecruitmentLinks({
      pathParams: {
        moderatedStudyId: moderatedStudyId,
      },
    })
  const recruitmentLinks =
    recruitmentLinksData &&
    recruitmentLinksData.moderated_study_recruitment_links
  const ordersCount = 0

  const invalidateRecruitmentLinksQuery = async () => {
    await queryClient.invalidateQueries(
      queryKeyFn({
        path: "/api/moderated_studies/{moderated_study_id}/recruitment_links",
        operationId: "getApiModeratedStudiesModeratedStudyIdRecruitmentLinks",
        variables: {
          pathParams: {
            moderatedStudyId: moderatedStudyId,
          },
        },
      })
    )
  }

  const {
    mutate: createRecruitmentLink,
    isLoading: isCreatingRecruitmentLink,
  } = useCreateModeratedStudiesRecruitmentLink({
    onSuccess: (data) => {
      if (!data.moderated_study_recruitment_link_id) return

      void invalidateStudySummaryQuery()

      const editUrl = ROUTES.INTERVIEW.RECRUIT.LINK.buildPath({
        moderatedStudyId,
        moderatedStudyRecruitmentLinkId:
          data.moderated_study_recruitment_link_id,
      })
      navigate(editUrl)

      return invalidateRecruitmentLinksQuery()
    },
  })

  const panelMethodPrimaryAction = (
    <Button
      variant="primary"
      size="emphasized"
      as={Link}
      to={ROUTES.INTERVIEW.RECRUIT.ORDERS.buildPath({
        moderatedStudyId,
      })}
    >
      Place {ordersCount > 0 ? "another" : "an"} order
    </Button>
  )

  const linkMethodPrimaryAction = recruitmentLinks ? (
    <Button
      variant="primary"
      size="emphasized"
      isDisabled={isCreatingRecruitmentLink}
      onClick={() => {
        createRecruitmentLink({
          pathParams: {
            moderatedStudyId: moderatedStudyId,
          },
        })
      }}
    >
      Create {recruitmentLinks.length > 0 ? "another" : "a"} link
    </Button>
  ) : undefined

  return (
    <>
      <Helmet>
        <title>Recruit</title>
      </Helmet>

      <SessionQuotaExhaustedBanner />

      {isLoading && (
        <Flex
          mt="32"
          justifyContent="center"
          alignItems="center"
          direction="column"
          gap="4"
        >
          <Spinner size="lg" thickness="4px" color="gray.500" />
          Fetching...
        </Flex>
      )}

      {!isLoading && recruitmentLinks && (
        <main>
          <Box my="8" px="8" w="full" maxW="68rem" mx="auto">
            <RecruitMethodSelection>
              <RecruitMethodCard
                method="panel"
                bodyContent={
                  <Text textStyle="ds.paragraph.emphasized">
                    Access our global panel of 530k+ participants.
                  </Text>
                }
                action={panelMethodPrimaryAction}
                cornerBadge={<UnlimitedSessionsTag />}
              />

              <RecruitMethodCard
                method="link"
                bodyContent={
                  <Text textStyle="ds.paragraph.emphasized">
                    Use a link to invite participants from your network via any
                    channel you like.
                  </Text>
                }
                action={linkMethodPrimaryAction}
                cornerBadge={<SessionsRemainingTag />}
              />
            </RecruitMethodSelection>

            {recruitmentLinks.length > 0 && (
              <Box mt="8">
                <Heading as="h2" textStyle="ds.display.primary">
                  Recruitment activity
                </Heading>

                <Box my="4">
                  <OrderedList listStyleType="none" m="0" spacing="4">
                    {recruitmentLinks.map((recruitmentLink) => (
                      <ListItem
                        key={recruitmentLink.id}
                        bg="white"
                        borderRadius="md"
                        borderWidth="1px"
                        borderColor="gray.300"
                      >
                        <Box
                          as={Link}
                          display="block"
                          to={ROUTES.INTERVIEW.RECRUIT.LINK.buildPath({
                            moderatedStudyId,
                            moderatedStudyRecruitmentLinkId: recruitmentLink.id,
                          })}
                          _hover={{
                            bg: "blackAlpha.100",
                          }}
                          p={5}
                        >
                          <ModeratedStudyRecruitmentLinkEntry
                            recruitmentLink={recruitmentLink}
                            moderatedStudyId={moderatedStudyId}
                          />
                        </Box>
                      </ListItem>
                    ))}
                  </OrderedList>
                </Box>
              </Box>
            )}

            <PanelOrderList
              moderatedStudyId={moderatedStudyId}
              orders={recruitmentLinksData.moderated_study_orders}
            />
          </Box>
        </main>
      )}
    </>
  )
}

const UnlimitedSessionsTag: React.FC = () => {
  return (
    <Tooltip
      hasArrow
      rounded="md"
      label="Sessions booked from the Lyssna panel don’t count towards your session limit."
    >
      <Tag pos="absolute" top={4} right={4} cursor="help">
        Unlimited sessions
      </Tag>
    </Tooltip>
  )
}
const SessionsRemainingTag: React.FC = () => {
  const { data } = useGetModeratedStudyQuota({})

  if (!data) return null

  const sessionsRemaining = Math.max(
    data.self_recruited_sessions.quota - data.self_recruited_sessions.used,
    0
  )

  const tooltipText =
    sessionsRemaining > 0
      ? `You can collect as many applicants and send as many invitations as you like, but only ${sessionsRemaining} more ${pluralize(
          sessionsRemaining,
          "participant",
          "participants"
        )} from your network, across all active studies, can book a session before your limit resets on ${getDateString(
          data.period_end
        )}.`
      : `You can continue collecting applicants, but no new sessions can be booked until your balance renews on ${getDateString(
          data.period_end
        )}.`

  return (
    <Tooltip hasArrow rounded="md" label={tooltipText}>
      <Tag pos="absolute" top={4} right={4} cursor="help">
        {pluralizeWithCount(sessionsRemaining, "session", "sessions")} left
      </Tag>
    </Tooltip>
  )
}

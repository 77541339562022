import { Box, HStack, Icon, Text, Tooltip } from "@chakra-ui/react"
import React from "react"

import { getSectionTypeRules } from "Constants/test-section-types"
import { useFeatureFlag } from "Hooks/use-feature-flag"
import { PlaySquareSolidIcon } from "Shared/icons/untitled-ui/PlaySquareSolidIcon"
import { UsabilityTestSectionType } from "Types"
import { AnimatingBadge } from "UsabilityHub/components/AnimatingBadge/AnimatingBadge"
import { RECORDING_KEY } from "UsabilityHub/views/settings/FeatureAccessPage/FeatureAccessForm"
import { useListDisabledFeatures } from "~/api/generated/usabilityhub-components"

interface AddSectionCardButtonProps {
  sectionType: UsabilityTestSectionType
  onAddSection: (sectionType: UsabilityTestSectionType) => void
}

export const AddSectionCardButton: React.FC<
  React.PropsWithChildren<AddSectionCardButtonProps>
> = ({ sectionType, onAddSection }) => {
  const {
    badgeText,
    colorScheme,
    defaultTitle: title,
    description,
    icon,
    qaId,
    isRecordable,
  } = getSectionTypeRules(sectionType)
  const recordingFeatureFlagEnabled = useFeatureFlag(
    "usability_test_recordings"
  )

  const { data: disabledFeatures } = useListDisabledFeatures({})
  // We won't display the record button if the api is still fetching data
  const isRecordingDisabled =
    disabledFeatures === undefined ||
    disabledFeatures.disabled_features.includes(RECORDING_KEY)
  const isSectionRecordingEnabled =
    isRecordable && !isRecordingDisabled && recordingFeatureFlagEnabled

  return (
    <HStack
      data-qa={`add-${qaId}-button`}
      as="button"
      type="button" // Prevent form submit
      spacing={4}
      alignItems="flex-start"
      textAlign="left"
      padding={5}
      sx={{
        "& .isRecordableTooltip": {
          visibility: "hidden",
        },
        "&:hover .isRecordableTooltip, &:focus .isRecordableTooltip, &:focus-within .isRecordableTooltip":
          {
            visibility: "visible",
          },
      }}
      _hover={{ bg: "gray.100" }}
      _focus={{ bg: "gray.100" }}
      _active={{ bg: "gray.200" }}
      borderRadius="md"
      onClick={() => onAddSection(sectionType)}
    >
      <Icon as={icon} boxSize={8} flexShrink={0} color={colorScheme} />
      <Box flex="1">
        <HStack>
          <Text as="span" lineHeight={6} fontWeight="medium" color="gray.600">
            {title}
          </Text>
          {badgeText && (
            <AnimatingBadge colorScheme="pink">{badgeText}</AnimatingBadge>
          )}
          {isSectionRecordingEnabled && (
            <Tooltip
              label="This section supports recording"
              aria-label={`${title} sections support recording`}
              placement="top"
              borderRadius={4}
            >
              <Icon
                as={PlaySquareSolidIcon}
                boxSize={5}
                flexShrink={0}
                color="ds.icon.subtle"
                ml="auto"
                className="isRecordableTooltip"
                // allows the tooltip to be opened when keyboard navigating
                tabIndex={0}
              />
            </Tooltip>
          )}
        </HStack>
        <Text as="span" fontSize="sm" color="gray.500">
          {description}
        </Text>
      </Box>
    </HStack>
  )
}
